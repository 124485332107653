import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Button from "../../../../../../../../components/Button";
import Textarea from "../../../../../../../../components/Textarea";
import { ViewType } from "../../types";
import { EditActionsContainer, EditCommentContainer } from "./styles";

interface IEditComment {
  editComment: () => Promise<void>;
  setView: Dispatch<SetStateAction<ViewType>>;
  commentText: string;
  handleCommentTextChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function EditComment({ editComment, setView, commentText, handleCommentTextChange}: IEditComment) {
  return (
    <EditCommentContainer>
      <Textarea
        value={commentText}
        onChange={handleCommentTextChange}
        placeholder="O que você deseja comentar sobre essa publicação?"
        height={100}
      />

      <EditActionsContainer>
        <Button cancel small onClick={() => setView('default')}>
          Cancelar
        </Button>

        <Button small onClick={() => editComment()} disabled={!commentText}>
          Confirmar
        </Button>
      </EditActionsContainer>
    </EditCommentContainer>
  )
}
