import { Container } from './styles';
import dateWith0 from '../../../../../../utils/dateWith0';

interface TitleInterface {
  status: string;
  registerDate: string;
  activateDate: string;
}

export default function Title({
  status,
  registerDate,
  activateDate,
}: TitleInterface) {
  return (
    <Container>
      {dateWith0(registerDate.split(' ')[0]) === dateWith0(activateDate)
        ? (
          <h1 className="green">
            {status}
            {' '}
            cadastrado e ativado em
            {' '}
            {dateWith0(registerDate.split(' ')[0])}
          </h1>
        )
        : (
          <h1 className={!(status === 'Cadastrado') ? 'green' : ''}>
            {status === 'Cadastrado' ? 'Cadastrado em' : `${status} cadastrado em`}
            {' '}
            {dateWith0(registerDate.split(' ')[0])}
            {activateDate && (
            <>
              , plano ativado em
              {' '}
              {dateWith0(activateDate)}
            </>
            )}
          </h1>
        )}
    </Container>
  );
}
