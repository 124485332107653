import { Dispatch, SetStateAction } from 'react';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { Header } from './styles';
import { Link } from 'react-router-dom';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  downloadExcel: () => void;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
  setModalImportCsvShow: Dispatch<SetStateAction<boolean>>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  downloadExcel,
  setAddModalShow,
  setModalImportCsvShow,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <>
            <div className="leftAlignment">
              <strong>
                {filteredListLength}
                {' '}
                {filteredListLength === 1 ? singularLabel : pluralLabel}
              </strong>
            </div>
          </>
        )}

          <div className="actionButtons">
            {doesListExists && (
              <button type="button" onClick={downloadExcel}>
                Download XLSX
              </button>
            )}
              <Link to="/files/Modelo importar categorias Kiddlepass.csv" target="_blank" download>
                Exemplo CSV
              </Link>
              <button type="button" onClick={() => setModalImportCsvShow(true)}>
                Importar CSV
              </button>
              <button type="button" onClick={() => setAddModalShow(true)}>
                Nova Categoria
              </button>
          </div>
      </Header>
    </OpacityAnimation>
  );
}
