import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import MyModal from '../../../../../components/Modal';
import { MonthsGrid } from '../styles';

type MonthsNumbersType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ChangeDateModalInterface {
  dateModalShow: boolean;
  setSelectedYear: Dispatch<SetStateAction<number>>;
  setSelectedMonth: Dispatch<SetStateAction<number | ''>>;
  selectedYear: number;
  selectedMonth: number | '';
  currentYear: number;
  currentMonth: number | string;
  setCurrentMonthBeingShow: Dispatch<SetStateAction<number | ''>>;
  setCurrentYearBeingShow: Dispatch<SetStateAction<number>>;
  setDateModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function ChangeDateModal({
  currentYear,
  dateModalShow,
  selectedYear,
  setSelectedYear,
  setSelectedMonth,
  selectedMonth,
  currentMonth,
  setCurrentMonthBeingShow,
  setCurrentYearBeingShow,
  setDateModalShow,
}: ChangeDateModalInterface) {
  const monthsMap: { [key: string]: string } = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const startDate = '01/04/2023';
  const startYear = Number(startDate.split('/')[2]);
  const startMonth = Number(startDate.split('/')[1]);
  const yearsArraySinceStart = [];
  for (let i = startYear; i <= currentYear; i++) {
    yearsArraySinceStart.push(i);
  }

  return (
    <MyModal
      show={dateModalShow}
      type="action"
      title="Alterar mês de visualização dos dados"
      modalBody={(
        <div className="modal-body-centered">
          <div className="year-selection">
            {yearsArraySinceStart.map((year) => (
              <FilterRadioButton
                key={year}
                onClick={() => {
                  setSelectedYear(year);
                  setSelectedMonth('');
                }}
                selected={selectedYear == year}
              >
                {year}
              </FilterRadioButton>
            ))}
          </div>
          <MonthsGrid>
            {months.map((month) => (
              <FilterRadioButton
                key={month}
                onClick={() => setSelectedMonth(Number(month))}
                selected={month == selectedMonth}
                invisible={
                      (Number(`${selectedYear}${(month)}`)
                      > Number(`${currentYear}${currentMonth}`))
                      || (Number(`${startYear}${startMonth}`)
                      > Number(`${selectedYear}${Number(month)}`))
                    }
              >
                {monthsMap[Number(month)]}
              </FilterRadioButton>
            ))}
          </MonthsGrid>
        </div>
)}
      size="lg"
      onAction={() => {
        setCurrentMonthBeingShow(Number(selectedMonth) as MonthsNumbersType);
        setCurrentYearBeingShow(selectedYear);
        setDateModalShow(false);
      }}
      onClose={() => setDateModalShow(false)}
      actionButtonLabel="Alterar mês"
      closeButtonLabel="Cancelar"
    />
  );
}
