/* eslint-disable react/forbid-prop-types */
import { VictoryPie } from 'victory';
import { motion } from 'framer-motion';

import { Card } from '../../styles';
import NoData from '../../../../../components/NoData';

interface KidsAgeCardInterface {
  ages?: {
    '0-2': number;
    '3-5': number;
    '6-8': number;
    '9-12': number;
  }
}

export default function KidsAgeCard({ ages }: KidsAgeCardInterface) {
  let hasSomeInfo = false;
  if (!ages) {
    hasSomeInfo = false;
  }
  if (ages) {
    hasSomeInfo = !!ages['0-2'] || !!ages['3-5'] || !!ages['6-8'] || !!ages['9-12'];
  }
  return (
    <Card>
      <div className="card-title">
        <div>Idade das crianças</div>
      </div>

      {!!ages && !!hasSomeInfo && (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 1.05, type: 'tween', stiffness: 10000 }}
          className="card-main-graph"
        >
          <VictoryPie
            data={[
              {
                x: 1,
                y: ages['0-2'],
                label: `0-2: ${ages['0-2']} %`,
              },
              {
                x: 2,
                y: ages['3-5'],
                label: `3-5: ${ages['3-5']} %`,
              },
              {
                x: 3,
                y: ages['6-8'],
                label: `6-8: ${ages['6-8']} %`,
              },
              {
                x: 4,
                y: ages['9-12'],
                label: `9-12: ${ages['9-12']} %`,
              },
            ]}
            animate={{
              duration: 2000,
              onLoad: { duration: 5000 },
            }}
            colorScale={['#8b9a71', '#ff6361', '#ffa600', '#003f5c']}
            innerRadius={70}
            height={162}
            padAngle={0}
            padding={0}
          />
        </motion.div>
        <div className="legend">
          <div>
            <small className="green">
              ● 0-2:
              {' '}
              {ages['0-2']}
              %
            </small>
            <small className="red">
              ● 3-5:
              {' '}
              {ages['3-5']}
              %
            </small>
          </div>
          <div>
            <small className="orange">
              ● 6-8:
              {' '}
              {ages['6-8']}
              %
            </small>
            <small className="blue">
              ● 9-12:
              {' '}
              {ages['9-12']}
              %
            </small>
          </div>
        </div>
      </>
      )}

      {(!ages || !hasSomeInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre a faixa etária das crianças
          </>
                  )}
      />
      )}
    </Card>
  );
}
