/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import NoData from '../../../../../components/NoData';
import useThemeContext from '../../../../../contexts/theme';
import { AsideContainer, Group, Container as StyledContainer } from '../styles';
import { ActivityType, DateTimeOption, DateTimeType, PartnerType } from '../types';

interface GeneralDataCardInterface {
  selectedActivity: ActivityType;
  selectedPartner: PartnerType;
  selectedDateTime: DateTimeOption;
  partnerOptions: PartnerType[];
  setSelectedPartner: Dispatch<SetStateAction<PartnerType>>;
  selectedFeatPartner: PartnerType,
  setSelectedFeatPartner: Dispatch<SetStateAction<PartnerType>>,
  activityOptions: ActivityType[];
  setSelectedActivity: Dispatch<SetStateAction<ActivityType>>;
  dateTimeOptions: DateTimeType;
  setSelectedDateTime: Dispatch<SetStateAction<DateTimeOption>>;
  isEdit: boolean;
}

export default function GeneralDataCard({
  selectedActivity,
  selectedPartner,
  selectedDateTime,
  partnerOptions,
  setSelectedPartner,
  activityOptions,
  setSelectedActivity,
  dateTimeOptions,
  setSelectedDateTime,
  selectedFeatPartner,
  setSelectedFeatPartner,
  isEdit,
}: GeneralDataCardInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Atividade *
          </div>
          <Select
            value={{ value: selectedActivity?.value, label: selectedActivity?.label }}
            options={activityOptions}
            onChange={(opt) => {
              const correspondingActivity = activityOptions.find((activity) => activity.value === opt!.value)!;
              setSelectedActivity(correspondingActivity);

              if (!correspondingActivity.ativo) {
                toast.warn('Essa atividade atualmente não está ativa. Tem certeza que deseja incluí-la como uma aula realizada?');
              }
              setSelectedPartner({ value: correspondingActivity.partner.id, label: correspondingActivity.partner.nome_fantasia});
              if (correspondingActivity.featPartner) {
                setSelectedFeatPartner({ value: correspondingActivity.featPartner.id, label: correspondingActivity.featPartner.nome_fantasia });
              } else {
                setSelectedFeatPartner({ value: '', label: 'Selecione um Parceiro' });
              }
            }}
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
            isDisabled={isEdit}
          />
        </Group>
        <Group>
          <div className="title">
            Parceiro *
          </div>
          <Select
            value={{ value: selectedPartner?.value, label: selectedPartner?.label }}
            options={partnerOptions}
            onChange={(opt) => {
              setSelectedPartner(opt!);
            }}
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        {!!selectedActivity.featPartner && (
          <Group>
            <div className="title">
              Parceiro Secundário
            </div>
            <Select
              value={{ value: selectedFeatPartner?.value, label: selectedFeatPartner?.label }}
              options={partnerOptions.filter((partner) => partner.value !== selectedPartner.value)}
              onChange={(opt) => {
                setSelectedFeatPartner(opt!);
              }}
              styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </Group>
        )}
      </AsideContainer>
      {!isEdit && (
        <Group>
          <div className="title">
            Data e hora
          </div>
          {selectedActivity.value ? (
            <Container>
              <Row xs={1} md={2} lg={6}>
                {dateTimeOptions[selectedActivity.value].map((opt) => (
                  <Col key={opt.id}>
                    <FilterRadioButton disabled={isEdit} nowrap smallText onClick={() => setSelectedDateTime(opt)} selected={opt.id === selectedDateTime.id} key={opt.id}>
                      {`${opt.date} | ${opt.startTime} - ${opt.endTime}`}
                    </FilterRadioButton>
                  </Col>
                ))}
              </Row>
            </Container>
          ) : (
            <NoData icon='emptyBox' label={<>Selecione uma atividade, para após selecionar a data e horário em que a mesma foi realizada,</>} />
          )
        }
        </Group>
      )}
    </StyledContainer>
  );
}
