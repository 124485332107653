import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useErrors from '../../../../../../hooks/useErrors';
import formatCep from '../../../../../../utils/formatCep';
import formatCpf from '../../../../../../utils/formatCpf';
import formatPhone from '../../../../../../utils/formatPhone';
import isCpfvalid from '../../../../../../utils/isCpfValid';
import isDateBeforeToday from '../../../../../../utils/isDateBeforeToday';
import isEmailValid from '../../../../../../utils/isEmailValid';

export default function useInputChanges() {
  const [registerDate, setRegisterDate] = useState('');
  const [activateDate, setActivateDate] = useState('');
  const [status, setStatus] = useState('');

  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [company, setCompany] = useState('');
  const [cupom, setCupom] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [gender, setGender] = useState({ value: 'Prefiro não informar', label: 'Prefiro não informar' });

  const [cep, setCep] = useState('');
  const [streetName, setStreetName] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [isGettingCepInfo, setIsGettingCepInfo] = useState(false);

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  function handleCpfChange(event: ChangeEvent<HTMLInputElement>) {
    setCpf(formatCpf(event.target.value));
    if (!event.target.value || !isCpfvalid(formatCpf(event.target.value))) {
      setError({ field: 'cpf', message: 'Informe um cpf válido!' });
    } else {
      removeError('cpf');
    }
  }

  function handleCellphoneChange(event: ChangeEvent<HTMLInputElement>) {
    setCellphone(formatPhone(event.target.value));
    if (!event.target.value) {
      setError({ field: 'cellphone', message: 'Celular é obrigatório!' });
    } else if (event.target.value.length < 14) {
      setError({ field: 'cellphone', message: 'Celular inválido!' });
    } else {
      removeError('cellphone');
    }
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome do parceiro é obrigatório!' });
    } else {
      removeError('name');
    }
  }

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    if (!event.target.value) {
      setError({ field: 'email', message: 'E-mail é obrigatório!' });
    } else if (!isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido!' });
    } else {
      removeError('email');
    }
  }

  function handleBirthDateChange(event: ChangeEvent<HTMLInputElement>) {
    setBirthDate(event.target.value);
    if (!event.target.value) {
      setError({ field: 'birthDate', message: 'Data de nascimento é obrigatória!' });
    } else if (!isDateBeforeToday(event.target.value)) {
      setError({ field: 'birthDate', message: 'Data de nascimento inválida!' });
    } else {
      removeError('birthDate');
    }
  }

  const handleCepChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setCep(formatCep(event.target.value));
      if (!event.target.value) {
        setError({ field: 'cep', message: 'CEP é obrigatório!' });
      }
      if (formatCep(event.target.value).length === 9) {
        setIsGettingCepInfo(true);
        const response = await fetch(`https://viacep.com.br/ws/${event.target.value}/json/`);
        const cepInfo = await response.json();
        if (cepInfo.erro) {
          setError({ field: 'cep', message: 'CEP inválido!' });
          setStreetName('');
          setDistrict('');
          setCity('');
          setUf('');
          return;
        }
        setStreetName(cepInfo.logradouro);
        setDistrict(cepInfo.bairro);
        setCity(cepInfo.localidade);
        setUf(cepInfo.uf);
      }
      if (formatCep(event.target.value).length !== 9) {
        setError({ field: 'cep', message: 'CEP inválido!' });
        setStreetName('');
        setDistrict('');
        setCity('');
        setUf('');
        return;
      }
      removeError('cep');
    } catch (error) {
      toast.error(`Ocorreu um erro ao buscar o CEP (${error})`);
    } finally {
      setIsGettingCepInfo(false);
    }
  }, [removeError, setError]);

  return {
    registerDate,
    setRegisterDate,
    activateDate,
    setActivateDate,
    status,
    setStatus,
    name,
    setName,
    cpf,
    setCpf,
    company,
    setCompany,
    cupom,
    setCupom,
    email,
    setEmail,
    birthDate,
    setBirthDate,
    cellphone,
    setCellphone,
    gender,
    setGender,
    cep,
    setCep,
    streetName,
    setStreetName,
    district,
    setDistrict,
    city,
    setCity,
    uf,
    setUf,
    getErrorMessageByFieldName,
    errors,
    handleCpfChange,
    handleCellphoneChange,
    handleNameChange,
    handleEmailChange,
    handleBirthDateChange,
    handleCepChange,
    isGettingCepInfo,
  };
}
