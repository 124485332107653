import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import { UsersRegionsType } from '../../types';
import { UseDashboardDataInterface } from '../interfaces';

export default function useUsersRegions({ setHasError }: UseDashboardDataInterface) {
  const [regionsData, setRegionsData] = useState<UsersRegionsType>({});

  const { apiCall } = useApiCall();

  const getUsersRegions = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersRegions,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar os dados de maior utilização por região', {
            toastId: 'getUsersDashboardFail',
          });
          return;
        }
        const usersRegions = apiResponse.cities;
        setRegionsData(usersRegions);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar os dados de maior utilização por região'
    })
  }, [apiCall, setHasError]);

  return {
    regionsData,
    getUsersRegions,
  };
}
