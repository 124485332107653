import { ContentContainer } from "./styles";

interface IContent {
  text: string;
}

export default function Content({ text }: IContent) {
  return (
    <ContentContainer>
      <div>
        {text}
      </div>
    </ContentContainer>
  )
}
