import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0px;
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 4px;
  border-radius: 4px;

  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-weight: bold;
    div {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 15px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      margin-left: 4px;
      &:hover {
        opacity: 0.7;
      }
    }
    .download{
      img{
        width: 20px;
      }
    }
  }

  .card-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 2px;
    div {
      font-weight: bold;
      font-size: 24px;
    }
    small {
      font-size: 12px;
    }
  }
`;
