/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { addHours, format } from 'date-fns';
import {
  Dispatch,
  SetStateAction,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import { useQuery } from '../../../../hooks/useQuery';
import activitiesService from '../../../../services/activitiesService';
import partnersService from '../../../../services/partnersService';
import floatToCurrency from '../../../../utils/floatToCurrency';
import { Value } from '../../types/CalendarTypes';
import { ActivityDetailsFromApiResponse, CategoriesFromApiResponse, CategoriesType, IsRecurrentType, LevelType, NeedsCompanionType, PartnerType, PartnersFromApiResponse, SubcategoriesType, SubcategoryType } from '../../types/FormTypes';
import { TimetableTranslatedType } from '../../types/TimetableTranslatedType';
import { DayBeingAddedAtTimetableType, DayInfoBeingShowType } from '../../types/TimetableTypes';
import toPersistenceTimetable from '../../utils/toPersistenceTimetable';

interface UseLoadFormInterface {
  setKindOfActivity: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setPartner: Dispatch<SetStateAction<PartnerType>>;
  setFeatPartner: Dispatch<SetStateAction<PartnerType>>;
  setCategory: Dispatch<SetStateAction<CategoriesType>>;
  setSubcategory: Dispatch<SetStateAction<SubcategoryType>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setMethodology: Dispatch<SetStateAction<string>>;
  setOrientations: Dispatch<SetStateAction<string>>;
  setVideoLink: Dispatch<SetStateAction<string>>;
  setIsRecurrent: Dispatch<SetStateAction<IsRecurrentType>>;
  setVideoFile: Dispatch<SetStateAction<string | File | undefined>>;
  setImageFile: Dispatch<SetStateAction<string | File | undefined>>;
  setMinAge: Dispatch<SetStateAction<number | string>>;
  setMaxAge: Dispatch<SetStateAction<number | string>>;
  setNeedsCompanion: Dispatch<SetStateAction<NeedsCompanionType>>;
  setNecessaryMaterials: Dispatch<SetStateAction<string>>;
  setClothes: Dispatch<SetStateAction<string>>;
  setLevel: Dispatch<SetStateAction<LevelType>>;
  setNecessaryKnowLedge: Dispatch<SetStateAction<string>>;
  setDayInfoBeingShow: Dispatch<SetStateAction<DayInfoBeingShowType>>;
  setDayBeingAddedAtTimetable: Dispatch<SetStateAction<DayBeingAddedAtTimetableType>>;
  setStartTimeBeingAdded: Dispatch<SetStateAction<string>>;
  setEndTimeBeingAdded: Dispatch<SetStateAction<string>>;
  setVacanciesQty: Dispatch<SetStateAction<number | string>>;
  setTimetable: Dispatch<SetStateAction<TimetableTranslatedType>>;
  setOriginalDates: Dispatch<SetStateAction<string[]>>;
  setUpdatedDates: Dispatch<SetStateAction<string[]>>;
  setDuration: Dispatch<SetStateAction<number>>;
  setSpecificPeriod: Dispatch<SetStateAction<Value>>
  setSpecificPeriodLabel: Dispatch<SetStateAction<string>>
  category: {
    value: string;
    label: string;
  };
  approvement?: boolean;
  setSelectedTags: Dispatch<SetStateAction<MultiValue<{ value: string, label: string}>>>;
  setIsFree: Dispatch<SetStateAction<boolean>>;
  setClassValue: Dispatch<SetStateAction<string | number>>;
}

export default function useLoadForm({
  setKindOfActivity,
  setName,
  setPartner,
  setFeatPartner,
  setCategory,
  setSubcategory,
  setDescription,
  setMethodology,
  setOrientations,
  setVideoLink,
  setIsRecurrent,
  setVideoFile,
  setImageFile,
  setMinAge,
  setMaxAge,
  setNeedsCompanion,
  setNecessaryMaterials,
  setClothes,
  setLevel,
  setNecessaryKnowLedge,
  setDayInfoBeingShow,
  setDayBeingAddedAtTimetable,
  setStartTimeBeingAdded,
  setEndTimeBeingAdded,
  setVacanciesQty,
  setTimetable,
  setOriginalDates,
  setUpdatedDates,
  setDuration,
  category,
  approvement,
  setSpecificPeriod,
  setSpecificPeriodLabel,
  setSelectedTags,
  setIsFree,
  setClassValue,
}: UseLoadFormInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(true);
  const [categories, setCategories] = useState<CategoriesType[]>([]);
  const [partners, setPartners] = useState<PartnerType[]>([]);
  const [subcategories, setSubcategories] = useState<SubcategoriesType[]>([]);

  const query = useQuery();

  const returnLink = query.has('return') ? `/${query.get('return')}` : (
    approvement ? '/activitiesApprove' : '/activities'
  );

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const subcategoriesOptions = useMemo(() => {
    const filteredSubcategoriesByCategory = subcategories.filter((sbctg) => (
      sbctg.category === category.label
    ));
    const mappedSubcategories = filteredSubcategoriesByCategory.map((sbctg) => (
      { label: sbctg.subcategory, value: sbctg.id }
    ));

    return mappedSubcategories;
  }, [category.label, subcategories]);

  const companionOptions = [
    { value: true, label: 'Necessário' },
    { value: false, label: 'Não necessário' },
  ];

  const recurrentOptions = [
    { value: true, label: 'Recorrente' },
    { value: false, label: 'Não recorrente' },
  ];

  const levelOptions = [
    { value: 'Iniciante', label: 'Iniciante' },
    { value: 'Intermediário', label: 'Intermediário' },
    { value: 'Avançado', label: 'Avançado' },
    { value: null, label: 'Não especificado' },
  ];

  const getCategories = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getCategories,
      catchMessage: 'Não foi possível abrir o formulário de inclusão de atividade. Por favor, tente novamente',
      actionAfterResponse: (apiResponse) => {
        const categoriesListFromApi: CategoriesFromApiResponse = apiResponse.categories;
        const categoriesOptions = [{ value: 'Atividades Educacionais', label: 'Atividades Educacionais'}, { value: "Atividades Físicas", label: 'Atividades Físicas'}, { value: 'Entretenimento', label: 'Entretenimento' }]

        const subcategoriesMap = categoriesListFromApi.map((ctg) => ({
          category: ctg.type, subcategory: ctg.name, id: ctg.id
        }));
        const subcategoriesFlatted = subcategoriesMap.flat();

        setCategories(categoriesOptions);
        setSubcategories(subcategoriesFlatted);
      },
      catchAction: () => navigate(returnLink),
    })
  }, [apiCall, navigate, returnLink]);

  const getPartners = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getResumedPartnersList,
      catchMessage: 'Não foi possível abrir o formulário de inclusão de atividade. Por favor, tente novamente',
      actionAfterResponse: (apiResponse) => {
        const partnersListFromApi: PartnersFromApiResponse = apiResponse.list;
        const partnersOptions = partnersListFromApi.map((prtn) => (
          { value: prtn.id, label: prtn.nome_fantasia }
        ));
        partnersOptions.shift();

        setPartners(partnersOptions);
      },
      catchAction: () => {
        navigate(returnLink);
      }
    })
  }, [apiCall, navigate, returnLink]);

  const loadActivity = useCallback(async () => {
    await apiCall({
      apiToCall: approvement ? activitiesService.getActivityApprovementDetails : activitiesService.getActivityDetails,
      queryParams: { id },
      actionAfterResponse: (apiResponse : ActivityDetailsFromApiResponse) => {
        if (!apiResponse.details) {
          toast.error(`Não foi possível carregar a atividade (${apiResponse.erro})`, {
            toastId: 'getActivityFail',
          });
          navigate(returnLink);
          return;
        }
        const activityDetails = apiResponse.details;
        const needsCompanionBoolean = activityDetails.acompanhante ? (
          activityDetails.acompanhante !== 'false'
        ) : false;
        const activityKind = activityDetails.tipo === 'record' ? 'video' : 'online';
        setKindOfActivity(activityKind);
        setName(activityDetails.nome);
        setPartner({ value: activityDetails.partner.id, label: activityDetails.partner.nome_fantasia });
        setFeatPartner({ value: activityDetails.featPartner?.id || null, label: activityDetails.featPartner?.nome_fantasia || 'Selecione um parceiro' });
        setCategory({
          value: activityDetails.categoria,
          label: activityDetails.categoria,
        });
        setIsFree(activityDetails.isFree);
        setSubcategory({ value: activityDetails.category?.id, label: activityDetails.subcategoria });
        setDescription(activityDetails.descricao);
        setMethodology(activityDetails.metodologia);
        setOrientations(activityDetails.orientacoes);
        setVideoLink(activityDetails.videoUrl);
        setIsRecurrent({ value: activityDetails.recorrente, label: activityDetails.recorrente ? 'Recorrente' : 'Não recorrente' });
        setVideoFile(activityKind === 'video' ? activityDetails.videoUrl : '');
        setImageFile(activityDetails.cover);
        setMinAge(activityDetails.minima);
        setMaxAge(activityDetails.maxima);
        setNeedsCompanion({
          value: needsCompanionBoolean,
          label: !needsCompanionBoolean ? 'Não necessário' : 'Necessário',
        });
        setNecessaryMaterials(activityDetails.levar);
        setClothes(activityDetails.vestuario);
        setLevel({
          value: activityDetails.nivel || null,
          label: activityDetails.nivel || 'Não informado',
        });
        setDuration(Number(activityDetails.duracao) ?? 30);
        setNecessaryKnowLedge(activityDetails.conhecimento);
        setDayInfoBeingShow({} as DayInfoBeingShowType);
        setDayBeingAddedAtTimetable({ value: null, label: 'Selecione um dia' });
        setStartTimeBeingAdded('');
        setEndTimeBeingAdded('');
        setVacanciesQty('');
        setTimetable(toPersistenceTimetable(activityDetails.grade));
        setOriginalDates(activityDetails.datasDesabilitadas || []);
        setUpdatedDates(activityDetails.datasDesabilitadas || []);
        setSelectedTags(activityDetails.tags?.map((tag) => ({ value: tag, label: tag })) || []);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setClassValue(floatToCurrency(activityDetails.classValue || 0)!);

        if (activityDetails.specificPeriod && (activityDetails.specificPeriod?.length > 0)) {
          const parsedDates = activityDetails.specificPeriod.map((date) => new Date(date))
          console.log(parsedDates);
          setSpecificPeriod([parsedDates[0], parsedDates[1]]);
          const periodLabel = `De ${format(addHours(parsedDates[0], 3) as Date, 'dd/MM/yyyy')} até ${format(addHours(parsedDates[1], 3) as Date, 'dd/MM/yyyy')}`
          setSpecificPeriodLabel(periodLabel);
        }
      },
      catchAction: () => { navigate(returnLink); },
    })
  }, [apiCall, approvement, id, navigate, returnLink, setCategory, setClassValue, setClothes, setDayBeingAddedAtTimetable, setDayInfoBeingShow, setDescription, setDuration, setEndTimeBeingAdded, setFeatPartner, setImageFile, setIsFree, setIsRecurrent, setKindOfActivity, setLevel, setMaxAge, setMethodology, setMinAge, setName, setNecessaryKnowLedge, setNecessaryMaterials, setNeedsCompanion, setOrientations, setOriginalDates, setPartner, setSelectedTags, setSpecificPeriod, setSpecificPeriodLabel, setStartTimeBeingAdded, setSubcategory, setTimetable, setUpdatedDates, setVacanciesQty, setVideoFile, setVideoLink]);

  const loadForm = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      await Promise.all([
        getCategories(),
        getPartners(),
      ]);
      if (id) {
        await loadActivity();
      }
    } catch (error) {
      toast.error(`Não foi possível carregar o formulário. Por favor, tente novamente (${error})`);
      navigate(returnLink);
    } finally {
      setIsLoadLoading(false);
    }
  }, [getCategories, getPartners, id, loadActivity, navigate, returnLink]);

  useEffect(() => {
    loadForm();
  }, [getCategories, getPartners, id, loadActivity, loadForm]);

  return {
    isLoadLoading,
    categories,
    partners,
    subcategories,
    subcategoriesOptions,
    companionOptions,
    recurrentOptions,
    levelOptions,
  };
}
