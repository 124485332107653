import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import Textarea from '../../../../../components/Textarea';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';
import { ChangeEvent } from 'react';

interface GeneralDataCardInterface {
  kindOfPartner: string;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  cpf: string;
  handleCpfChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cnpj: string;
  handleCnpjChange: (event: ChangeEvent<HTMLInputElement>) => void;
  corporateName: string;
  handleCorporateNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fantasyName: string;
  handleFantasyNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function GeneralDataCard({
  kindOfPartner,
  getErrorMessageByFieldName,
  cpf,
  handleCpfChange,
  name,
  handleNameChange,
  cnpj,
  handleCnpjChange,
  corporateName,
  handleCorporateNameChange,
  fantasyName,
  handleFantasyNameChange,
  description,
  handleDescriptionChange,
}: GeneralDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        {kindOfPartner === 'cpf' && (
          <>
            <Group>
              <div className="title">
                CPF *
              </div>
              <FormGroup error={getErrorMessageByFieldName('cpf')}>
                <Input
                  value={cpf}
                  onChange={handleCpfChange}
                  error={getErrorMessageByFieldName('cpf')}
                  placeholder="Informe o CPF"
                  maxLength={14}
                />
              </FormGroup>
            </Group>
            <Group>
              <div className="title">
                Nome completo *
              </div>
              <FormGroup error={getErrorMessageByFieldName('name')}>
                <Input
                  value={name}
                  onChange={handleNameChange}
                  error={getErrorMessageByFieldName('name')}
                  placeholder="Informe nome e sobrenome"
                />
              </FormGroup>
            </Group>
          </>
        )}
        {kindOfPartner === 'cnpj' && (
          <>
            <Group>
              <div className="title">
                CNPJ *
              </div>
              <FormGroup error={getErrorMessageByFieldName('cnpj')}>
                <Input
                  value={cnpj}
                  onChange={handleCnpjChange}
                  error={getErrorMessageByFieldName('cnpj')}
                  placeholder="Informe um CNPJ"
                  maxLength={18}
                />
              </FormGroup>
            </Group>
            <Group>
              <div className="title">
                Razão Social *
              </div>
              <FormGroup error={getErrorMessageByFieldName('corporateName')}>
                <Input
                  value={corporateName}
                  onChange={handleCorporateNameChange}
                  error={getErrorMessageByFieldName('corporateName')}
                  placeholder="Informe a razão social da empresa"
                />
              </FormGroup>
            </Group>
          </>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Descrição *
          </div>
          <FormGroup error={getErrorMessageByFieldName('description')}>
            <Textarea
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Uma breve descrição sobre seu propósito, motivações, história, estrutura... É o momento de encantar!"
              height={113}
            />
          </FormGroup>
        </Group>
        {kindOfPartner === 'cnpj' && (
          <Group>
            <div className="title">
              Nome fantasia *
            </div>
            <FormGroup error={getErrorMessageByFieldName('fantasyName')}>
              <Input
                value={fantasyName}
                onChange={handleFantasyNameChange}
                error={getErrorMessageByFieldName('fantasyName')}
                placeholder="Informe o nome fantasia da empresa"
              />
            </FormGroup>
          </Group>
        )}
      </AsideContainer>
    </StyledContainer>
  );
}
