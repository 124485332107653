import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { MappedCategoriesType } from "../../types";
import useApiCall from "../../../../../hooks/useApiCall";
import categoriesService from "../../../../../services/categoriesService";
import { toast } from "react-toastify";

interface IUseLoadCategories {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function useLoadCategories({ setIsLoading }: IUseLoadCategories) {
  const [categories, setCategories] = useState<MappedCategoriesType[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [filteredList, setFilteredList] = useState<MappedCategoriesType[]>([]);

  const { apiCall } = useApiCall();

  const loadCategories = useCallback(async () => {
    await apiCall({
      apiToCall: categoriesService.getCategoriesList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível carregar a lista de categorias (${apiResponse.error})`);
          setDoesListApiHasError(true);
          setIsLoading(false);
          setCategories([]);
          return;
        }
        const categoriesList: MappedCategoriesType[] = apiResponse.categories;
        setCategories(categoriesList);
        setFilteredList(categoriesList);
        setDoesListApiHasError(false);
      },
    })
  }, [apiCall, setIsLoading]);

  function handleTryAgain() {
    loadCategories()
  }

  useEffect(() => {
    loadCategories()
  }, [loadCategories])

  return {
    categories,
    doesListApiHasError,
    filteredList,
    handleTryAgain,
    loadCategories,
    setFilteredList,
  }
}
