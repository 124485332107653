import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import useErrors from "../../../../../hooks/useErrors";
import activitiesService from "../../../../../services/activitiesService";
import { ProgramBeingEditedType } from "../../types";

interface IUseFeedbackModal {
  setFeedbackModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  programToBeSentFeedback: ProgramBeingEditedType;
}

export default function useFeedbackModal({ setIsLoading, setFeedbackModalShow, programToBeSentFeedback }: IUseFeedbackModal) {
  const [feedback, setFeedback] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleFeedbackChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setFeedback(event.target.value);
    if (!event.target.value) {
      setError({ field: 'feedback', message: 'Devolutiva da programação é obrigatória!' })
    } else {
      removeError('feedback')
    }
  }

  const sendFeedback = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.giveFeedbackOfActivityPedagogicalProgram,
      onStartLoad: () => { setIsLoading(true) },
      queryParams: { id: programToBeSentFeedback.id },
      reqBody: JSON.stringify({ feedback }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Devolutiva enviada com sucesso!!');
          setFeedbackModalShow(false);
          setFeedback('');
          setIsLoading(false);
          return;
        }
        toast.error('Não foi possível enviar a devolutiva. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível enviar a devolutiva. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, feedback, programToBeSentFeedback.id, setFeedbackModalShow, setIsLoading]);

  const isSendFeedbackFormValid = !!feedback && errors.length === 0;

  return {
    sendFeedback,
    feedback,
    handleFeedbackChange,
    getErrorMessageByFieldName,
    isSendFeedbackFormValid,
  }
}
