import { Header } from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { Dispatch, SetStateAction } from 'react';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  listLength: number;
  setManageHighlightModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function HighlightsHeader({
  doesListExists,
  hasError,
  listLength,
  setManageHighlightModalShow,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <strong>
              {listLength}
              {' de 6 destaques definidos'}
          </strong>
        )}
        <div className="actionButtons">
          <button type="button" onClick={() => setManageHighlightModalShow(true)}>
              Gerenciar Destaques
          </button>
        </div>
      </Header>
    </OpacityAnimation>
  );
}
