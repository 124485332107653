import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface IGivenClassesService {
  id?: string;
  reqBody?: ReqBodyType;
  month?: number;
  year?: number;
  token: string;
  partnerId?: string;
}

class GivenClassesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getGivenClassesByPeriod = async ({
    token,
    month,
    year,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes?month=${month}&year=${year}`,
      token,
    });
  }

  getRelatoriesDownloadsPermissionsByPeriod = async({
    token,
    month,
    year,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/download-relatory-permission?month=${month}&year=${year}`,
      token,
    });
  }

  changeRelatoryDownloadPermission = async({
    token,
    reqBody,
  }: IGivenClassesService) => {
    return this.httpClient.patch({
      path: '/given-classes/change-download-relatory-permission',
      token,
      reqBody,
    })
  }

  changeAllRelatoryDownloadPermission = async({
    token,
    reqBody,
  }: IGivenClassesService) => {
    return this.httpClient.patch({
      path: '/given-classes/change-all-download-relatory-permission',
      token,
      reqBody,
    })
  }

  getGivenClassById = async ({
    token,
    id,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/details/${id}`,
      token,
    });
  };

  createGivenClass = async({
    reqBody,
    token,
  }: IGivenClassesService) => {
    return this.httpClient.post({
      path: '/given-classes',
      reqBody,
      token,
    });
  }

  updateGivenClass = async ({
    id,
    reqBody,
    token,
  }: IGivenClassesService) => {
    return this.httpClient.put({
      path: `/given-classes/${id}`,
      reqBody,
      token,
    });
  }

  deleteGivenClass = async ({
    id,
    token,
  }: IGivenClassesService) => {
    return this.httpClient.delete({
      path: `/given-classes/${id}`,
      token,
    });
  }

  findActivitiesOptions = async ({
    token,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/activities-options`,
      token,
    });
  }

  findPartnersOptions = async ({
    token,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/partners-options`,
      token,
    });
  }

  downloadPdfRelatory = async ({
    token,
    month,
    year,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/relatory?month=${month}&year=${year}`,
      token,
    });
  }

  downloadPdfRelatoryByPartner = async ({
    token,
    month,
    year,
    partnerId,
  }: IGivenClassesService) => {
    return this.httpClient.get({
      path: `/given-classes/relatory/${partnerId}?month=${month}&year=${year}`,
      token,
    });
  }
}

export default new GivenClassesService();
