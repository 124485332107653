import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${({ theme }) => theme.colors.lighterBackground};
  border-radius: 10px;

  background: ${({ theme }) => theme.colors.lighterBackground};
  border-radius: 0.375em;
  padding: 16px 24px 24px 24px;
  margin: 12px 8px 24px 0px;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;

    img {
      width: 28px;
      margin-right: 8px;
    }

    div {
      font-size: 18px;
      font-weight: bold;
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 12px 0;

    @media(max-width: 500px) {
      flex-direction: column;
    }

    .range-slider {
      width: 100%;

      .range-slider__range {
        background: ${({ theme }) => theme.colors.primary.main};
      }

      .range-slider__thumb {
        background: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`;

export const SelectContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  z-index: 50;

  @media(max-width: 500px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  position: relative;
  bottom: 10px;

  header {
    font-size: 14px;
  }
`;
