import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import UserForm from '../components/UserForm';

export default function EditUser() {
  return (
    <>
      <Sidebar active="Users" />
      <Transitions>
        <UserForm edit />
      </Transitions>
    </>
  );
}
