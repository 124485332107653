import { useMemo, useState } from "react";
import useLoadCupons from "./functions/useLoadCupons"
import useSearches from "./functions/useSearches";
import useDownloadExcel from "./functions/useDownloadExcel";
import useEdit from "./functions/useEdit";
import useDelete from "./functions/useDelete";
import useUpdateCupomStatus from "./functions/useUpdateCupomStatus";

export default function useCupons() {
  const [currentPage, setCurrentPage] = useState(1);
  const [addCupomModalShow, setAddCupomModalShow] = useState(false);

  const {
    isLoading,
    fullCupons,
    filteredCupons,
    cuponsSplited,
    doesListApiHasError,
    setCuponsSplited,
    setFilteredCupons,
    loadCupons,
    companiesOptions,
    setIsLoading,
  } = useLoadCupons({ setCurrentPage });

  const {
    searchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
  } = useSearches({ fullCupons, setCuponsSplited, setFilteredCupons });

  const {
    handleOpenEditModal,
    editCupomModalShow,
    setEditModalShow,
    cupomObjectBeingEditted,
    setCupomObjectBeingEditted,
  } = useEdit();

  const {
    handleOpenDeleteModal,
    cupomBeingDeleted,
    cupomIdBeingDeleted,
    setDeleteModalShow,
    deleteCupom,
    deleteModalShow,
  } = useDelete({ setIsLoading, loadCupons });

  const {
    cupomChangingStatus,
    setChangeStatusModalShow,
    updateCupomStatus,
    changeStatusModalShow,
    handleOpenChangeStatusModal,
  } = useUpdateCupomStatus({ setIsLoading, loadCupons });

  const { downloadExcel } = useDownloadExcel({ fullCupons });

  const pagesQuantity = useMemo(() => cuponsSplited.length, [cuponsSplited.length]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredCupons(cuponsSplited[page]);
  }

  function handleTryAgain() {
    loadCupons();
  }

  return {
    fullCupons,
    filteredCupons,
    isLoading,
    doesListApiHasError,
    searchTerm,
    handleChangeSearchTerm,
    handlePageChange,
    pagesQuantity,
    currentPage,
    downloadExcel,
    setAddCupomModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleTryAgain,
    addCupomModalShow,
    editCupomModalShow,
    setEditModalShow,
    cupomObjectBeingEditted,
    setIsLoading,
    loadCupons,
    cupomBeingDeleted,
    cupomIdBeingDeleted,
    setDeleteModalShow,
    deleteCupom,
    deleteModalShow,
    companiesOptions,
    setCupomObjectBeingEditted,
    cupomChangingStatus,
    setChangeStatusModalShow,
    updateCupomStatus,
    changeStatusModalShow,
    handleOpenChangeStatusModal,
    selectedCompany,
    handleCompanyChange,
  }
}
