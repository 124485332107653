import { Col, Container, Row } from 'react-bootstrap';
import { KidsContainer, SchedulesGroup } from './styles';
import renderArray from '../../../../utils/renderArrayWithComa';
import { ScheduleToRemoveBeingShown } from '../../types';
import { Dispatch, SetStateAction } from 'react';

interface SchedulesToRemoveList {
  list: ScheduleToRemoveBeingShown[];
  setSelectedScheduleIdToRemove: Dispatch<SetStateAction<string>>;
  selectedScheduleIdToRemove: string;
}

export default function SchedulesToRemoveList({
  list,
  setSelectedScheduleIdToRemove,
  selectedScheduleIdToRemove,
}: SchedulesToRemoveList) {
  if (!list) {
    return null;
  }

  return (
    <KidsContainer>
      <Container>
        <Row xs={1} md={2} lg={2}>
          {list.map((scheduleToRemove) => (
            <Col key={scheduleToRemove.id}>
              <SchedulesGroup
                onClick={() => setSelectedScheduleIdToRemove(scheduleToRemove.id)}
                selected={selectedScheduleIdToRemove === scheduleToRemove.id}
              >
                <div className="info">
                  <div className="card-title">
                    <strong>{scheduleToRemove.responsavel}</strong>
                    <small>{`${(scheduleToRemove.criancas.length)} ${scheduleToRemove.criancas.length === 1 ? 'criança' : 'crianças'}`}</small>
                  </div>
                  <span>
                    Crianças:
                    {' '}
                    {renderArray(scheduleToRemove.criancas.map((child) => child.nome))}
                  </span>
                </div>
              </SchedulesGroup>
            </Col>
          ))}
        </Row>
      </Container>
    </KidsContainer>
  );
}
