import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ActiveItem, MenuItem } from '../styles';

import chartPie from '../../../assets/images/icons/chartPie.svg';
import financial from '../../../assets/images/icons/financial.svg';
import lock from '../../../assets/images/icons/lock.svg';
import { SidebarInterface } from '../interface';

export default function FinancialSidebar({ active }: SidebarInterface) {
  return (
    <>
      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}

      {active === 'Financial'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={financial} alt="financial" />
                Financeiro
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/financial">
            <MenuItem>
              <img src={financial} alt="financial" />
              Financeiro
            </MenuItem>
          </Link>
        )}
    </>
  );
}

FinancialSidebar.propTypes = {
  active: PropTypes.string.isRequired,
};
