import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';

interface DeleteModalInterface {
  modalDeleteShow: boolean;
  dateBeingDeleted?: string;
  setModalDeleteShow: Dispatch<SetStateAction<boolean>>;
  deleteDate: () => void;
}

export default function DeleteDateModal({
  modalDeleteShow,
  dateBeingDeleted,
  setModalDeleteShow,
  deleteDate,
}: DeleteModalInterface) {
  return (
    <MyModal
      show={modalDeleteShow}
      title={`Reabilitar data ${dateBeingDeleted}`}
      modalBody={(
        <>
          Tem certeza que deseja reabilitar a data
          {' '}
          <strong>
            {dateBeingDeleted}
          </strong>
          ?
        </>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Reabilitar"
      onClose={() => setModalDeleteShow(false)}
      onAction={() => deleteDate()}
      type='action'
    />
  );
}
