import { TimetableFromApiType } from '../types/TimetableFromApiType';

export default function toPersistenceTimetable(timetable: TimetableFromApiType) {
  const mondayClasses = timetable.find((classInfo) => classInfo.dia === 'seg');
  const mondayClassesInfo = mondayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const tuesdayClasses = timetable.find((classInfo) => classInfo.dia === 'ter');
  const tuesdayClassesInfo = tuesdayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const wednesdayClasses = timetable.find((classInfo) => classInfo.dia === 'qua');
  const wednesdayClassesInfo = wednesdayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const thursdayClasses = timetable.find((classInfo) => classInfo.dia === 'qui');
  const thursdayClassesInfo = thursdayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const fridayClasses = timetable.find((classInfo) => classInfo.dia === 'sex');
  const fridayClassesInfo = fridayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const saturdayClasses = timetable.find((classInfo) => classInfo.dia === 'sab');
  const saturdayClassesInfo = saturdayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  const sundayClasses = timetable.find((classInfo) => classInfo.dia === 'dom');
  const sundayClassesInfo = sundayClasses?.horarios?.map((info) => ({
    startTime: info.horarioInicio,
    endTime: info.horarioTermino,
    vacanciesQty: info.vagas,
    id: `${info.horarioInicio}${info.horarioTermino}${info.vagas}`,
  }));

  return {
    monday: mondayClassesInfo || [],
    tuesday: tuesdayClassesInfo || [],
    wednesday: wednesdayClassesInfo || [],
    thursday: thursdayClassesInfo || [],
    friday: fridayClassesInfo || [],
    saturday: saturdayClassesInfo || [],
    sunday: sundayClassesInfo || [],
  };
}
