import Loader from "../../../components/Loader";
import NoData from "../../../components/NoData";
import PageHeader from "../../../components/PageHeader";
import Sidebar from "../../../components/Sidebar";
import Transitions from "../../../components/Transition";
import ChangeAllPermissionsModal from "./components/ChangeAllPermissionsModal";
import ChangeDownloadRelatoryPermissionModal from "./components/ChangeDownloadRelatoryPermissionModal";
import Filters from "./components/Filters";
import ListHeader from "./components/Header";
import List from "./components/list";
import useAllowRelatoriesDownloads from "./useAllowRelatoriesDownloads";

export default function AllowRelatoriesDownloads() {
  const {
    isLoading,
    month,
    year,
    monthsMap,
    handleOpenChangePermissionModal,
    partnerOptions,
    setSelectedPartner,
    selectedPartner,
    setSelectedPermission,
    selectedPermission,
    filteredDownloadRelatoryPermissions,
    relatoryToChangePermission,
    changePermissionModalShow,
    handleCloseChangePermissionModal,
    changeRelatoryDownloadPermission,
    handleOpenChangeAllPermissionsModal,
    changeAllDownloadPermissions,
    setChangeAllPermissionsModalShow,
    changeAllPermissionsModalShow,
    actionOfPermissionChange,
  } = useAllowRelatoriesDownloads();

  return (
    <>
      <Sidebar active="GivenClasses" />
      <Transitions>
        <Loader isLoading={isLoading} />
        <PageHeader
          title={`Permissão para Downloads de Relatórios pelos Parceiros e Financeiro (${monthsMap[month]} de ${year})`}
          link="/givenClasses"
        />

        {!isLoading && (
          <>
            <Filters
              partnerOptions={partnerOptions}
              setSelectedPartner={setSelectedPartner}
              selectedPartner={selectedPartner}
              setSelectedPermission={setSelectedPermission}
              selectedPermission={selectedPermission}
            />

          {filteredDownloadRelatoryPermissions.length !== 0 && (
            <>
              <ListHeader
                filteredListLength={filteredDownloadRelatoryPermissions.length}
                singularLabel="Relatório Disponível"
                pluralLabel="Relatórios Disponíveis"
                handleOpenChangeAllPermissionsModal={handleOpenChangeAllPermissionsModal}
              />

              <List
                filteredList={filteredDownloadRelatoryPermissions}
                onOpenChangePermissionModal={handleOpenChangePermissionModal}
              />
            </>
          )}

          {filteredDownloadRelatoryPermissions.length === 0 && (
            <NoData
              icon="searchNotFound"
              label={<>Nenhum resultado foi encontrado conforme os filtros selecionados.</>}
            />
          )}
          </>
        )}

        <ChangeDownloadRelatoryPermissionModal
          relatoryToChangePermission={relatoryToChangePermission}
          changePermissionModalShow={changePermissionModalShow}
          handleCloseChangePermissionModal={handleCloseChangePermissionModal}
          changeRelatoryDownloadPermission={changeRelatoryDownloadPermission}
        />

        <ChangeAllPermissionsModal
          actionOfPermissionChange={actionOfPermissionChange}
          setChangeAllPermissionsModalShow={setChangeAllPermissionsModalShow}
          changeAllDownloadPermissions={changeAllDownloadPermissions}
          changeAllPermissionsModalShow={changeAllPermissionsModalShow}
        />
      </Transitions>
    </>
  )
}
