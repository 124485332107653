import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface CategoriesServiceInterface {
  name?: string;
  reqBody?: ReqBodyType;
  token: string;
  categoryId?: string;
}

class CategoriesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getCategoriesList = async ({
    token,
  }: CategoriesServiceInterface) => {
    return this.httpClient.get({
      path: '/categories-list',
      token,
    });
  }

  createCategory = async ({
    reqBody,
    token,
  }: CategoriesServiceInterface) => {
    return this.httpClient.post({
        path: `/categories`,
        reqBody,
        token,
      });
  }

  createManyCategories = async ({
    reqBody,
    token,
  }: CategoriesServiceInterface) => {
    return this.httpClient.post({
        path: `/categories/many`,
        reqBody,
        token,
      });
  }

  updateCategory = async ({
    categoryId,
    name,
    token,
  }: CategoriesServiceInterface) => {
    return this.httpClient.patch({
        path: `/categories/${categoryId}?name=${name}`,
        token,
      });
  }

  deleteCategory = async ({
    categoryId,
    token,
  }: CategoriesServiceInterface) => {
    return this.httpClient.delete({
        path: `/categories/${categoryId}`,
        token,
      });
  }
}

export default new CategoriesService();
