import MyModal from '../../../components/Modal';
import { PartnerChangingStatusType } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface UpdatePartnerStatusInterface {
  partnerId: string;
  ativo: boolean;
}

interface ChangePartnerStatusModalInterface {
  partnerChangingStatus: PartnerChangingStatusType,
  setChangeStatusModalShow: Dispatch<SetStateAction<boolean>>,
  updatePartnerStatus: ({ partnerId, ativo }: UpdatePartnerStatusInterface) => void,
  changeStatusModalShow: boolean,
}

export default function ChangePartnerStatusModal({
  partnerChangingStatus,
  setChangeStatusModalShow,
  updatePartnerStatus,
  changeStatusModalShow,
}: ChangePartnerStatusModalInterface) {
  return (
    <MyModal
      title={partnerChangingStatus.status === 'Ativo' ? `Inativar parceiro ${partnerChangingStatus.name}` : `Reativar parceiro ${partnerChangingStatus.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={partnerChangingStatus.status === 'Ativo' ? 'Inativar' : 'Reativar'}
      modalBody={(
        <div>
          {partnerChangingStatus.status === 'Ativo' ? 'Tem certeza que deseja inativar o parceiro' : 'Tem certeza que deseja reativar o parceiro'}
          {' '}
          <strong>{partnerChangingStatus.name}</strong>
          {' '}
          ?
        </div>
          )}
      onClose={() => setChangeStatusModalShow(false)}
      onAction={() => updatePartnerStatus({
        partnerId: partnerChangingStatus.id,
        ativo: partnerChangingStatus.status !== 'Ativo',
      })}
      show={changeStatusModalShow}
      type={partnerChangingStatus.status === 'Ativo' ? 'suspendAction' : 'activateAction'}
    />
  );
}
