import { useState } from 'react';
import useCompanyData from './functions/useCompanyData';
import useLoadFinancial from './functions/useLoadFinancial';
import usePartnerData from './functions/usePartnerData';

export default function useFinancial() {
  const [viewKind, setViewKind] = useState('company');

  const {
    isLoadLoading,
    companiesOptions,
    partnersOptions,
    doesListApiHasError,
  } = useLoadFinancial();

  const {
    isCompanyDataLoading,
    selectedCompany,
    companyData,
    companyDataError,
    reloadCompanyData,
    handleSelectedCompanyChange,
    handleTryGetCompanyDataAgain,
    startDate,
    agreedAmounth,
  } = useCompanyData();

  const {
    isPartnerDataLoading,
    selectedPartner,
    partnerData,
    partnerDataError,
    reloadPartnerData,
    handleSelectedPartnerChange,
    handleTryGetPartnerDataAgain,
    classPrice,
  } = usePartnerData();

  const isLoading = (
    isLoadLoading
  || isCompanyDataLoading
  || isPartnerDataLoading
  );

  return {
    viewKind,
    setViewKind,
    companiesOptions,
    partnersOptions,
    handleSelectedCompanyChange,
    handleSelectedPartnerChange,
    selectedCompany,
    selectedPartner,
    doesListApiHasError,
    isLoading,
    companyData,
    partnerData,
    companyDataError,
    partnerDataError,
    handleTryGetPartnerDataAgain,
    handleTryGetCompanyDataAgain,
    reloadCompanyData,
    reloadPartnerData,
    startDate,
    agreedAmounth,
    classPrice,
  };
}
