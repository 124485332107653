import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';

interface KindOfPartnerInterface {
  handlePartnerChange: (kind: string) => void;
  kindOfPartner: string;
}

export default function KindOfPartner({ handlePartnerChange, kindOfPartner }: KindOfPartnerInterface) {
  return (
    <FilterRadioButtonsContainer>
      <FilterRadioButton
        onClick={() => handlePartnerChange('cpf')}
        selected={kindOfPartner === 'cpf'}
      >
        Pessoa Física
      </FilterRadioButton>
      <FilterRadioButton
        onClick={() => handlePartnerChange('cnpj')}
        selected={kindOfPartner === 'cnpj'}
      >
        Pessoa Jurídica
      </FilterRadioButton>
    </FilterRadioButtonsContainer>
  );
}
