import MyModal from '../../../components/Modal';
import { Dispatch, SetStateAction } from 'react';
import { ActivityBeingDeletedType } from '../types';

interface DeleteModalInterface {
  activityBeingDeleted: ActivityBeingDeletedType;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deleteActivity: (activityId: string) => void;
  deleteModalShow: boolean;
}

export default function DeleteModal({
  activityBeingDeleted,
  setDeleteModalShow,
  deleteActivity,
  deleteModalShow,
}: DeleteModalInterface) {
  return (
    <MyModal
      title={`Excluir atividade ${activityBeingDeleted.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja excluir a atividade
            {' '}
            <strong>{activityBeingDeleted.name}</strong>
            {' '}
            ?
          </div>
          <br />
          <strong>Atenção, esta ação é irreversível!</strong>
        </>
          )}
      onClose={() => setDeleteModalShow(false)}
      onAction={() => deleteActivity(activityBeingDeleted.id)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
