import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import { CurrrentMonthBeingShowType, CurrrentYearBeingShowType } from '../../../../types';
import { Container } from './styles';

interface HeaderInterface {
  currentMonthBeingShow: CurrrentMonthBeingShowType;
  currentYearBeingShow: CurrrentYearBeingShowType;
  handleDateModalShow: Dispatch<SetStateAction<boolean>>;
  isNfUploaded: boolean;
  nfDownloadLink?: string;
  isPaymentVoucherUploaded: boolean;
  paymentVoucherLink?: string;
  handlePaymentVoucherModalShow: Dispatch<SetStateAction<boolean>>;
  hasFinancialRelatoryAllowedForDownload: boolean;
  getFinancialRelatoryPreview: () => Promise<void>;
}

export default function Header({
  currentMonthBeingShow,
  currentYearBeingShow,
  handleDateModalShow,
  isNfUploaded,
  isPaymentVoucherUploaded,
  handlePaymentVoucherModalShow,
  nfDownloadLink,
  paymentVoucherLink,
  hasFinancialRelatoryAllowedForDownload,
  getFinancialRelatoryPreview,
}: HeaderInterface) {
  const monthsMap = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Container>
        <strong>
          {monthsMap[currentMonthBeingShow]}
          {' '}
          de
          {' '}
          {currentYearBeingShow}
        </strong>
        <div className="actionButtons">
          <button type="button" onClick={() => handleDateModalShow(true)}>
            Alterar mês
          </button>
          {hasFinancialRelatoryAllowedForDownload && (
            <button type="button" onClick={() => getFinancialRelatoryPreview()}>
              Visualizar Relatório Financeiro
            </button>
          )}
          {isNfUploaded && (
            <a href={nfDownloadLink} target="_blank" download rel="noreferrer">
              Baixar NF-e
            </a>
          )}
          {!isPaymentVoucherUploaded && (
            <button type="button" onClick={() => handlePaymentVoucherModalShow(true)}>
              Enviar comprovante de pagamento
            </button>
          )}
          {isPaymentVoucherUploaded && (
            <a href={paymentVoucherLink} target="_blank" download rel="noreferrer">
              Baixar comprovante enviado
            </a>
          )}
        </div>
      </Container>
    </motion.div>
  );
}
