import { ChangeEvent } from 'react';
import FileInput from '../../../components/FileInput';
import MyModal from '../../../components/Modal';

interface UploadImageModalInterface {
  uploadImageModalShow: boolean;
  imageFileName: string;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmitFile: () => void;
  handleUploadImageModalClose: () => void;
}

export default function UploadImageModal({
  uploadImageModalShow,
  imageFileName,
  handleFileUpload,
  handleSubmitFile,
  handleUploadImageModalClose,
}: UploadImageModalInterface) {
  return (
    <MyModal
      show={uploadImageModalShow}
      title="Incluir/alterar imagem do parceiro"
      centeredBody
      modalBody={(
        <>
          <FileInput
            buttonLabel="Confirmar imagem"
            fileNameChoosed={imageFileName}
            isSubmitButtonDisabled={!imageFileName}
            onFileUpload={handleFileUpload}
            onSubmitFile={handleSubmitFile}
            acceptedFiles=".jpg, .png, .jpeg"
          />
        </>
      )}
      closeButtonLabel="Fechar"
      onClose={handleUploadImageModalClose}
      type="info"
    />
  );
}
