import NoData from '../../../components/NoData';
import { Card } from '../styles';

interface IActiveUsersCard {
  quantity: number;
  type: string;
}

export default function ActiveUsersCard({
  quantity,
  type,
}: IActiveUsersCard) {
  const hasInfo = quantity !== 0;
  const typeFormatted = type === 'Hoje' ? 'Hoje' : type === 'Última Semana' ? 'Na última semana' : 'No último mês';

  return (
    <Card>
      <div className="card-title">
        <div>Usuários Ativos - {type}</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {quantity}
        </div>
        <small>
          Usuários utilizaram a plataforma {typeFormatted}
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da quantidade de usuários ativos {typeFormatted}.
          </>
                    )}
      />
      )}
    </Card>
  );
}
