import { Dispatch, SetStateAction } from "react";
import MyModal from "../../../../components/Modal";
import useDeletePostModal from "./useDeletePostModal";

interface IDeletePostModal {
  deletePostModalShow: boolean;
  loadForum: () => Promise<void>;
  setIsLoading:  Dispatch<SetStateAction<boolean>>;
  handleCloseDeleteModal: () => void;
  postIdBeingDeleted: string;
}

export default function DeletePostModal({
  deletePostModalShow,
  handleCloseDeleteModal,
  loadForum,
  setIsLoading,
  postIdBeingDeleted,
}: IDeletePostModal) {
  const {
    deletePost,
  } = useDeletePostModal({
    loadForum,
    setIsLoading,
    handleCloseDeleteModal,
    postIdBeingDeleted,
  });

  return (
    <MyModal
    show={deletePostModalShow}
    onClose={() => handleCloseDeleteModal()}
    title={`Editar texto da publicação`}
    modalBody={<>Tem certeza que deseja remover essa publicação?</>}
    closeButtonLabel="Fechar"
    // size="xl"
    type='deleteAction'
    actionButtonLabel="Remover"
    onAction={() => deletePost()}
    />
  )
}
