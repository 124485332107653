import { useCallback, useState } from 'react';
import useApiCall from '../../../../hooks/useApiCall';
import subscribersService from '../../../../services/subscribersService';
import differenceBetweenDates from '../../../../utils/differenceBetweenDates';
import { SubscriberDetailsType } from '../../types';

export default function useSubscriberDetails() {
  const [isSubscriberDetailsLoading, setIsSubscriberDetailsLoading] = useState(false);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [detailView, setDetailView] = useState('subscriber');
  const [subscriberDetailsBeingShow, setSubscriberDetailsBeingShow] = useState<SubscriberDetailsType>({} as SubscriberDetailsType);

  const { apiCall } = useApiCall();

  const handleOpenDetailModal = useCallback(async (id: string) => {
    await apiCall({
      apiToCall: subscribersService.getSuscriberDetails,
      queryParams: { id },
      onStartLoad: () => setIsSubscriberDetailsLoading(true),
      onEndLoad: () => setIsSubscriberDetailsLoading(false),
      actionAfterResponse: (apiResponse) => {
        const subscriberData = apiResponse.details;
        const statusLiterals: { [key: string]: string } = {
          Ativa: 'Ativo',
          'Período de teste': 'Teste',
          Cancelada: 'Cancelado',
          Suspensa: 'Suspenso',
        }
        setSubscriberDetailsBeingShow({
          name: subscriberData.nome,
          lastAppointmentDate: apiResponse.lastAppointment?.dataAgendamento,
          cityUf: `${subscriberData.endereco ? `${subscriberData.endereco?.cidade}, ${subscriberData.endereco?.UF}` : `Sem endereço`}`,
          status: statusLiterals[subscriberData.assinatura_status],
          gender: subscriberData.genero ?? `Não informou gênero`,
          cpf: subscriberData.cpf ?? `Não informou CPF`,
          email: subscriberData.email,
          cellphone: subscriberData.celular,
          age: `${subscriberData.data_nascimento ? `${Number(differenceBetweenDates(subscriberData.data_nascimento).years)}` : `Não informou idade`}`,
          activateDate: subscriberData.data_ativacao,
          registerDate: subscriberData.data_cadastro.split(' ')[0],
          company: subscriberData.company?.nome_fantasia ?? 'B2C',
          collaboratorId: subscriberData.collaborator?.id ?? 'Sem identificador de empresa',
          cupom: subscriberData.cupom?.name || subscriberData.cupom_utilizado?.code || 'Sem cupom',
          favorites: subscriberData.favoritos,
          paymentMethod: subscriberData.payment_method ?? 'Sem método',
          childrens: subscriberData.kids,
          engagementStatus: subscriberData.engagementStatus,
          schedulesInLast30Days: subscriberData.schedulesInLast30Days,
          videosViewsInLast30Days: subscriberData.videosViewsInLast30Days,
          joinedClassesInLast30Days: subscriberData.joinedClassesInLast30Days,
          ratings: subscriberData.ratings_app,
        });
        setDetailModalShow(true);
      },
      catchMessage: 'Não foi possível exibir os detalhes desse assinante',
      catchAction: () => setDetailModalShow(false)
    })
  }, [apiCall]);

  function handleCloseDetailModal() {
    setDetailModalShow(false);
    setDetailView('subscriber');
  }

  return {
    isSubscriberDetailsLoading,
    detailModalShow,
    detailView,
    subscriberDetailsBeingShow,
    setDetailView,
    handleOpenDetailModal,
    handleCloseDetailModal,
  };
}
