import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import activate from '../../../../assets/images/icons/activate.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import fileImage from '../../../../assets/images/icons/fileImage.svg';
import halfStar from '../../../../assets/images/icons/half-star.svg';
import list from '../../../../assets/images/icons/list.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { MappedPartnerType, PartnerBeingDeletedType, PartnerChangingStatusType } from '../../types';
import {
  Card,
} from './styles';

interface OnOpenPartnerActivitiesModalInterface {
  name: string;
  id: string;
}

interface ListInterface {
  filteredList: MappedPartnerType[];
  onOpenDeleteModal: ({ id, name }: PartnerBeingDeletedType) => void;
  onOpenPartnerActivitiesModal: ({ id, name }: OnOpenPartnerActivitiesModalInterface) => void;
  onOpenChangeStatusModal: ({ name, id, status }: PartnerChangingStatusType) => void;
  onOpenUploadImageModal: (partnerId: string) => void;
  downloadRatings: (partnerId: string) => void;
}

export default function List({
  filteredList,
  onOpenDeleteModal,
  onOpenPartnerActivitiesModal,
  onOpenChangeStatusModal,
  onOpenUploadImageModal,
  downloadRatings,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((partner) => (
          <Col key={partner.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes e editar">
                <div className="info">
                  <div className="card-title">
                    <strong>{partner.name}</strong>
                    <small className={partner.status === 'Inativo' ? 'orange' : ''}>
                      {partner.status}
                    </small>
                  </div>
                  <span>
                    Responsável:
                    {' '}
                    {partner.responsible || 'Não informado'}
                  </span>
                  <span>
                    {partner.cnpj && 'CNPJ: '}
                    {(partner.cpf && !partner.cnpj) && 'CPF: '}
                    {(!partner.cnpj && !partner.cpf) && 'CNPJ:'}
                    {' '}
                    {partner.cnpj || partner.cpf || 'Não informado'}
                  </span>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => onOpenUploadImageModal(partner.id)}
                    className={partner.profileImage ? 'profileImage' : ''}
                  >
                    <img className='profileImage' src={partner.profileImage || fileImage} alt="" title={`Incluir/alterar foto de perfil do parceiro ${partner.name}`} />
                  </button>
                  {partner.hasRatings && (
                  <button
                    type="button"
                    onClick={() => downloadRatings(partner.id)}
                  >
                    <img src={halfStar} alt="trash" title="Baixar XLSX de avaliações" />
                  </button>
                )}
                  <button
                    type="button"
                    onClick={() => onOpenChangeStatusModal({
                      name: partner.name,
                      id: partner.id,
                      status: partner.status,
                    })}
                  >
                    {partner.status === 'Ativo' ? (
                      <img src={suspend} alt="suspend" title="Inativar Parceiro" />
                    ) : (
                      <img src={activate} alt="trash" title="Reativar Parceiro" />
                    )}
                  </button>
                  <Link to={`/partners/${partner.id}`}>
                    <img src={edit} alt="" title={`Editar parceiro ${partner.name}`} className="primaryColor" />
                  </Link>
                  <button
                    type="button"
                    onClick={() => onOpenPartnerActivitiesModal({
                      id: partner.id,
                      name: partner.name,
                    })}
                  >
                    <img src={list} alt="" title={`Atividades do parceiro ${partner.name}`} />
                  </button>
                  <button
                    type="button"
                    onClick={() => onOpenDeleteModal({
                      id: partner.id,
                      name: partner.name,
                    })}
                  >
                    <img src={trash} alt="" title={`Remover parceiro ${partner.name}`} />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
