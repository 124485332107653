/* eslint-disable react/jsx-no-useless-fragment */
import { Dispatch, SetStateAction } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import trash from '../../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { useAppContext } from '../../../../../contexts/auth';
import { UserBeingDeletedInterface } from '../../interfaces/UserBeingDeletedInterface';
import { UsersList } from '../../interfaces/UsersList';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList:  UsersList[] | [];
  setUserBeingDeleted: Dispatch<SetStateAction<UserBeingDeletedInterface>>;
  setModalDeleteShow: Dispatch<SetStateAction<boolean>>;
  isReadOnly: boolean;
  viewByAssociatedCompanies: boolean;
}

export default function List({
  filteredList,
  setUserBeingDeleted,
  setModalDeleteShow,
  isReadOnly,
  viewByAssociatedCompanies,
}: ListInterface) {
  const idsLength = filteredList?.map((list) => list.id.length);
  const bigIdsLength = idsLength?.filter((idLength) => idLength > 18);
  const hasAnyBigIdLength = bigIdsLength?.length > 0;

  const { user: { permission } } = useAppContext();
  return (
    <>
      {filteredList?.length > 0 && (
        <Container>
          <Row xs={1} md={2} lg={hasAnyBigIdLength ? 2 : 3}>
            {filteredList?.map((collaborator) => (
              <Col key={collaborator._id}>
                <OpacityAnimation delay={0.1}>
                  <Card>
                    <div className="info">
                      <div className="card-title">
                        <strong>{isReadOnly ? collaborator.name : collaborator.id}</strong>
                        {(collaborator.status === 'Suspenso') && (
                          <small className="orange">Suspenso</small>
                        )}
                        {(collaborator.status === 'Ativo') && (
                          <small className="green">Ativo</small>
                        )}
                        {(collaborator.status === 'Registrado') && (
                          <small>Registrado</small>
                        )}
                      </div>
                      <span>
                        {collaborator.dataAtivacao !== 'Nunca ativado' && (
                          <>
                        Ativado em
                            {' '}
                            {collaborator.dataAtivacao}
                          </>
                        )}
                        {collaborator.dataAtivacao === 'Nunca ativado' && (
                          <>Não foi ativado</>
                        )}
                      </span>
                      {isReadOnly && (
                        <>
                          <span>
                            Email: {collaborator.email || 'Não informado'}
                          </span>
                          <span>
                            Celular: {collaborator.cellphone || 'Não informado'}
                          </span>
                        </>
                      )}

                      {viewByAssociatedCompanies && (
                        <span>
                        Empresa associada: {collaborator.associatedCompany ?? 'Não identificada'}
                        </span>
                      )}
                    </div>

                  {(!isReadOnly && permission === 'super') && (
                    <div className="actions">
                      <button
                        type="button"
                        onClick={() => {
                          setUserBeingDeleted(collaborator);
                          setModalDeleteShow(true);
                        }}
                      >
                        <img src={trash} alt="delete" title="Excluir colaborador" />
                      </button>
                    </div>
                  )}

                  </Card>
                </OpacityAnimation>
              </Col>
            ))}
          </Row>
        </Container>

      )}
    </>
  );
}
