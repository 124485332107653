import { Container, Col, Row } from 'react-bootstrap';
import NoData from '../../../NoData';
import { Container as StyledContainer } from '../../styles';
import plus from '../../../../assets/images/icons/plus.svg';

import { SecondaryButton } from '../../../SecondaryButton';
import { NoDataContainer } from '../../../NoData/styles';
import Title from './components/Title';
import OpacityAnimation from '../../../OpacityAnimation';
import DayCard from './components/DayCard';
import AddTimeModal from './components/AddTimeModal';
import TimesModal from './components/TimesModal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { ClassesInfoType, DayBeingAddedAtTimetableType, DayInfoBeingShowType } from '../../types/TimetableTypes';
import { TimesInSomeDayType } from '../../types/TimetableTranslatedType';

interface TimetableCardInterface {
  setAddTimetableModalShow: Dispatch<SetStateAction<boolean>>;
  quantityOfMondayClasses: number;
  setDayTimesModalShow: Dispatch<SetStateAction<boolean>>;
  setDayInfoBeingShow: Dispatch<SetStateAction<DayInfoBeingShowType>>;
  mondayClasses: ClassesInfoType[];
  quantityOfTuesdayClasses: number;
  tuesdayClasses: ClassesInfoType[];
  quantityOfWednesdayClasses: number;
  wednesdayClasses: ClassesInfoType[];
  quantityOfThursdayClasses: number;
  thursdayClasses: ClassesInfoType[];
  quantityOfFridayClasses: number;
  fridayClasses: ClassesInfoType[];
  quantityOfSaturdayClasses: number;
  saturdayClasses: ClassesInfoType[];
  quantityOfSundayClasses: number;
  sundayClasses: ClassesInfoType[];
  addTimetableModalShow: boolean;
  addNewTimeAtTimetable: () => void;
  startTimeBeingAdded: string;
  endTimeBeingAdded: string;
  dayBeingAddedAtTimetable: DayBeingAddedAtTimetableType;
  timetableDaysOptions: {
    value: string;
    label: string;
  }[];
  setDayBeingAddedAtTimetable: Dispatch<SetStateAction<DayBeingAddedAtTimetableType>>;
  handleStartTimeBeingAddedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  vacanciesQty: number | string;
  handleVacanciesQtyChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dayTimesModalShow: boolean;
  dayInfoBeingShow: DayInfoBeingShowType;
  removeTimeAtTimetable: (time: TimesInSomeDayType, day: string) => void;
  hasClasses: boolean;
}

export default function TimetableCard({
  setAddTimetableModalShow,
  quantityOfMondayClasses,
  setDayTimesModalShow,
  setDayInfoBeingShow,
  mondayClasses,
  quantityOfTuesdayClasses,
  tuesdayClasses,
  quantityOfWednesdayClasses,
  wednesdayClasses,
  quantityOfThursdayClasses,
  thursdayClasses,
  quantityOfFridayClasses,
  fridayClasses,
  quantityOfSaturdayClasses,
  saturdayClasses,
  quantityOfSundayClasses,
  sundayClasses,
  addTimetableModalShow,
  addNewTimeAtTimetable,
  startTimeBeingAdded,
  endTimeBeingAdded,
  dayBeingAddedAtTimetable,
  timetableDaysOptions,
  setDayBeingAddedAtTimetable,
  handleStartTimeBeingAddedChange,
  vacanciesQty,
  handleVacanciesQtyChange,
  dayTimesModalShow,
  dayInfoBeingShow,
  removeTimeAtTimetable,
  hasClasses,
}: TimetableCardInterface) {
  return (
    <>
      <StyledContainer>
        <Title
          hasClasses={hasClasses}
          addImage={plus}
          setAddTimetableModalShow={setAddTimetableModalShow}
        />
        <Container>
          <Row xs={1} md={1} lg={2}>
            {!!quantityOfMondayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Segunda-feira"
                  dayEn="monday"
                  quantityOfClasses={quantityOfMondayClasses}
                  classes={mondayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfTuesdayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Terça-feira"
                  dayEn="tuesday"
                  quantityOfClasses={quantityOfTuesdayClasses}
                  classes={tuesdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfWednesdayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Quarta-feira"
                  dayEn="wednesday"
                  quantityOfClasses={quantityOfWednesdayClasses}
                  classes={wednesdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfThursdayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Quinta-feira"
                  dayEn="thursday"
                  quantityOfClasses={quantityOfThursdayClasses}
                  classes={thursdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfFridayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Sexta-feira"
                  dayEn="friday"
                  quantityOfClasses={quantityOfFridayClasses}
                  classes={fridayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfSaturdayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Sábado"
                  dayEn="saturday"
                  quantityOfClasses={quantityOfSaturdayClasses}
                  classes={saturdayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}
            {!!quantityOfSundayClasses && (
            <Col>
              <OpacityAnimation>
                <DayCard
                  day="Domingo"
                  dayEn="sunday"
                  quantityOfClasses={quantityOfSundayClasses}
                  classes={sundayClasses}
                  setDayTimesModalShow={setDayTimesModalShow}
                  setDayInfoBeingShow={setDayInfoBeingShow}
                />
              </OpacityAnimation>
            </Col>
            )}

            {!hasClasses && (
              <NoDataContainer timetable>
                <SecondaryButton onClick={() => setAddTimetableModalShow(true)}>
                  Adicionar novo horário
                </SecondaryButton>
                <NoData
                  icon="emptyBox"
                  label={(
                    <>
                      Comece a montar sua grade horária clicando no botão
                      {' '}
                      <strong>Adicionar novo horário</strong>
                      {' '}
                      acima.
                      Se atente para não inserir horários que conflitem entre si.
                    </>
  )}
                />
              </NoDataContainer>
            )}
          </Row>
        </Container>
      </StyledContainer>

      <AddTimeModal
        addTimetableModalShow={addTimetableModalShow}
        setAddTimetableModalShow={setAddTimetableModalShow}
        addNewTimeAtTimetable={addNewTimeAtTimetable}
        startTimeBeingAdded={startTimeBeingAdded}
        endTimeBeingAdded={endTimeBeingAdded}
        dayBeingAddedAtTimetable={dayBeingAddedAtTimetable}
        timetableDaysOptions={timetableDaysOptions}
        setDayBeingAddedAtTimetable={setDayBeingAddedAtTimetable}
        handleStartTimeBeingAddedChange={handleStartTimeBeingAddedChange}
        vacanciesQty={vacanciesQty}
        handleVacanciesQtyChange={handleVacanciesQtyChange}
      />

      <TimesModal
        dayTimesModalShow={dayTimesModalShow}
        setDayTimesModalShow={setDayTimesModalShow}
        dayInfoBeingShow={dayInfoBeingShow}
        removeTimeAtTimetable={removeTimeAtTimetable}
      />
    </>
  );
}
