import styled from "styled-components";

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lighterBackground};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  & + & {
    margin-top: 16px;
  }
`;
