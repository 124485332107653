import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../services/activitiesService';
import { ActivityBeingDeletedType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseDeleteActivityInterface {
  resetFields?: () => void;
  loadActivities: () => void;
}

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

export default function useDeleteActivity({
  // resetFields,
  loadActivities,
}: UseDeleteActivityInterface) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [activityBeingDeleted, setActivityBeingDeleted] = useState({} as ActivityBeingDeletedType);

  const { apiCall } = useApiCall();

  const deleteActivity = useCallback(async (activityId: string) => {
    await apiCall({
        apiToCall: activitiesService.deleteActivity,
        queryParams: { id: activityId },
        onStartLoad: () => setIsDeleting(true),
        onEndLoad: () => {
          setIsDeleting(false);
          setDeleteModalShow(false);
        },
        actionAfterResponse: (apiResponse) => {
          if (!apiResponse.success) {
            toast.error('Não foi possível deletar a atividade. Por favor, tente novamente', {
              toastId: 'deleteActivityFail',
            });
            return;
          }
          toast.success('A atividade foi removida com sucesso!', {
            toastId: 'deleteActivitySuccess',
          });
          // resetFields();
          loadActivities();
        },
        catchMessage: 'Não foi possível deletar a atividade, Por favor, tente novamente',
    })
  }, [apiCall, loadActivities]);

  function handleOpenDeleteModal({ name, id }: HandleOpenDeleteModalInterface) {
    setDeleteModalShow(true);
    setActivityBeingDeleted({
      name,
      id,
    });
  }

  return {
    isDeleting,
    deleteModalShow,
    activityBeingDeleted,
    deleteActivity,
    handleOpenDeleteModal,
    setDeleteModalShow,
  };
}
