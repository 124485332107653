import MyModal from '../../../components/Modal';
import { CupomChangingStatusType, UpdateCupomStatusType } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface ChangeStatusModalInterface {
  cupomChangingStatus: CupomChangingStatusType;
  setChangeStatusModalShow: Dispatch<SetStateAction<boolean>>;
  updateCupomStatus: ({ id, active }: UpdateCupomStatusType) => void;
  changeStatusModalShow: boolean;
}

export default function ChangeStatusModal({
  cupomChangingStatus,
  setChangeStatusModalShow,
  updateCupomStatus,
  changeStatusModalShow,
}: ChangeStatusModalInterface) {
  return (
    <MyModal
      title={cupomChangingStatus.active ? `Inativar cupom ${cupomChangingStatus.name}` : `Reativar cupom ${cupomChangingStatus.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={cupomChangingStatus.active ? 'Inativar' : 'Reativar'}
      modalBody={(
        <div>
          {cupomChangingStatus.active ? 'Tem certeza que deseja inativar o cupom' : 'Tem certeza que deseja reativar o cupom'}
          {' '}
          <strong>{cupomChangingStatus.name}</strong>
          {' '}
          ?
        </div>
          )}
      onClose={() => setChangeStatusModalShow(false)}
      onAction={() => updateCupomStatus({
        id: cupomChangingStatus.id,
        active: !cupomChangingStatus.active,
      })}
      show={changeStatusModalShow}
      type={cupomChangingStatus.active ? 'suspendAction' : 'activateAction'}
    />
  );
}
