import { ChangeEvent } from 'react';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';

interface SocialMediaDataCardInterface {
  facebook: string;
  handleFacebookChange: (event: ChangeEvent<HTMLInputElement>) => void;
  site: string;
  handleSiteChange: (event: ChangeEvent<HTMLInputElement>) => void;
  instagram: string;
  handleInstagramChange: (event: ChangeEvent<HTMLInputElement>) => void;
  tiktok: string;
  handleTiktokChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function SocialMediaDataCard({
  facebook,
  handleFacebookChange,
  site,
  handleSiteChange,
  instagram,
  handleInstagramChange,
  tiktok,
  handleTiktokChange,
}: SocialMediaDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Redes sociais / Site
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Site
          </div>
          <FormGroup>
            <Input
              value={site}
              onChange={handleSiteChange}
              placeholder="Informe o site"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Facebook
          </div>
          <FormGroup>
            <Input
              value={facebook}
              onChange={handleFacebookChange}
              placeholder="Informe o facebook"
            />
          </FormGroup>
        </Group>
      </AsideContainer>

      <AsideContainer>
        <Group>
          <div className="title">
            Instagram
          </div>
          <FormGroup>
            <Input
              value={instagram}
              onChange={handleInstagramChange}
              placeholder="Informe o Instagram"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Tiktok
          </div>
          <FormGroup>
            <Input
              value={tiktok}
              onChange={handleTiktokChange}
              placeholder="Informe o tiktok"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
