import MyModal from '../../../components/Modal';
import { PartnerBeingDeletedType } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface DeletePartnerModalInterface {
  partnerBeingDeleted: PartnerBeingDeletedType;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deletePartner: (partnerId: string) => void;
  deleteModalShow: boolean;
}

export default function DeletePartnerModal({
  partnerBeingDeleted,
  setDeleteModalShow,
  deletePartner,
  deleteModalShow,
}: DeletePartnerModalInterface) {
  return (
    <MyModal
      title={`Excluir parceiro ${partnerBeingDeleted.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja excluir o parceiro
            {' '}
            <strong>{partnerBeingDeleted.name}</strong>
            {' '}
            ?
          </div>
          <br />
          <strong>Atenção, esta ação é irreversível!</strong>
        </>
)}
      onClose={() => setDeleteModalShow(false)}
      onAction={() => deletePartner(partnerBeingDeleted.id)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
