import { getDay, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import schedulesService from '../../../../services/schedulesService';
import dateWith0 from '../../../../utils/dateWith0';
import dateWithout0 from '../../../../utils/dateWithout0';
import formatDate from '../../../../utils/formatDate';
import removeDuplicates from '../../../../utils/removeDuplicates';
import renderArray from '../../../../utils/renderArrayWithComa';
import { ActivityOptionType, PartnerOptionType, ScheduleType, UserOptionType } from '../../types';
import useDates from './useDates';

export default function useLoadSchedules() {
  const dates = useDates();
  const {
    currentDate,
  } = dates;

  const { apiCall } = useApiCall();

  const [isLoadLoading, setIsLoadLoading] = useState(true);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [schedules, setSchedules] = useState<ScheduleType[]>([]);
  const [dateBeingShown, setDateBeingShown] = useState(currentDate);

  const [activitiesList, setActivitiesList] = useState<ActivityOptionType[]>([]);
  const [partnerOptions, setPartnerOptions] = useState<PartnerOptionType[]>([]);
  const [userOptions, setUserOptions] = useState<UserOptionType[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);

  const getScheduleList = useCallback(async (date = currentDate) => {
    await apiCall({
      apiToCall: schedulesService.getSchedulesList,
      queryParams: { date: dateWithout0(date) },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar os agendamentos (${apiResponse.error})`, {
            toastId: 'getSchedulesFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const schedulesListFromApi: ScheduleType[] = apiResponse?.appointments;

        const schedulesList = schedulesListFromApi.map((schedule) => {
          const parsedDate = parse(formatDate(dateWith0(schedule.data)) as string, 'dd/MM/yyyy', new Date());
          const weekDaysLiterals: { [key: number]: string } = {
            0: 'dom',
            1: 'seg',
            2: 'ter',
            3: 'qua',
            4: 'qui',
            5: 'sex',
            6: 'sab'
          }
          const weekday = weekDaysLiterals[getDay(parsedDate)];
          const timesAtWeekday = schedule.grade.find((time) => time.dia === weekday)
          const scheduleStartTime = schedule.horario.split('-')[0];
          const vacanciesQtyAtTimeOfSchedule = timesAtWeekday?.horarios.find((time) => time.horarioInicio === scheduleStartTime)?.vagas;
          if (!vacanciesQtyAtTimeOfSchedule) {
            return schedule;
          }
          const vacanciesFilled = schedule.criancas.length;
          const remainingVacancies = vacanciesQtyAtTimeOfSchedule - vacanciesFilled;

          return ({
            ...schedule,
            remainingVacancies,
          })
        })

        const activities = schedulesListFromApi?.map((apt) => (
          { value: apt.atividade_id, label: apt.atividade }
        ));
        activities.unshift({
          value: '', label: 'Todas',
        });

        const users = schedulesListFromApi?.map((apt) => {
          const responsibles = apt.criancas.map((child) => ({
            value: child.responsavel, label: child.responsavel,
          }));
          return responsibles;
        });
        const usersWithoutDuplicates = removeDuplicates(users.flat());
        usersWithoutDuplicates.unshift({
          value: '', label: 'Todos',
        });

        const partners = schedulesListFromApi?.map((apt) => ({
          value: apt.parceiro_id, label: apt.parceiro,
        }));
        const featPartners = schedulesListFromApi?.map((apt) => ({
          value: apt.parceiroFeat_id, label: apt.parceiroFeat,
        }));
        const partnersWithoutDuplicates = removeDuplicates([...partners, featPartners]);
        partnersWithoutDuplicates.unshift({
          value: '', label: 'Todos',
        });

        setUserOptions(usersWithoutDuplicates);
        setPartnerOptions(partnersWithoutDuplicates.filter((pt) => !!pt.label));
        setActivitiesList(removeDuplicates(activities));
        setSchedules(schedulesList);
        setDoesListApiHasError(false);
        setDateBeingShown(date);

        const warningsArray: string[] = [];
        const dateStringToShow = date === currentDate ? 'Hoje' : `Em ${date}`;
        const childrensBirthdayToday = schedulesList.map((sch) => {
          const onlyChildsWithTodayBirthday = sch.criancas.filter(
            (child) => child.nascimento?.slice(0, 5) === currentDate.slice(0, 5),
          );
          return {
            atividade: sch.atividade,
            horario: sch.horario,
            criancas: onlyChildsWithTodayBirthday,
          };
        }).filter((sch) => sch.criancas.length > 0);

        if (childrensBirthdayToday.length > 0) {
          childrensBirthdayToday.forEach((childBirthday) => {
            const childsNamesString = renderArray(childBirthday.criancas.map((child) => child.nome));

            warningsArray.push(`🎂 ${dateStringToShow} é o aniversário de ${childsNamesString}, que participará(ão) da aula de ${childBirthday.atividade}, as ${childBirthday.horario.split('-')[0]}!`);
          });
        }

        const schedulesWithChildrensWithDeficiency = schedulesList.filter((sch) => sch.criancas.some((kid) => !!kid.hasDeficiency))?.map((sch) => ({
            atividade: sch.atividade,
            horario: sch.horario,
            criancas: sch.criancas,
        }));

        if (schedulesWithChildrensWithDeficiency.length > 0) {
          schedulesWithChildrensWithDeficiency.forEach((kidObs) => {
           kidObs.criancas.forEach((kid) => {
            if (kid.needsInterpreter) {
              warningsArray.push(`🧏 A criança ${kid.nome}, que participará da aula de ${kidObs.atividade} ${dateStringToShow.toLowerCase()} ás ${kidObs.horario.split('-')[0]}, necessita de intérprete de libras.`)
            }

            if (kid.observation && kid.hasDeficiency) {
              warningsArray.push(`⚠️ A criança ${kid.nome}, que participará da aula de ${kidObs.atividade} ${dateStringToShow.toLowerCase()} ás ${kidObs.horario.split('-')[0]}, possui uma observação: ${kid.observation}`)
            } else if (!kid.observation && kid.hasDeficiency) {
              warningsArray.push(`⚠️ A criança ${kid.nome}, que participará da aula de ${kidObs.atividade} ${dateStringToShow.toLowerCase()} ás ${kidObs.horario.split('-')[0]}, possui uma deficiência não especificada`)
            }
           })
          })
        }

        if (warningsArray.length > 0) {
          const message = warningsArray.length > 1 ? `Existem ${warningsArray.length} avisos para os agendamentos da data selecionada. Clique no botão avisos para visualizá-los!` : `Existe ${warningsArray.length} aviso para os agendamentos da data selecionada. Clique no botão avisos para visualizá-los`;
          setWarnings(warningsArray);
          toast.warn(message, {
            toastId: 'warnings'
          })
        }
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar os agendamentos',
    })
  }, [apiCall, currentDate]);

  useEffect(() => {
    getScheduleList();
  }, [getScheduleList]);

  return {
    isLoadLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    activitiesList,
    partnerOptions,
    userOptions,
    getScheduleList,
    warnings,
    setIsLoadLoading,
  };
}
