import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { CupomFromApiObject } from "../../types";
import splitArray from "../../../../utils/splitArray";

interface UseSearchesI {
  fullCupons: CupomFromApiObject[];
  setCuponsSplited: Dispatch<SetStateAction<Array<CupomFromApiObject[]>>>;
  setFilteredCupons: Dispatch<SetStateAction<CupomFromApiObject[]>>;
}

export default function useSearches({ fullCupons, setCuponsSplited, setFilteredCupons }: UseSearchesI) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({ value: '', label: 'Filtrar por empresa' });

  const filterCupons = useCallback(() => {
    const filteredActivities = fullCupons.filter((cp) => {
      const searchFilter = cp.name.toLowerCase().includes(String(searchTerm).toLowerCase());
      const companyFilter = cp.company?.id === selectedCompany.value || selectedCompany.value === '';
      return searchFilter && companyFilter;
    });

    const cuponsSplitedbyFilters = splitArray(filteredActivities);
    setCuponsSplited(cuponsSplitedbyFilters);
    setFilteredCupons(cuponsSplitedbyFilters[0]);
  }, [fullCupons, setCuponsSplited, setFilteredCupons, searchTerm, selectedCompany.value]);

  useEffect(() => {
    filterCupons();
  }, [filterCupons]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleCompanyChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCompany(event);
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
  }
}
