import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const CommentsContainer = styled.div`
  max-height: 355px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
  /* border-radius: 8px; */
  `;

export const AddCommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-top: 16px;
  border-top: 2px solid ${({ theme }) => theme.colors.gray[75]};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0px;
  margin-right: 16px;

  div.title {
    margin: 4px 0px;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 16px;
    }
    img {
      &:hover {
        opacity: 0.6
      }
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      width: 21px;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 2px;
  }
`;
