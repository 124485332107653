import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../../components/Modal';

interface IChangeAllPermissionsModal {
  actionOfPermissionChange: 'lock' | 'allow';
  setChangeAllPermissionsModalShow: Dispatch<SetStateAction<boolean>>;
  changeAllDownloadPermissions: () => Promise<void>;
  changeAllPermissionsModalShow: boolean;
}

export default function ChangeAllPermissionsModal({
  actionOfPermissionChange,
  setChangeAllPermissionsModalShow,
  changeAllDownloadPermissions,
  changeAllPermissionsModalShow,
}: IChangeAllPermissionsModal) {
  return (
    <MyModal
      title={`${actionOfPermissionChange === 'allow' ? 'Liberar' : 'Bloquear'} Todos os Downloads de Relatórios`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={actionOfPermissionChange === 'allow' ? 'Liberar' : 'Bloquear'}
      modalBody={(
        <>
          <div>
            Tem certeza que deseja
            {' '}
            {`${actionOfPermissionChange === 'allow' ? 'liberar' : 'bloquear'} todos os relatórios deste período de serem baixados pelos parceiros e financeiro?`}
          </div>
          <br />
        </>
)}
      onClose={() => setChangeAllPermissionsModalShow(false)}
      onAction={() => changeAllDownloadPermissions()}
      show={changeAllPermissionsModalShow}
      type={actionOfPermissionChange === 'allow' ? 'activateAction' : 'suspendAction'}
    />
  );
}
