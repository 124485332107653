import { format } from 'date-fns';
import MyModal from '../../../components/Modal';
import { GivenClassType } from '../types';

interface IDeleteGivenClassModal {
  givenClassBeingDeleted: GivenClassType | null;
  handleCloseDeleteModal: () => void;
  deleteGivenClass: () => Promise<void>;
  deleteModalShow: boolean;
}

export default function DeleteGivenClassModal({
  givenClassBeingDeleted,
  handleCloseDeleteModal,
  deleteGivenClass,
  deleteModalShow,
}: IDeleteGivenClassModal) {
  const formattedDate = `${format(new Date(givenClassBeingDeleted?.date || new Date()), 'dd/MM/yyyy')} das ${givenClassBeingDeleted?.startTime} às ${givenClassBeingDeleted?.endTime}`;
  return (
    <MyModal
      title={`Remover Aula Realizada`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Remover"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja remover a aula de
            {' '}
            <strong>{givenClassBeingDeleted?.activity.nome}</strong>
            {' '}
            realizada no dia
            {' '}
            <strong>{formattedDate}</strong>
            {' '}
            pelo parceiro
            {' '}
            <strong>{givenClassBeingDeleted?.partner.nome_fantasia}</strong>
            ?
          </div>
          <br />
        </>
)}
      onClose={() => handleCloseDeleteModal()}
      onAction={() => deleteGivenClass()}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
