import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import PageHeader from '../../components/PageHeader';
import CompaniesForm from './components/CompaniesForm';

export default function NewCompany() {
  return (
    <>
      <Sidebar active="Companies" />
      <Transitions>
        <PageHeader
          title="Nova Empresa"
          link="/companies"
        />
        <CompaniesForm edit={false} />
      </Transitions>
    </>
  );
}
