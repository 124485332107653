/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart
} from 'victory';
import expand from '../../../assets/images/icons/expand.svg';
import MyModal from '../../../components/Modal';
import NoData from '../../../components/NoData';
import OpacityAnimation from '../../../components/OpacityAnimation';
import { Card } from '../styles';
import { CompaniesUsageType } from '../types';


interface CompaniesChartCard {
  companiesUsage: CompaniesUsageType
}

export default function CompaniesChartCard({
  companiesUsage,
}: CompaniesChartCard) {
  const [expandedCompaniesChart, setExpandedCompaniesChart] = useState(false);

  const resumedCompaniesUsage: { [key: string]: number | string } = {};
  let count = 0;
  let others = 0;
  for (const [key, value] of Object.entries(companiesUsage)) {
    if (count < 4) {
      resumedCompaniesUsage[key] = Number(value);
    } else {
      others += Number(parseFloat(String(value)));
    }
    count++;
  }
  resumedCompaniesUsage.Outros = others;
  // eslint-disable-next-line max-len
  const companiesUsageDataToArray = resumedCompaniesUsage && Object.entries(resumedCompaniesUsage);
  const domainPaddingValue = 108 / companiesUsageDataToArray.length;

  const fullCompaniesUsageDataToArray = companiesUsage && Object.entries(companiesUsage);
  const fullDomainPaddingValue = 150 / companiesUsageDataToArray.length;

  return (
    <Card>
      <div className="card-title">
        <div>Comparação de uso entre as empresas</div>
        <img
          src={expand}
          alt=""
          title="Expandir gráfico"
          onClick={() => setExpandedCompaniesChart(true)}
          className="primaryColor"
        />
      </div>

      {(!!companiesUsage && companiesUsageDataToArray.length > 0) && (
        <>
          <OpacityAnimation>
            <div className="card-main-graph">
              <VictoryChart
                height={200}
                width={400}
                padding={{
                  top: 25, bottom: 30, left: 30, right: 30,
                }}
                domainPadding={domainPaddingValue}
                maxDomain={{ y: 100 }}
              >
                <VictoryBar
                  style={{
                    data: { fill: '#4aafff' },
                    parent: { border: 'none' },
                    labels: {
                      fontSize: 15,
                    },
                  }}
                  categories={
                { x: companiesUsageDataToArray.map((data) => data[0]) }
              }
                  data={companiesUsageDataToArray.map((data) => (
                    { x: `${data[0]}`, y: data[1], label: `${Number(data[1]).toFixed(1)} %` }
                  ))}
                  animate={{
                    duration: 5000,
                    onLoad: { duration: 3000 },
                  }}
                />
              </VictoryChart>
            </div>
          </OpacityAnimation>

          <MyModal
            show={expandedCompaniesChart}
            title="Comparação de uso entre as empresas"
            closeButtonLabel="Fechar"
            onClose={() => setExpandedCompaniesChart(false)}
            size="xl"
            modalBody={(
              <div className="card-main-graph">
                <VictoryChart
                  height={300}
                  width={800}
                  padding={{
                    top: 25, bottom: 30, left: 30, right: 30,
                  }}
                  domainPadding={fullDomainPaddingValue}
                  maxDomain={{ y: 100 }}
                >
                  <VictoryBar
                    style={{
                      data: { fill: '#4aafff' },
                      parent: { border: 'none' },
                      labels: {
                        fontSize: 12,
                      },
                    }}
                    categories={
                      { x: fullCompaniesUsageDataToArray.map((data) => data[0]) }
                    }
                    data={fullCompaniesUsageDataToArray.map((data) => (
                      { x: `${data[0]}`, y: data[1] }
                    ))}
                    animate={{
                      duration: 5000,
                      onLoad: { duration: 3000 },
                    }}
                    labels={({ datum }) => {
                      const label = `${datum.y.toFixed()} %`;
                      // Limitar o label a 10 caracteres e adicionar "..."
                      return label.length > 5 ? `${label.substring(0, 5)}...` : label;
                    }}
                  />

                  <VictoryAxis
                    dependentAxis
                  />

                  <VictoryAxis
                    tickFormat={(t) => {
                      const label = t.toString(); // Converter o valor do eixo para string se necessário
                      // Limitar o label a 10 caracteres e adicionar "..."
                      return label.length > 5 ? `${label.substring(0, 5)}...` : label;
                    }}
                    style={{
                      tickLabels: {
                        fontSize: 12,
                      },
                    }}
                  />
                </VictoryChart>
              </div>
        )}
          />
        </>
      )}

      {(!companiesUsage || companiesUsageDataToArray.length === 0) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre o uso comparativo entre as empresas
          </>
                  )}
      />
      )}
    </Card>
  );
}
