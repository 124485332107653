import { Dispatch, SetStateAction, useCallback, useState } from "react"
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import { InternalUserObject } from "../../types";
import internalUsersService from "../../../../services/internalUsersService";

interface UseDeleteI {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadInternalUsers: () => Promise<void>;
}

export default function useDelete({ setIsLoading, loadInternalUsers }: UseDeleteI) {
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [internalUserBeingDeleted, setInternalUserBeingDeleted] = useState({} as InternalUserObject);

  const { apiCall } = useApiCall();

  function handleOpenDeleteModal(internalUserBeingDeleted: InternalUserObject) {
    setDeleteModalShow(true);
    setInternalUserBeingDeleted(internalUserBeingDeleted);
  }

  const deleteInternalUser = useCallback(async () => {
    await apiCall({
      apiToCall: internalUsersService.deleteInternalUser,
      queryParams: { id: internalUserBeingDeleted.id },
      onStartLoad: () => setIsLoading(true),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível remover o usuário. Por favor, tente novamente');
          setIsLoading(false);
          return;
        }
        toast.success('O usuário foi removido com sucesso!');
        setDeleteModalShow(false);
        loadInternalUsers();
      },
      catchMessage: 'Não foi possível remover o usuário. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, internalUserBeingDeleted.id, loadInternalUsers, setIsLoading]);

  return {
    handleOpenDeleteModal,
    internalUserBeingDeleted,
    setDeleteModalShow,
    deleteInternalUser,
    deleteModalShow,
  }
}
