import { Col, Container, Row } from "react-bootstrap";
import Button from '../../../../components/Button';
import { ButtonContainer } from "../../../../components/Form";
import Loader from "../../../../components/Loader";
import PageHeader from "../../../../components/PageHeader";
import GeneralDataCard from "./components/GeneralDataCard";
import useGivenClassesForm from "./useGivenClassesForm";

interface IGivenClassesForm {
  isEdit: boolean;
}

export default function GivenClassesForm({ isEdit = false }: IGivenClassesForm) {
  const {
    isLoading,
    selectedActivity,
    selectedPartner,
    selectedDateTime,
    partnerOptions,
    setSelectedPartner,
    activityOptions,
    setSelectedActivity,
    dateTimeOptions,
    setSelectedDateTime,
    isFormValid,
    addGivenClass,
    updateGivenClass,
    selectedFeatPartner,
    setSelectedFeatPartner,
  } = useGivenClassesForm({ isEdit });

  return (
    <>
      <Loader isLoading={isLoading} />
      {isEdit && (
        <PageHeader
          title={`Editar Aula Realizada`}
          link="/givenClasses"
        />
      )}

      <Container>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <GeneralDataCard
              selectedActivity={selectedActivity}
              selectedPartner={selectedPartner}
              selectedDateTime={selectedDateTime}
              partnerOptions={partnerOptions}
              setSelectedPartner={setSelectedPartner}
              activityOptions={activityOptions}
              setSelectedActivity={setSelectedActivity}
              dateTimeOptions={dateTimeOptions}
              setSelectedDateTime={setSelectedDateTime}
              isEdit={isEdit}
              selectedFeatPartner={selectedFeatPartner}
              setSelectedFeatPartner={setSelectedFeatPartner}
            />
          </Col>
        </Row>

        <ButtonContainer>
          {!isEdit && (
            <Button
              disabled={!isFormValid}
              onClick={() => addGivenClass()}
            >
              {`Adicionar Aula Realizada`}
            </Button>
          )}

          {isEdit && (
            <Button
              disabled={!isFormValid}
              onClick={() => updateGivenClass()}
            >
              {`Confirmar Troca do Parceiro que Realizou a Aula`}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </>
  )
}
