import trophyFirst from '../../../assets/images/icons/trophyFirst.svg';
import trophySecond from '../../../assets/images/icons/trophySecond.svg';
import trophyThird from '../../../assets/images/icons/trophyThird.svg';
import { Card, RankingList } from '../styles';

interface ActivitiesRankingCardInterface {
  ranking: string[]
  label: string
}

export default function ActivitiesRankingCard({ ranking, label }: ActivitiesRankingCardInterface) {
  return (
    <Card>
      <div className="card-title">
        <div>{label}</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!ranking && (
        <RankingList>
          {ranking[0] && (
            <div>
              <img src={trophyFirst} alt="" />
              <span>{ranking[0]}</span>
            </div>
          )}
          {ranking[1] && (
            <div>
              <img src={trophySecond} alt="" />
              <span>{ranking[1]}</span>
            </div>
          )}
          {ranking[2] && (
            <div>
              <img src={trophyThird} alt="" />
              <span>{ranking[2]}</span>
            </div>
          )}
          {ranking[3] && (
            <div>
              <strong>#4</strong>
              <span>{ranking[3]}</span>
            </div>
          )}
          {ranking[4] && (
            <div>
              <strong>#5</strong>
              <span>{ranking[4]}</span>
            </div>
          )}
        </RankingList>
      )}

      {(!ranking || ranking.length === 0) && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <div>😢</div>
          <label htmlFor="">Ranking não disponível</label>
        </div>
      )}
    </Card>
  );
}
