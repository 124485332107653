/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import FilterRadioButton from "../../../../components/FilterRadioButtons";
import { FilterRadioButtonsContainer } from "../../../../components/FilterRadioButtonsContainer";
import MyModal from "../../../../components/Modal";
import OpacityAnimation from "../../../../components/OpacityAnimation";
import { Group } from "../../styles";
import { InternalUserObject } from "../../types";
import { ModalContainer } from "./styles";
import useEditModal from "./useEditModal";

interface IInternalUserModal {
  editModalShow: boolean;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  internalUserObjectBeingEditted: InternalUserObject;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadInternalUsers: () => Promise<void>;
}

export default function EditModal({
  editModalShow,
  setEditModalShow,
  setIsLoading,
  loadInternalUsers,
  internalUserObjectBeingEditted,
}: IInternalUserModal) {
  const {
    editInternalUser,
    permission,
    setPermission,
  } = useEditModal({ setIsLoading, loadInternalUsers, setEditModalShow, internalUserObjectBeingEditted });

  return (
    <MyModal
      show={editModalShow}
      onClose={() => setEditModalShow(false)}
      title={`Editar perfil do usuário ${internalUserObjectBeingEditted.email}`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
                Perfil do usuário
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton
                  selected={permission === 'super'}
                  onClick={() => setPermission('super')}
                >
                  Administrador
                </FilterRadioButton>
                <FilterRadioButton
                  selected={permission === 'coordinator'}
                  onClick={() => setPermission('coordinator')}
                >
                  Coordenador
                </FilterRadioButton>
                <FilterRadioButton
                  selected={permission === 'support'}
                  onClick={() => setPermission('support')}
                >
                  Suporte
                </FilterRadioButton>
                <FilterRadioButton
                  selected={permission === 'financial'}
                  onClick={() => setPermission('financial')}
                >
                  Financeiro
                </FilterRadioButton>
                <FilterRadioButton
                  selected={permission === 'analytics'}
                  onClick={() => setPermission('analytics')}
                >
                  Analítico
                </FilterRadioButton>
                <FilterRadioButton
                  selected={permission === 'comercial'}
                  onClick={() => setPermission('comercial')}
                >
                  Comercial
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>
          </OpacityAnimation>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Editar"
      onAction={() => editInternalUser()}
    />
  )
}
