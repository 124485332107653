/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import useThemeContext from '../../../../contexts/theme';
import { Container, SelectContainer } from './styles';

import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';

import { Dispatch, SetStateAction } from 'react';
import filter from '../../../../assets/images/icons/filter.svg';
import { ActivityOptionType, PartnerOptionType, PeriodType } from '../../types';
import ChangePeriodModal from './components/ChangePeriodModal';

interface FiltersInterface {
  partnerOptions: PartnerOptionType[];
  setSelectedPartner: Dispatch<SetStateAction<PartnerOptionType>>;
  selectedPartner: PartnerOptionType;
  setSelectedPeriod: Dispatch<SetStateAction<PeriodType>>;
  periodBeingShown: PeriodType;
  activitiesList: ActivityOptionType[];
  selectedActivity: ActivityOptionType;
  setSelectedActivity: Dispatch<SetStateAction<ActivityOptionType>>;
  doesListExists: boolean;
  changePeriodModalShow: boolean;
  setChangePeriodModalShow: Dispatch<SetStateAction<boolean>>;
  periodBeingSelected: PeriodType;
  setPeriodBeingSelected: Dispatch<SetStateAction<PeriodType>>;
}

export default function Filters({
  partnerOptions,
  setSelectedPartner,
  selectedPartner,
  setSelectedPeriod,
  periodBeingShown,
  activitiesList,
  selectedActivity,
  setSelectedActivity,
  doesListExists,
  changePeriodModalShow,
  setChangePeriodModalShow,
  periodBeingSelected,
  setPeriodBeingSelected,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();

  const monthsMap: { [key: number]: string } = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return (
    <>
      <Container>
        <header>
          <img src={filter} alt="filter" className="primaryColor" />
          <div>Pesquise através dos filtros abaixo</div>
        </header>

        <div>
          {activitiesList.length > 0 && doesListExists && (
            <SelectContainer>
              <Select
                value={{ value: selectedPartner.value, label: selectedPartner.label === 'Todos' ? 'Filtrar por parceiro' : selectedPartner.label }}
                options={partnerOptions}
                onChange={(opt) => setSelectedPartner(opt!)}
                placeholder="Filtrar por parceiro"
                styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
                // menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="react-select-container-as-filter"
              />
              <Select
                value={{ value: selectedActivity.value, label: selectedActivity.label === 'Todas' ? 'Filtrar por atividade' : selectedActivity.label, hasFeatPartner: selectedActivity.hasFeatPartner }}
                options={activitiesList}
                onChange={(opt) => setSelectedActivity(opt!)}
                placeholder="Filtrar por atividade"
                styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
                // menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="react-select-container-as-filter"
              />
            </SelectContainer>
          )}
          <FilterRadioButtonsContainer flexDirection="column">
            <FilterRadioButton
              sameWidth
              onClick={() => setChangePeriodModalShow(true)}
              selected
            >
              Alterar Período
              {` (${monthsMap[periodBeingShown.month]} de ${periodBeingShown.year})`}
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
        </div>
      </Container>

      <ChangePeriodModal
        changePeriodModalShow={changePeriodModalShow}
        setChangePeriodModalShow={setChangePeriodModalShow}
        periodBeingSelected={periodBeingSelected}
        setPeriodBeingSelected={setPeriodBeingSelected}
        setSelectedPeriod={setSelectedPeriod}
      />
    </>
  );
}
