import useDeleteDates from "./functions/useDeleteDates";
import useDisableDate from "./functions/useDisableDate";
import useDownloadExcel from "./functions/useDownloadExcel";
import useLoadDates from "./functions/useLoadDates"
import useSearches from "./functions/useSearches";
import useUploadFile from "./functions/useUploadFile";

export default function useDisabledDates() {
  const {
    fullDates,
    filteredDates,
    isLoadLoading,
    doesListApiHasError,
    setFilteredDates,
    handleTryAgain,
    loadDates,
  } = useLoadDates();

  const {
    searchTerm,
    handleChangeSearchTerm,
  } = useSearches({ fullDates, setFilteredDates });

  const {
    isSubmittingFile,
    csvFileName,
    modalImportCsvShow,
    csvAction,
    setCsvAction,
    handleFileUpload,
    handleSubmitFile,
    setModalImportCsvShow,
  } = useUploadFile({ loadDates });

  const {
    isDeleting,
    modalDeleteShow,
    setModalDeleteShow,
    dateBeingDeleted,
    handleOpenDeleteModal,
    deleteDate,
  } = useDeleteDates({ loadDates });

  const {
    isAdding,
    addDateModalShow,
    setAddDateModalShow,
    handleAddDateModalClose,
    dateToDisable,
    handleDateToDisableChange,
    disableDate,
  } = useDisableDate({ loadDates });

  const { downloadExcel } = useDownloadExcel({ fullDates });

  const isLoading = isLoadLoading || isSubmittingFile || isDeleting || isAdding;

  return {
    fullDates,
    filteredDates,
    isLoading,
    doesListApiHasError,
    searchTerm,
    handleChangeSearchTerm,
    downloadExcel,
    setAddDateModalShow,
    handleOpenDeleteModal,
    handleTryAgain,
    addDateModalShow,
    dateToDisable,
    handleDateToDisableChange,
    handleAddDateModalClose,
    disableDate,
    dateBeingDeleted,
    setModalDeleteShow,
    deleteDate,
    modalDeleteShow,
    modalImportCsvShow,
    setCsvAction,
    csvAction,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose: () => setModalImportCsvShow(false),
    setImportCsvModalShow: setModalImportCsvShow,
  }
}
