import MyModal from "../../../../components/Modal";
import { DownloadRelatoryPermissionType } from "../types";

interface IChangeDownloadRelatoryPermissionModal {
  relatoryToChangePermission: DownloadRelatoryPermissionType | null;
  changePermissionModalShow: boolean;
  handleCloseChangePermissionModal: () => void;
  changeRelatoryDownloadPermission: (relatoryToChangePermission: DownloadRelatoryPermissionType) => Promise<void>;
}

export default function ChangeDownloadRelatoryPermissionModal({
  relatoryToChangePermission,
  changePermissionModalShow,
  handleCloseChangePermissionModal,
  changeRelatoryDownloadPermission,
}: IChangeDownloadRelatoryPermissionModal) {
  if (!relatoryToChangePermission) return null;

  return (
    <MyModal
      title={`Alterar Permissão de Download do Relatório`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Alterar"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja alterar a permissão de download do relatório financeiro do parceiro
            {' '}
            <strong>{relatoryToChangePermission.partnerName}</strong>
            ?
            {' '}
            Fazendo isso, o relatório ficará, ou deixará de ficar disponível para download para o Parceiro e Financeiro (de acordo com o status atual do mesmo).
          </div>
        </>
)}
      onClose={() => handleCloseChangePermissionModal()}
      onAction={() => changeRelatoryDownloadPermission(relatoryToChangePermission)}
      show={changePermissionModalShow}
      type={relatoryToChangePermission.isAllowed ? 'suspendAction' : 'activateAction'}
    />
)
}
