import { useCallback } from "react"
import { MappedCategoriesType } from "../../types";
import getCurrentDateWithHyphen from "../../../../../utils/getCurrentDateWithHyphen";
import ExportXlsx from "../../../../../utils/ExportXlsx";

interface IUseDownloadExcel {
  categories: MappedCategoriesType[]
}

export default function useDownloadExcel({ categories }: IUseDownloadExcel) {
  const downloadExcel = useCallback(() => {
    const mappedCategories = categories.map((ctg) => ({
      Categoria: ctg.name,
      Tipo: ctg.type,
    }));
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Categorias Kiddlepass - ${currentDate}`,
      data: mappedCategories,
    });
  }, [categories]);

  return { downloadExcel }
}
