import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import activitiesService from '../../../../services/activitiesService';
import { ApprovementDetailsType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseApprovementDecisiionInterface {
  getApprovements: () => void;
}

interface ApprovementDetailsInterface {
  approvementId: string;
  activityId: string;
  name: string;
  descricao?: string;
  datasDesabilitadas?: {
    acao: string;
    data: string;
  };
  minima?: number;
  maxima?: number;
  field: 'descrição' | 'datas desabilitadas' | 'idade';
}

type fieldsMapType = Record<string, string>

export default function useApprovementDecision({ getApprovements }: UseApprovementDecisiionInterface) {
  const [isMakingDecision, setIsMakingDecision] = useState(false);
  const [approvementDetails, setApprovementDetails] = useState<ApprovementDetailsType>({} as ApprovementDetailsType);
  const [detailModalShow, setDetailModalShow] = useState(false);

  const { user } = useAppContext();
  const { apiCall } = useApiCall();

  function handleOpenDetailModal({
    approvementId,
    activityId,
    name,
    minima,
    maxima,
    field,
    descricao,
    datasDesabilitadas,
  }: ApprovementDetailsInterface) {
    setApprovementDetails({
      approvementId,
      activityId,
      name,
      field,
      minima,
      maxima,
      descricao,
      datasDesabilitadas,
    });
    setDetailModalShow(true);
  }

  function handleCloseDetailModal() {
    setApprovementDetails({} as ApprovementDetailsInterface);
    setDetailModalShow(false);
  }

  const handleApprovementUpdateDecision = useCallback(async (approve: boolean) => {
    const fieldsMap: fieldsMapType = {
      descrição: 'descricao',
      idade: 'idade',
      'datas desabilitadas': 'datasDesabilitadas',
    };
    await apiCall({
      apiToCall: activitiesService.handleActivityApprovement,
      reqBody: JSON.stringify({
        approve_id: approvementDetails.approvementId,
        activity_id: approvementDetails.activityId,
        action: 'update',
        approve,
        permission: user.permission,
        field: fieldsMap[approvementDetails.field],
        minima: approvementDetails.field === 'idade' ? approvementDetails.minima : null,
        maxima: approvementDetails.field === 'idade' ? approvementDetails.maxima : null,
        descricao: approvementDetails.field === 'descrição' ? approvementDetails.descricao : null,
        datasDesabilitadas: approvementDetails.field === 'datas desabilitadas' ? approvementDetails.datasDesabilitadas : null,
      }),
      onStartLoad: () => {
        setDetailModalShow(false);
        setIsMakingDecision(true);
      },
      onEndLoad: () => setIsMakingDecision(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível ${approve ? 'aprovar' : 'rejeitar'} a alteração. Por favor, tente novamente`, { toastId: 'errorApprovementDecision' });
          setIsMakingDecision(false);
          setDetailModalShow(true);
          return;
        }
        toast.success(`A alteração foi ${approve ? 'aprovada' : 'rejeitada'} com sucesso!`, { toastId: 'approvementAction' });
        getApprovements();
      },
      catchAction: () => {
        setDetailModalShow(true);
      },
      catchMessage: `Não foi possível ${approve ? 'aprovar' : 'rejeitar'} a alteração. Por favor, tente novamente`,
    })
  // eslint-disable-next-line max-len
  }, [apiCall, approvementDetails.activityId, approvementDetails.approvementId, approvementDetails.datasDesabilitadas, approvementDetails.descricao, approvementDetails.field, approvementDetails.maxima, approvementDetails.minima, getApprovements, user.permission]);

  return {
    isMakingDecision,
    handleOpenDetailModal,
    detailModalShow,
    handleCloseDetailModal,
    approvementDetails,
    handleApprovementUpdateDecision,
  };
}
