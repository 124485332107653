/* eslint-disable react/forbid-prop-types */
import { VictoryPie } from 'victory';

import { useState } from 'react';
import NoData from '../../../components/NoData';
import OpacityAnimation from '../../../components/OpacityAnimation';
import useThemeContext from '../../../contexts/theme';
import { Card } from '../styles';
import { PercentageKindsType } from '../types';

interface UsersKindCardInterface {
  percentageKinds: PercentageKindsType;
}

export default function UsersKindCard({ percentageKinds }: UsersKindCardInterface) {
  const [labelBeingShow, setLabelBeingShow] = useState({ description: '', value: '' });
  const { selectedTheme } = useThemeContext();

  return (
    <Card>
      <div className="card-title">
        <div>Tipos de usuários (B2B x B2C)</div>
      </div>

      {!!percentageKinds && (
      <>
        <OpacityAnimation>
        <div className="card-main-graph">
          <VictoryPie
            data={[
              {
                x: ' B2B',
                y: percentageKinds.b2b.percentage,
              },
              {
                x: ' B2C',
                y: percentageKinds.b2c.percentage,
              },
            ]}
            animate={{
              duration: 2000,
              onLoad: { duration: 5000 },
            }}
            colorScale={['#003f5c', '#bc5090']}
            innerRadius={80}
            height={250}
            padAngle={0}
            padding={{ bottom: 10, top: 0 }}
            events={[{
              target: 'data',
              eventHandlers: {
                onMouseOver: () => {
                  return [
                    {
                      target: 'data',
                      mutation: ({ style, data, index }) => {
                        setLabelBeingShow({ description: data[index].x, value: data[index].y });
                        return { style: Object.assign({}, style, { opacity: '0.8', cursor: 'help' }) };
                      }
                    }
                  ];
                },
                onMouseOut: () => {
                  return [
                    {
                      target: 'data',
                      mutation: () => {
                        setLabelBeingShow({ description: '', value: '' });
                        return null;
                      }
                    }
                  ];
                }
              }
            }]}
            style={{
              labels: { fontSize: 0, fill: selectedTheme === 'dark' ? '#fff' : '#000' }
            }}
            labelPlacement="vertical"
            labelRadius={0.1}
            labelPosition="endAngle"
          />
          <label>
            <span>{labelBeingShow.value ? `${Number(labelBeingShow.value).toFixed(0)}%` : ''}</span>
            <span>{labelBeingShow.description}</span>
          </label>
        </div>
        </OpacityAnimation>
      </>
      )}

      {(!percentageKinds) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre o tipo dos usuários
          </>
                  )}
      />
      )}
    </Card>
  );
}
