import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UseDashboardDataInterface } from '../interfaces';
import useApiCall from '../../../../hooks/useApiCall';

export default function useKidsAverage({ setHasError }: UseDashboardDataInterface) {
  const [kidsAverage, setKidsAverage] = useState(0);

  const { apiCall } = useApiCall();

  const getKidsAverage = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersKidsAverage,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar a média de crianças por plano', {
            toastId: 'getKidsDashboardFail',
          });
          return;
        }
        const average = apiResponse.result;
        setKidsAverage(average);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar a média de crianças por plano',
    })
  }, [apiCall, setHasError]);

  return {
    kidsAverage,
    getKidsAverage,
  };
}
