/* eslint-disable react/jsx-no-useless-fragment */
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';

import NoData from '../../../components/NoData';
import PageHeader from '../../../components/PageHeader';
import { useQuery } from '../../../hooks/useQuery';
import ActivesCard from './components/ActivesCard';
import CompanySelection from './components/CompanySelection';
import KidsAgeCard from './components/KidsAgeCard';
import KidsAverageCard from './components/KidsAverageCard';
import RankingCard from './components/RankingCard';
import RatingsCard from './components/RatingsCard';
import useDashboard from './useDashboard';

export default function Dashboard() {
  const {
    isLoading,
    collaboratorsInfo,
    kidsInfo,
    ranking,
    ratings,
    hasCollaboratorsInfoError,
    hasKidsInfoError,
    hasRatingsError,
    hasRankingError,
    handleTryAgain,
    associatedCompanies,
    selectedCompany,
    handleCompanyChange,
  } = useDashboard();

  const hasSomeError = (
    hasCollaboratorsInfoError
    || hasKidsInfoError
    || hasRatingsError
    || hasRankingError);

  const { companyName } = useParams();
  const query = useQuery();
  const isReadOnly = query.has('readOnly');
  const viewByAssociatedCompanies = query.has('viewByAssociatedCompanies');

  return (
    <>
      <Sidebar active="Companies" />
      <Transitions>
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <>
            <PageHeader
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              title={`Dashboard ${decodeURIComponent(companyName!)}`}
              link="/companies"
            />
            {viewByAssociatedCompanies && !hasSomeError && (
              <CompanySelection
                companyOptions={associatedCompanies}
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
              />
            )}
            {!hasSomeError && (
            <Container>
              <Row xs={1} md={isReadOnly ? 2 : 3} lg={isReadOnly ? 2 : 3}>
                {!isReadOnly && (
                  <Col>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.15, type: 'tween', stiffness: 10000 }}
                    >
                      <ActivesCard
                        actives={collaboratorsInfo.active}
                      />
                    </motion.div>
                  </Col>
                )}

                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                  >
                    <KidsAverageCard
                      average={Number(kidsInfo.average)}
                    />
                  </motion.div>
                </Col>

                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.45, type: 'tween', stiffness: 10000 }}
                  >
                    <RatingsCard
                      rating={ratings.ratings}
                    />
                  </motion.div>
                </Col>
              </Row>

              <Row xs={1} md={2} lg={2}>
                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.75, type: 'tween', stiffness: 10000 }}
                  >
                    <KidsAgeCard
                      ages={kidsInfo.age}
                    />
                  </motion.div>
                </Col>

                <Col>
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.9, type: 'tween', stiffness: 10000 }}
                  >
                    <RankingCard
                      ranking={ranking}
                    />
                  </motion.div>
                </Col>
              </Row>
            </Container>
            )}

            {hasSomeError && (
              <NoData
                icon="sad"
                label={(
                  <>
                    Ocorreu um erro ao obter os dados do dashboard.
                    <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                    .
                  </>
  )}
              />
            )}
          </>
        )}
      </Transitions>
    </>
  );
}
