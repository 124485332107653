import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import useFinancial from './useFinancial';
import ViewKind from './components/ViewKind';
import Loader from '../../components/Loader';
import OpacityAnimation from '../../components/OpacityAnimation';
import CompanyView from './components/CompanyView';
import PartnerView from './components/PartnerView';
import NoData from '../../components/NoData';

export default function Financial() {
  const {
    viewKind,
    setViewKind,
    companiesOptions,
    partnersOptions,
    handleSelectedCompanyChange,
    handleSelectedPartnerChange,
    selectedCompany,
    selectedPartner,
    doesListApiHasError,
    isLoading,
    companyData,
    partnerData,
    companyDataError,
    partnerDataError,
    handleTryGetPartnerDataAgain,
    handleTryGetCompanyDataAgain,
    reloadCompanyData,
    reloadPartnerData,
    startDate,
    agreedAmounth,
    classPrice,
  } = useFinancial();

  const hasPartnerData = (!doesListApiHasError && viewKind === 'partner' && selectedPartner.value && !partnerDataError && partnerData?.id);
  const noPartnerSelected = !selectedPartner.value && viewKind === 'partner';
  const errorAtGettingPartnerData = partnerDataError && viewKind === 'partner';
  const doesntHavePartnerData = !partnerData?.id && !doesListApiHasError && viewKind === 'partner' && selectedPartner.value && !partnerDataError;

  const hasCompanyData = (!doesListApiHasError && viewKind === 'company' && selectedCompany.value && !companyDataError && companyData?.id && startDate);
  const companyDoesntHaveStartDate = (!doesListApiHasError && viewKind === 'company' && selectedCompany.value && !companyDataError && companyData?.id && !startDate);
  const noCompanySelected = !selectedCompany.value && viewKind === 'company';
  const errorAtGettingCompanyData = companyDataError && viewKind === 'company';
  const doesntHaveCompanyData = !companyData?.id && !doesListApiHasError && viewKind === 'company' && selectedCompany.value && !companyDataError;

  return (
    <>
      <Sidebar active="Financial" />
      <Loader isLoading={isLoading} />
      <Transitions>
        {!doesListApiHasError && !isLoading && (
          <ViewKind
            viewKind={viewKind}
            setViewKind={setViewKind}
            companiesOptions={companiesOptions}
            partnersOptions={partnersOptions}
            handleSelectedCompanyChange={handleSelectedCompanyChange}
            handleSelectedPartnerChange={handleSelectedPartnerChange}
            selectedCompany={selectedCompany}
            selectedPartner={selectedPartner}
          />
        )}

        {hasCompanyData && (
          <OpacityAnimation delay={0.05}>
            <CompanyView
              companyData={companyData}
              reloadCompanyData={reloadCompanyData}
              startDate={startDate}
              agreedAmounth={agreedAmounth}
            />
          </OpacityAnimation>
        )}

        {noCompanySelected && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Por favor, selecione uma empresa para visualizar os dados financeiros da mesma.
            </>
)}
        />
        )}

        {errorAtGettingCompanyData && (
        <NoData
          icon="sad"
          label={(
            <>
              Ocorreu um erro ao obter os dados financeiros da empresa.
              <button type="button" onClick={handleTryGetCompanyDataAgain}>Tentar Novamente</button>
            </>
)}
        />
        )}

        {doesntHaveCompanyData && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Esta empresa não possui dados financeiros.
              </>
)}
          />
        )}

        {companyDoesntHaveStartDate && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Esta empresa não possui uma data de início do contrato.
              </>
)}
          />
        )}

        {hasPartnerData && (
          <OpacityAnimation delay={0.05}>
            <PartnerView
              partnerData={partnerData}
              reloadPartnerData={reloadPartnerData}
              classPrice={classPrice}
            />
          </OpacityAnimation>
        )}

        {noPartnerSelected && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Por favor, selecione um parceiro para visualizar os dados financeiros do mesmo.
            </>
)}
        />
        )}

        {errorAtGettingPartnerData && (
        <NoData
          icon="sad"
          label={(
            <>
              Ocorreu um erro ao obter os dados financeiros do parceiro.
              <button type="button" onClick={handleTryGetPartnerDataAgain}>Tentar Novamente</button>
            </>
)}
        />
        )}

        {doesntHavePartnerData && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Este parceiro não possui dados financeiros.
              </>
)}
          />
        )}

      </Transitions>
    </>
  );
}
