import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import PartnersForm from '../components/PartnersForm';

export default function EditPartner() {
  return (
    <>
      <Sidebar active="Partners" />
      <Transitions>
        <PartnersForm edit />
      </Transitions>
    </>
  );
}
