import styled from "styled-components";

export const DeleteCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
`
