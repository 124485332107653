import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import forumInternalService from "../../../../services/forumInternalService";
import { PostType } from "../../types";

interface IUseEditPostModal {
  loadForum: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleCloseEditModal: () => void;
  post: PostType;
  editPostModalShow: boolean;
}

export default function useEditPostModal({
  loadForum,
  setIsLoading,
  handleCloseEditModal,
  post,
  editPostModalShow,
}: IUseEditPostModal) {
  const [postText, setPostText] = useState(post.text);

  const { apiCall } = useApiCall();

  function handlePostTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setPostText(event.target.value);
  }

  const editPost = useCallback(async () => {
    await apiCall({
      apiToCall: forumInternalService.updatePost,
      queryParams: { id: post.id },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      reqBody: JSON.stringify({ text: postText }),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível editar o post. Por favor, tente novamente');
          return;
        }
        setPostText('');
        handleCloseEditModal();
        await loadForum();
        toast.success('Post editado com sucesso!');
      },
    })
  }, [apiCall, handleCloseEditModal, loadForum, post.id, postText, setIsLoading]);

  useEffect(() => {
    setPostText(post.text)
  }, [editPostModalShow]);

  return {
    postText,
    handlePostTextChange,
    editPost,
  }
}
