import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../../../../components/FilterRadioButtons';
import MyModal from '../../../../../../components/Modal';
import { PeriodType } from '../../../../types';
import { MonthsGrid } from './styles';

interface IChangePeriodModal {
  periodBeingSelected: PeriodType;
  setPeriodBeingSelected: Dispatch<SetStateAction<PeriodType>>;
  setSelectedPeriod: Dispatch<SetStateAction<PeriodType>>;
  changePeriodModalShow: boolean;
  setChangePeriodModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function ChangePeriodModal({
  changePeriodModalShow,
  setChangePeriodModalShow,
  periodBeingSelected,
  setPeriodBeingSelected,
  setSelectedPeriod,
}: IChangePeriodModal) {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const monthsMap: { [key: number]: string } = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  console.log(currentMonth, currentYear);

  return (
    <MyModal
      show={changePeriodModalShow}
      type="action"
      title="Alterar Período"
      centeredBody
      modalBody={(
        <div className="modal-body-centered">
          <div className="year-selection">
            <FilterRadioButton
              onClick={() => {
                setPeriodBeingSelected((prevState) => ({
                  ...prevState,
                  year: Number(currentYear) - 2
                }));
              }}
              selected={periodBeingSelected.year == Number(currentYear) - 2}
            >
              {String(Number(currentYear) - 2)}
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => {
                setPeriodBeingSelected((prevState) => ({
                  ...prevState,
                  year: Number(currentYear) - 1
                }));
              }}
              selected={periodBeingSelected.year == Number(currentYear) - 1}
            >
              {String(Number(currentYear) - 1)}
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => {
                setPeriodBeingSelected((prevState) => ({
                  ...prevState,
                  year: Number(currentYear)
                }));
              }}
              selected={periodBeingSelected.year == Number(currentYear)}
            >
              {currentYear}
            </FilterRadioButton>
          </div>
          <MonthsGrid>
            {months.map((month) => (
                <FilterRadioButton
                  key={month}
                  onClick={() => setPeriodBeingSelected((prevState) => ({
                    ...prevState,
                    month,
                  }))}
                  selected={month == periodBeingSelected.month}
                  invisible={currentYear === periodBeingSelected.year && month > currentMonth}
                >
                  {monthsMap[month]}
                </FilterRadioButton>
            ))}
          </MonthsGrid>
        </div>
      )}
      size="lg"
      onAction={() => {
        setSelectedPeriod(periodBeingSelected);
        setChangePeriodModalShow(false);
      }}
      onClose={() => setChangePeriodModalShow(false)}
      actionButtonLabel="Alterar Período"
      closeButtonLabel="Cancelar"
    />
  );
}
