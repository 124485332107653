import useApprovementDecision from './functions/useApprovementDecision';
import useLoadApprovements from './functions/useLoadApprovements';
import useSearch from './functions/useSearch';

export default function useActivitiesApprove() {
  const {
    isLoadLoading,
    approvements,
    getApprovements,
    hasError,
  } = useLoadApprovements();

  const {
    searchTerm,
    handleChangeSearchTerm,
    filteredApprovements,
  } = useSearch({ approvements });

  const {
    isMakingDecision,
    handleOpenDetailModal,
    detailModalShow,
    handleCloseDetailModal,
    approvementDetails,
    handleApprovementUpdateDecision,
  } = useApprovementDecision({ getApprovements });

  function handleTryAgain() {
    getApprovements();
  }

  const isLoading = isLoadLoading || isMakingDecision;

  return {
    isLoading,
    hasError,
    approvements,
    handleTryAgain,
    filteredApprovements,
    searchTerm,
    handleChangeSearchTerm,
    handleOpenDetailModal,
    detailModalShow,
    handleCloseDetailModal,
    approvementDetails,
    handleApprovementUpdateDecision,
  };
}
