import { Dispatch, SetStateAction } from 'react';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
  renderAddModalShowButtonTrigger: boolean;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  setAddModalShow,
  renderAddModalShowButtonTrigger,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <>
            <div className="leftAlignment">
              <strong>
                {filteredListLength}
                {' '}
                {filteredListLength === 1 ? singularLabel : pluralLabel}
              </strong>
            </div>
          </>
        )}

        {renderAddModalShowButtonTrigger && (
          <div className="actionButtons">
              <button type="button" onClick={() => setAddModalShow(true)}>
                Nova Programação Pedagógica
              </button>
          </div>
        )}
      </Header>
    </OpacityAnimation>
  );
}
