import { Col, Row } from 'react-bootstrap';
import Header from './components/Header';
import usePartnerView from './usePartnerView';

import Loader from '../../../../components/Loader';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { CurrrentMonthBeingShowType, PartnerFinancialDetailsFromApi } from '../../types';
import AgreedAmounthCard from './components/AgreedAmounthCard';
import ChangeDateModal from './components/ChangeDateModal';
import DueDateCard from './components/DueDateCard';
import NfDateCard from './components/NfDateCard';
import PaymentStatusCard from './components/PaymentStatusCard';
import PaymentVoucherUploadModal from './components/PaymentVoucherUploadModal';
import StartDateCard from './components/StartDateCard';

interface PartnerViewInterface {
  partnerData: PartnerFinancialDetailsFromApi;
  reloadPartnerData: () => void;
  classPrice: string;
}

export default function PartnerView({
  partnerData,
  reloadPartnerData,
  classPrice,
}: PartnerViewInterface) {
  const {
    currentYear,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    paymentVoucherUploadModalShow,
    setPaymentVoucherUploadModalShow,
    handlePaymentVoucherFileSubmit,
    handlePaymentVoucherFileUpload,
    paymentVoucherFileName,
    isLoading,
    getFinancialRelatoryPreview,
  } = usePartnerView({
    reloadPartnerData,
    partnerId: partnerData.partner_id,
  });

  const startDate = '01/04/2023';

  // const agreedAmounth = 'R$ 53.578,00';
  const dueDate = `08/${String(currentMonthBeingShow).length === 2 ? currentMonthBeingShow : `0${currentMonthBeingShow}`}/${currentYearBeingShow}`;

  const paymentInfoAtSelectedDate = partnerData.comps.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const paymentDateInJsFormat = (paymentInfoAtSelectedDate
    ? new Date(paymentInfoAtSelectedDate?.data_envio) : null);
  const paymentDate = paymentDateInJsFormat?.toLocaleDateString('pt-BR');

  const nfInfoAtSelectedDate = partnerData.nfs.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const nfDateInJsFormat = (nfInfoAtSelectedDate
    ? new Date(nfInfoAtSelectedDate?.data_envio) : null);
  const nfDate = nfDateInJsFormat?.toLocaleDateString('pt-BR');

  const hasFinancialRelatoryAllowedForDownload = partnerData.partner.allowedRelatoryPeriodsDownload.includes(`${currentMonthBeingShow}/${currentYearBeingShow}`);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Header
        currentMonthBeingShow={currentMonthBeingShow as CurrrentMonthBeingShowType}
        currentYearBeingShow={currentYearBeingShow}
        handleDateModalShow={setDateModalShow}
        isNfUploaded={!!nfInfoAtSelectedDate}
        nfDownloadLink={nfInfoAtSelectedDate?.link}
        isPaymentVoucherUploaded={!!paymentInfoAtSelectedDate}
        paymentVoucherLink={paymentInfoAtSelectedDate?.link}
        handlePaymentVoucherModalShow={setPaymentVoucherUploadModalShow}
        hasFinancialRelatoryAllowedForDownload={hasFinancialRelatoryAllowedForDownload}
        getFinancialRelatoryPreview={getFinancialRelatoryPreview}
      />

      <Row xs={1} md={2} lg={2}>
        <Col>
          <OpacityAnimation delay={0.15}>
            <StartDateCard
              startDate={startDate}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.3}>
            <AgreedAmounthCard
              agreedAmounth={classPrice}
            />
          </OpacityAnimation>
        </Col>
      </Row>

      <Row xs={1} md={2} lg={3}>
        <Col>
          <OpacityAnimation delay={0.45}>
            <DueDateCard
              dueDate={dueDate}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.6}>
            <PaymentStatusCard
              paymentDate={paymentDate}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.75}>
            <NfDateCard
              nfDate={nfDate}
            />
          </OpacityAnimation>
        </Col>
      </Row>

      <ChangeDateModal
        currentYear={Number(currentYear)}
        dateModalShow={dateModalShow}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        selectedMonth={selectedMonth}
        currentMonth={currentMonth}
        setCurrentMonthBeingShow={setCurrentMonthBeingShow}
        setCurrentYearBeingShow={setCurrentYearBeingShow}
        setDateModalShow={setDateModalShow}
      />

      <PaymentVoucherUploadModal
        paymentVoucherUploadModalShow={paymentVoucherUploadModalShow}
        paymentVoucherFileName={paymentVoucherFileName}
        handlePaymentVoucherFileUpload={handlePaymentVoucherFileUpload}
        handlePaymentVoucherFileSubmit={handlePaymentVoucherFileSubmit}
        setPaymentVoucherUploadModalShow={setPaymentVoucherUploadModalShow}
      />
    </>
  );
}
