import { ChangeEvent } from 'react';
import FileInput from '../../../../components/FileInput';
import MyModal from '../../../../components/Modal';

interface ImportCsvModalInterface {
  modalImportCsvShow: boolean;
  csvFileName: string;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmitFile: () => void;
  handleImportModalClose: () => void;
}

export default function ImportCsvModal({
  modalImportCsvShow,
  csvFileName,
  handleFileUpload,
  handleSubmitFile,
  handleImportModalClose,
}: ImportCsvModalInterface) {
  return (
    <MyModal
      show={modalImportCsvShow}
      title="Importar categorias por CSV"
      centeredBody
      modalBody={(
        <>
          <FileInput
            buttonLabel="Importar arquivo"
            fileNameChoosed={csvFileName}
            isSubmitButtonDisabled={!csvFileName}
            onFileUpload={handleFileUpload}
            onSubmitFile={handleSubmitFile}
            acceptedFiles=".csv"
          />
        </>
      )}
      closeButtonLabel="Fechar"
      onClose={handleImportModalClose}
      type="info"
    />
  );
}
