import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import { isBefore, isToday } from 'date-fns';
import disabledDatesService from '../../../../services/disabledDatesService';
import formatDateToUsa from '../../../../utils/formatDateToUsa';

interface UseDisableDateI {
  loadDates: () => void;
}

export default function useDisableDate({ loadDates }: UseDisableDateI) {
  const [isAdding, setIsAdding] = useState(false);
  const [addDateModalShow, setAddDateModalShow] = useState(false);
  const [dateToDisable, setDateToDisable] = useState('');

  const { apiCall } = useApiCall();

  function handleDateToDisableChange(event: ChangeEvent<HTMLInputElement>) {
    const selectedDateValue = event.target.value;
    const selectedDate = new Date(selectedDateValue);

    if (isBefore(selectedDate, new Date())) {
      toast.warn('Não é possível desabilitar uma data do passado');
      return;
    } else if (isToday(selectedDate)) {
      toast.warn('Não é possível desabilitar a data de hoje');
      return;
    } else {
      setDateToDisable(selectedDateValue);
    }
  }

  function handleAddDateModalClose() {
    setAddDateModalShow(false);
  }

  const disableDate = useCallback(async () => {
    await apiCall({
      apiToCall: disabledDatesService.disableDates,
      reqBody: JSON.stringify({
        dates: [formatDateToUsa(dateToDisable)],
      }),
      onStartLoad: () => {
        setAddDateModalShow(false);
        setIsAdding(true);
      },
      onEndLoad: () => {
        setIsAdding(false);
        setDateToDisable('');
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsAdding(false);
          toast.error('Não foi possível desabilitar a data');
          return;
        }
        loadDates();
        toast.success('Data desabilitada com sucesso');
      },
      catchMessage: 'Não foi possível desabilitar a data'
    });
  }, [apiCall, dateToDisable, loadDates]);

  return {
    isAdding,
    addDateModalShow,
    setAddDateModalShow,
    handleAddDateModalClose,
    dateToDisable,
    handleDateToDisableChange,
    disableDate,
  };
}
