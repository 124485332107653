/* eslint-disable max-len */
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
// import FormGroup from '../../components/FormGroup';
import ChangeDateModal from './components/ChangeDateModal';
import Filters from './components/Filters';
import ListHeader from './components/header';
import KidsListModal from './components/KidsListModal';
import List from './components/list';
import NewScheduleModal from './components/NewScheduleModal';
import RemoveScheduleModal from './components/RemoveScheduleModal';
import WarningsModal from './components/WarningsModal';
import useSchedules from './useSchedules';

export default function Schedules() {
  const {
    isLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    getScheduleList,
    dates,
    specificDateModalShow,
    setSpecificDateModalShow,
    selectedDate,
    handleDateChange,
    activitiesList,
    filteredSchedules,
    setSelectedActitvity,
    selectedActivity,
    kidsModalShow,
    setKidsModalShow,
    scheduleKidsListBeingShow,
    setScheduleKidsListBeingShow,
    handleTryAgain,
    userOptions,
    partnerOptions,
    setSelectedPartner,
    setSelectedUser,
    selectedPartner,
    selectedUser,
    setSchedulesRemoveBeingShow,
    setSchedulesRemoveModalShow,
    schedulesRemoveBeingShow,
    schedulesRemoveModalShow,
    setSelectedScheduleIdToRemove,
    selectedScheduleIdToRemove,
    removeSchedule,
    downloadFullExcel,
    downloadPageExcel,
    warnings,
    warningsModalShow,
    setWarningsModalShow,
    setNewScheduleModalShow,
    newScheduleModalShow,
    setIsLoading,
  } = useSchedules();

  const listLength = schedules.length;
  const filteredListLength = filteredSchedules.length;
  const hasSchedules = listLength !== 0 && !!schedules;
  const isListEmpty = !doesListApiHasError && (!isLoading && filteredListLength === 0);

  const appointmentsQuantityAtEachClass = filteredSchedules.map((schedule) => schedule.criancas.length);
  const appointmentsQuantity = appointmentsQuantityAtEachClass.reduce((acc, cur) => acc + cur, 0);

  const selectedDay = selectedDate.split('-')[2];
  const selectedMonth = selectedDate.split('-')[1];
  const selectedYear = selectedDate.split('-')[0];
  const formattedSelectedDate = `${selectedDay}/${selectedMonth}/${selectedYear}`;

  return (
    <>
      <Sidebar active="Schedules" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {!doesListApiHasError && (
          <>
            <Filters
              userOptions={userOptions}
              partnerOptions={partnerOptions}
              setSelectedUser={setSelectedUser}
              setSelectedPartner={setSelectedPartner}
              handleDateChange={getScheduleList}
              dateBeingShown={dateBeingShown}
              dates={dates}
              handleModalSpecificDateShow={() => setSpecificDateModalShow(true)}
              activitiesList={activitiesList}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActitvity}
              doesListExists={hasSchedules}
              selectedPartner={selectedPartner}
              selectedUser={selectedUser}
            />

            <ListHeader
              doesListExists={hasSchedules}
              hasError={doesListApiHasError}
              filteredListLength={filteredListLength}
              appointmentsQuantity={appointmentsQuantity}
              singularLabel="aula"
              pluralLabel="aulas"
              downloadFullExcel={downloadFullExcel}
              downloadPageExcel={downloadPageExcel}
              warnings={warnings}
              setWarningsModalShow={setWarningsModalShow}
              setNewScheduleModalShow={setNewScheduleModalShow}
            />
          </>
        )}

        {!isListEmpty && !doesListApiHasError && (
          <List
            filteredList={filteredSchedules}
            setKidsModalShow={setKidsModalShow}
            setScheduleKidsListBeingShow={setScheduleKidsListBeingShow}
            setSchedulesRemoveBeingShow={setSchedulesRemoveBeingShow}
            setSchedulesRemoveModalShow={setSchedulesRemoveModalShow}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum agendamento no dia
                {' '}
                <strong>{dateBeingShown}</strong>
                {' '}
                Para os filtros:
                {selectedActivity.value && (
                  <>
                    {' '}
                    Atividade
                    {' '}
                    <strong>{selectedActivity.label}</strong>
                  </>
                )}
                {selectedPartner.value && (
                  <>
                    {' '}
                    Parceiro
                    {' '}
                    <strong>{selectedPartner.label}</strong>
                  </>
                )}
                {selectedUser.value && (
                  <>
                    {' '}
                    Usuário
                    {' '}
                    <strong>{selectedUser.label}</strong>
                  </>
                )}
                {'. '}
                Por favor, tente mudar seus critérios de busca.
              </>
)}
          />
        )}

        {doesListApiHasError && (
        <NoData
          icon="sad"
          label={(
            <>
              Ocorreu um erro ao obter os agendamentos.
              <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
              .
            </>
)}
        />
        )}

        <ChangeDateModal
          specificDateModalShow={specificDateModalShow}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          setSpecificDateModalShow={setSpecificDateModalShow}
          getScheduleList={getScheduleList}
          formattedSelectedDate={formattedSelectedDate}
        />

        <NewScheduleModal
          newScheduleModalShow={newScheduleModalShow}
          setNewScheduleModalShow={setNewScheduleModalShow}
          setIsLoading={setIsLoading}
          reloadPage={getScheduleList}
        />

        <WarningsModal
          warnings={warnings}
          warningsModalShow={warningsModalShow}
          setWarningsModalShow={setWarningsModalShow}
        />

        {!isLoading && (
          <>
            <KidsListModal
              kidsModalShow={kidsModalShow}
              setKidsModalShow={setKidsModalShow}
              scheduleKidsListBeingShow={scheduleKidsListBeingShow}
            />

            <RemoveScheduleModal
              schedulesRemoveModalShow={schedulesRemoveModalShow}
              setSchedulesRemoveModalShow={setSchedulesRemoveModalShow}
              setSelectedScheduleIdToRemove={setSelectedScheduleIdToRemove}
              removeSchedule={removeSchedule}
              selectedScheduleIdToRemove={selectedScheduleIdToRemove}
              schedulesRemoveBeingShow={schedulesRemoveBeingShow}
            />
          </>
        )}
      </Transitions>
    </>
  );
}
