import { motion } from 'framer-motion';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import {
  InputSearchContainer, SearchContainer,
} from './styles';
import { ChangeEvent } from 'react';
import { SelectedStatusType } from '../../types';

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
  handleStatusChange: (status: SelectedStatusType) => void;
  selectedStatus: SelectedStatusType;
}

export default function Search({
  searchTerm,
  onChangeSearchTerm,
  handleStatusChange,
  selectedStatus,
}: SearchInterface) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar parceiro"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>

        <FilterRadioButtonsContainer>
          <FilterRadioButton
            selected={selectedStatus === 'Todos'}
            onClick={() => handleStatusChange('Todos')}
          >
            Todos
          </FilterRadioButton>

          <FilterRadioButton
            selected={selectedStatus === 'Ativo'}
            onClick={() => handleStatusChange('Ativo')}
          >
            Ativos
          </FilterRadioButton>

          <FilterRadioButton
            selected={selectedStatus === 'Inativo'}
            onClick={() => handleStatusChange('Inativo')}
          >
            Inativos
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
      </SearchContainer>
    </motion.div>
  );
}
