import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Financial from '../pages/Financial';
import NotFound from '../pages/NotFound';

// import Financial from '../pages/Financial';

export default function FinancialRoute() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />

        <Route path="/financial" element={<Financial />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
