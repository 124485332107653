import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import partnersService from '../../../../services/partnersService';
import { PartnerChangingStatusType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseUpdatePartnerStatusInterface {
  loadPartners: () => void;
}

interface UpdatePartnerStatusInterface {
  partnerId: string;
  ativo: boolean;
}

export default function useUpdatePartnerStatus({
  loadPartners,
}: UseUpdatePartnerStatusInterface) {
  const [isChangingPartnerStatus, setIsChangingPartnerStatus] = useState(false);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [partnerChangingStatus, setPartnerChangingStatus] = useState<PartnerChangingStatusType>({} as PartnerChangingStatusType);

  const { apiCall } = useApiCall();

  const updatePartnerStatus = useCallback(async ({ partnerId, ativo }: UpdatePartnerStatusInterface) => {
    await apiCall({
      apiToCall: partnersService.updatePartnerStatus,
      queryParams: { partnerId, ativo },
      onStartLoad: () => setIsChangingPartnerStatus(true),
      onEndLoad: () => {
        setIsChangingPartnerStatus(false);
        setChangeStatusModalShow(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse?.success) {
          toast.error('Não foi possível alterar o status do parceiro. Por favor, tente novamente');
          return;
        }
        toast.success(`O parceiro foi ${ativo ? 'reativado' : 'inativado'} com sucesso`);
        setChangeStatusModalShow(false);
        loadPartners();
      },
      catchMessage: 'Não foi possível alterar o status do parceiro, Por favor, tente novamente'
    })
  }, [apiCall, loadPartners]);

  function handleOpenChangeStatusModal({ name, id, status }: PartnerChangingStatusType) {
    setChangeStatusModalShow(true);
    setPartnerChangingStatus({
      name,
      id,
      status,
    });
  }

  return {
    isChangingPartnerStatus,
    changeStatusModalShow,
    partnerChangingStatus,
    updatePartnerStatus,
    handleOpenChangeStatusModal,
    setChangeStatusModalShow,
  };
}
