/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Select from 'react-select';
import 'react-range-slider-input/dist/style.css';

import { Container, SelectContainer } from './styles';
import useThemeContext from '../../../../contexts/theme';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';

import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import FilterRadioButton from '../../../../components/FilterRadioButtons';

import filter from '../../../../assets/images/icons/filter.svg';
import { ActivityOptionType, PartnerOptionType, UserOptionType } from '../../types';
import { Dispatch, SetStateAction } from 'react';

interface FiltersInterface {
  userOptions: UserOptionType[];
  partnerOptions: PartnerOptionType[];
  setSelectedUser: Dispatch<SetStateAction<UserOptionType>>;
  setSelectedPartner: Dispatch<SetStateAction<PartnerOptionType>>;
  selectedPartner: PartnerOptionType;
  selectedUser: UserOptionType;
  handleDateChange: (date: string) => void;
  dateBeingShown: string;
  dates: {
    currentDate: string;
    tomorrowDate: string;
    afterTomorrowDate: string;
  };
  handleModalSpecificDateShow: () => void;
  activitiesList: ActivityOptionType[];
  selectedActivity: ActivityOptionType;
  setSelectedActivity: Dispatch<SetStateAction<ActivityOptionType>>;
  doesListExists: boolean;
}

export default function Filters({
  userOptions,
  partnerOptions,
  setSelectedUser,
  setSelectedPartner,
  selectedPartner,
  selectedUser,
  handleDateChange,
  dateBeingShown,
  dates,
  handleModalSpecificDateShow,
  activitiesList,
  selectedActivity,
  setSelectedActivity,
  doesListExists,
}: FiltersInterface) {
  const isSpecificDateButtonSelected = (
    dateBeingShown !== dates.currentDate
    && dateBeingShown !== dates.tomorrowDate
    && dateBeingShown !== dates.afterTomorrowDate
  );
  const { selectedTheme } = useThemeContext();

  return (
    <Container>
      <header>
        <img src={filter} alt="filter" className="primaryColor" />
        <div>Pesquise através dos filtros abaixo</div>
      </header>

      <div>
        {activitiesList.length > 0 && doesListExists && (
          <SelectContainer>
            <Select
              value={{ value: selectedActivity.value, label: selectedActivity.label === 'Todas' ? 'Filtrar por atividade' : selectedActivity.label }}
              options={activitiesList}
              onChange={(opt) => setSelectedActivity(opt!)}
              placeholder="Filtrar por atividade"
              styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
              // menuPortalTarget={document.body}
              classNamePrefix="react-select"
              className="react-select-container-as-filter"
            />
            <Select
              value={{ value: selectedPartner.value, label: selectedPartner.label === 'Todos' ? 'Filtrar por parceiro' : selectedPartner.label }}
              options={partnerOptions}
              onChange={(opt) => setSelectedPartner(opt!)}
              placeholder="Filtrar por parceiro"
              styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
              // menuPortalTarget={document.body}
              classNamePrefix="react-select"
              className="react-select-container-as-filter"
            />
            <Select
              value={{ value: selectedUser.value, label: selectedUser.label === 'Todos' ? 'Filtrar por usuário' : selectedUser.label }}
              options={userOptions}
              onChange={(opt) => setSelectedUser(opt!)}
              placeholder="Filtrar por usuário"
              styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
              // menuPortalTarget={document.body}
              classNamePrefix="react-select"
              className="react-select-container-as-filter"
            />
          </SelectContainer>
        )}
        <FilterRadioButtonsContainer flexDirection="column">
          <FilterRadioButton
            sameWidth
            onClick={() => handleDateChange(dates.currentDate)}
            selected={dateBeingShown === dates.currentDate}
            smallText={isSpecificDateButtonSelected}
          >
            Hoje
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleDateChange(dates.tomorrowDate)}
            selected={dateBeingShown === dates.tomorrowDate}
            sameWidth
            smallText={isSpecificDateButtonSelected}
          >
            Amanhã
          </FilterRadioButton>
          <FilterRadioButton
            nowrap
            onClick={() => handleDateChange(dates.afterTomorrowDate)}
            selected={dateBeingShown === dates.afterTomorrowDate}
            sameWidth
            smallText={isSpecificDateButtonSelected}
          >
            Depois de amanhã
          </FilterRadioButton>
          <FilterRadioButton
            sameWidth
            onClick={handleModalSpecificDateShow}
            selected={isSpecificDateButtonSelected}
            smallText={isSpecificDateButtonSelected}
          >
            Data específica
            {isSpecificDateButtonSelected && (
              ` (${dateBeingShown})`
            )}
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
      </div>
    </Container>
  );
}
