/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../../components/CustomSelectStyleDarkTheme';
import useThemeContext from '../../../../../contexts/theme';
import { Container, SelectContainer } from './styles';

import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';

import { Dispatch, SetStateAction } from 'react';
import filter from '../../../../../assets/images/icons/filter.svg';
import { PartnerOptionType, PermissionsType } from '../../types';

interface FiltersInterface {
  partnerOptions: PartnerOptionType[];
  setSelectedPartner: Dispatch<SetStateAction<PartnerOptionType>>;
  selectedPartner: PartnerOptionType;
  setSelectedPermission: Dispatch<SetStateAction<PermissionsType>>;
  selectedPermission: PermissionsType;
}

export default function Filters({
  partnerOptions,
  setSelectedPartner,
  selectedPartner,
  setSelectedPermission,
  selectedPermission,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <>
      <Container>
        <header>
          <img src={filter} alt="filter" className="primaryColor" />
          <div>Pesquise através dos filtros abaixo</div>
        </header>

        <div>
          <SelectContainer>
            <Select
                value={{ value: selectedPartner.value, label: selectedPartner.label === 'Todos' ? 'Filtrar por parceiro' : selectedPartner.label }}
                options={partnerOptions}
                onChange={(opt) => setSelectedPartner(opt!)}
                placeholder="Filtrar por parceiro"
                styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
                // menuPortalTarget={document.body}
                classNamePrefix="react-select"
                className="react-select-container-as-filter"
              />
          </SelectContainer>

          <FilterRadioButtonsContainer flexDirection="row">
          <FilterRadioButton
              onClick={() => setSelectedPermission('all')}
              selected={selectedPermission === 'all'}
            >
              Todos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setSelectedPermission('allowed')}
              selected={selectedPermission === 'allowed'}
            >
              Liberados
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setSelectedPermission('forbidden')}
              selected={selectedPermission === 'forbidden'}
            >
              Bloqueados
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
        </div>
      </Container>
    </>
  );
}
