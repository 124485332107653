import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import companiesService from '../../../../services/companiesService';
import { CompanyChangingStatus, HandleOpenChangeStatusModalInterface, UpdateCompanyStatusInterface } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseUpdateCompanyStatusInterface {
  loadCompanies: () => void;
}

export default function useUpdateCompanyStatus({ loadCompanies }: UseUpdateCompanyStatusInterface) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [companyChangingStatus, setCompanyChangingStatus] = useState<CompanyChangingStatus>(
    {} as CompanyChangingStatus
  );

  const { apiCall } = useApiCall();

  const updateCompanyStatus = useCallback(async ({ id, ativo }: UpdateCompanyStatusInterface) => {
    await apiCall({
      apiToCall: companiesService.changeCompanyStatus,
      queryParams: { id, ativo },
      onStartLoad: () => setIsUpdating(true),
      onEndLoad: () => {
        setIsUpdating(false);
        setChangeStatusModalShow(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar o status da empresa. Por favor, tente novamente');
          return;
        }
        toast.success(`A empresa foi ${ativo ? 'reativada' : 'inativada'} com sucesso`);
        setChangeStatusModalShow(false);
        loadCompanies();
      },
      catchMessage: 'Não foi possível alterar o status da empresa, Por favor, tente novamente',
    })
  }, [apiCall, loadCompanies]);

  function handleOpenChangeStatusModal({ name, id, status }: HandleOpenChangeStatusModalInterface) {
    setChangeStatusModalShow(true);
    setCompanyChangingStatus({
      name,
      id,
      status,
    });
  }

  return {
    isUpdating,
    changeStatusModalShow,
    setChangeStatusModalShow,
    companyChangingStatus,
    updateCompanyStatus,
    handleOpenChangeStatusModal,
  };
}
