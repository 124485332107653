import PropTypes from 'prop-types';
import { Header } from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
            </strong>
          </div>
        )}
      </Header>
    </OpacityAnimation>
  );
}

ListHeader.propTypes = {
  doesListExists: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  filteredListLength: PropTypes.number.isRequired,
  singularLabel: PropTypes.string.isRequired,
  pluralLabel: PropTypes.string.isRequired,
};
