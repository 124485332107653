/* eslint-disable @typescript-eslint/no-empty-function */
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import DeleteGivenClassModal from "./components/DeleteGivenClassModal";
import DownloadRelatoryModal from "./components/DownloadRelatoryModal";
import Filters from "./components/Filters";
import ListHeader from "./components/Header";
import List from "./components/list";
import useGivenClasses from "./useGivenClasses";

export default function GivenClasses() {
  const {
    isLoading,
    doesListApiHasError,
    givenClasses,
    filteredGivenClasses,
    setSelectedPartner,
    selectedPartner,
    activitiesList,
    selectedActivity,
    setSelectedActivity,
    setSelectedPeriod,
    periodBeingShown,
    changePeriodModalShow,
    setChangePeriodModalShow,
    periodBeingSelected,
    setPeriodBeingSelected,
    currentPage,
    pagesQuantity,
    handlePageChange,
    handleTryAgain,
    selectedPeriod,
    handleOpenDeleteModal,
    givenClassBeingDeleted,
    handleCloseDeleteModal,
    deleteGivenClass,
    deleteModalShow,
    downloadPdfRelatory,
    downloadPdfRelatoryByPartner,
    setDownloadRelatoryModalShow,
    downloadRelatoryModalShow,
    partnersWithGivenClassesOpt,
  } = useGivenClasses();

  const listLength = givenClasses?.length || 0;
  const filteredListLength = filteredGivenClasses?.length || 0;
  const hasGivenClasses = listLength !== 0 && !!givenClasses;
  const isListEmpty = !doesListApiHasError && (!isLoading && givenClasses.length === 0);
  const anyResultsByFilterSelection = !filteredGivenClasses && !isLoading && !isListEmpty;

  return (
    <>
      <Sidebar active="GivenClasses" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {!doesListApiHasError && (
          <>
            <Filters
              partnerOptions={partnersWithGivenClassesOpt}
              setSelectedPartner={setSelectedPartner}
              selectedPartner={selectedPartner}
              activitiesList={activitiesList}
              selectedActivity={selectedActivity}
              setSelectedActivity={setSelectedActivity}
              setSelectedPeriod={setSelectedPeriod}
              periodBeingShown={periodBeingShown}
              changePeriodModalShow={changePeriodModalShow}
              setChangePeriodModalShow={setChangePeriodModalShow}
              periodBeingSelected={periodBeingSelected}
              setPeriodBeingSelected={setPeriodBeingSelected}
              doesListExists={hasGivenClasses}
            />

            <ListHeader
              doesListExists={hasGivenClasses}
              hasError={doesListApiHasError}
              filteredListLength={filteredListLength}
              singularLabel="aula"
              pluralLabel="aulas"
              currentPage={currentPage}
              pagesQuantity={pagesQuantity}
              onPageChange={handlePageChange}
              selectedPeriod={selectedPeriod}
              setDownloadRelatoryModalShow={setDownloadRelatoryModalShow}
            />

            {hasGivenClasses && (
              <List
                filteredList={filteredGivenClasses}
                onOpenDeleteModal={handleOpenDeleteModal}
                selectedPeriod={selectedPeriod}
              />
            )}
          </>
        )}

        {anyResultsByFilterSelection && (
            <NoData
              icon="searchNotFound"
              label={(
                <>
                  Não encontramos nenhum resultado conforme os filtros selecionados.
                  Por favor, tente alterar os critérios.
                </>
              )}
            />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as aulas dadas.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma aula realizada no período selecionado, clique no botão
                {' '}
                <strong>Nova Aula</strong>
                {' '}
                acima para incluir!
              </>
          )}
          />
        )}

        <DeleteGivenClassModal
          givenClassBeingDeleted={givenClassBeingDeleted}
          handleCloseDeleteModal={handleCloseDeleteModal}
          deleteGivenClass={deleteGivenClass}
          deleteModalShow={deleteModalShow}
        />

        <DownloadRelatoryModal
          downloadPdfRelatory={downloadPdfRelatory}
          downloadPdfRelatoryByPartner={downloadPdfRelatoryByPartner}
          partnerOptions={partnersWithGivenClassesOpt}
          setDownloadRelatoryModalShow={setDownloadRelatoryModalShow}
          downloadRelatoryModalShow={downloadRelatoryModalShow}
        />
      </Transitions>
    </>
  )
}
