/* eslint-disable no-nested-ternary */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import useThemeContext from '../../../../contexts/theme';
import { Container, Group } from './styles';

import { Dispatch, SetStateAction } from 'react';
import filter from '../../../../assets/images/icons/filter.svg';

interface FiltersInterface {
  companysList: { value: string, label: string }[];
  selectedCompany: { value: string, label: string };
  statusOptions: { value: string, label: string }[];
  selectedStatus: { value: string, label: string };
  handleStatusChange: (status: { value: string, label: string }) => void;
  handleCompanyChange: (company: { value: string, label: string }) => void;
  selectedAgeRange: number[];
  setSelectedAgeRange: Dispatch<SetStateAction<number[]>>;
  handleAgeSelection: (ageRange: number[]) => void;
}

export default function Filters({
  companysList,
  selectedCompany,
  statusOptions,
  selectedStatus,
  handleStatusChange,
  handleCompanyChange,
  selectedAgeRange,
  handleAgeSelection,
  setSelectedAgeRange,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <Container>
      <header>
        <img src={filter} alt="filter" className="primaryColor" />
        <div>Pesquise através dos filtros abaixo</div>
      </header>

      <div>
        <Select
          value={{ value: selectedCompany.value, label: selectedCompany.label === 'Todas' ? 'Filtrar por empresa' : selectedCompany.label }}
          options={companysList}
          onChange={(company) => handleCompanyChange(company as { value: string, label: string })}
          placeholder="Filtrar por empresa"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedStatus.value, label: selectedStatus.label === 'Todos' ? 'Filtrar por status' : selectedStatus.label }}
          options={statusOptions}
          onChange={(status) => handleStatusChange(status as { value: string, label: string })}
          placeholder="Filtrar por status"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />

        <Group>
          <header>
            Idade criança:
            {' '}
            {selectedAgeRange[1] === 13 ? 'Todas' : (
              selectedAgeRange[1] === 1
                ? `${selectedAgeRange[1]} ano`
                : `${selectedAgeRange[1]} anos`
            )}
          </header>
          <RangeSlider
            min={0}
            max={13}
            value={selectedAgeRange}
            onThumbDragEnd={() => handleAgeSelection(selectedAgeRange)}
            onInput={(evt: number[]) => setSelectedAgeRange(evt)}
            className="single-thumb"
            thumbsDisabled={[true, false]}
            rangeSlideDisabled
          />
        </Group>
      </div>
    </Container>
  );
}
