import { Link } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import { Header } from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  pagesQuantity: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  downloadExcel: () => void;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  pagesQuantity = 0,
  currentPage,
  onPageChange,
  downloadExcel,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
            </strong>
            <Pagination
              pagesQuantity={pagesQuantity}
              handlePageChange={onPageChange}
              currentPage={currentPage}
            />
          </div>
        )}
        <div className="actionButtons">
          {doesListExists && (
            <button type="button" onClick={downloadExcel}>Download XLSX</button>
          )}
          <Link to="/companies/new">
            Nova Empresa
          </Link>
        </div>
      </Header>
    </OpacityAnimation>
  );
}
