import styled from "styled-components";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img {
    filter: ${({ theme }) => theme.filters.primary};
    width: 1.3em;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  a {
    color: ${({ theme }) => theme.colors.primary.main};
    text-decoration: none;
    font-weight: bold;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    padding: 4px 9px;
    border-radius: 4px;
    transition: all 0.2s ease-in;
    margin: 0px 4px;
    &:hover {
      background: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.lighterBackground};
    }
    }
`;
