/* eslint-disable max-len */
import { ChangeEvent, useMemo, useState } from 'react';
import { ScheduleKidsListBeingShown } from '../types';
import useDates from './functions/useDates';
import useDownloadExcel from './functions/useDownloadExcel';
import useLoadSchedules from './functions/useLoadSchedules';
import useRemoveSchedule from './functions/useRemoveSchedule';

export default function useSchedules() {
  const [specificDateModalShow, setSpecificDateModalShow] = useState(false);
  const [kidsModalShow, setKidsModalShow] = useState(false);
  const [warningsModalShow, setWarningsModalShow] = useState(false);
  const [newScheduleModalShow, setNewScheduleModalShow] = useState(false);
  const [scheduleKidsListBeingShow, setScheduleKidsListBeingShow] = useState<ScheduleKidsListBeingShown>({} as ScheduleKidsListBeingShown);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedActivity, setSelectedActitvity] = useState({ value: '', label: 'Filtrar por atividade' });

  const [selectedPartner, setSelectedPartner] = useState({ value: '', label: 'Filtrar por parceiro' });
  const [selectedUser, setSelectedUser] = useState({ value: '', label: 'Filtrar por usuário' });

  const [isLoadingNewScheduleModalData, setIsLoadingNewScheduleModalData] = useState(false);

  const dates = useDates();

  const {
    isLoadLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    activitiesList,
    partnerOptions,
    userOptions,
    getScheduleList,
    warnings,
  } = useLoadSchedules();

  const {
    isRemovingSchedule,
    schedulesRemoveBeingShow,
    setSchedulesRemoveBeingShow,
    schedulesRemoveModalShow,
    selectedScheduleIdToRemove,
    setSelectedScheduleIdToRemove,
    removeSchedule,
    setSchedulesRemoveModalShow,
  } = useRemoveSchedule({
    getScheduleList,
    dateBeingShown,
  });

  const {
    downloadFullExcel,
    downloadPageExcel,
  } = useDownloadExcel({
    schedules,
    dateBeingShown,
  });

  const filteredSchedules = useMemo(() => {
    const originalArrayFiltered = schedules.filter((schedule) => (
      (schedule.atividade_id === selectedActivity.value || selectedActivity.value === '')
      && (schedule.parceiro_id === selectedPartner.value || selectedPartner.value === '' || schedule.parceiroFeat_id === selectedPartner.value)
    ));

    const originalArrayFilteredByUser = originalArrayFiltered.map((schedule) => {
      const filteredByUser = schedule.criancas.filter((child) => (
        child.responsavel === selectedUser.value
      ));
      return { ...schedule, criancas: filteredByUser };
    });

    const finalArray = originalArrayFilteredByUser.filter(
      (schedule) => schedule.criancas.length !== 0,
    );

    return selectedUser.value ? finalArray : originalArrayFiltered;
  }, [schedules, selectedActivity.value, selectedPartner.value, selectedUser.value]);

  function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDate(event.target.value);
  }

  function handleTryAgain() {
    getScheduleList();
  }

  const isLoading = isLoadLoading || isRemovingSchedule || isLoadingNewScheduleModalData;

  return {
    isLoading,
    doesListApiHasError,
    schedules,
    dateBeingShown,
    getScheduleList,
    dates,
    specificDateModalShow,
    setSpecificDateModalShow,
    selectedDate,
    handleDateChange,
    activitiesList,
    filteredSchedules,
    setSelectedActitvity,
    selectedActivity,
    kidsModalShow,
    setKidsModalShow,
    scheduleKidsListBeingShow,
    setScheduleKidsListBeingShow,
    handleTryAgain,
    userOptions,
    partnerOptions,
    setSelectedPartner,
    setSelectedUser,
    selectedPartner,
    selectedUser,
    setSchedulesRemoveBeingShow,
    setSchedulesRemoveModalShow,
    schedulesRemoveBeingShow,
    schedulesRemoveModalShow,
    selectedScheduleIdToRemove,
    setSelectedScheduleIdToRemove,
    removeSchedule,
    downloadFullExcel,
    downloadPageExcel,
    warnings,
    warningsModalShow,
    setWarningsModalShow,
    newScheduleModalShow,
    setNewScheduleModalShow,
    setIsLoading: setIsLoadingNewScheduleModalData,
  };
}
