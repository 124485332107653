import { Dispatch, SetStateAction } from "react";
import Select from 'react-select';
import MyModal from "../../../../../components/Modal";
import { Group, ModalContainer } from "./styles";
import OpacityAnimation from "../../../../../components/OpacityAnimation";
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import useAddModal from "./useAddModal";

interface IAddModal {
  addModalShow: boolean;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
  loadCategories: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddModal({
  addModalShow,
  setAddModalShow,
  loadCategories,
  setIsLoading,
}: IAddModal) {
  const {
    name,
    type,
    setType,
    typesOptions,
    getErrorMessageByFieldName,
    handleNameChange,
    addCategory,
    isFormValid,
  } = useAddModal({
    loadCategories,
    setIsLoading,
    setAddModalShow,
  });

  return (
    <MyModal
    show={addModalShow}
    onClose={() => setAddModalShow(false)}
    title={`Adicionar categoria`}
    modalBody={(
      <ModalContainer>
        <OpacityAnimation delay={0.1}>
          <Group>
            <div className="title">
              Tipo da categoria
            </div>
            <Select
              value={{ value: type?.value, label: type?.label }}
              options={[{
                value: '', label: 'Selecione o tipo da categoria'
              }, ...typesOptions]}
              onChange={(opt) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                setType({ value: opt!.value, label: opt!.label });
              }}
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </Group>

          <Group>
            <div className="title">
              Nome da categoria
            </div>
            <FormGroup error={getErrorMessageByFieldName('name')}>
              <Input
                error={getErrorMessageByFieldName('name')}
                value={name}
                onChange={handleNameChange}
                placeholder="Nome da categoria"
              />
            </FormGroup>
          </Group>
        </OpacityAnimation>
      </ModalContainer>
            )}
    closeButtonLabel="Fechar"
    size="lg"
    type='action'
    actionButtonLabel="Adicionar"
    isActionButtonDisabled={!isFormValid}
    onAction={() => addCategory()}
  />
  )
}
