import Loader from '../../components/Loader';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
// import MyModal from '../../components/Modal';
import NoData from '../../components/NoData';
import ChangePartnerStatusModal from './components/ChangePartnerStatusModal';
import DeletePartnerModal from './components/DeletePartnerModal';
import PartnersActivitiesModal from './components/PartnersActivitiesModal';
import UploadImageModal from './components/UploadImageModal';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import usePartners from './usePartners';

export default function Partners() {
  const {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredPartners,
    handleTryAgain,
    fullPartners,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleStatusChange,
    selectedStatus,
    deleteModalShow,
    handleOpenDeleteModal,
    setDeleteModalShow,
    deletePartner,
    partnerBeingDeleted,
    partnerActivitiesBeingShow,
    setPartnerActivitiesModalShow,
    partnerActivitiesModalShow,
    handleOpenPartnerActivitiesModal,
    isPartnerActivitiesLoading,
    setChangeStatusModalShow,
    updatePartnerStatus,
    changeStatusModalShow,
    partnerChangingStatus,
    handleOpenChangeStatusModal,
    downloadExcel,
    handleOpenUploadImageModal,
    uploadImageModalShow,
    imageFileName,
    handleFileUpload,
    handleSubmitFile,
    handleUploadImageModalClose,
    downloadRatings,
  } = usePartners();

  const hasPartners = fullPartners?.length !== 0 && !!fullPartners && !isLoading;
  const filteredListLength = filteredPartners?.length ?? 0;
  const anyResultsByFilterSelection = !filteredPartners && !isLoading;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullPartners.length === 0);

  return (
    <>
      <Sidebar active="Partners" />
      <Transitions>
        <Loader isLoading={isLoading || isPartnerActivitiesLoading} />

        {hasPartners && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            handleStatusChange={handleStatusChange}
            selectedStatus={selectedStatus}
          />
        )}

        <ListHeader
          doesListExists={hasPartners}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="parceiro"
          pluralLabel="parceiros"
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          downloadExcel={downloadExcel}
        />

        {hasPartners && (
          <List
            filteredList={filteredPartners}
            onOpenChangeStatusModal={handleOpenChangeStatusModal}
            onOpenDeleteModal={handleOpenDeleteModal}
            onOpenPartnerActivitiesModal={handleOpenPartnerActivitiesModal}
            onOpenUploadImageModal={handleOpenUploadImageModal}
            downloadRatings={downloadRatings}
          />
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme os filtros selecionados.
                Por favor, tente alterar os critérios.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista dos parceiros.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum parceiro cadastrado, clique no botão
                {' '}
                <strong>Novo parceiro</strong>
                {' '}
                acima para cadastrar seu primeiro!
              </>
)}
          />
        )}

        <DeletePartnerModal
          partnerBeingDeleted={partnerBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deletePartner={deletePartner}
          deleteModalShow={deleteModalShow}
        />

        <PartnersActivitiesModal
          partnerActivitiesBeingShow={partnerActivitiesBeingShow}
          setPartnerActivitiesModalShow={setPartnerActivitiesModalShow}
          partnerActivitiesModalShow={partnerActivitiesModalShow}
        />

        <ChangePartnerStatusModal
          partnerChangingStatus={partnerChangingStatus}
          setChangeStatusModalShow={setChangeStatusModalShow}
          updatePartnerStatus={updatePartnerStatus}
          changeStatusModalShow={changeStatusModalShow}
        />

        <UploadImageModal
          uploadImageModalShow={uploadImageModalShow}
          imageFileName={imageFileName}
          handleFileUpload={handleFileUpload}
          handleSubmitFile={handleSubmitFile}
          handleUploadImageModalClose={handleUploadImageModalClose}
        />
      </Transitions>
    </>
  );
}
