import PropTypes from 'prop-types';
import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from '../../styles';

interface PaymentStatusCardInterface {
  paymentDate?: string;
}

export default function PaymentStatusCard({ paymentDate }: PaymentStatusCardInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);

  const paymentDay = Number(paymentDate?.split('/')[0]);
  const paymentMonth = Number(paymentDate?.split('/')[1]);
  const paymentYear = Number(paymentDate?.split('/')[2]);

  const differenceBetweenDates = paymentDate
    ? (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(paymentYear, Number(paymentMonth - 1), paymentDay),
    }))
    : null;

  const differenceBetweenDatesInDays = differenceBetweenDates?.days;

  return (
    <Card>
      <div className="card-title">
        <div>Status do pagamento</div>
      </div>

      {!!paymentDate && (
      <div className="card-main">
        <div>
          Pago em
          {' '}
          {paymentDate}
        </div>
        <small>
          {differenceBetweenDatesInDays != 0 && (
            `${differenceBetweenDatesInDays} dias atrás`
          )}
          {differenceBetweenDatesInDays == 0 && (
            'Hoje'
          )}
        </small>
      </div>
      )}

      {(!paymentDate) && (
      <div className="card-main">
        <div>
          Pendente
        </div>
        <small>
          Caso já tenha sido enviado, verifique novamente mais tarde.
        </small>
      </div>
      )}
    </Card>
  );
}

PaymentStatusCard.propTypes = {
  paymentDate: PropTypes.string.isRequired,
};
