import Loader from '../../components/Loader';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import NoData from '../../components/NoData';
import ListHeader from './components/header';
import SearchAtPage from './components/searchAtPage';
import List from './components/list';
import DetailChangeModal from './components/DetailChangeModal';
import useActivitiesApprove from './useActivitiesApprove';

export default function ActivitiesApprove() {
  const {
    isLoading,
    hasError,
    approvements,
    handleTryAgain,
    filteredApprovements,
    searchTerm,
    handleChangeSearchTerm,
    handleOpenDetailModal,
    detailModalShow,
    handleCloseDetailModal,
    approvementDetails,
    handleApprovementUpdateDecision,
  } = useActivitiesApprove();

  const hasActivitiesToApprove = approvements.length !== 0;
  const isSearchEmpty = hasActivitiesToApprove && filteredApprovements.length === 0;

  return (
    <>
      <Sidebar active="Approve" />
      <Loader isLoading={isLoading} />
      <Transitions>
        {hasActivitiesToApprove && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasActivitiesToApprove}
          hasError={hasError}
          filteredListLength={filteredApprovements.length}
          singularLabel="Aprovação pendente"
          pluralLabel="Aprovações pendentes"
        />

        {hasActivitiesToApprove && (
          <List
            filteredList={filteredApprovements}
            handleOpenDetailModal={handleOpenDetailModal}
          />
        )}

        {hasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as atividades com aprovações pendentes.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
  )}
          />
        )}

        {isSearchEmpty && !isLoading && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado para
                {' '}
                <strong>{searchTerm}</strong>
              </>
  )}
          />
        )}

        {!hasActivitiesToApprove && !isLoading && !hasError && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma atividade pendente de aprovação
              </>
)}
          />
        )}

        <DetailChangeModal
          detailModalShow={detailModalShow}
          handleCloseDetailModal={handleCloseDetailModal}
          approvementDetails={approvementDetails}
          handleApprovementUpdateDecision={handleApprovementUpdateDecision}
        />
      </Transitions>
    </>
  );
}
