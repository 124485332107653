import styled from "styled-components";
interface GroupInterface {
  link?: boolean;
}

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  div.card-title {
    font-weight: bold;
    font-size: 16px;
    margin-top: -8px;
    margin-left: -4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      font-weight: bold;
      font-size: 16px;
    }

    div.actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }

    img {
      width: 16px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      &:hover {
        opacity: 0.7
      }
    }
  }
`;

export const Group = styled.div<GroupInterface>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 4px 0px;
  margin-right: 16px;

  div.title {
    margin: 4px 0px;
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      font-size: 12px;
    }
    img {
      &:hover {
        opacity: 0.6
      }
      cursor: pointer;
      filter: ${({ theme }) => theme.filters.primary};
      width: 21px;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    max-height: 66px;
    overflow: auto;
    margin-bottom: 2px;
    word-break: ${({ link }) => (link ? 'break-all' : 'keep-all')};
  }
`;

export const AsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;
