/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import usersService from '../../../../../../services/usersService';
import formatDate from '../../../../../../utils/formatDate';
import useApiCall from '../../../../../../hooks/useApiCall';

interface UseUpdateUserInterface {
  email: string;
  name: string;
  cpf: string;
  birthDate: string;
  cellphone: string;
  gender: { value: string, label: string };
  cep: string;
  streetName: string;
  district: string;
  city: string;
  uf: string;
}

export default function useUpdateUser({
  email,
  name,
  cpf,
  birthDate,
  cellphone,
  gender,
  cep,
  streetName,
  district,
  city,
  uf,
}: UseUpdateUserInterface) {
  const [isUpdating, setIsUpdating] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const updateUser = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.updateUser,
      queryParams: { userId: id, },
      reqBody: JSON.stringify({
        email,
        nome: name,
        cpf,
        data_nascimento: formatDate(birthDate),
        celular: cellphone,
        genero: gender.value,
        endereco: {
          cep,
          rua: streetName,
          bairro: district,
          cidade: city,
          UF: uf,
        },
      }),
      onStartLoad: () => setIsUpdating(true),
      onEndLoad: () => setIsUpdating(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível editar o usuário ${name}. Por favor, tente novamente.`);
          return;
        }
        toast.success(`O usuário ${name} foi editado com sucesso!`);
        navigate('/users');
      },
      catchMessage: `Não foi possível editar o usuário ${name}. Por favor, tente novamente`,
    })
  }, [apiCall, birthDate, cellphone, cep, city, cpf, district, email, gender.value, id, name, navigate, streetName, uf]);

  return {
    isUpdating,
    updateUser,
  };
}
