/* eslint-disable @typescript-eslint/no-empty-function */
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { Header } from './styles';

interface ListHeaderInterface {
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  handleOpenChangeAllPermissionsModal: (action: 'allow' | 'lock') => void;
}

export default function ListHeader({
  filteredListLength,
  singularLabel,
  pluralLabel,
  handleOpenChangeAllPermissionsModal,
}: ListHeaderInterface) {

  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={true} apiFetched={true}>
        <div className="leftAlignment">
          <strong>
            {filteredListLength}
            {' '}
            {filteredListLength === 1 ? singularLabel : pluralLabel}
          </strong>
        </div>
        <div className="actionButtons">
          <button type="button" onClick={() => handleOpenChangeAllPermissionsModal('allow')}>
            Liberar Todos
          </button>
          <button type="button" onClick={() => handleOpenChangeAllPermissionsModal('lock')}>
            Bloquear Todos
          </button>
        </div>
      </Header>
    </OpacityAnimation>
  );
}
