import { Dispatch, SetStateAction, useCallback, useState } from "react"
import useApiCall from "../../../../hooks/useApiCall";
import cuponsService from "../../../../services/cuponsService";
import { toast } from "react-toastify";

interface UseDeleteI {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadCupons: () => Promise<void>;
}

export default function useDelete({ setIsLoading, loadCupons }: UseDeleteI) {
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [cupomBeingDeleted, setCupomBeingDeleted] = useState('');
  const [cupomIdBeingDeleted, setCupomIdBeingDeleted] = useState('');
  const [cupomPlanIdBeingDeleted, setCupomPlanIdBeingDeleted] = useState<number | null | string | undefined>('');

  const { apiCall } = useApiCall();

  function handleOpenDeleteModal(cupomName: string, cupomId: string, cupomPlanId?: number | null | string) {
    setDeleteModalShow(true);
    setCupomBeingDeleted(cupomName);
    setCupomIdBeingDeleted(cupomId);
    setCupomPlanIdBeingDeleted(cupomPlanId);
  }

  const deleteCupom = useCallback(async () => {
    await apiCall({
      apiToCall: cuponsService.deleteCupom,
      queryParams: { id: cupomIdBeingDeleted, planId: cupomPlanIdBeingDeleted },
      onStartLoad: () => setIsLoading(true),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível remover o cupom. Por favor, tente novamente');
          setIsLoading(false);
          return;
        }
        toast.success('O cupom foi removido com sucesso!');
        setDeleteModalShow(false);
        loadCupons();
      },
      catchMessage: 'Não foi possível remover o cupom. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, cupomIdBeingDeleted, cupomPlanIdBeingDeleted, loadCupons, setIsLoading]);

  return {
    handleOpenDeleteModal,
    cupomBeingDeleted,
    cupomIdBeingDeleted,
    setDeleteModalShow,
    deleteCupom,
    deleteModalShow,
  }
}
