import { ChangeEvent } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import useThemeContext from '../../../../contexts/theme';
import {
  InputSearchContainer, SearchContainer,
} from './styles';

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCompanyChange: (companyObject: { value: string, label: string }) => void;
  selectedCompany: { value: string, label: string };
  companiesList: { value: string, label: string }[],
}

export default function SearchAtPage({
  searchTerm,
  onChangeSearchTerm,
  selectedCompany,
  companiesList,
  handleCompanyChange,
}: SearchInterface) {
  const { selectedTheme } = useThemeContext();

  return (
    <OpacityAnimation delay={0.1}>
      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar cupom"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>
        <div>
          <Select
            value={{ value: selectedCompany.value, label: selectedCompany.label === 'Todas' ? 'Filtrar por empresa' : selectedCompany.label }}
            options={[
              { value: '', label: 'Todas' },
              ...companiesList
            ]}
            onChange={(company) => handleCompanyChange(company as { value: string, label: string })}
            placeholder="Filtrar por empresa"
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container-as-filter"
          />
        </div>
      </SearchContainer>
    </OpacityAnimation>
  );
}
