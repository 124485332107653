import PropTypes from 'prop-types';
import NoData from '../../../../../components/NoData';
import { Card } from '../../styles';

interface RatingsCard {
  rating?: number;
}

export default function RatingsCard({ rating }: RatingsCard) {
  return (
    <Card>
      <div className="card-title">
        <div>Avaliação média</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!rating && (
      <div className="card-main">
        <div>
          {rating}
          {' '}
          /
          {' '}
          10
        </div>
        <small>
          É a nota dada pelos beneficiários.
        </small>
      </div>
      )}

      {(!rating) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da avaliação dos colaboradores
          </>
                    )}
      />
      )}
    </Card>
  );
}

RatingsCard.propTypes = {
  rating: PropTypes.number.isRequired,
};
