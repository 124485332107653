import React from 'react';
import { Card } from '../styles';
import NoData from '../../../components/NoData';

interface ActivitiesQuantityCardInterface {
  activitiesQty: number;
}

export default function ActivitiesQuantityCard({
  activitiesQty,
}: ActivitiesQuantityCardInterface) {
  const hasInfo = activitiesQty !== 0;

  return (
    <Card>
      <div className="card-title">
        <div>Atividades</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {activitiesQty}
        </div>
        <small>
          Atividades disponíveis
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da quantidade de atividades
          </>
                    )}
      />
      )}
    </Card>
  );
}
