import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import trash from '../../../../../../../../assets/images/icons/trash.svg';
import NoData from '../../../../../../../../components/NoData';
import { CupomObject } from '../../../../types';

interface ListInterface {
  list: CupomObject[];
  onDeleteCupom: (cupom: string) => void;
  edit: boolean;
}

export default function List({
  list,
  onDeleteCupom,
  edit,
}: ListInterface) {
  const cupomKindLiterals: { [key: string]: JSX.Element } = {
    free: <small className='green'>Acesso Grátis</small>,
    discount: <small>Desconto</small>,
    trial: <small className='orange'>Período grátis</small>,
    trial_discount: <small className='gray'>Período + desconto</small>,
  }

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {list?.map((cupom) => (
          <Col key={cupom.cupomName}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{ delay: 0.1, type: 'tween' }}
            >
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{cupom.cupomName}</strong>
                    {cupomKindLiterals[cupom.cupomPlanKind.value]}
                  </div>

                  <span>
                    Valor: {cupom.cupomPlanValue || 'Não definido'}
                  </span>
                  <span>
                    Período (dias): {cupom.cupomPlanTrial || 'Não definido'}
                  </span>
                </div>
                {!edit && (
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => onDeleteCupom(cupom.cupomName)}
                    >
                      <img src={trash} alt="" title="Remover empresa associada" />
                    </button>
                  </div>
                )}
              </Card>
            </motion.div>
          </Col>
        ))}

        {list.length === 0 && (
          <NoData
            icon='emptyBox'
            label={<>Sem cupons criados</>}
          />
        )}
      </Row>
    </Container>
  );
}
