import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { InternalUserObject } from "../../types";
import useApiCall from "../../../../hooks/useApiCall";
import { toast } from "react-toastify";
import internalUsersService from "../../../../services/internalUsersService";

interface UseEditModalI {
  loadInternalUsers: () => Promise<void>;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  internalUserObjectBeingEditted: InternalUserObject;
}

export default function useEditModal({ setIsLoading, loadInternalUsers, setEditModalShow, internalUserObjectBeingEditted }: UseEditModalI) {
  const [permission, setPermission] = useState(internalUserObjectBeingEditted.permission);

  const { apiCall } = useApiCall();

  const editInternalUser = useCallback(async () => {
    await apiCall({
      apiToCall: internalUsersService.updateInternalUser,
      queryParams: { id: internalUserObjectBeingEditted.id },
      onStartLoad: () => { setIsLoading(true) },
      reqBody: JSON.stringify({
        permission,
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Usuário editado com sucesso!');
          setEditModalShow(false);
          loadInternalUsers()
          return;
        }
        toast.error('Não foi possível editar o usuário. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível editar o usuário. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, internalUserObjectBeingEditted.id, loadInternalUsers, permission, setEditModalShow, setIsLoading])


  useEffect(() => { setPermission(internalUserObjectBeingEditted.permission) }, [internalUserObjectBeingEditted.permission]);

  return {
    editInternalUser,
    permission,
    setPermission,
  }
}
