import { ChangeEvent, useMemo, useState } from 'react';
import { ApprovementType } from '../../types';

interface UseSearchInterface {
  approvements: ApprovementType[];
}

export default function useSearch({
  approvements,
}: UseSearchInterface) {
  const [searchTerm, setSearchTerm] = useState('');

  function handleChangeSearchTerm(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }
  const filteredApprovements = useMemo(() => approvements?.filter((appr) => (
    appr.name.toLowerCase().includes(searchTerm.toLowerCase())
  )), [approvements, searchTerm]);

  return {
    searchTerm,
    handleChangeSearchTerm,
    filteredApprovements,
  };
}
