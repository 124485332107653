import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import companiesService from '../../../../../services/companiesService';
import useApiCall from '../../../../../hooks/useApiCall';

type KidsInfoType = {
  average?: number,
  age?: {
    '0-2': number,
    '3-5': number,
    '6-8': number,
    '9-12': number,
  }
}

export default function useKidsInfo() {
  const [kidsInfo, setKidsInfo] = useState<KidsInfoType>({});
  const [hasKidsInfoError, setHasKidsInfoError] = useState(false);

  const { companyId } = useParams();
  const { apiCall } = useApiCall();

  const getKidsInfo = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: companiesService.getKidsInfo,
      queryParams: { companyId, associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setKidsInfo(apiResponse);
        setHasKidsInfoError(false);
      },
      catchAction: () => setHasKidsInfoError(true),
      catchMessage: 'Não foi possível carregar a informação das crianças',
    })
  }, [apiCall, companyId]);

  return {
    kidsInfo,
    hasKidsInfoError,
    getKidsInfo,
  };
}
