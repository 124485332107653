import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import restart from '../../../../../assets/images/icons/restart.svg';

interface TitleInterface {
  hasOriginalDatesChanged: boolean;
  resetDisabledDates: () => void;
  addImage: string;
  setDisableDateModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function Title({
  hasOriginalDatesChanged,
  resetDisabledDates,
  addImage,
  setDisableDateModalShow,
}: TitleInterface) {
  return (
    <div className="card-title">
      <div>
        Datas desabilitadas
      </div>
      <div className="actions">
        {hasOriginalDatesChanged && (
        <img
          src={restart}
          alt="add"
          className="primaryColor"
          title="Desfazer ações"
          onClick={() => resetDisabledDates()}
        />
        )}
        <img
          src={addImage}
          alt="add"
          className="primaryColor"
          title="Desabilitar nova data"
          onClick={() => setDisableDateModalShow(true)}
        />
      </div>
    </div>
  );
}

Title.propTypes = {
  hasOriginalDatesChanged: PropTypes.bool.isRequired,
  resetDisabledDates: PropTypes.func.isRequired,
  addImage: PropTypes.string.isRequired,
  setDisableDateModalShow: PropTypes.func.isRequired,
};
