import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from './styles';

interface StartDateCardInterface {
  startDate?: string | null;
}

export default function StartDateCard({ startDate }: StartDateCardInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);

  const startDay = Number(startDate?.split('/')[0]);
  const startMonth = Number(startDate?.split('/')[1]);
  const startYear = Number(startDate?.split('/')[2]);

  const differenceBetweenDates = startDate
    ? (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(startYear, Number(startMonth - 1), startDay),
    }))
    : null;

  const differenceBetweenDatesInDays = differenceBetweenDates?.days;
  const differenceBetweenDatesInMonths = differenceBetweenDates?.months;
  const differenceBetweenDatesInYears = differenceBetweenDates?.years;

  return (
    <Card>
      {!!startDate && (
        <>
          <div className="card-title">
            <div>
              Início da contratação
            </div>
          </div>
          <div className="card-main">
            <div>
              {startDate || 'Não informado'}
            </div>
            {startDate && (
              <small>
                {(differenceBetweenDatesInDays != 0
            && differenceBetweenDatesInMonths != 0
            && differenceBetweenDatesInYears != 0)
            && (
              'Essa empresa possui '
            )}
                {!!differenceBetweenDatesInYears && (
                  `${differenceBetweenDatesInYears} anos, `
                )}
                {!!differenceBetweenDatesInMonths && (
                  `${differenceBetweenDatesInMonths} meses e `
                )}
                {!!differenceBetweenDatesInDays && (
                  `${differenceBetweenDatesInDays} dias `
                )}
                {(differenceBetweenDatesInDays != 0
            && differenceBetweenDatesInMonths != 0
            && differenceBetweenDatesInYears != 0)
            && (
              'de contrato'
            )}
                {(differenceBetweenDatesInDays == 0
            && differenceBetweenDatesInMonths == 0
            && differenceBetweenDatesInYears == 0) && (
                  'Parabéns pelo seu primeiro dia!'
                )}
              </small>
            )}
          </div>
        </>
      )}
    </Card>
  );
}
