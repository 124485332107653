/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import companiesService from '../../../../services/companiesService';
import splitArray from '../../../../utils/splitArray';
import { CompanyFromApi, MappedCompany } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseLoadCompaniesInterface {
  setFullCompanies: Dispatch<SetStateAction<MappedCompany[]>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setCompaniesSplited: Dispatch<SetStateAction<Array<MappedCompany[]>>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilteredCompanies: Dispatch<SetStateAction<MappedCompany[]>>;
}

export default function useLoadCompanies({
  setFullCompanies,
  setSearchTerm,
  setCompaniesSplited,
  setCurrentPage,
  setFilteredCompanies,
}: UseLoadCompaniesInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { user } = useAppContext();
  const { apiCall } = useApiCall();

  const loadCompanies = useCallback(async () => {
    await apiCall({
      apiToCall: companiesService.getCompaniesList,
      queryParams: { permission: user.permission },
      onStartLoad: () => {
        setSearchTerm('');
        setIsLoadLoading(true);
      },
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.list) {
          toast.error(`Não foi possível carregar a lista de empresas (${apiResponse.erro})`, {
            toastId: 'getCompaniesFail',
          });
          setDoesListApiHasError(true);
          setIsLoadLoading(false);
          return;
        }
        const companiesList: CompanyFromApi = apiResponse.list;
        const mappedCompaniesList = companiesList.map((company) => ({
          id: company.id,
          collection: company.collection,
          name: company.nome_fantasia || company.nome,
          status: company.ativo ? 'Ativo' : ((company.ativo === undefined || company.ativo === null) ? 'Ativo' : 'Inativo'),
          responsible: company.responsavel_nome,
          cnpj: company.cnpj,
          companyName: company.razao_social,
          contactName: company.responsavel,
          contactCellphone: company.contato_celular,
          contactEmail: company.contato_email,
          employeesQty: company.funcionarios,
          elegiblesQty: company.elegiveis,
          adherenceGoal: company.meta_adesao,
          city: company.endereco?.cidade,
          uf: company.endereco?.uf,
          readOnly: company.readOnly,
          viewByAssociatedCompanies: company.viewByAssociatedCompanies,
        }));
        setFullCompanies(mappedCompaniesList);

        const splitedArray = splitArray(mappedCompaniesList);
        setCompaniesSplited(splitedArray);
        setCurrentPage(0);
        setFilteredCompanies(splitedArray[0]);
        setDoesListApiHasError(false);
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar a lista das empresas'
    })
  }, [apiCall, setCompaniesSplited, setCurrentPage, setFilteredCompanies, setFullCompanies, setSearchTerm, user.permission]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return {
    loadCompanies,
    isLoadLoading,
    doesListApiHasError,
  };
}
