import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import activate from '../../../../assets/images/icons/activate.svg';
import chartLinkIcon from '../../../../assets/images/icons/chartLinkIcon.svg';
import collaboratorIcon from '../../../../assets/images/icons/collaboratorIcon.svg';
import edit from '../../../../assets/images/icons/edit.svg';
import suspend from '../../../../assets/images/icons/suspend.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { useAppContext } from '../../../../contexts/auth';
import { HandleOpenChangeStatusModalInterface, MappedCompany } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedCompany[];
  onOpenChangeStatusModal: ({ name, id, status }: HandleOpenChangeStatusModalInterface) => void;
}

export default function List({
  filteredList,
  onOpenChangeStatusModal,
}: ListInterface) {
  const { user: { permission } } = useAppContext();

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((company) => (
          <Col key={company.id}>
            <OpacityAnimation>
              <Card title="Expandir detalhes e editar">
                <div className="info">
                  <div className="card-title">
                    <strong>{company.name}</strong>
                    <small className={company.status === 'Inativo' ? 'orange' : ''}>
                      {company.status}
                    </small>
                  </div>
                  <span>
                    CNPJ:
                    {' '}
                    {company.cnpj || 'Não informado'}
                  </span>
                  <span>
                    Razão Social:
                    {' '}
                    {company.companyName || 'Não informado'}
                  </span>
                </div>
                <div className="actions">
                  {permission === 'super' && (
                    <button
                      type="button"
                      onClick={() => onOpenChangeStatusModal({
                        name: company.name,
                        id: company.id,
                        status: company.status,
                      })}
                    >
                      {company.status === 'Ativo' ? (
                        <img src={suspend} alt="suspend" title="Inativar Parceiro" />
                      ) : (
                        <img src={activate} alt="trash" title="Reativar Parceiro" />
                      )}
                    </button>
                  )}
                  <Link to={`/companies/${company.id}`}>
                    <img src={edit} alt="" title={`Editar empresa ${company.name}`} className="primaryColor" />
                  </Link>
                  <Link to={`/companies/dashboard/${company.id}/${company.name}${company.readOnly ? '?readOnly&' : '?'}${company.viewByAssociatedCompanies ? 'viewByAssociatedCompanies' : ''}`}>
                    <img src={chartLinkIcon} alt="" title={`Dashboard empresa ${company.name}`} />
                  </Link>
                  <Link to={`/companies/collaborators/${company.id}/${company.name}${company.readOnly ? '?readOnly&' : '?'}${company.viewByAssociatedCompanies ? 'viewByAssociatedCompanies' : ''}`}>
                    <img src={collaboratorIcon} alt="" title={`Beneficiários empresa ${company.name}`} />
                  </Link>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
