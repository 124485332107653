/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import { VictoryPie } from 'victory';

import NoData from '../../../components/NoData';
import OpacityAnimation from '../../../components/OpacityAnimation';
import useThemeContext from '../../../contexts/theme';
import { Card } from '../styles';
import { KidsAgeType } from '../types';

interface KidsAgeCardInterface {
  ages: KidsAgeType;
}

export default function KidsAgeCard({ ages }: KidsAgeCardInterface) {
  const hasInfo = ages['0-2'] || ages['3-5'] || ages['6-8'] || ages['9-12'];
  const [labelBeingShow, setLabelBeingShow] = useState({ description: '', value: '' });
  const { selectedTheme } = useThemeContext();

  return (
    <Card>
      <div className="card-title">
        <div>Idade das crianças</div>
      </div>

      {!!ages && !!hasInfo && (
      <>
        <OpacityAnimation>
          <div className="card-main-graph">
            <VictoryPie
              data={[
                {
                  x: '0-2 anos',
                  y: ages['0-2'],
                  label: ' ',
                },
                {
                  x: '3 - 5 anos',
                  y: ages['3-5'],
                  label: ' ',
                },
                {
                  x: '6 - 8 anos',
                  y: ages['6-8'],
                  label: ' ',
                },
                {
                  x: '9 - 12 anos',
                  y: ages['9-12'],
                  label: ' ',
                },
              ]}
              animate={{
                duration: 2000,
                onLoad: { duration: 5000 },
              }}
              colorScale={['#8b9a71', '#ff6361', '#ffa600', '#003f5c']}
              innerRadius={80}
              height={250}
              padAngle={0}
              padding={{ bottom: 10, top: 0 }}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: 'data',
                        mutation: ({ style, data, index }) => {
                          setLabelBeingShow({ description: data[index].x, value: data[index].y });
                          return { style: Object.assign({}, style, { opacity: '0.8', cursor: 'help' }) };
                        }
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: 'data',
                        mutation: () => {
                          setLabelBeingShow({ description: '', value: '' });
                          return null;
                        }
                      }
                    ];
                  }
                }
              }]}
              style={{
                labels: { fontSize: 0, fill: selectedTheme === 'dark' ? '#fff' : '#000' }
              }}
              labelPlacement="vertical"
              labelRadius={0.1}
              labelPosition="endAngle"
            />
            <label>
              <span>{labelBeingShow.value ? `${Number(labelBeingShow.value).toFixed(0)}%` : ''}</span>
              <span>{labelBeingShow.description}</span>
          </label>
          </div>
        </OpacityAnimation>
      </>
      )}

      {(!ages || !hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre a faixa etária das crianças
          </>
                  )}
      />
      )}
    </Card>
  );
}
