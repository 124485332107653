import { useState } from "react";
import useDelete from "./functions/useDelete";
import useEdit from "./functions/useEdit";
import useLoadInternalUsers from "./functions/useLoadInternalUsers"
import useSearches from "./functions/useSearches";

export default function useInternalUsers() {
  const [addInternalUserModalShow, setAddInternalUserModalShow] = useState(false);

  const {
    isLoading,
    filteredInternalUsers,
    doesListApiHasError,
    loadInternalUsers,
    setFilteredInternalUsers,
    setIsLoading,
    internalUsers,
  } = useLoadInternalUsers();

  const {
    searchTerm,
    handleChangeSearchTerm,
  } = useSearches({ setFilteredInternalUsers, internalUsers });

  const {
    handleOpenDeleteModal,
    internalUserBeingDeleted,
    setDeleteModalShow,
    deleteInternalUser,
    deleteModalShow,
  } = useDelete({ setIsLoading, loadInternalUsers });

  const {
    handleOpenEditModal,
    editModalShow,
    setEditModalShow,
    internalUserObjectBeingEditted,
    setInternalUserObjectBeingEditted,
  } = useEdit();

  return {
    isLoading,
    filteredInternalUsers,
    doesListApiHasError,
    loadInternalUsers,
    setFilteredInternalUsers,
    setIsLoading,
    internalUsers,
    searchTerm,
    handleChangeSearchTerm,
    handleOpenDeleteModal,
    internalUserBeingDeleted,
    setDeleteModalShow,
    deleteInternalUser,
    deleteModalShow,
    handleOpenEditModal,
    editModalShow,
    setEditModalShow,
    internalUserObjectBeingEditted,
    setInternalUserObjectBeingEditted,
    addInternalUserModalShow,
    setAddInternalUserModalShow,
  }
}
