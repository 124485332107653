import { Dispatch, SetStateAction, useState } from 'react';
import MyModal from '../../../components/Modal';
import SchedulesToRemoveList from './schedulesToRemoveList';
import { SchedulesToRemoveBeingShown } from '../types';

interface RemoveScheduleModalInterface {
  schedulesRemoveModalShow: boolean;
  setSchedulesRemoveModalShow: Dispatch<SetStateAction<boolean>>;
  setSelectedScheduleIdToRemove: Dispatch<SetStateAction<string>>;
  removeSchedule: (scheduleId: string) => void;
  selectedScheduleIdToRemove: string;
  schedulesRemoveBeingShow: SchedulesToRemoveBeingShown;
}

export default function RemoveScheduleModal({
  schedulesRemoveModalShow,
  setSchedulesRemoveModalShow,
  setSelectedScheduleIdToRemove,
  removeSchedule,
  selectedScheduleIdToRemove,
  schedulesRemoveBeingShow,
}: RemoveScheduleModalInterface) {
  const [willDelete, setWillDelete] = useState(false);

  return (
    <MyModal
      show={schedulesRemoveModalShow}
      onClose={() => {
        setSchedulesRemoveModalShow(false);
        setSelectedScheduleIdToRemove('');
        setWillDelete(false);
      }}
      onAction={() => {
        if (!willDelete) {
          setWillDelete(true);
          return;
        }
        removeSchedule(selectedScheduleIdToRemove);
        setWillDelete(false);
      }}
      actionButtonLabel={willDelete ? 'Confirmar exclusão' : 'Excluir'}
      isActionButtonDisabled={!selectedScheduleIdToRemove}
      title={`Selecione um dos agendamentos realizados para ${schedulesRemoveBeingShow?.title} para removê-lo`}
      modalBody={(
        <SchedulesToRemoveList
          list={schedulesRemoveBeingShow?.list}
          setSelectedScheduleIdToRemove={setSelectedScheduleIdToRemove}
          selectedScheduleIdToRemove={selectedScheduleIdToRemove}
        />
              )}
      closeButtonLabel="Fechar"
      type="deleteAction"
      size='lg'
    />
  );
}
