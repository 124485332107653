import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import financialService from '../../../../services/financialService';
import givenClassesService from '../../../../services/givenClassesService';

interface UsePartnerViewInterface {
  reloadPartnerData: () => void;
  partnerId: string;
}

export default function usePartnerView({
  reloadPartnerData,
  partnerId,
}: UsePartnerViewInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentYear = currentDate.split('-')[0];
  const currentMonth = currentDate.split('-')[1];
  const [isLoading, setIsLoading] = useState(false);
  const [dateModalShow, setDateModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number | ''>(Number(currentMonth));
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const [currentMonthBeingShow, setCurrentMonthBeingShow] = useState<number | ''>(Number(currentMonth));
  const [currentYearBeingShow, setCurrentYearBeingShow] = useState(Number(currentYear));
  const [file, setFile] = useState<File[]>([]);

  const [paymentVoucherUploadModalShow, setPaymentVoucherUploadModalShow] = useState(false);
  const [paymentVoucherFileName, setPaymentVoucherFileName] = useState('');

  const { apiCall } = useApiCall();

  const handlePaymentVoucherFileSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.uploadPartnerPaymentVoucher,
      queryParams: { partnerId },
      reqBody: [
        { key: 'doc', value: file[0] },
        { key: 'data', value: `${currentMonthBeingShow}_${currentYearBeingShow}` },
      ],
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível realizar o upload do comprovante de pagamento. Por favor, tente novamente');
        }
        toast.success('Comprovante de pagamento enviado com sucesso!');
        reloadPartnerData();
        setPaymentVoucherUploadModalShow(false);
      },
      catchMessage: 'Não foi possível realizar o upload do comprovante de pagamento. Por favor, tente novamente'
    })
  }, [apiCall, currentMonthBeingShow, currentYearBeingShow, file, partnerId, reloadPartnerData]);

  const getFinancialRelatoryPreview = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.downloadPdfRelatoryByPartner,
      queryParams: {
        month: currentMonthBeingShow,
        year: currentYearBeingShow,
        partnerId,
      },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response: Blob) => {
        console.log(response);
        const url = window.URL.createObjectURL(response);
        window.open(url, '_blank');
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `Relatorio_Repasse_Financeiro_${currentMonthBeingShow}_${currentYearBeingShow}_${partnerId}.pdf`);
        // document.body.appendChild(link);
        // link.click();

        // Revoga o URL após o download
        window.URL.revokeObjectURL(url);
      }
    });
  }, [apiCall, currentMonthBeingShow, currentYearBeingShow, partnerId]);

  function handlePaymentVoucherFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setPaymentVoucherFileName(files[0].name);
      setFile([files[0]]);
    }
  }

  return {
    currentYear,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    paymentVoucherUploadModalShow,
    setPaymentVoucherUploadModalShow,
    handlePaymentVoucherFileSubmit,
    handlePaymentVoucherFileUpload,
    paymentVoucherFileName,
    isLoading,
    getFinancialRelatoryPreview,
  };
}
