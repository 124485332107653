import Select from 'react-select';
import { CustomStyle } from '../../../components/CustomSmallSelectStyle';
import FormGroup from '../../../components/FormGroup';
import MyModal from '../../../components/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { SelectedNewHighlightType } from '../types';
import { FilterRadioButtonsContainer } from '../../../components/FilterRadioButtonsContainer';
import FilterRadioButton from '../../../components/FilterRadioButtons';

interface ManageHighlighModalInterface {
  modalShow: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
  setSelectedNewHighlight: Dispatch<SetStateAction<SelectedNewHighlightType>>;
  manageHighlight: (order: number | null) => void;
  selectedNewHighlight: SelectedNewHighlightType;
  activitiesList: { value: string, label: string }[];
}

export default function ManageHighlightModal({
  modalShow,
  setModalShow,
  setSelectedNewHighlight,
  manageHighlight,
  selectedNewHighlight,
  activitiesList,
}: ManageHighlighModalInterface) {
  const [selectedOrder, setSelectedOrder] = useState<number | null>(null);

  const ordersOptions = [1, 2, 3, 4, 5, 6]

  return (
    <MyModal
      show={modalShow}
      onClose={() => {
        setModalShow(false);
        setSelectedNewHighlight({} as  SelectedNewHighlightType);
      }}
      onAction={() => manageHighlight(selectedOrder)}
      isActionButtonDisabled={!selectedNewHighlight.value || !selectedOrder}
      title="Gerenciar destaque"
      type="action"
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      minHeight="230px"
      modalBody={(
        <>
        <FormGroup>
          <label htmlFor="contactName">Selecione uma atividade</label>
          <Select
            value={
                            {
                              value: selectedNewHighlight.value,
                              label: selectedNewHighlight.label,
                            }
                          }
            options={activitiesList}
            onChange={(opt) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setSelectedNewHighlight({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="order">Escolha a posição deste destaque</label>
          <FilterRadioButtonsContainer>
          {ordersOptions.map((order) => (
            <FilterRadioButton
              key={order}
              onClick={() => setSelectedOrder(order)}
              selected={selectedOrder === order}
            >
              {order}
            </FilterRadioButton>
          ))}
        </FilterRadioButtonsContainer>
        </FormGroup>
        </>
)}
    />
  );
}
