import Select from 'react-select';
import {
  InputSearchContainer, SearchContainer,
} from './styles';
import { ChangeEvent } from 'react';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import useThemeContext from '../../../../../contexts/theme';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../../components/CustomSelectStyleDarkTheme';

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
  handleTypeChange: (companyObject: { value: string, label: string }) => void;
  selectedType: { value: string, label: string };
}

export default function Search({
  searchTerm,
  onChangeSearchTerm,
  selectedType,
  handleTypeChange,
}: SearchInterface) {
  const { selectedTheme } = useThemeContext();
  const typesList = [
    { value: '', label: 'Todos' },
    { value: 'Atividades Educacionais', label: 'Atividades Educacionais' },
    { value: 'Atividades Físicas', label: 'Atividades Físicas' },
    { value: 'Entretenimento', label: 'Entretenimento' }
  ]

  return (
    <OpacityAnimation delay={0.1}>
      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar categoria"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>
        <div>
          <Select
            value={{ value: selectedType.value, label: selectedType.label === 'Todos' ? 'Filtrar por tipo' : selectedType.label }}
            options={typesList}
            onChange={(type) => handleTypeChange(type as { value: string, label: string })}
            placeholder="Filtrar por empresa"
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            className="react-select-container-as-filter"
          />
        </div>
      </SearchContainer>
    </OpacityAnimation>
  );
}
