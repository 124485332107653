import { Col, Container, Row } from 'react-bootstrap';
import Button from '../../../../components/Button';
import { ButtonContainer } from '../../../../components/Form';
import Loader from '../../../../components/Loader';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import PageHeader from '../../../../components/PageHeader';
import AddressDataCard from './components/AddressDataCard';
import ChildrensCard from './components/ChildrensCard';
import GeneralDataCard from './components/GeneralDataCard';
import Title from './components/Title';
import useUserForm from './useUserForm';

interface UserFormInterface {
  edit: boolean
}

export default function UserForm({ edit = false }: UserFormInterface) {
  const {
    isLoading,
    registerDate,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    email,
    cellphone,
    company,
    cep,
    handleCepChange,
    streetName,
    district,
    city,
    uf,
    isFormValid,
    cupom,
    birthDate,
    handleBirthDateChange,
    gender,
    setGender,
    addChildrenModalShow,
    setAddChildrenModalShow,
    childrenDetailsModalShow,
    childrenInfoBeingShow,
    addNewChildren,
    removeChildren,
    handleOpenChildrenDetailsModal,
    handleCellphoneChange,
    handleEmailChange,
    interestsOptions,
    setChildrenNameBeingAdded,
    setChildrenBirthDateBeingAdded,
    setChildrenPeriodBeingAdded,
    setChildrenInterestsBeingAdded,
    activateDate,
    status,
    childrenNameBeingAdded,
    childrenBirthDateBeingAdded,
    childrenPeriodBeingAdded,
    childrenInterestsBeingAdded,
    childrens,
    periodOptions,
    handleOpenChildrenRemoveModal,
    childrenBeingRemoved,
    childrenRemoveModalShow,
    editChildren,
    childrenNameBeingEditted,
    setChildrenNameBeingEditted,
    childrenBirthDateBeingEditted,
    setChildrenBirthDateBeingEditted,
    childrenPeriodBeingEditted,
    setChildrenInterestsBeingEditted,
    childrenInterestsBeingEditted,
    setChildrenPeriodBeingEditted,
    setChildrenRemoveModalShow,
    setChildrenDetailsModalShow,
    updateUser,
    isGettingCepInfo,
    setChildrenObservationBeingAdded,
    childrenObservationBeingAdded,
    childrenObservationBeingEditted,
    setChildrenObservationBeingEditted,
    childrenneedsInterpreterBeingAdded,
    childrenneedsInterpreterBeingEditted,
    setChildrenneedsInterpreterBeingAdded,
    setChildrenneedsInterpreterBeingEditted,
  } = useUserForm();

  return (
    <>
      <Loader isLoading={isLoading} />
      {edit && (
        <PageHeader
          title={`Editar Usuário ${name}`}
          link="/users"
        />
      )}
      <Title status={status} registerDate={registerDate} activateDate={activateDate} />
      <Container>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <OpacityAnimation>
              <GeneralDataCard
                name={name}
                onNameChange={handleNameChange}
                cpf={cpf}
                onCpfChange={handleCpfChange}
                birthDate={birthDate}
                onBirthDateChange={handleBirthDateChange}
                gender={gender}
                onGenderChange={setGender}
                email={email}
                onEmailChange={handleEmailChange}
                cellphone={cellphone}
                onCellphoneChange={handleCellphoneChange}
                company={company}
                cupom={cupom}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
              />
            </OpacityAnimation>

          </Col>
          <Col>
            <OpacityAnimation>
              <AddressDataCard
                cep={cep}
                onCepChange={handleCepChange}
                streetName={streetName}
                district={district}
                city={city}
                uf={uf}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                isGettingCepInfo={isGettingCepInfo}
              />
            </OpacityAnimation>
          </Col>

          <Col>
            <OpacityAnimation>
              <ChildrensCard
                addChildrenModalShow={addChildrenModalShow}
                setAddChildrenModalShow={setAddChildrenModalShow}
                childrenDetailsModalShow={childrenDetailsModalShow}
                childrenInfoBeingShow={childrenInfoBeingShow}
                addNewChildren={addNewChildren}
                removeChildren={removeChildren}
                onOpenChildrenDetailsModal={handleOpenChildrenDetailsModal}
                interestsOptions={interestsOptions}
                childrenNameBeingAdded={childrenNameBeingAdded}
                setChildrenNameBeingAdded={setChildrenNameBeingAdded}
                childrenBirthDateBeingAdded={childrenBirthDateBeingAdded}
                setChildrenBirthDateBeingAdded={setChildrenBirthDateBeingAdded}
                childrenPeriodBeingAdded={childrenPeriodBeingAdded}
                setChildrenPeriodBeingAdded={setChildrenPeriodBeingAdded}
                childrenInterestsBeingAdded={childrenInterestsBeingAdded}
                setChildrenInterestsBeingAdded={setChildrenInterestsBeingAdded}
                childrens={childrens}
                periodOptions={periodOptions}
                onOpenChildrenRemoveModal={handleOpenChildrenRemoveModal}
                childrenBeingRemoved={childrenBeingRemoved}
                childrenRemoveModalShow={childrenRemoveModalShow}
                onEditChildren={editChildren}
                childrenNameBeingEditted={childrenNameBeingEditted}
                setChildrenNameBeingEditted={setChildrenNameBeingEditted}
                childrenBirthDateBeingEditted={childrenBirthDateBeingEditted}
                setChildrenBirthDateBeingEditted={setChildrenBirthDateBeingEditted}
                childrenPeriodBeingEditted={childrenPeriodBeingEditted}
                setChildrenInterestsBeingEditted={setChildrenInterestsBeingEditted}
                childrenInterestsBeingEditted={childrenInterestsBeingEditted}
                setChildrenPeriodBeingEditted={setChildrenPeriodBeingEditted}
                setChildrenRemoveModalShow={setChildrenRemoveModalShow}
                setChildrenDetailsModalShow={setChildrenDetailsModalShow}
                setChildrenObservationBeingAdded={setChildrenObservationBeingAdded}
                childrenObservationBeingAdded={childrenObservationBeingAdded}
                childrenObservationBeingEditted={childrenObservationBeingEditted}
                setChildrenObservationBeingEditted={setChildrenObservationBeingEditted}
                childrenneedsInterpreterBeingAdded={childrenneedsInterpreterBeingAdded}
                childrenneedsInterpreterBeingEditted={childrenneedsInterpreterBeingEditted}
                setChildrenneedsInterpreterBeingAdded={setChildrenneedsInterpreterBeingAdded}
                setChildrenneedsInterpreterBeingEditted={setChildrenneedsInterpreterBeingEditted}
              />
            </OpacityAnimation>
          </Col>
        </Row>

        <ButtonContainer>
          {!edit && (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <Button disabled={!isFormValid} onClick={() => {}}>
              {`Adicionar parceiro ${name ?? ''}`}
            </Button>
          )}

          {edit && (
            <Button disabled={!isFormValid} onClick={() => updateUser()}>
              {`Editar usuário ${name ?? ''}`}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}
