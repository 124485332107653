import { useCallback } from 'react';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';

interface UseDownloadExcelInterface {
  fullDates: string[];
}

export default function useDownloadExcel({
  fullDates,
}: UseDownloadExcelInterface) {
  const downloadExcel = useCallback(() => {
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Datas desabilitadas Kiddlepass - ${currentDate}`,
      data: fullDates.map((date) => ({ data: date })),
    });
  }, [fullDates]);

  return {
    downloadExcel,
  };
}
