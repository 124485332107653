import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

interface UseSearchesI {
  fullDates: string[];
  setFilteredDates: Dispatch<SetStateAction<string[]>>;
}

export default function useSearches({ fullDates, setFilteredDates }: UseSearchesI) {
  const [searchTerm, setSearchTerm] = useState('');

  const filterDates = useCallback(() => {
    const filteredActivities = fullDates.filter((date) => {
      const searchFilter = date.includes(String(searchTerm));

      return searchFilter;
    });

    setFilteredDates(filteredActivities);
  }, [fullDates, setFilteredDates, searchTerm]);

  useEffect(() => {
    filterDates();
  }, [filterDates]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
  }
}
