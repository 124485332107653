import MyModal from '../../../components/Modal';
import formatDate from '../../../utils/formatDate';
import { ApprovementDetailsType } from '../types';

interface DetailChangeModalInterface {
  detailModalShow: boolean;
  handleCloseDetailModal: () => void;
  approvementDetails: ApprovementDetailsType;
  handleApprovementUpdateDecision: (approve: boolean) => void;
}

export default function DetailChangeModal({
  detailModalShow,
  handleCloseDetailModal,
  approvementDetails,
  handleApprovementUpdateDecision,
}: DetailChangeModalInterface) {
  const fieldContentMap = {
    'datas desabilitadas': () => {
      const { acao, data } = approvementDetails.datasDesabilitadas || {};
      const [day, time] = data?.includes('_') ? data.split('_') : [data];
      const formattedDate = formatDate(day);

      return (
        <>
          <strong>{acao === 'desabilitar' ? 'Desabilitação de data: ' : 'Reabilitação de data:'}</strong>
          {data?.includes('_')
            ? `Dia ${formattedDate} apenas no horário ${time}`
            : `${formattedDate} (dia inteiro)`}
        </>
      );
    },
    descrição: () => (
      <>
        <strong>Nova descrição:</strong> {approvementDetails?.descricao}
      </>
    ),
    idade: () => (
      <>
        <strong>Nova idade:</strong> De {approvementDetails?.minima} a {approvementDetails?.maxima} anos
      </>
    ),
  };

  const renderFieldContent = () => {
    const fieldContentFn = fieldContentMap[approvementDetails?.field];
    return fieldContentFn ? fieldContentFn() : null;
  };

  return (
    <MyModal
      show={detailModalShow}
      onClose={handleCloseDetailModal}
      title={`Alteração de ${approvementDetails?.field} da atividade ${approvementDetails?.name}`}
      modalBody={renderFieldContent()}
      closeButtonLabel="Fechar"
      size="lg"
      type="decision"
      actionButtonLabel="Aprovar alteração"
      negateActionLabel="Reprovar alteração"
      onAction={() => handleApprovementUpdateDecision(true)}
      oneNegateAction={() => handleApprovementUpdateDecision(false)}
    />
  );
}
