import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import { useQuery } from '../../../../hooks/useQuery';
import companiesService from '../../../../services/companiesService';
import useCollaboratorsInfo from './functions/useCollaboratorsInfo';
import useKidsInfo from './functions/useKidsInfo';
import useRanking from './functions/useRanking';
import useRatings from './functions/useRatings';

export default function useDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{ value: string | null, label: string }>({ value: null, label: 'Visão Geral' });
  const [associatedCompanies, setAssociatedCompanies] = useState<string[]>([]);

  const {
    collaboratorsInfo,
    hasCollaboratorsInfoError,
    getCollaboratorsInfo,
  } = useCollaboratorsInfo();

  const {
    kidsInfo,
    hasKidsInfoError,
    getKidsInfo,
  } = useKidsInfo();

  const {
    ratings,
    hasRatingsError,
    getRatings,
  } = useRatings();

  const {
    ranking,
    hasRankingError,
    getRanking,
  } = useRanking();

  const { apiCall } = useApiCall();
  const { companyId } = useParams();
  const navigate  = useNavigate();
  const query = useQuery();

  const viewByAssociatedCompanies = query.has('viewByAssociatedCompanies');

  const handleCompanyChange = useCallback((company: { value: string | null, label: string }) => {
    setSelectedCompany(company);
  }, []);

  const getCompanyDetails = useCallback(async () => {
    await apiCall({
      apiToCall: companiesService.getCompanyDetails,
      queryParams: { companyId },
      catchAction: () => navigate('/companies'),
      actionAfterResponse: (response) => {
        if (response.erro) {
          toast.error('Não foi possível encontrar a empresa');
          navigate('/companies');
          return;
        }
        const companyData = response.details;
        setAssociatedCompanies(companyData.associated_companies.map((x: { name: string }) => x.name));
      },
      catchMessage: 'Não foi possível encontrar a empresa',
    })
  }, [apiCall, companyId, navigate]);

  const loadDashboard = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        getCollaboratorsInfo(selectedCompany.value),
        getKidsInfo(selectedCompany.value),
        getRatings(selectedCompany.value),
        getRanking(selectedCompany.value),
        ...(viewByAssociatedCompanies ? [getCompanyDetails()] : [])
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar o dashboard (${error})`, {
        toastId: 'loadDashboardFail',
      });
    } finally {
      setIsLoading(false);
    }
  }, [getCollaboratorsInfo, getCompanyDetails, getKidsInfo, getRanking, getRatings, selectedCompany.value, viewByAssociatedCompanies]);

  useEffect(() => {
    loadDashboard();
  }, [getCollaboratorsInfo, getKidsInfo, getRatings, getRanking, loadDashboard]);

  function handleTryAgain() {
    loadDashboard();
  }

  return {
    isLoading,
    collaboratorsInfo,
    kidsInfo,
    ranking,
    ratings,
    hasCollaboratorsInfoError,
    hasKidsInfoError,
    hasRatingsError,
    hasRankingError,
    handleTryAgain,
    associatedCompanies,
    selectedCompany,
    handleCompanyChange,
  };
}
