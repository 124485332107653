/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { getDay, parse } from 'date-fns';
import formatDateToUsa from '../../../../utils/formatDateToUsa';
import isDateBeforeToday from '../../../../utils/isDateBeforeToday';
import { TimetableTranslatedType } from '../../types/TimetableTranslatedType';
import formatDate from '../../../../utils/formatDate';

interface UseDisableDatesInterface {
  timetable: TimetableTranslatedType
}

export default function useDisableDates({ timetable }: UseDisableDatesInterface) {
  const [disableDateModalShow, setDisableDateModalShow] = useState(false);
  const [selectedDisableDate, setSelectedDisableDate] = useState('');
  const [selectedRehabilitateDate, setRehabilitateDate] = useState('');
  const [rehabilitateDateModalShow, setRehabilitateDateModalShow] = useState(false);
  const [updatedDates, setUpdatedDates] = useState<string[]>([]);
  const [originalDates, setOriginalDates] = useState<string[]>([]);

  const [isSpecificTime, setIsSpecificTime] = useState(false);
  const [timesOptions, setTimesOptions] = useState<{ value: string, label: string }[]>([]);
  const [selectedDisableTime, setSelectedDisableTime] = useState<{ value: string,  label: string }>({} as { value: string,  label: string });

  const onlyDatesAfterTodayOrToday = updatedDates?.filter((date) => (
    !isDateBeforeToday(date?.split('_')[0])
  ));

  const hasOriginalDatesChanged = JSON.stringify(updatedDates) !== JSON.stringify(originalDates);

  const finalDates = onlyDatesAfterTodayOrToday;

  const hasDatesToBeRendered = onlyDatesAfterTodayOrToday.length !== 0;

  const isFutureDateChoosed = useMemo(
    () => !isDateBeforeToday(selectedDisableDate),
    [selectedDisableDate],
  );

  const disableDate = useCallback(() => {
    let clonedDates: string[] = JSON.parse(JSON.stringify(updatedDates));
    const formattedDisableDate = formatDateToUsa(selectedDisableDate);

    const isOptionAlreadyDisabled = isSpecificTime
      ? clonedDates.includes(`${formattedDisableDate}_${selectedDisableTime.value}`)
        || clonedDates.includes(formattedDisableDate!)
      : clonedDates.includes(formattedDisableDate!);

    if (isOptionAlreadyDisabled) {
      toast.error('Essa opção já está setada para ser desabilitada');
      return;
    }

    if (isSpecificTime) {
      // checks if all times of a day are selected, and if yes, it replaces to a full day option
      const parsedDate = parse(formatDate(formattedDisableDate!)!, 'dd/MM/yyyy', new Date());
      const weekDaysLiterals: { [key: number]: string } = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday'
      }
      const weekday = weekDaysLiterals[getDay(parsedDate)];

      clonedDates.push(`${formattedDisableDate}_${selectedDisableTime.value}`);

      const areAllTimesOfDayDisabled = timetable[weekday].every((timeInfo) => {
        const timeOption = `${formattedDisableDate}_${timeInfo.startTime}-${timeInfo.endTime}`;
        return clonedDates.includes(timeOption);
      });

      if (areAllTimesOfDayDisabled) {
        clonedDates = clonedDates.filter((dateStr) => !dateStr?.includes(formattedDisableDate!));
        clonedDates.push(formattedDisableDate!);
      }
    } else {
      //disable full day, and remove all specific times previously set at this day
      clonedDates = clonedDates.filter((dateStr) => !dateStr?.includes(formattedDisableDate!));
      clonedDates.push(formattedDisableDate!);
    }

    setUpdatedDates(clonedDates);
    toast.info(
      'A desabilitação foi adicionada. Para salvar as alterações não esqueça de confirmar a edição da atividade!'
    );
  }, [isSpecificTime, selectedDisableDate, selectedDisableTime.value, timetable, updatedDates]);

  const rehabilitateDate = useCallback(() => {
    const clonedDates: string[] = JSON.parse(JSON.stringify(updatedDates));
    const indexOfOptionToBeRehabilitate = clonedDates.findIndex((date) =>
      date === selectedRehabilitateDate
    );

    if (indexOfOptionToBeRehabilitate !== -1) {
      clonedDates.splice(indexOfOptionToBeRehabilitate, 1);
    }

    setUpdatedDates(clonedDates);
    setRehabilitateDateModalShow(false);
    toast.info('Opção reabilitada. Para salvar as alterações não esqueça de confirmar a edição da atividade!');
  }, [updatedDates, selectedRehabilitateDate]);

  const resetDisabledDates = () => {
    setUpdatedDates(originalDates);
    toast.info('Ações desfeitas');
  };

  function handleDisableDateChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDisableDate(event.target.value);
    setSelectedDisableTime({ value: '', label: '' });
    const formattedDate = formatDate(event.target.value);
    if (!formattedDate) {
      return;
    }
    const parsedDate = parse(formattedDate, 'dd/MM/yyyy', new Date());
    const weekDaysLiterals: { [key: number]: string } = {
      0: 'sunday',
      1: 'monday',
      2: 'tuesday',
      3: 'wednesday',
      4: 'thursday',
      5: 'friday',
      6: 'saturday'
    }
    const weekday = weekDaysLiterals[getDay(parsedDate)];
    const hasTimesAtSelectedDay = timetable[weekday].length !== 0;
    if (!hasTimesAtSelectedDay) {
      toast.warn(`Não há nenhuma aula agendada para o dia ${formattedDate}`);
      setSelectedDisableDate('');
      return;
    }
    const timesAtSelectedDay = timetable[weekday].map((time) => `${time.startTime}-${time.endTime}`);
    const disableTimesOptions = timesAtSelectedDay.map((time) => ({ value: time, label: time }));
    setTimesOptions(disableTimesOptions);
  }

  return {
    updatedDates,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    finalDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    resetDisabledDates,
    hasOriginalDatesChanged,
    setOriginalDates,
    setUpdatedDates,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  };
}
