/* eslint-disable max-len */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import partnersService from '../../../../../../services/partnersService';

interface UseLoadFormInterface {
  setKindOfPartner: Dispatch<SetStateAction<string>>;
  setCpf: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setCnpj: Dispatch<SetStateAction<string>>;
  setCorporateName: Dispatch<SetStateAction<string>>;
  setFantasyName: Dispatch<SetStateAction<string>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setContactName: Dispatch<SetStateAction<string>>;
  setContactCellphone: Dispatch<SetStateAction<string>>;
  setContactEmail: Dispatch<SetStateAction<string>>;
  setCep: Dispatch<SetStateAction<string>>;
  setStreetName: Dispatch<SetStateAction<string>>;
  setNumber: Dispatch<SetStateAction<string | number>>;
  setComplement: Dispatch<SetStateAction<string>>;
  setDistrict: Dispatch<SetStateAction<string>>;
  setCity: Dispatch<SetStateAction<string>>;
  setUf: Dispatch<SetStateAction<string>>;
  setPixType: Dispatch<SetStateAction<{ value: string, label: string }>>;
  setCnpjPix: Dispatch<SetStateAction<string>>;
  setCpfPix: Dispatch<SetStateAction<string>>;
  setEmailPix: Dispatch<SetStateAction<string>>;
  setCellphonePix: Dispatch<SetStateAction<string>>;
  setRandomPix: Dispatch<SetStateAction<string>>;
  setAgency: Dispatch<SetStateAction<string>>;
  setAccount: Dispatch<SetStateAction<string>>;
  setCardholder: Dispatch<SetStateAction<string>>;
  setCnpjBank: Dispatch<SetStateAction<string>>;
  setSelectedBank: Dispatch<SetStateAction<{ value: string, label: string }>>;
  setResponsibleName: Dispatch<SetStateAction<string>>;
  setResponsibleRole: Dispatch<SetStateAction<string>>;
  setResponsibleCellphone: Dispatch<SetStateAction<string>>;
  setResponsibleEmail: Dispatch<SetStateAction<string>>;
  setFacebook: Dispatch<SetStateAction<string>>;
  setSite: Dispatch<SetStateAction<string>>;
  setInstagram: Dispatch<SetStateAction<string>>;
  setTiktok: Dispatch<SetStateAction<string>>;
  setClassPrice: Dispatch<SetStateAction<string>>;
  setContractDate: Dispatch<SetStateAction<string>>;
  setLoginEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
}

export default function useLoadForm({
  setKindOfPartner,
  setCpf,
  setName,
  setCnpj,
  setCorporateName,
  setFantasyName,
  setDescription,
  setContactName,
  setContactCellphone,
  setContactEmail,
  setCep,
  setStreetName,
  setNumber,
  setComplement,
  setDistrict,
  setCity,
  setUf,
  setPixType,
  setCnpjPix,
  setCpfPix,
  setEmailPix,
  setCellphonePix,
  setRandomPix,
  setAgency,
  setAccount,
  setCardholder,
  setCnpjBank,
  setSelectedBank,
  setResponsibleName,
  setResponsibleRole,
  setResponsibleCellphone,
  setResponsibleEmail,
  setFacebook,
  setSite,
  setInstagram,
  setTiktok,
  setClassPrice,
  setContractDate,
  setLoginEmail,
  setPassword,
}: UseLoadFormInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState<{ value: string, label: string }[]>([
    { value: 'Banco do Brasil S.A.', label: 'Banco do Brasil S.A.' },
    { value: 'BMG S.A.', label: 'BMG S.A.' },
    { value: 'Bradesco S.A.', label: 'Bradesco S.A.' },
    { value: 'BTG Pactual S.A.', label: 'BTG Pactual S.A.' },
    { value: 'Caixa Econômica Federal', label: 'Caixa Econômica Federal' },
    { value: 'Citibank S.A.', label: 'Citibank S.A.' },
    { value: 'HSBC Bank Brasil S.A. - Banco Múltiplo', label: 'HSBC Bank Brasil S.A. - Banco Múltiplo' },
    { value: 'Itaú BBA S.A.', label: 'Itaú BBA S.A.' },
    { value: 'Itaú Unibanco S.A.', label: 'Itaú Unibanco S.A.' },
    { value: 'J. Safra S.A.', label: 'J. Safra S.A.' },
    { value: 'Modal S.A.', label: 'Modal S.A.' },
    { value: 'Nu Pagamentos S.A', label: 'Nu Pagamentos S.A' },
    { value: 'Original S.A.', label: 'Original S.A.' },
    { value: 'PAN S.A.', label: 'PAN S.A.' },
    { value: 'Safra S.A.', label: 'Safra S.A.' },
    { value: 'Santander (Brasil) S.A.', label: 'Santander (Brasil) S.A.' },
    { value: 'SICOOB', label: 'SICOOB' },
    { value: 'Neon Pagamentos S.A', label: 'Neon Pagamentos S.A' },
    { value: 'Next Tecnologia e Serviços Digitais S.A.', label: 'Next Tecnologia e Serviços Digitais S.A.' },
    { value: 'C6 Bank', label: 'C6 Bank' },
    { value: 'Banco Inter', label: 'Banco Inter' },
  ]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const pixOptions = [
    { value: 'CNPJ', label: 'CNPJ' },
    { value: 'CPF', label: 'CPF' },
    { value: 'Celular', label: 'Celular' },
    { value: 'Email', label: 'E-mail' },
    { value: 'Chave', label: 'Chave aleatória' },
  ];

  const getBankOptions = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      const response = await fetch('https://brasilapi.com.br/api/banks/v1');
      const json = await response.json();
      setBankOptions(json.map((bank: { name: string }) => ({ value: bank.name, label: bank.name })));
    } catch (error) {
      toast.error('Não foi possível carregar a lista de bancos. Uma lista padrão foi carregada.');
    } finally {
      setIsLoadLoading(false);
    }
  }, []);

  const getPartnerDetails = useCallback(async () => {
    const labelPixTypeMap: { [key: string]: string } = {
      CNPJ: 'CNPJ',
      CPF: 'CPF',
      Celular: 'Celular',
      Email: 'E-mail',
      Chave: 'Chave aleatória',
    };
    await apiCall({
      apiToCall: partnersService.getPartnerDetails,
      queryParams: { partnerId: id },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível encontrar o parceiro', {
            toastId: 'getPartnerFail',
          });
          navigate('/partners');
          return;
        }
        const partnerData = apiResponse.details;
        setKindOfPartner(partnerData.cpf ? 'cpf' : 'cnpj');
        setCpf(partnerData.cpf);
        setName(partnerData.nome_fantasia);
        setCnpj(partnerData.cnpj);
        setCorporateName(partnerData.razao_social);
        setFantasyName(partnerData.nome_fantasia);
        setDescription(partnerData.descricao);
        setContactName(partnerData.contato_nome);
        setContactCellphone(partnerData.contato_celular);
        setContactEmail(partnerData.contato_email);
        setCep(partnerData.end_cep);
        setStreetName(partnerData.end_rua);
        setNumber(partnerData.end_numero);
        setComplement(partnerData.end_complemento);
        setDistrict(partnerData.end_bairro);
        setCity(partnerData.end_cidade);
        setUf(partnerData.end_uf);
        setPixType({ value: partnerData.tipo_pix || 'cpf', label: labelPixTypeMap[partnerData.tipo_pix] || '' });
        setCnpjPix(partnerData.tipo_pix === 'CNPJ' ? partnerData.pix : '');
        setCpfPix(partnerData.tipo_pix === 'CPF' ? partnerData.pix : '');
        setEmailPix(partnerData.tipo_pix === 'Email' ? partnerData.pix : '');
        setCellphonePix(partnerData.tipo_pix === 'Celular' ? partnerData.pix : '');
        setRandomPix(partnerData.tipo_pix === 'Chave' ? partnerData.pix : '');
        setAgency(partnerData.banco_agencia);
        setAccount(partnerData.banco_conta);
        setCardholder(partnerData.banco_titular);
        setCnpjBank(partnerData.banco_doc);
        setSelectedBank({ value: partnerData.banco_nome || '', label: partnerData.banco_nome || 'Selecione um banco' });
        setResponsibleName(partnerData.responsavel_nome);
        setResponsibleRole(partnerData.responsavel_cargo);
        setResponsibleCellphone(partnerData.responsavel_telefone);
        setResponsibleEmail(partnerData.responsavel_email);
        setFacebook(partnerData.facebook);
        setSite(partnerData.site);
        setInstagram(partnerData.instagram);
        setTiktok(partnerData.tiktok);
        setClassPrice(partnerData.valor ?? 'Não informado');
        setContractDate(partnerData.inicio_contrato_atual || '');
        setLoginEmail(partnerData.loginEmail || '');
        setPassword('x');
      },
      catchMessage: 'Não foi possível encontrar o parceiro',
      catchAction: () => navigate('/partners')
    })
  }, [apiCall, id, navigate, setAccount, setAgency, setCardholder, setCellphonePix, setCep, setCity, setClassPrice, setCnpj, setCnpjBank, setCnpjPix, setComplement, setContactCellphone, setContactEmail, setContactName, setContractDate, setCorporateName, setCpf, setCpfPix, setDescription, setDistrict, setEmailPix, setFacebook, setFantasyName, setInstagram, setKindOfPartner, setLoginEmail, setName, setNumber, setPassword, setPixType, setRandomPix, setResponsibleCellphone, setResponsibleEmail, setResponsibleName, setResponsibleRole, setSelectedBank, setSite, setStreetName, setTiktok, setUf]);

  useEffect(() => {
    getBankOptions();
    if (id) {
      getPartnerDetails();
    }
  }, [getPartnerDetails, id, getBankOptions]);

  return {
    isLoadLoading,
    pixOptions,
    banksOptions: bankOptions,
  };
}
