/* eslint-disable max-len */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import activitiesService from '../../../../../../services/activitiesService';
import usersService from '../../../../../../services/usersService';
import formatDateToUsa from '../../../../../../utils/formatDateToUsa';
import { UserChildType } from '../../../../types';
import { CategoriesFromApi } from '../../types';

interface UseLoadFormInterface {
  setStatus: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setRegisterDate: Dispatch<SetStateAction<string>>;
  setActivateDate: Dispatch<SetStateAction<string>>;
  setCpf: Dispatch<SetStateAction<string>>;
  setName: Dispatch<SetStateAction<string>>;
  setCep: Dispatch<SetStateAction<string>>;
  setStreetName: Dispatch<SetStateAction<string>>;
  setDistrict: Dispatch<SetStateAction<string>>;
  setCity: Dispatch<SetStateAction<string>>;
  setUf: Dispatch<SetStateAction<string>>;
  setCompany: Dispatch<SetStateAction<string>>;
  setCupom: Dispatch<SetStateAction<string>>;
  setBirthDate: Dispatch<SetStateAction<string>>;
  setGender: Dispatch<SetStateAction<{ value: string, label: string }>>;
  setChildrens: Dispatch<SetStateAction<UserChildType[]>>;
  setCellphone: Dispatch<SetStateAction<string>>;
  setInterestsOptions: Dispatch<SetStateAction<{ value: string, label: string }[]>>;
}

export default function useLoadForm({
  setStatus,
  setEmail,
  setRegisterDate,
  setActivateDate,
  setCpf,
  setName,
  setCep,
  setStreetName,
  setDistrict,
  setCity,
  setUf,
  setCompany,
  setCupom,
  setBirthDate,
  setGender,
  setChildrens,
  setCellphone,
  setInterestsOptions,
}: UseLoadFormInterface) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const periodOptions = [
    { value: 'Manhã', label: 'Matutino' },
    { value: 'Tarde', label: 'Vespertino' },
    { value: 'Noite', label: 'Noturno' },
  ];

  const getUserDetails = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUserDetails,
      queryParams: { id },
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível encontrar o usuário', {
            toastId: 'getUserFail',
          });
          navigate('/users');
          return;
        }
        const userData = apiResponse.details;
        setStatus(userData.status);
        setEmail(userData.email);
        setRegisterDate(userData.data_cadastro);
        setActivateDate(userData.data_ativacao || '');
        setCpf(userData.cpf || '');
        setName(userData.nome || '');
        setCep(userData.endereco?.cep || '');
        setStreetName(userData.endereco?.rua || '');
        setDistrict(userData.endereco?.bairro || '');
        setCity(userData.endereco?.cidade || '');
        setUf(userData.endereco?.uf || '');
        setCompany(userData.company?.nome_fantasia || '');
        setCupom(userData.cupom?.name || userData.cupom_utilizado?.code || userData.collaborator?.id || '');
        setBirthDate(formatDateToUsa(userData.data_nascimento) || '');
        setGender({ value: userData.genero, label: userData.genero });
        setChildrens(userData.kids || []);
        setCellphone(userData.celular);
      },
      catchAction: () => navigate('/users'),
      catchMessage: 'Não foi possível encontrar o usuário'
    })
  }, [apiCall, id, navigate, setActivateDate, setBirthDate, setCellphone, setCep, setChildrens, setCity, setCompany, setCpf, setCupom, setDistrict, setEmail, setGender, setName, setRegisterDate, setStatus, setStreetName, setUf]);

  const getInterestsOptions = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getCategories,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error('Não foi possível encontrar as áreas de interesse', {
            toastId: 'getInterestsOptionsFail',
          });
          return;
        }
        const interests: CategoriesFromApi = apiResponse.categories;
        setInterestsOptions(interests.map((ctg) => ({
          value: ctg.id, label: ctg.name,
        })));
      },
      catchMessage: 'Não foi possível encontrar as áreas de interesse',
    })
  }, [apiCall, setInterestsOptions]);

  const loadForm = useCallback(async () => {
    await Promise.all([getUserDetails(), getInterestsOptions()])
  }, [getInterestsOptions, getUserDetails])

  useEffect(() => {
    if (id) {
      loadForm();
    }
  }, [getInterestsOptions, getUserDetails, id, loadForm]);

  return {
    isLoadLoading,
    periodOptions,
    loadForm,
  };
}
