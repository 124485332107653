import useHandleInputChanges from './functions/useHandleInputChanges';
import useHandleSubmit from './functions/useHandleSubmit';

export default function useChangePassowrd() {
  const {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
    handleCurrentPasswordChange,
    handleNewPasswordChange,
    handleNewPasswordConfirmationChange,
    getErrorMessageByFieldName,
    errors,
  } = useHandleInputChanges();

  const {
    handleSubmit,
    isLoading,
  } = useHandleSubmit({
    currentPassword,
    newPassword,
  });

  const isFormValid = (errors.length === 0
    && (currentPassword && newPassword && newPasswordConfirmation));

  return {
    handleSubmit,
    getErrorMessageByFieldName,
    currentPassword,
    handleCurrentPasswordChange,
    newPassword,
    handleNewPasswordChange,
    newPasswordConfirmation,
    handleNewPasswordConfirmationChange,
    isFormValid,
    isLoading,
  };
}
