import { format } from 'date-fns';
import { DateTimeFormatOptions } from 'intl';
import download from '../../../assets/images/icons/download.svg';
import NoData from '../../../components/NoData';
import ExportXlsx from '../../../utils/ExportXlsx';
import { Card } from '../styles';
import { RatingsType } from '../types';

export default function RatingsCard({
  average,
  details,
}: RatingsType) {
  const hasInfo = average && details;

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'America/Sao_Paulo',
  };
  const date = new Date().toLocaleDateString('pt-BR', options as DateTimeFormatOptions);

  function downloadRatingsDetails() {
    ExportXlsx({
      data: details.map((list) => ({
        Usuário: list.nome,
        'E-mail': list.email,
        'Data de cadastro': list.user?.data_cadastro ?? 'Usuário não é mais registrado em nossa base',
        'Nota dada': list.nota,
        'Data da nota': list.ratedAt ? format(new Date(list.ratedAt), 'dd/MM/yyyy') : 'Sem registro da data',
      })),
      filename: `Relatório completo de avaliação NPS - ${date.replaceAll('/', '-')}`,
    });
  }

  return (
    <Card>
      <div className="card-title">
        <div>Avalição Média</div>
        <img src={download} alt="download" title="Baixar relatório excel" onClick={downloadRatingsDetails} className="primaryColor" />
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {average}
          {' '}
          / 10
        </div>
        <small>
          É a nota dada pelos usuários
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da avaliação NPS
          </>
                    )}
      />
      )}
    </Card>
  );
}
