import PropTypes from 'prop-types';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { ChangeEvent } from 'react';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';

interface AddressDataCardInterface {
  cep: string;
  handleCepChange: (event: ChangeEvent<HTMLInputElement>) => void;
  streetName: string;
  number: number | '';
  handleNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  complement: string;
  handleComplementChange: (event: ChangeEvent<HTMLInputElement>) => void;
  district: string;
  city: string;
  uf: string;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  isGettingCepInfo: boolean;
}

export default function AddressDataCard({
  cep,
  handleCepChange,
  streetName,
  number,
  handleNumberChange,
  complement,
  handleComplementChange,
  district,
  city,
  uf,
  getErrorMessageByFieldName,
  isGettingCepInfo,
}: AddressDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Endereço
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            CEP *
          </div>
          <FormGroup error={getErrorMessageByFieldName('cep')} isLoading={isGettingCepInfo}>
            <Input
              disabled={isGettingCepInfo}
              value={cep}
              onChange={handleCepChange}
              error={getErrorMessageByFieldName('cep')}
              placeholder="Informe o CEP"
              maxLength={9}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Logradouro *
          </div>
          <FormGroup isLoading={isGettingCepInfo}>
            <Input
              disabled
              value={streetName}
              placeholder="Preenchido automaticamente"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Número *
          </div>
          <FormGroup error={getErrorMessageByFieldName('number')}>
            <Input
              value={number}
              onChange={handleNumberChange}
              error={getErrorMessageByFieldName('number')}
              placeholder="Informe o número do endereço"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Complemento
          </div>
          <FormGroup error={getErrorMessageByFieldName('complemento')}>
            <Input
              value={complement}
              onChange={handleComplementChange}
              error={getErrorMessageByFieldName('complement')}
              placeholder="Informe o complemento, caso tenha."
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Bairro *
          </div>
          <FormGroup isLoading={isGettingCepInfo}>
            <Input
              disabled
              value={district}
              placeholder="Preenchido automaticamente"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Cidade *
          </div>
          <FormGroup isLoading={isGettingCepInfo}>
            <Input
              disabled
              value={city}
              placeholder="Preenchido automaticamente"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            UF *
          </div>
          <FormGroup isLoading={isGettingCepInfo}>
            <Input
              disabled
              value={uf}
              placeholder="Preenchido automaticamente"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}

AddressDataCard.propTypes = {
  cep: PropTypes.string.isRequired,
  handleCepChange: PropTypes.func.isRequired,
  streetName: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  complement: PropTypes.string.isRequired,
  handleComplementChange: PropTypes.func.isRequired,
  district: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  uf: PropTypes.string.isRequired,
  getErrorMessageByFieldName: PropTypes.string.isRequired,
  isGettingCepInfo: PropTypes.bool.isRequired,
};
