import { ThemeProvider } from 'styled-components';
import GlobalStyles from './assets/styles/global';
import AnalyticRoute from './routes/AnalyticRoute';
import AppRoute from './routes/AppRoute';
import FinancialRoute from './routes/FinancialRoute';
import LoginRoute from './routes/LoginRoute';
import SupportRoute from './routes/SupportRoute';

import {
  Container, PageContainer, RouteContainer, ThemeRadioButtonsContainer,
} from './components/App/styles';
import ThemeRadioButton from './components/ThemeRadioButton';

import darkTheme from './assets/styles/themes/dark';
import defaultTheme from './assets/styles/themes/default';

import moon from './assets/images/icons/moon.svg';
import sun from './assets/images/icons/sun.svg';

import { ReactNode } from 'react';
import { useAppContext } from './contexts/auth';
import useLocalState from './hooks/useLocalState';
import ComercialRoute from './routes/ComercialRoute';
import CoordinatorRoute from './routes/CoordinatorRoute';

export default function Routes() {
  const [theme, setTheme] = useLocalState('theme', defaultTheme);
  const { signed, user } = useAppContext();
  const { permission } = user;

  const routeMap: { [key: string]: ReactNode } = {
    super: <AppRoute />,
    coordinator: <CoordinatorRoute />,
    support: <SupportRoute />,
    analytics: <AnalyticRoute />,
    financial: <FinancialRoute />,
    comercial: <ComercialRoute />,
  };

  return signed
    ? (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Container>
          <PageContainer>
            <RouteContainer>
              <div className="darkMode">
                <ThemeRadioButtonsContainer>
                  <ThemeRadioButton
                    onClick={() => setTheme(defaultTheme)}
                    selected={theme.colors.background != '#121212'}
                  >
                    <img src={sun} alt="lightTheme" />
                  </ThemeRadioButton>

                  <ThemeRadioButton
                    onClick={() => setTheme(darkTheme)}
                    selected={theme.colors.background == '#121212'}
                  >
                    <img src={moon} alt="darkTheme" />
                  </ThemeRadioButton>
                </ThemeRadioButtonsContainer>
              </div>

              {routeMap[permission]}

            </RouteContainer>
          </PageContainer>
        </Container>
      </ThemeProvider>
    )
    : (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PageContainer>
          <div className="darkModeAtLoginPage">
            <ThemeRadioButtonsContainer>
              <ThemeRadioButton
                onClick={() => setTheme(defaultTheme)}
                selected={theme.colors.background != '#121212'}
              >
                <img src={sun} alt="lightTheme" />
              </ThemeRadioButton>

              <ThemeRadioButton
                onClick={() => setTheme(darkTheme)}
                selected={theme.colors.background == '#121212'}
              >
                <img src={moon} alt="darkTheme" />
              </ThemeRadioButton>
            </ThemeRadioButtonsContainer>
          </div>
        </PageContainer>
        <LoginRoute />
      </ThemeProvider>
    );
}
