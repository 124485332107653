import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import edit from '../../../../assets/images/icons/edit.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { GivenClassType, PeriodType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: GivenClassType[];
  onOpenDeleteModal: (givenClassToBeDeleted: GivenClassType) => void;
  selectedPeriod: PeriodType;
}

export default function List({
  filteredList,
  onOpenDeleteModal,
  selectedPeriod,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((gvCl) => (
          <Col key={gvCl.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{gvCl.activity.nome}</strong>
                    <small>
                      {`${format(new Date(gvCl.date), 'dd/MM/yyyy')} | ${gvCl.startTime} - ${gvCl.endTime}`}
                    </small>
                  </div>
                  <span>
                    Parceiro:
                    {' '}
                    {gvCl.partner.nome_fantasia}
                  </span>
                </div>

                <div className="actions">
                  <Link to={`/givenClasses/${gvCl.id}?month=${selectedPeriod.month}&year=${selectedPeriod.year}`}>
                    <img className='primaryColor' src={edit} alt="" title='Alterar Parceiro que Realizou esta aula' />
                  </Link>

                  <button type='button' onClick={() => onOpenDeleteModal(gvCl)}>
                    <img src={trash} alt="" />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
