/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import disabledDatesService from '../../../../services/disabledDatesService';
import formatDateToUsa from '../../../../utils/formatDateToUsa';

interface UseDeleteDatesI {
  loadDates: () => void;
}

export default function useDeleteDates({ loadDates }: UseDeleteDatesI) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [dateBeingDeleted, setDateBeingDeleted] = useState('')

  const { apiCall } = useApiCall();

  function handleOpenDeleteModal(date: string) {
    setDateBeingDeleted(date);
    setModalDeleteShow(true);
  }

  const deleteDate = useCallback(async () => {
    await apiCall({
      apiToCall: disabledDatesService.deleteDisabledDates,
      reqBody: JSON.stringify({
        dates: [formatDateToUsa(dateBeingDeleted)],
      }),
      onStartLoad: () => {
        setModalDeleteShow(false);
        setIsDeleting(true);
      },
      onEndLoad: () => setIsDeleting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível reabilitar a data. Por favor, tente novamente');
          setIsDeleting(false);
          return;
        }
        toast.success('Data reabilitada com sucesso!');
        loadDates();
      },
      catchMessage: 'Não foi possível reabilitar a data. Por favor, tente novamente'
    });
  }, [apiCall, dateBeingDeleted, loadDates]);

  return {
    isDeleting,
    modalDeleteShow,
    setModalDeleteShow,
    dateBeingDeleted,
    handleOpenDeleteModal,
    deleteDate,
  };
}
