import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import CompaniesForm from './components/CompaniesForm';

export default function EditCompany() {
  return (
    <>
      <Sidebar active="Companies" />
      <Transitions>
        <CompaniesForm edit />
      </Transitions>
    </>
  );
}
