import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface ActivitiesServiceInterface {
  id?: string;
  reqBody?: ReqBodyType;
  ativo?: boolean;
  category?: string;
  current?: string;
  next?: string;
  order?: number;
  main?: boolean;
  activityId?: string;
  token: string;
}

class ActivitiesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getActivitiesList = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities',
      token,
    });
  }

  getFullActivitiesList = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities/download',
      token,
    });
  }

  getActivityDetails = async ({
    id,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
        path: `/activities/${id}`,
        token,
      });
  }

  getActivityApprovementDetails = async ({
    id,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
        path: `/activities-approve/${id}`,
        token,
      });
  }

  updateActivity = async ({
    id,
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.put({
      path: `/activities/${id}`,
      reqBody,
      token,
      contentType: 'multipart/form-data',
    });
  }

  getCategories = async ({ token }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/categories-list',
      token,
    });
  }

  createActivity = async({
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.post({
      path: '/activities',
      reqBody,
      contentType: 'multipart/form-data',
      token,
    });
  }

  deleteActivity = ({
    id,
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.delete({
      path: `/activities/${id}`,
      reqBody,
      token,
    });
  }

  updateActivityStatus = async ({
    id,
    ativo,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.patch({
      path: `/activities/${id}?ativo=${ativo}`,
      token,
    });
  }

  getActivitiesApprovements = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities-approve',
      token,
    });
  }

  getActivityRatings = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-ratings/activity/${activityId}`,
      token,
    });
  }

  getActivityVideosViews = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/videos-views`,
      token,
    });
  }

  getAllActivitiesRatings = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-ratings`,
      token,
    });
  }

  handleActivityApprovement = async ({
    reqBody,
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.post({
      path: '/activities-approve',
      reqBody,
      token,
    });
  }

  getActivityPrograms = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-program/${activityId}`,
      token,
    });
  }

  updateActivityProgram = async ({
      id,
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.patch({
        path: `/activities-program/${id}`,
        reqBody,
        token,
      });
  }

  giveFeedbackOfActivityProgram = async ({
      id,
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.put({
        path: `/activities-program/${id}`,
        reqBody,
        token,
      });
  }

  createActivityProgram = async({
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.post({
        path: '/activities-program',
        reqBody,
        token,
      });
  }

  deleteActivityProgram = ({
      id,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.delete({
        path: `/activities-program/${id}`,
        token,
      });
  }

  getActivityPedagogicalPrograms = async ({
    token,
    activityId,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-pedagogical-program/${activityId}`,
      token,
    });
  }

  updateActivityPedagogicalProgram = async ({
      id,
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.patch({
        path: `/activities-pedagogical-program/${id}`,
        reqBody,
        token,
      });
  }

  giveFeedbackOfActivityPedagogicalProgram = async ({
      id,
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.put({
        path: `/activities-pedagogical-program/${id}`,
        reqBody,
        token,
      });
  }

  createActivityPedagogicalProgram = async({
      reqBody,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.post({
        path: '/activities-pedagogical-program',
        reqBody,
        token,
      });
  }

  deleteActivityPedagogicalProgram = ({
      id,
      token,
    }: ActivitiesServiceInterface) => {
      return this.httpClient.delete({
        path: `/activities-pedagogical-program/${id}`,
        token,
      });
  }

  getActivitiesVideoViewsRanking = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/views-ranking',
      token,
    });
  }

  getAllSchedulable = async ({
    token,
  }: ActivitiesServiceInterface) => {
    return this.httpClient.get({
      path: '/activities/schedulable',
      token,
    });
  }
}

export default new ActivitiesService();
