import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import useErrors from "../../../../../hooks/useErrors";
import useApiCall from "../../../../../hooks/useApiCall";
import categoriesService from "../../../../../services/categoriesService";
import { toast } from "react-toastify";

interface IUseAddModal {
  loadCategories: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function useAddModal({
  loadCategories,
  setIsLoading,
  setAddModalShow
}: IUseAddModal) {
  const [name, setName] = useState('');
  const [type, setType] = useState({ value: '', label: 'Selecione o tipo da categoria' });

  const typesOptions = [
    { value: 'Atividades Educacionais', label: 'Atividades Educacionais' },
    { value: 'Atividades Físicas', label: 'Atividades Físicas' },
    { value: 'Entretenimento', label: 'Entretenimento' }
  ]

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome da categoria é obrigatório!' })
    } else {
      removeError('name')
    }
  }

  const addCategory = useCallback(async () => {
    await apiCall({
      apiToCall: categoriesService.createCategory,
      onStartLoad: () => { setIsLoading(true) },
      reqBody: JSON.stringify({
        name,
        type: type.value,
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Categoria adicionada com sucesso!');
          setAddModalShow(false);
          setName('');
          setType({ value: '', label: 'Selecione o tipo da categoria' });
          loadCategories();
          return;
        }
        toast.error('Não foi possível adicionar a categoria. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar a categoria. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, loadCategories, name, setAddModalShow, setIsLoading, type]);

  const isFormValid = !!name && !!type.value && errors.length === 0;

  return {
    name,
    type,
    setType,
    typesOptions,
    getErrorMessageByFieldName,
    handleNameChange,
    addCategory,
    isFormValid,
  }
}
