import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import NoData from '../../../../components/NoData';
import { RatingsType } from '../../types';
import { Container as CustomContainer, Group } from './styles';

interface RatingsListInterface {
  list: RatingsType[];
}

export default function RatingsList({ list }: RatingsListInterface) {
  const hasRatings = !list || list.length !== 0;

  return (
    <CustomContainer>
      {hasRatings && (
        <Container>
          <Row xs={1} md={list.length === 1 ? 1 : 2} lg={list.length === 1 ? 1 : 2}>
            {list.map((rt) => (
              <Col key={rt.id}>
                <Group>
                  <div className="info">
                    <div className="card-title">
                      <strong>{rt.nota}</strong>
                    </div>
                    <span>
                      Avaliado em
                      {' '}
                      {format(new Date(rt.ratedAt), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </Group>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {!hasRatings && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Este assinante ainda não avaliou o App.
              .
            </>
          )}
        />
      )}
    </CustomContainer>
  );
}
