import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useErrors from '../../../../../../hooks/useErrors';
import formatCep from '../../../../../../utils/formatCep';
import formatCnpj from '../../../../../../utils/formatCnpj';
import formatCpf from '../../../../../../utils/formatCpf';
import formatCurrency from '../../../../../../utils/formatCurrency';
import formatPhone from '../../../../../../utils/formatPhone';
import isCnpjValid from '../../../../../../utils/isCnpjValid';
import isCpfvalid from '../../../../../../utils/isCpfValid';
import isDateBeforeToday from '../../../../../../utils/isDateBeforeToday';
import isEmailValid from '../../../../../../utils/isEmailValid';
import onlyNumbers from '../../../../../../utils/onlyNumbers';

export default function useInputChanges() {
  const [kindOfPartner, setKindOfPartner] = useState('cpf');
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [description, setDescription] = useState('');

  const [contactName, setContactName] = useState('');
  const [contactCellphone, setContactCellphone] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const [cep, setCep] = useState('');
  const [streetName, setStreetName] = useState('');
  const [number, setNumber] = useState<string | number>('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [isGettingCepInfo, setIsGettingCepInfo] = useState(false);

  const [pixType, setPixType] = useState({ value: 'Email', label: 'E-mail' });
  const [cnpjPix, setCnpjPix] = useState('');
  const [cpfPix, setCpfPix] = useState('');
  const [emailPix, setEmailPix] = useState('');
  const [cellphonePix, setCellphonePix] = useState('');
  const [randomPix, setRandomPix] = useState('');
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [cnpjBank, setCnpjBank] = useState('');
  const [selectedBank, setSelectedBank] = useState({ value: '', label: 'Selecione um banco' });
  const [classPrice, setClassPrice] = useState('');
  const [contractDate, setContractDate] = useState('');

  const [responsibleName, setResponsibleName] = useState('');
  const [responsibleRole, setResponsibleRole] = useState('');
  const [responsibleCellphone, setResponsibleCellphone] = useState('');
  const [responsibleEmail, setResponsibleEmail] = useState('');

  const [facebook, setFacebook] = useState('');
  const [site, setSite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');

  const [loginEmail, setLoginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  function handleCpfChange(event: ChangeEvent<HTMLInputElement>) {
    setCpf(formatCpf(event.target.value));
    if (!event.target.value) {
      setError({ field: 'cpf', message: 'CPF é obrigatório!' });
    } else if (!isCpfvalid(formatCpf(event.target.value))) {
      setError({ field: 'cpf', message: 'CPF inválido!' });
    } else {
      removeError('cpf');
    }
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome do parceiro é obrigatório!' });
    } else {
      removeError('name');
    }
  }

  function handleCnpjChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpj(formatCnpj(event.target.value));
    if (!event.target.value) {
      setError({ field: 'cnpj', message: 'CNPJ é obrigatório!' });
    } else if (!isCnpjValid(formatCnpj(event.target.value))) {
      setError({ field: 'cnpj', message: 'CNPJ inválido!' });
    } else {
      removeError('cnpj');
    }
  }

  function handleCorporateNameChange(event: ChangeEvent<HTMLInputElement>) {
    setCorporateName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'corporateName', message: 'Razão Social é obrigatório!' });
    } else {
      removeError('corporateName');
    }
  }

  function handleFantasyNameChange(event: ChangeEvent<HTMLInputElement>) {
    setFantasyName(event.target.value);
  }

  function handleDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value);
  }

  function handleContactNameChange(event: ChangeEvent<HTMLInputElement>) {
    setContactName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'contactName', message: 'Nome do contato é obrigatório!' });
    } else {
      removeError('contactName');
    }
  }

  function handleContactCellphoneChange(event: ChangeEvent<HTMLInputElement>) {
    setContactCellphone(formatPhone(event.target.value));
    if (!event.target.value) {
      setError({ field: 'contactCellphone', message: 'Celular do contato é obrigatório!' });
    } else {
      removeError('contactCellphone');
    }
  }

  function handleContactEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setContactEmail(event.target.value);
    if (!event.target.value) {
      setError({ field: 'contactEmail', message: 'E-mail é obrigatório!' });
    } else if (!isEmailValid(event.target.value)) {
      setError({ field: 'contactEmail', message: 'E-mail inválido!' });
    } else {
      removeError('contactEmail');
    }
  }

  function handleNumberChange(event: ChangeEvent<HTMLInputElement>) {
    setNumber(onlyNumbers(event.target.value));
    if (!event.target.value) {
      setError({ field: 'number', message: 'Número é obrigatório!' });
    } else {
      removeError('number');
    }
  }

  function handleComplementChange(event: ChangeEvent<HTMLInputElement>) {
    setComplement(event.target.value);
  }

  function handleChangePixType(event: { value: string, label: string }) {
    setPixType(event);
    setCnpjPix('');
    setCpfPix('');
    setEmailPix('');
    setCellphonePix('');
    setRandomPix('');
  }

  function handleSelectBank(event: { value: string, label: string }) {
    setSelectedBank(event);
  }

  function handleCnpjPixChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpjPix(formatCnpj(event.target.value));
    if (!isCnpjValid(formatCnpj(event.target.value)) && event.target.value) {
      setError({ field: 'cnpjPix', message: 'Informe um CNPJ válido!' });
    } else {
      removeError('cnpjPix');
    }
  }

  function handleCpfPixChange(event: ChangeEvent<HTMLInputElement>) {
    setCpfPix(formatCpf(event.target.value));
    if (!isCpfvalid(formatCpf(event.target.value)) && event.target.value) {
      setError({ field: 'cpfPix', message: 'Informe um CPF válido!' });
    } else {
      removeError('cpfPix');
    }
  }

  function handleCellphonePixChange(event: ChangeEvent<HTMLInputElement>) {
    setCellphonePix(formatPhone(event.target.value));
  }

  function handleEmailPixChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailPix(event.target.value);
    if (!isEmailValid(event.target.value) && event.target.value) {
      setError({ field: 'emailPix', message: 'Informe um e-mail válido!' });
    } else {
      removeError('emailPix');
    }
  }

  function handleRandomPixChange(event: ChangeEvent<HTMLInputElement>) {
    setRandomPix(event.target.value);
  }

  function handleAgencyChange(event: ChangeEvent<HTMLInputElement>) {
    setAgency(event.target.value);
    if (!event.target.value) {
      setError({ field: 'agency', message: 'Agência é um campo obrigatório!' });
    } else {
      removeError('agency');
    }
  }

  function handleAccountChange(event: ChangeEvent<HTMLInputElement>) {
    setAccount(event.target.value);
    if (!event.target.value) {
      setError({ field: 'account', message: 'Conta é um campo obrigatório!' });
    } else {
      removeError('account');
    }
  }

  function handleCardholderChange(event: ChangeEvent<HTMLInputElement>) {
    setCardholder(event.target.value);
  }

  function handleCnpjBankChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpjBank(formatCnpj(event.target.value));
    if (!isCnpjValid(formatCnpj(event.target.value)) && event.target.value) {
      setError({ field: 'cnpjBank', message: 'Informe um CNPJ válido!' });
    } else {
      removeError('cnpjBank');
    }
  }

  function handleClassPriceChange(event: ChangeEvent<HTMLInputElement>) {
    setClassPrice(formatCurrency(event.target.value));
  }

  function handleResponsibleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setResponsibleName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'responsibleName', message: 'Nome do responsável é obrigatório' });
    } else {
      removeError('responsibleName');
    }
  }

  function handleResponsibleRoleChange(event: ChangeEvent<HTMLInputElement>) {
    setResponsibleRole(event.target.value);
  }

  function handleResponsibleCellphoneChange(event: ChangeEvent<HTMLInputElement>) {
    setResponsibleCellphone(formatPhone(event.target.value));
    if (!event.target.value) {
      setError({ field: 'responsibleCellphone', message: 'Telefone do responsável é obrigatório' });
    } else {
      removeError('responsibleCellphone');
    }
  }

  function handleResponsibleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setResponsibleEmail(event.target.value);
    if (!event.target.value) {
      setError({ field: 'responsibleEmail', message: 'E-mail é obrigatório!' });
    } else if (!isEmailValid(event.target.value)) {
      setError({ field: 'responsibleEmail', message: 'E-mail inválido!' });
    } else {
      removeError('responsibleEmail');
    }
  }

  function handleFacebookChange(event: ChangeEvent<HTMLInputElement>) {
    setFacebook(event.target.value);
  }

  function handleSiteChange(event: ChangeEvent<HTMLInputElement>) {
    setSite(event.target.value);
  }

  function handleInstagramChange(event: ChangeEvent<HTMLInputElement>) {
    setInstagram(event.target.value);
  }

  function handleTiktokChange(event: ChangeEvent<HTMLInputElement>) {
    setTiktok(event.target.value);
  }

  function handlePartnerChange(value: string) {
    setKindOfPartner(value);
    setCnpj('');
    setCpf('');
    setName('');
    setFantasyName('');
    setCorporateName('');
  }

  function handleContractDateChange(event: ChangeEvent<HTMLInputElement>) {
    setContractDate(event.target.value);

    if (!isDateBeforeToday(event.target.value)) {
      setError({ field: 'contractDate', message: 'A data do contrato não pode ser futura!' });
    } else {
      removeError('contractDate');
    }
  }

  function handleLoginEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setLoginEmail(event.target.value);

    if (!isEmailValid(event.target.value)) {
      setError({ field: 'loginEmail', message: 'E-mail inválido' });
    } else {
      removeError('loginEmail');
    }
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);

    if (event.target.value.length < 8) {
      setError({ field: 'password', message: 'Senha deve conter ter 8 caracteres!' });
    } else if (event.target.value) {
      removeError('password');
    } if (event.target.value !== passwordConfirmation) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else if (event.target.value === passwordConfirmation) {
      removeError('passwordConfirmation');
    }
  }

  function handlePasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setPasswordConfirmation(event.target.value);

    if (event.target.value !== password) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else {
      removeError('passwordConfirmation');
    }
  }

  const handleCepChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setCep(formatCep(event.target.value));
      if (!event.target.value) {
        setError({ field: 'cep', message: 'CEP é obrigatório!' });
      }
      if (formatCep(event.target.value).length === 9) {
        setIsGettingCepInfo(true);
        const response = await fetch(`https://viacep.com.br/ws/${event.target.value}/json/`);
        const cepInfo = await response.json();
        if (cepInfo.erro) {
          setError({ field: 'cep', message: 'CEP inválido!' });
          setStreetName('');
          setDistrict('');
          setCity('');
          setUf('');
          return;
        }
        setStreetName(cepInfo.logradouro);
        setDistrict(cepInfo.bairro);
        setCity(cepInfo.localidade);
        setUf(cepInfo.uf);
      }
      if (formatCep(event.target.value).length !== 9) {
        setError({ field: 'cep', message: 'CEP inválido!' });
        setStreetName('');
        setDistrict('');
        setCity('');
        setUf('');
        return;
      }
      removeError('cep');
    } catch (error) {
      toast.error(`Ocorreu um erro ao buscar o CEP (${error})`);
    } finally {
      setIsGettingCepInfo(false);
    }
  }, [removeError, setError]);

  function resetFields() {
    setCpf('');
    setName('');
    setCnpj('');
    setCorporateName('');
    setFantasyName('');
    setDescription('');
    setContactName('');
    setContactCellphone('');
    setContactEmail('');
    setCep('');
    setStreetName('');
    setNumber('');
    setComplement('');
    setDistrict('');
    setCity('');
    setUf('');
    setPixType({ value: 'E-mail', label: 'E-mail' });
    setCnpjPix('');
    setCpfPix('');
    setEmailPix('');
    setCellphonePix('');
    setRandomPix('');
    setAgency('');
    setAccount('');
    setCardholder('');
    setCnpjBank('');
    setSelectedBank({ value: '', label: 'Selecione um banco' });
    setResponsibleName('');
    setResponsibleRole('');
    setResponsibleCellphone('');
    setResponsibleEmail('');
    setFacebook('');
    setSite('');
    setInstagram('');
    setTiktok('');
    setClassPrice('');
    setContractDate('');
    setPassword('');
    setPasswordConfirmation('');
    setLoginEmail('');
  }

  return {
    kindOfPartner,
    handlePartnerChange,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    description,
    handleDescriptionChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    responsibleName,
    handleResponsibleNameChange,
    responsibleRole,
    handleResponsibleRoleChange,
    responsibleCellphone,
    handleResponsibleCellphoneChange,
    responsibleEmail,
    handleResponsibleEmailChange,
    facebook,
    handleFacebookChange,
    site,
    handleSiteChange,
    instagram,
    handleInstagramChange,
    tiktok,
    handleTiktokChange,
    resetFields,
    errors,
    setKindOfPartner,
    setCpf,
    setName,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setDescription,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    setResponsibleName,
    setResponsibleRole,
    setResponsibleCellphone,
    setResponsibleEmail,
    setFacebook,
    setSite,
    setInstagram,
    setTiktok,
    isGettingCepInfo,
    classPrice,
    setClassPrice,
    handleClassPriceChange,
    contractDate,
    handleContractDateChange,
    setContractDate,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    setLoginEmail,
    setPassword,
  };
}
