import { useCallback } from 'react';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { MappedCompany } from '../../types';

interface UseDownloadExcelInterface {
  fullCompanies: MappedCompany[];
}

export default function useDownloadExcel({ fullCompanies }: UseDownloadExcelInterface) {
  const downloadExcel = useCallback(() => {
    const mappedCompaniesList = fullCompanies?.map((cp) => ({
      'Nome Fantasia': cp.name || 'Não informado',
      'Razão Social': cp.companyName || 'Não informado',
      CNPJ: cp.cnpj || 'Não informado',
      Status: cp.status || 'Não informado',
      Responsável: cp.contactName || 'Não informado',
      'Celular de contato': cp.contactCellphone || 'Não informado',
      'E-mail do contato': cp.contactEmail || 'Não informado',
      'Quantidade de funcionários': cp.employeesQty || 'Não informado',
      'Quantidade de elegíveis': cp.elegiblesQty || 'Não informado',
      'Meta de adesão': cp.adherenceGoal ? `${cp.adherenceGoal}%` : 'Não informado',
      Cidade: cp.city || 'Não informado',
      UF: cp.uf || 'Não informado',
    }));
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Clientes B2B Kiddlepass - ${currentDate}`,
      data: mappedCompaniesList,
    });
  }, [fullCompanies]);

  return { downloadExcel };
}
