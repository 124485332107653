import { DateTimeFormatOptions } from 'intl';
import download from '../../../assets/images/icons/download.svg';
import NoData from '../../../components/NoData';
import ExportXlsx from '../../../utils/ExportXlsx';
import { Card } from '../styles';

interface CanceledsCardInterface {
  canceleds: number;
  details: {
    _id: string;
    data_ativacao: string;
    data_cancelamento: string;
    duracao: string;
    company: null | string;
    channel: 'app' | 'admin' | 'adminCompany' | 'ws';
    canceledBy: string;
  }[]
}

export default function CanceledsCard({
  canceleds,
  details,
}: CanceledsCardInterface) {
  const hasInfo = (canceleds || canceleds === 0) && details;

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'America/Sao_Paulo',
  };
  const date = new Date().toLocaleDateString('pt-BR', options as DateTimeFormatOptions);

  function downloadCanceledsDetails() {
    const channelLiterals: { [key: string]: string } = {
      app: 'Aplicativo',
      admin: 'Admin Interno',
      adminCompany: 'Admin Empresa',
      ws: 'Integração API',
    }

    ExportXlsx({
      data: details.map((list) => ({
        Empresa: list.company || 'B2C',
        'Data de ativação': list.data_ativacao,
        'Data de cancelamento': list.data_cancelamento,
        Duração: list.duracao,
        Canal: channelLiterals[list.channel],
        'Cancelado por': list.canceledBy === 'user' ? 'Próprio Usuário' : list.canceledBy,
      })),
      filename: `Relatório de cancelamentos KP - ${date.replaceAll('/', '-')}`,
    });
  }

  return (
    <Card>
      <div className="card-title">
        <div>Cancelamentos</div>
        <img src={download} alt="download" title="Baixar relatório excel" onClick={downloadCanceledsDetails} className="primaryColor" />
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {canceleds}
        </div>
        <small>
          {`${canceleds !== 1 ? 'Cancelados' : 'Cancelado'} neste mês`}
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito dos cancelamentos
          </>
                    )}
      />
      )}
    </Card>
  );
}
