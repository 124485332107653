import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import Papa, { ParseResult } from 'papaparse';
import { ImportCategoriesToApiType, ImportCategoriesType } from "../../types";
import useApiCall from "../../../../../hooks/useApiCall";
import removeDuplicates from "../../../../../utils/removeDuplicates";
import categoriesService from "../../../../../services/categoriesService";
import { toast } from "react-toastify";

interface IUseImportCsvModal {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadCategories: () => Promise<void>
}

export default function useImportCsvModal({ setIsLoading, loadCategories }: IUseImportCsvModal) {
  const [modalImportCsvShow, setModalImportCsvShow] = useState(false);
  const [csvFileName, setCsvFileName] = useState('');
  const [csvParsed, setCsvParsed] = useState<ImportCategoriesToApiType[]>([]);

  const { apiCall } = useApiCall();

  function handleImportModalClose() {
    setCsvParsed([]);
    setCsvFileName('');
    setModalImportCsvShow(false);
  }

  function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setCsvFileName(files[0].name)
      Papa.parse(files[0], {
        header: true,
        complete: (results: ParseResult<ImportCategoriesType>) => {
          console.log(results)
          setCsvParsed(results.data.map((index) => ({
            name: index.Nome,
            type: index.Tipo
        })))
        }
      })
    }
  }

  const handleSubmitFile = useCallback(async () => {
    console.log(csvParsed)
    const csvParsedWithoutNullValuesAndDuplicates = removeDuplicates(csvParsed.filter((list) => !!list.name));

    console.log(csvParsedWithoutNullValuesAndDuplicates)

    await apiCall({
      apiToCall: categoriesService.createManyCategories,
      reqBody: JSON.stringify({
        categories: csvParsedWithoutNullValuesAndDuplicates,
      }),
      onStartLoad: () => {
        setModalImportCsvShow(false);
        setIsLoading(true);
      },
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsLoading(false);
          toast.error('Não foi possível importar as categorias');
          return;
        }
        loadCategories();
        setIsLoading(false);
        toast.success('Categorias importadas com sucesso');
      },
      catchMessage: ('Não foi possível importar as categorias'),
      catchAction: () => setIsLoading(false),
    });
  }, [csvParsed, apiCall, setIsLoading, loadCategories]);

  return {
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose,
    setModalImportCsvShow
  }
}
