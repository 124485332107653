import { Dispatch, SetStateAction, useCallback } from "react";
import { ProgramBeingDeletedType } from "../../types";
import useApiCall from "../../../../../hooks/useApiCall";
import { toast } from "react-toastify";
import activitiesService from "../../../../../services/activitiesService";

interface UseDeleteModal {
  loadPrograms: () => Promise<void>;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  programBeingDeleted: ProgramBeingDeletedType;
}

export default function useDeleteModal({ loadPrograms, setDeleteModalShow, setIsLoading, programBeingDeleted }: UseDeleteModal) {
  const { apiCall } = useApiCall();

  const deleteProgram = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.deleteActivityProgram,
      onStartLoad: () => { setIsLoading(true) },
      queryParams: { id: programBeingDeleted.id },
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Programação removida com sucesso!');
          setDeleteModalShow(false);
          loadPrograms();
          return;
        }
        toast.error('Não foi possível remover a programação. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível remover a programação. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, loadPrograms, programBeingDeleted.id, setDeleteModalShow, setIsLoading]);


  return {
    deleteProgram,
  }
}
