import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import cuponsService from '../../../../services/cuponsService';
import { CupomChangingStatusType, HandleOpenChangeStatusModalInterface, UpdateCupomStatusType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseUpdateCupomtatusInterface {
  loadCupons: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function useUpdateCupomStatus({ loadCupons, setIsLoading }: UseUpdateCupomtatusInterface) {
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [cupomChangingStatus, setCupomChangingStatus] = useState<CupomChangingStatusType>(
    {} as CupomChangingStatusType
  );

  const { apiCall } = useApiCall();

  const updateCupomStatus = useCallback(async ({ id, active }: UpdateCupomStatusType) => {
    await apiCall({
      apiToCall: cuponsService.updateCupomStatus,
      queryParams: { id, active },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => {
        setIsLoading(false);
        setChangeStatusModalShow(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar o status da empresa. Por favor, tente novamente');
          return;
        }
        toast.success(`O cupom foi ${active ? 'reativado' : 'inativado'} com sucesso`);
        setChangeStatusModalShow(false);
        loadCupons();
      },
      catchMessage: 'Não foi possível alterar o status da empresa, Por favor, tente novamente',
    })
  }, [apiCall, loadCupons, setIsLoading]);

  function handleOpenChangeStatusModal({ name, id, active }: HandleOpenChangeStatusModalInterface) {
    setChangeStatusModalShow(true);
    setCupomChangingStatus({
      name,
      id,
      active,
    });
  }

  return {
    cupomChangingStatus,
    setChangeStatusModalShow,
    updateCupomStatus,
    changeStatusModalShow,
    handleOpenChangeStatusModal,
  };
}
