import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  margin-left: 8px;
  h2 {
    font-size: 16px;
    margin-left: 4px;
    font-weight: 700;
  }
`;
