import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import forumService from "../../../../services/forumService";

interface IUseCommentsModal {
  postIdOfComments: string,
  loadForum: () => Promise<void>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
}

export default function useCommentsModal({
  postIdOfComments,
  loadForum,
  setIsLoading,
}: IUseCommentsModal) {
  const [commentText, setCommentText] = useState('');

  const { apiCall } = useApiCall();

  function handleCommentTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setCommentText(event.target.value);
  }

  const addComment = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.createComment,
      reqBody: JSON.stringify({
        postId: postIdOfComments,
        text: commentText,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response) => {
        console.log('executei');
        if (!response.success) {
          toast.error('Não foi possível adicionar o comentário. Por favor, tente novamente');
          return;
        }
        setCommentText('');
        await loadForum();
        console.log('executei aqui tb');
        toast.success('Comentário adicionado com sucesso!');
      }
    })
  }, [apiCall, commentText, loadForum, postIdOfComments, setIsLoading]);

  const isFormValid = !!commentText;

  return {
    commentText,
    handleCommentTextChange,
    isFormValid,
    addComment,
  }
}
