import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../hooks/useApiCall";
import forumService from "../../../services/forumService";
import { ApiListResponseType, CommentType, PostType } from "../types";

export default function useForum() {
  const [isLoading, setIsLoading] = useState(false);
  const [addPostModalShow, setAddPostModalShow] = useState(false);
  const [editPostModalShow, setEditPostModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [commentsModalShow, setCommentsModalShow] = useState(false);

  const [postBeingEditted, setPostBeingEditted] = useState({} as PostType);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [postIdBeingDeleted, setPostIdBeingDeleted] = useState('');
  const [postIdOfCommentsBeingShow, setPostIdOfCommentsBeingShow] = useState('');

  const [posts, setPosts] = useState<PostType[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { apiCall } = useApiCall();

  const loadForum = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.getPosts,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response: ApiListResponseType) => {
        if (!response.success) {
          toast.error('Não foi possível recuperar os posts. Por favor, tente novamente');
          setDoesListApiHasError(true);
          return;
        }
        setDoesListApiHasError(false);
        setPosts(response.posts);
        console.log(response.posts);

        if (postIdOfCommentsBeingShow) {
          const commentsOfPostId = response.posts.find((post) => post.id === postIdOfCommentsBeingShow);
          setComments(commentsOfPostId?.comments ?? []);
          return;
        }
        toast.success('Posts carregados com sucesso!'), { toastId: 'loadPosts' };
      },
    })
  }, [apiCall, postIdOfCommentsBeingShow]);

  function handleOpenCommentsModal(commentsToBeShow: CommentType[], postId: string) {
    setComments(commentsToBeShow);
    setPostIdOfCommentsBeingShow(postId);
    setCommentsModalShow(true);
  }

  function handleCloseCommentsModal() {
    setComments([]);
    setCommentsModalShow(false);
  }

  function handleOpenEditModal(post: PostType) {
    setPostBeingEditted(post);
    setEditPostModalShow(true);
  }

  function handleCloseEditModal() {
    setPostBeingEditted({} as PostType);
    setEditPostModalShow(false);
  }

  function handleOpenDeleteModal(postId: string) {
    setPostIdBeingDeleted(postId);
    setDeleteModalShow(true);
  }

  function handleCloseDeleteModal() {
    setPostIdBeingDeleted('');
    setDeleteModalShow(false);
  }

  useEffect(() => {
    loadForum()
  }, []);

  return {
    posts,
    filteredPosts: posts,
    doesListApiHasError,
    setAddPostModalShow,
    addPostModalShow,
    isLoading,
    setIsLoading,
    loadForum,
    commentsModalShow,
    comments,
    handleOpenCommentsModal,
    handleCloseCommentsModal,
    postIdOfCommentsBeingShow,
    handleOpenEditModal,
    handleCloseEditModal,
    editPostModalShow,
    postBeingEditted,
    deleteModalShow,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    postIdBeingDeleted,
  }
}
