import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import differenceBetweenDates from '../../../../utils/differenceBetweenDates';
import formatDate from '../../../../utils/formatDate';
import splitArray from '../../../../utils/splitArray';
import { MappedUserType } from '../../types';

interface UseSearchesInterface {
  users: MappedUserType[];
  setUsersSplitted: Dispatch<SetStateAction<Array<MappedUserType[]>>>;
  setFilteredUsers: Dispatch<SetStateAction<MappedUserType[]>>;
}

export default function useSearches({
  users,
  setUsersSplitted,
  setFilteredUsers,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({ value: '', label: 'Filtrar por empresa' });
  const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'Filtrar por status' });
  const [selectedAgeRange, setSelectedAgeRange] = useState([0, 13]);
  const [choosedAgeRange, setChoosedAgeRange] = useState([0, 13]);

  const filterUsers = useCallback(() => {
    const usersFiltered = users.filter((user) => {
      const searchTermFilter = (
        user.name.toLowerCase().includes(String(searchTerm).toLowerCase())
        || user.cellphone.includes(String(searchTerm).toLowerCase())
        || user.email.includes(String(searchTerm).toLowerCase())
      );
      const companyFilter = user.companyId === selectedCompany.value || selectedCompany.value === '';
      const statusFilter = user.status === selectedStatus.value || selectedStatus.value === '';
      const ageFilter = (user.kids.some((kid) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const kidAge = differenceBetweenDates(formatDate(kid.birthDate)!).years

        return Number((choosedAgeRange[1])) === kidAge || Number(choosedAgeRange[1]) === 13
      }) || (user.kids.length === 0 && choosedAgeRange[1] === 13));

      return searchTermFilter && companyFilter && statusFilter && ageFilter;
    });

    const usersSplittedByFilters = splitArray(usersFiltered);
    setUsersSplitted(usersSplittedByFilters);
    setFilteredUsers(usersSplittedByFilters[0]);
  }, [choosedAgeRange, searchTerm, selectedCompany.value, selectedStatus.value, setFilteredUsers, setUsersSplitted, users]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleCompanyChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCompany(event);
  }, []);

  const handleStatusChange = useCallback((event: { value: string, label: string }) => {
    setSelectedStatus(event);
  }, []);

  const handleAgeSelection = useCallback((age: number[]) => {
    setChoosedAgeRange(age);
  }, []);

  return {
    searchTerm,
    setSearchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
    handleStatusChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    selectedStatus,
  };
}
