import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Forum from '../pages/Forum';
import ForumInternal from '../pages/ForumInternal';
import NotFound from '../pages/NotFound';

export default function AnalyticRoute() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forumInternal" element={<ForumInternal />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
