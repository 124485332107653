import { useCallback } from 'react';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { CupomFromApiObject } from '../../types';

interface UseDownloadExcelInterface {
  fullCupons: CupomFromApiObject[];
}

export default function useDownloadExcel({
  fullCupons,
}: UseDownloadExcelInterface) {
  const downloadExcel = useCallback(() => {
    const cupomKindLiterals: { [key: string]: string } = {
      free: 'Acesso grátis',
      discount: 'Desconto',
      trial: 'Período de teste',
      trial_no_subscription: 'Período de teste (sem cobrança)',
      trial_discount: 'Período de teste, e após, desconto',
    }

    const mappedPartnersList = fullCupons?.map((cp) => ({
      'Nome (código)': cp.name || 'Não informado',
      'Tipo do plano': cupomKindLiterals[cp.planKind] || 'Não informado',
      'Valor do plano': cp.planValue || 'Não informado',
      'Período de teste': cp.planTrialDays || 'Pessoa Juridica',
      'Empresa': cp.company?.nome_fantasia || 'Cupom geral',
    }));
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Cupons Kiddlepass - ${currentDate}`,
      data: mappedPartnersList,
    });
  }, [fullCupons]);

  return {
    downloadExcel,
  };
}
