import { Col, Container, Row } from 'react-bootstrap';
import NoData from '../../../../components/NoData';
import differenceBetweenDates from '../../../../utils/differenceBetweenDates';
import renderArray from '../../../../utils/renderArrayWithComa';
import { SubscriberChildType } from '../../types';
import { KidsContainer, KidsGroup } from './styles';

interface KidsListInterface {
  list: SubscriberChildType[];
}

export default function KidsList({ list }: KidsListInterface) {
  const hasChildrens = list.length !== 0;

  return (
    <KidsContainer>
      {hasChildrens && (
        <Container>
          <Row xs={1} md={list.length === 1 ? 1 : 2} lg={list.length === 1 ? 1 : 2}>
            {list.map((child) => (
              <Col key={child.id}>
                <KidsGroup>
                  <div className="info">
                    <div className="card-title">
                      <strong>{child.name}</strong>
                      {child.birthDate && (
                        <small>{`${differenceBetweenDates(child.birthDate).years} anos`}</small>
                      )}
                    </div>
                    <span>
                      Períodos:
                      {' '}
                      {renderArray(child.preferredPeriods, 'nenhum')}
                    </span>
                    <span>
                      Áreas de interesse:
                      {' '}
                      {renderArray(child.preferredCategories.map((ctg) => ctg.name), 'nenhuma')}
                    </span>
                    <span>
                      Observação:
                      {' '}
                      {child.observation ?? 'Sem observação'}
                    </span>
                    <span>
                      Intérprete de libras:
                      {' '}
                      {child.needsInterpreter ? 'Necessário' : 'Não Necessário'}
                    </span>
                  </div>
                </KidsGroup>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {!hasChildrens && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Este assinante ainda não cadastrou nenhuma criança.
              .
            </>
)}
        />
      )}
    </KidsContainer>
  );
}
