import { Header } from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  downloadExcel: () => void;
  setAddDateModalShow: Dispatch<SetStateAction<boolean>>,
  setImportCsvModalShow: Dispatch<SetStateAction<boolean>>,
  filePathDownload: string;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  singularLabel,
  pluralLabel,
  downloadExcel,
  setAddDateModalShow,
  setImportCsvModalShow,
  filePathDownload,
}: ListHeaderInterface) {
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <div className="leftAlignment">
            <strong>
              {filteredListLength}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
            </strong>
          </div>
        )}
        <div className="actionButtons">
          <Link to={filePathDownload} target="_blank" download>
              Exemplo CSV
          </Link>
          {doesListExists && (
            <button type="button" onClick={downloadExcel}>Download XLSX</button>
          )}
          <button type="button" onClick={() => setImportCsvModalShow(true)}>Importar CSV</button>
          <button type="button" onClick={() => setAddDateModalShow(true)}>Desabilitar nova data</button>
        </div>
      </Header>
    </OpacityAnimation>
  );
}
