import { pathName } from '../pathName';
import HttpClient from './utils/HttpClient';

interface HighlightsServiceInterface {
  order?: number;
  token: string;
  activityId?: string;
  highlightId?: string;
  type?: 'live' | 'record';
}

class HighlightsService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getHighlightsList = async ({
    token,
  }: HighlightsServiceInterface) => {
    return this.httpClient.get({
      path: '/highlights',
      token,
    });
  }

  createOrUpdateHighlight = async ({
    order,
    activityId,
    type,
    token,
  }: HighlightsServiceInterface) => {
    return this.httpClient.post({
        path: `/highlights/${order}?activityId=${activityId}&type=${type}`,
        token,
      });
  }

  deleteHighlight = async ({
    highlightId,
    token,
  }: HighlightsServiceInterface) => {
    return this.httpClient.delete({
        path: `/highlights/${highlightId}`,
        token,
      });
  }
}

export default new HighlightsService();
