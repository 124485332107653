import useAnimatedUnmount from '../../hooks/useAnimatedUnmount';
import ReactPortal from '../ReactPortal';
// import Spinner from '../Spinner';
import earthKiddle from '../../assets/images/earthKiddle.svg';
import rocketKiddle from '../../assets/images/rocketKiddle.svg';
import { ImagesContainer, Overlay } from './styles';

interface LoaderInterface {
  isLoading: boolean;
}

export default function Loader({ isLoading }: LoaderInterface) {
  const { animatedElementRef, shouldRender } = useAnimatedUnmount(isLoading);

  if (!shouldRender) {
    return null;
  }

  return (
    <ReactPortal containerId="loader-root">
      <Overlay isLeaving={!isLoading} ref={animatedElementRef}>
        <ImagesContainer>
          <img src={earthKiddle} alt="" className='earth' />
          <img src={rocketKiddle} alt="" className='rocket' />
        </ImagesContainer>
      </Overlay>
    </ReactPortal>
  );
}
