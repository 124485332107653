import { ChangeEvent } from 'react';
import MyModal from '../../../components/Modal';
import FormGroup from '../../../components/FormGroup';
import Input from '../../../components/Input';

interface AddModalInterface {
  addDateModalShow: boolean;
  dateToDisable: string;
  handleDateToDisableChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAddDateModalClose: () => void;
  disableDate: () => void;
}

export default function AddDateModal({
  addDateModalShow,
  dateToDisable,
  handleDateToDisableChange,
  handleAddDateModalClose,
  disableDate,
}: AddModalInterface) {
  return (
    <MyModal
      show={addDateModalShow}
      title="Desabilitar nova data"
      modalBody={(
        <FormGroup>
          <label htmlFor="disabledDate">Selecione a data</label>
          <Input
            type='date'
            placeholder="Insira o id do colaborador a ser adicionado"
            value={dateToDisable}
            onChange={handleDateToDisableChange}
            autoComplete="new-password"
            darkBorder
          />
        </FormGroup>
      )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Desabilitar"
      onClose={handleAddDateModalClose}
      onAction={() => disableDate()}
      isActionButtonDisabled={!dateToDisable}
      type="action"
    />
  );
}
