/* eslint-disable max-len */
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import splitArray from '../../../../utils/splitArray';
import { MappedSubscriber } from '../../types';

interface UseSearchesInterface {
  fullSubscribers: MappedSubscriber[];
  setSubscribersSplited: Dispatch<SetStateAction<Array<MappedSubscriber[]>>>;
  setFilteredSubscribers: Dispatch<SetStateAction<MappedSubscriber[]>>;
}

export default function useSearches({
  fullSubscribers,
  setSubscribersSplited,
  setFilteredSubscribers,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCupom, setSelectedCupom] = useState({ value: '', label: 'Filtrar por cupom' });
  const [selectedCompany, setSelectedCompany] = useState({ value: '', label: 'Filtrar por empresa' });
  const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'Filtrar por status' });
  const [selectedEngagement, setSelectedEngagement] = useState({ value: '', label: 'Filtrar por engajamento' });
  const [selectedKind, setSelectedKind] = useState('');

  const filterSubscribers = useCallback(() => {
    const subscribersFiltered = fullSubscribers.filter((sbs) => {
      const companyFilter = sbs.company === selectedCompany.value || selectedCompany.value === '';
      const cupomFilter = sbs.cupom === selectedCupom.value || selectedCupom.value === '';
      const statusFilter = sbs.status === selectedStatus.value || selectedStatus.value === '';
      const engagementFilter = sbs.engagementStatus === selectedEngagement.value || selectedEngagement.value === '';
      const searchTermFilter = (
        sbs.name.toLowerCase().includes(searchTerm.toLowerCase())
        || sbs.email.toLowerCase().includes(searchTerm.toLowerCase())
        || sbs.cellphone.includes(searchTerm)
      );
      const subscriberKindFilter = sbs.kind === selectedKind || selectedKind === '';

      return companyFilter && cupomFilter && statusFilter && searchTermFilter && subscriberKindFilter && engagementFilter;
    });

    const subscribersSplitedbyFilters = splitArray(subscribersFiltered);
    setSubscribersSplited(subscribersSplitedbyFilters);
    setFilteredSubscribers(subscribersSplitedbyFilters[0]);
  }, [fullSubscribers, searchTerm, selectedCompany.value, selectedCupom.value, selectedEngagement.value, selectedKind, selectedStatus.value, setFilteredSubscribers, setSubscribersSplited]);

  useEffect(() => {
    filterSubscribers();
  }, [filterSubscribers]);

  const handleCompanyChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCompany(event);
  }, []);

  const handleCupomChange = useCallback((event: { value: string, label: string }) => {
    setSelectedCupom(event);
  }, []);

  const handleStatusChange = useCallback((event: { value: string, label: string }) => {
    setSelectedStatus(event);
  }, []);

  const handleEngagementChange = useCallback((event: { value: string, label: string }) => {
    setSelectedEngagement(event);
  }, []);

  const handleKindChange = useCallback((event: string) => {
    setSelectedKind(event);
  }, []);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  return {
    searchTerm,
    selectedCupom,
    selectedCompany,
    selectedStatus,
    selectedKind,
    handleCompanyChange,
    handleCupomChange,
    handleStatusChange,
    handleKindChange,
    handleChangeSearchTerm,
    selectedEngagement,
    handleEngagementChange,
  };
}
