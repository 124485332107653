/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';
import ExportXlsx from '../../../../../utils/ExportXlsx';
import removeDuplicates from '../../../../../utils/removeDuplicates';

type CollaboratorType = {
  id: string;
  empresa_suspenso?: string | null;
  data_ativacao?: string | null;
  associated_company?: string;
}

type UsersFromApiResponse = {
  result?: CollaboratorType[];
  associatedCompanies?: { name: string, createdAt: Date }[];
  error?: string;
}

export default function useDownloadUsageRelatory() {
  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId, companyName } = useParams();

  const today = new Date().toJSON().slice(0, 10);
  const day = today.split('-')[2];
  const month = today.split('-')[1];
  const year = today.split('-')[0];
  const currentDate = `${day}-${month}-${year}`;

  const downloadUsageRelatory = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await toast.promise(apiCall({
      apiToCall: companiesService.listUsers,
      queryParams: { companyId },
      actionAfterResponse: (apiResponse: UsersFromApiResponse) => {
        if (apiResponse.error && apiResponse.error !== 'sem usuarios' && !apiResponse.result) {
          toast.error(`Não foi possível baixar o xlsx dos colaboradores (${apiResponse.error})`);
          return;
        }

        const collaborators = apiResponse.result!;
        const associatedCompaniesObjects = apiResponse.associatedCompanies;

        const allAssociatedCompanies = removeDuplicates(collaborators.map((collaborator) => collaborator.associated_company));
        const hasSomeAssociatedCompany = allAssociatedCompanies.length !== 0 && Boolean(allAssociatedCompanies[0]);
        const associatedCompanies = allAssociatedCompanies.map((associatedCompany) => associatedCompaniesObjects?.find((company) => company.name === associatedCompany));

        function determineCollaboratorStatus(collaborator: CollaboratorType) {
          return (collaborator.empresa_suspenso
            ? 'Suspenso'
            : (
              collaborator.data_ativacao
                ? 'Ativo'
                : 'Registrado'
            ))
        }

        const totalRegisteredCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Registrado').length;
        const totalActiveCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Ativo').length;
        const totalSuspendCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Suspenso').length;
        const totalCollaborators = collaborators.length;

        if (!hasSomeAssociatedCompany) {
          const relatory: { [key: string]: string | number; }[] = [
            {
              'Usuários Ativos': totalActiveCollaborators ?? 0,
              'Usuários Registrados': totalRegisteredCollaborators ?? 0,
              'Usuários Suspensos': totalSuspendCollaborators ?? 0,
              'Total': totalCollaborators ?? 0,
            },
          ]

          ExportXlsx({
            data: relatory,
            filename: `Relatório de uso benefício kiddlepass - ${companyName} - ${currentDate}`,
          });
        } else {
          const relatoryByAssociatedCompany: { [key: string]: string | number; }[] = [];

          associatedCompanies.forEach((associatedCompany) => {
            if (!associatedCompany) return;
            const totalRegisteredCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Registrado' && collaborator.associated_company === associatedCompany.name).length;
            const totalActiveCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Ativo' && collaborator.associated_company === associatedCompany.name).length;
            const totalSuspendCollaborators = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Suspenso' && collaborator.associated_company === associatedCompany.name).length;
            const totalCollaborators = collaborators.filter((collaborator) => collaborator.associated_company === associatedCompany.name).length;

            console.log(associatedCompany);
            relatoryByAssociatedCompany.push({
              'Empresa Associada': associatedCompany.name,
              'Adicionada Em': format(new Date(associatedCompany.createdAt), 'dd/MM/yyyy'),
              'Usuários Ativos': totalActiveCollaborators ?? 0,
              'Usuários Registrados': totalRegisteredCollaborators ?? 0,
              'Usuários Suspensos': totalSuspendCollaborators ?? 0,
              'Total': totalCollaborators ?? 0,
            });
          });

          const hasSomeCollaboratorWithoutAssociatedCompany = collaborators.some((collaborator) => !collaborator.associated_company);
          const totalRegisteredCollaboratorsWithoutAssociatedCompany = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Registrado' && !collaborator.associated_company)?.length;
          const totalActiveCollaboratorsWithoutAssociatedCompany = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Ativo' &&!collaborator.associated_company)?.length;
          const totalSuspendCollaboratorsWithoutAssociatedCompany = collaborators.filter((collaborator) => determineCollaboratorStatus(collaborator) === 'Suspenso' &&!collaborator.associated_company)?.length;
          const totalCollaboratorsWithoutAssociatedCompany = collaborators.filter((collaborator) => !collaborator.associated_company)?.length;

          const footer: { [key: string]: string | number; }[] = [
            ...(hasSomeCollaboratorWithoutAssociatedCompany ? [{
              'Empresa Associada': 'Não Definida',
              'Adicionada Em': 'Empresa não definida',
              'Usuários Ativos': totalRegisteredCollaboratorsWithoutAssociatedCompany ?? 0,
              'Usuários Registrados': totalActiveCollaboratorsWithoutAssociatedCompany ?? 0,
              'Usuários Suspensos': totalSuspendCollaboratorsWithoutAssociatedCompany ?? 0,
              'Total': totalCollaboratorsWithoutAssociatedCompany ?? 0,
           }] : []),
            {
               'Empresa Associada': 'TOTAL',
               'Adicionada Em': 'Empresa não definida',
               'Usuários Ativos': totalActiveCollaborators ?? 0,
               'Usuários Registrados': totalRegisteredCollaborators ?? 0,
               'Usuários Suspensos': totalSuspendCollaborators ?? 0,
               'Total': totalCollaborators ?? 0,
            },
          ]

          const finalRelatory = relatoryByAssociatedCompany.concat(footer);

          ExportXlsx({
            data: finalRelatory,
            filename: `Relatório de uso benefício kiddlepass por empresa associada - ${companyName} - ${currentDate}`,
          });
        }
      },
      catchMessage: 'Não foi possível baixar o relatório de uso,'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    });
  }, [companyId, apiCall, navigate, companyName, currentDate]);

  return {
    downloadUsageRelatory,
  };
}
