import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import highlightsService from '../../../../services/highlightsService';

interface UseDeleteHighlightI {
  loadHighlightsPage: () => void;
}

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

interface HighlightBeingDeletedI {
  id: string;
  name: string;
}

export default function useDeleteHighlight({
  loadHighlightsPage,
}: UseDeleteHighlightI) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [highlightBeingDeleted, setHighlightBeingDeleted] = useState({} as HighlightBeingDeletedI);

  const { apiCall } = useApiCall();

  const deleteHighlight = useCallback(async (highlightId: string) => {
    await apiCall({
        apiToCall: highlightsService.deleteHighlight,
        queryParams: { highlightId },
        onStartLoad: () => setIsDeleting(true),
        onEndLoad: () => {
          setIsDeleting(false);
          setDeleteModalShow(false);
        },
        actionAfterResponse: (apiResponse) => {
          if (!apiResponse.success) {
            toast.error('Não foi possível remover o destaque. Por favor, tente novamente');
            return;
          }
          toast.success('O destaque foi reomvido com sucesso!', {
            toastId: 'deleteActivitySuccess',
          });
          loadHighlightsPage();
        },
        catchMessage: 'Não foi possível deletar a atividade, Por favor, tente novamente',
    })
  }, [apiCall, loadHighlightsPage]);

  function handleOpenDeleteModal({ name, id }: HandleOpenDeleteModalInterface) {
    setDeleteModalShow(true);
    setHighlightBeingDeleted({
      name,
      id,
    });
  }

  return {
    isDeleting,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    highlightBeingDeleted,
    deleteHighlight
  };
}
