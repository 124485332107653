import { useState } from 'react';
import useActiveUsersPeriod from './functions/useActiveUsersPeriod';
import useActivitiesQuantity from './functions/useActivitiesQuantity';
import useActivitiesRanking from './functions/useActivitiesRanking';
import useCanceleds from './functions/useCanceleds';
import useChurn from './functions/useChurn';
import useKidsAges from './functions/useKidsAges';
import useKidsAverage from './functions/useKidsAverage';
import useLoadDashboard from './functions/useLoadDashboard';
import usePartnersQuantity from './functions/usePartnersQuantity';
import useRatings from './functions/useRatings';
import useSchedulesPerformance from './functions/useSchedulesPerformance';
import useUsersSo from './functions/useSoPercentage';
import useUsersQuantities from './functions/useUsersQuantities';
import useUsersRegions from './functions/useUsersRegions';

export default function useDashboard() {
  const [hasError, setHasError] = useState(false);

  const {
    getUsersQuantities,
    activeUsersQty,
    registeredUsersQty,
    percentageKinds,
    companiesUsage,
  } = useUsersQuantities({ setHasError });

  const {
    partnersQty,
    getPartnersQuantity,
  } = usePartnersQuantity({ setHasError });

  const {
    activitiesQty,
    getActivitiesQuantity,
  } = useActivitiesQuantity({ setHasError });

  const {
    kidsAverage,
    getKidsAverage,
  } = useKidsAverage({ setHasError });

  const {
    schedulesQtyByMonth,
    getSchedulesPerformance,
  } = useSchedulesPerformance({ setHasError });

  const {
    regionsData,
    getUsersRegions,
  } = useUsersRegions({ setHasError });

  const {
    activitiesRanking,
    getActivitiesRanking,
    getActivitiesVideosViewsRanking,
    activitiesVideosViewsRanking,
  } = useActivitiesRanking({ setHasError });

  const {
    canceleds,
    getCanceleds,
  } = useCanceleds({ setHasError });

  const {
    churnData,
    getChurn,
  } = useChurn({ setHasError });

  const {
    nps,
    ratings,
    getUsersRatings,
  } = useRatings({ setHasError });

  const {
    kidsAge,
    getKidsAges,
  } = useKidsAges({ setHasError });

  const {
    soData,
    getUsersSo,
  } = useUsersSo({ setHasError });

  const {
    todayActiveUsers,
    lastWeekActiveUsers,
    lastMonthActiveUsers,
    getActiveUsersByPeriod,
  } = useActiveUsersPeriod({ setHasError });

  const {
    isLoading,
    loadDashboard,
  } = useLoadDashboard({
    getUsersQuantities,
    getPartnersQuantity,
    getActivitiesQuantity,
    getKidsAverage,
    getSchedulesPerformance,
    getUsersRegions,
    getActivitiesRanking,
    getUsersRatings,
    getKidsAges,
    getCanceleds,
    getChurn,
    getActivitiesVideosViewsRanking,
    getActiveUsersByPeriod,
    getUsersSo,
    setHasError,
  });

  function handleTryAgain() {
    loadDashboard();
  }

  return {
    isLoading,
    hasError,
    handleTryAgain,
    activeUsersQty,
    registeredUsersQty,
    partnersQty,
    activitiesQty,
    kidsAverage,
    schedulesQtyByMonth,
    regionsData,
    activitiesRanking,
    ratings,
    percentageKinds,
    companiesUsage,
    kidsAge,
    nps,
    canceleds,
    churnData,
    activitiesVideosViewsRanking,
    soData,
    todayActiveUsers,
    lastWeekActiveUsers,
    lastMonthActiveUsers,
  };
}
