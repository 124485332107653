import { Col, Container, Row } from 'react-bootstrap';
import { Card } from './styles';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';

interface HandleOpenDeleteModalInterface {
  name: string;
  id: string;
}

interface MappedHighlightsListType {
  activity: {
    nome: string;
    categoria: string;
    subcategoria: string;
    partner: {
      nome_fantasia: string;
    };
    featPartner: {
      nome_fantasia: string;
    };
  }
  order: number;
  id: string;
}

interface ListInterface {
  list: MappedHighlightsListType[];
  handleOpenDeleteModal: ({ name, id }: HandleOpenDeleteModalInterface) => void;
}

export default function List({
  list,
  handleOpenDeleteModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {list?.map((highlight) => (
          <Col key={highlight.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{highlight.activity.nome}</strong>
                    <small>
                      # {highlight.order}
                    </small>
                  </div>
                  <span>
                    Categoria:
                    {' '}
                    {highlight.activity.categoria}
                  </span>
                  <span>
                    Subcategoria:
                    {' '}
                    {highlight.activity.subcategoria}
                  </span>
                  <span>
                    Parceiro:
                    {' '}
                    {highlight.activity.partner.nome_fantasia}
                    {(!!highlight.activity.featPartner?.nome_fantasia) && ` / ${highlight.activity.featPartner?.nome_fantasia}`}
                  </span>
                </div>

                <div className="actions">
                  <button
                    type="button"
                    onClick={() => handleOpenDeleteModal({
                      name: highlight.activity.nome,
                      id: highlight.id,
                    })}
                  >
                    <img src={trash} alt="trash" title="Excluir Destaque" />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
