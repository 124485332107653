/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Select, { MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { AsideContainer, Group, Container as StyledContainer } from '../styles';

import FormGroup from '../../FormGroup';
import Input from '../../Input';
import Textarea from '../../Textarea';

import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import info from '../../../assets/images/icons/info.svg';
import { GetErrorMessageByFieldNameType } from '../../../hooks/useErrors';
import tags from '../../../mocks/tags';
import { CustomStyle } from '../../CustomSelectStyle';
import { Value } from '../types/CalendarTypes';
import { CategoriesType, IsRecurrentType, PartnerType, SubcategoryType } from '../types/FormTypes';
import PeriodSelectionModal from './PeriodSelectionModal';

interface GeneralDataCardInterface {
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  name: string;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  category: CategoriesType;
  categories: CategoriesType[];
  setCategory: Dispatch<SetStateAction<CategoriesType>>;
  subcategory: SubcategoryType;
  subcategoriesOptions: SubcategoryType[];
  setSubcategory: Dispatch<SetStateAction<SubcategoryType>>;
  setMinAge: Dispatch<SetStateAction<number | string>>;
  setMaxAge: Dispatch<SetStateAction<number | string>>;
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  description: string;
  kindOfActivity: string;
  handleVideoLinkChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  videoLink: string;
  handleMethodologyChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  methodology: string;
  handleOrientationsChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  orientations: string;
  isRecurrent: IsRecurrentType;
  setIsRecurrent: Dispatch<SetStateAction<IsRecurrentType>>;
  recurrentOptions: IsRecurrentType[];
  partner: PartnerType;
  partners: PartnerType[];
  setPartner: Dispatch<SetStateAction<PartnerType>>;
  featPartner: PartnerType,
  setFeatPartner: Dispatch<SetStateAction<PartnerType>>;
  specificPeriod: Value;
  handleSpecificPeriodChange: (value: Value, event: React.MouseEvent<HTMLButtonElement>) => void;
  setPeriodSelectionModalShow: Dispatch<SetStateAction<boolean>>;
  specificPeriodLabel: string;
  periodSelectionModalShow: boolean;
  selectedTags: MultiValue<{ value: string, label: string }>;
  handleTagsChange: (tag: MultiValue<{ value: string, label: string }>) => void;
  isFree: boolean;
  setIsFree: Dispatch<SetStateAction<boolean>>;
  classValue: number | string;
  handleClassValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function GeneralDataCard({
  getErrorMessageByFieldName,
  name,
  handleNameChange,
  category,
  categories,
  setCategory,
  partner,
  partners,
  setPartner,
  subcategory,
  subcategoriesOptions,
  setSubcategory,
  setMinAge,
  setMaxAge,
  handleDescriptionChange,
  description,
  kindOfActivity,
  handleVideoLinkChange,
  videoLink,
  handleMethodologyChange,
  methodology,
  handleOrientationsChange,
  orientations,
  isRecurrent,
  setIsRecurrent,
  recurrentOptions,
  featPartner,
  setFeatPartner,
  isFree,
  setIsFree,
  handleSpecificPeriodChange,
  specificPeriod,
  setPeriodSelectionModalShow,
  specificPeriodLabel,
  periodSelectionModalShow,
  selectedTags,
  handleTagsChange,
  classValue,
  handleClassValueChange,
}: GeneralDataCardInterface) {
  const tagsOptions: MultiValue<{ value: string, label: string }> = tags.map((tg) => ({ value: tg, label: tg }));
  const animatedComponents = makeAnimated();
  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome *
          </div>
          <FormGroup error={getErrorMessageByFieldName('name')}>
            <Input
              value={name}
              onChange={handleNameChange}
              error={getErrorMessageByFieldName('name')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Parceiro *
          </div>
          <Select
            value={{ value: partner?.value, label: partner?.label }}
            options={partners}
            onChange={(opt) => {
              setPartner({ value: opt!.value, label: opt!.label });
              setFeatPartner({ value: null, label: 'Selecione um parceiro' })
            }}
            placeholder="Selecione um parceiro"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            <div>
              Parceiro Secundário
              {' '}
              <img src={info} alt="interrogation" title="Caso outro parceiro também dê essa aula, selecione o mesmo aqui" />
            </div>
          </div>
          <Select
            value={{ value: featPartner?.value, label: featPartner?.label }}
            options={[
              { value: null, label: 'Nenhum parceiro' },
              ...partners.filter((pt) => !(pt.value === partner.value as string))]}
            isDisabled={!partner.value}
            onChange={(opt) => {
              setFeatPartner({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione um parceiro"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            <div>
              Disponibilidade
            </div>
          </div>
          <Select
            value={{ value: isFree ? 'all' : 'subscribers', label: isFree ? 'Todos Usuários' : 'Apenas Assinantes' }}
            options={[
              { value: 'subscribers', label: 'Apenas Assinantes' },
              { value: 'all', label: 'Todos Usuários' }
            ]}
            onChange={(opt) => {
              setIsFree(opt!.value === 'all');
            }}
            placeholder="Selecione a disponibilidade"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
      </AsideContainer>
      <AsideContainer>
      <Group>
          <div className="title">
            Categoria *
          </div>
          <Select
            value={{ value: category?.value, label: category?.label }}
            options={categories}
            onChange={(opt) => {
              setCategory({ value: opt!.value, label: opt!.label });
            }}
            placeholder="Selecione uma categoria"
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Subcategoria *
          </div>
          <Select
            value={{ value: subcategory?.value, label: subcategory?.label }}
            options={subcategoriesOptions}
            onChange={(opt) => {
              setSubcategory({ value: opt!.value, label: opt!.label });
              if (opt!.value === 'Educação parental') {
                setMinAge(18);
                setMaxAge('Não possui limite de idade');
              }
            }}
            placeholder="Selecione uma subcategoria"
            styles={CustomStyle}
            isDisabled={!category.value}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        {kindOfActivity === 'online' && (
          <Group>
            <div className="title">
              <div>
                Atividade recorrente?
                {' '}
                <img src={info} alt="interrogation" title="Permite o agendamento automático desta atividade pelos alunos para todo o mês" />
              </div>
            </div>
            <Select
              value={{ value: isRecurrent?.value, label: isRecurrent?.label }}
              options={recurrentOptions}
              onChange={(opt) => {
                setIsRecurrent({ value: opt!.value, label: opt!.label });
              }}
              placeholder="Selecione uma opção"
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </Group>
        )}
        {!isRecurrent.value && (
            <Group>
              <div className="title">
                <div>
                  Período em que ocorrerá
                </div>
              </div>
              <FormGroup error={getErrorMessageByFieldName('specificPeriod')}>
              <Input
                value={specificPeriodLabel}
                periodInput
                onClick={() => setPeriodSelectionModalShow(true)}
                error={getErrorMessageByFieldName('specificPeriod')}
                readOnly
              />
              </FormGroup>
            </Group>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            <div>
              Descrição *
            </div>
          </div>
          <FormGroup error={getErrorMessageByFieldName('description')}>
            <Textarea
              onChange={handleDescriptionChange}
              placeholder="Descreva com mais detalhes sua atividade"
              value={description}
              height={100}
            />
          </FormGroup>
        </Group>
        {kindOfActivity === 'online' && (
        <Group link>
          <div className="title">
            Link da aula *
          </div>
          <FormGroup error={getErrorMessageByFieldName('videoLink')}>
            <Textarea
              onChange={handleVideoLinkChange}
              placeholder="Insira o link da sua aula online"
              value={videoLink}
              height={100}
            />
          </FormGroup>
        </Group>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Metodologia
          </div>
          <Textarea
            onChange={handleMethodologyChange}
            placeholder="Explique sobre a metodologia de ensino desta atividade"
            value={methodology}
            height={100}
          />
        </Group>
        <Group>
          <div className="title">
            Orientações
          </div>
          <Textarea
            onChange={handleOrientationsChange}
            placeholder="Insira aqui orientações adicionais para sua atividade"
            value={orientations}
            height={100}
          />
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Tags
          </div>
          <Select
            value={selectedTags}
            options={tagsOptions}
            onChange={(opt) => handleTagsChange(opt)}
            placeholder="Selecione as tags da atividade"
            isMulti
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
            components={animatedComponents}
            closeMenuOnSelect={false}
          />
        </Group>
        {kindOfActivity === 'online' && (
          <Group>
            <div className="title">
              Valor/aula dada *
            </div>
            <FormGroup error={getErrorMessageByFieldName('classValue')}>
              <Input
                value={classValue}
                onChange={handleClassValueChange}
                error={getErrorMessageByFieldName('classValue')}
              />
            </FormGroup>
          </Group>
        )}
      </AsideContainer>

      <PeriodSelectionModal
        periodSelectionModalShow={periodSelectionModalShow}
        setPeriodSelectionModalShow={setPeriodSelectionModalShow}
        handleSpecificPeriodChange={handleSpecificPeriodChange}
        specificPeriod={specificPeriod}
      />
    </StyledContainer>
  );
}
