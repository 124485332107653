import { Card } from './styles';

interface AgreedAmounthCardInterface {
  agreedAmounth?: string;
}

export default function AgreedAmounthCard({ agreedAmounth }: AgreedAmounthCardInterface) {
  return (
    <Card>
      <div className="card-title">
        <div>
          Valor acordado
        </div>
      </div>
      <div className="card-main">
        <div>
          {agreedAmounth || 'Não informado'}
        </div>
        {!!agreedAmounth && (
          <small>
            Por mês
          </small>
        )}
      </div>
    </Card>
  );
}
