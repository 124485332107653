import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import useErrors from "../../../../hooks/useErrors";
import forumService from "../../../../services/forumService";

interface IUseAddPostModal {
  loadForum: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setAddPostModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function useAddPostModal({
  loadForum,
  setIsLoading,
  setAddPostModalShow,
}: IUseAddPostModal) {
  const [postText, setPostText] = useState('');
  const [file, setFile] = useState<File| string | undefined>(undefined);
  const [fileName, setFileName] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handlePostTextChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setPostText(event.target.value);
    if (!event.target.value) {
      setError({ field: 'postText', message: 'Texto da publicação é obrigatório' })
    } else {
      removeError('postText');
    }
  }

  function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir esse arquivo. Por favor, tente novamente')
      return;
    }
    setFile(files[0])
    setFileName(files[0].name)
  }

  function removeFile() {
    setFileName('');
    setFile(undefined);
  }

  const addPost = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.createPost,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      reqBody: [
        { key: 'text', value: postText },
        { key: 'file', value: file }
      ],
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível criar o post. Por favor, tente novamente');
          return;
        }
        setFileName('');
        setFile(undefined);
        setPostText('');
        setAddPostModalShow(false);
        await loadForum();
        toast.success('Post criado com sucesso!');
      },
    })
  }, [apiCall, file, loadForum, postText, setAddPostModalShow, setIsLoading]);

  const isFormValid = !!postText && errors.length === 0;

  return {
    postText,
    file,
    fileName,
    getErrorMessageByFieldName,
    handlePostTextChange,
    handleFileUpload,
    removeFile,
    isFormValid,
    addPost,
  }
}
