import PropTypes from 'prop-types';
import {
  ReactNode,
  createContext,
  useCallback, useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthService from '../services/authService';

import useApiCall from '../hooks/useApiCall';
import useLocalState from '../hooks/useLocalState';
import { isLocal } from '../pathName';

interface AuthProviderProps {
  children: ReactNode;
}

export interface AppContextInterface {
  signed: boolean,
  token: string,
  user: {
    id: string,
    permission: string,
    email: string,
  }
  signOut: () => void,
  // eslint-disable-next-line no-unused-vars
  login: (email: string, password: string) => void,
  loginWithGoogle: (code: string) => void,
}

const AuthContext = createContext({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAppContext(): AppContextInterface | any {
  const context = useContext(AuthContext);

  return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [signed, setSigned] = useState(false);
  const [signedLocalStorage, setSignedLocalStorage] = useLocalState('validado');

  const [tokenJwt, setTokenJwt] = useState('');
  const [tokenLocalStorage, setTokenLocalStorage] = useLocalState('token');

  const [userData, setUserData] = useState({});
  const [userDataLocalStorage, setUserDataLocalStorage] = useLocalState('userData');

  const [,setTargetOptions] = useLocalState('targetOption');

  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const login = useCallback(async (email: string, password: string) => {
    await apiCall({
      apiToCall: AuthService.auth,
      reqBody: JSON.stringify({
        email,
        password,
      }),
      actionAfterResponse: (apiResponse) => {
        const { user, token } = apiResponse;
        if (user?._id && token) {
          setTokenJwt(token);
          setTokenLocalStorage(token);
          setUserData({
            id: user._id,
            permission: user.permission || 'analytics',
            email,
          });
          setUserDataLocalStorage({
            id: user._id,
            permission: user.permission || 'analytics',
            email,
          });
          setSigned(true);
          setSignedLocalStorage(true);
          toast.success('Login realizado, bem vindo ao sistema administrativo Kiddlepass!', { toastId: 'loginSuccess' });
          return;
        }
        setSigned(false);
        setSignedLocalStorage(false);
        const errorMessage = (apiResponse.success === false
          ? 'Login/senha inválidos!'
          : 'Houve um erro interno e não foi possível realizar seu login'
        );
        toast.error(errorMessage, { toastId: 'loginFailes' });
      },
      catchAction: () => {
        setSigned(false);
        setSignedLocalStorage(false);
      },
      catchMessage: 'Erro, por favor, tente novamente.'
    })
    },
    [apiCall, setSignedLocalStorage, setTokenLocalStorage, setUserDataLocalStorage],
  );

  const loginWithGoogle = useCallback(async (code: string) => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    await apiCall({
      apiToCall: AuthService.googleLogin,
      reqBody: JSON.stringify({
        code,
        redirectUri: isLocal ? 'http://localhost:3000' :`${protocol}//${hostname}`,
      }),
      actionAfterResponse: (apiResponse) => {
        const { user, token, email } = apiResponse;
        if (user?._id && token) {
          setTokenJwt(token);
          setTokenLocalStorage(token);
          setUserData({
            id: user._id,
            permission: user.permission || 'analytics',
            email,
          });
          setUserDataLocalStorage({
            id: user._id,
            permission: user.permission || 'analytics',
            email,
          });
          setSigned(true);
          setSignedLocalStorage(true);
          toast.success('Login realizado, bem vindo ao sistema administrativo Kiddlepass!', { toastId: 'loginSuccess' });
          window.history.replaceState(null, '', window.location.pathname);
          return;
        }
        setSigned(false);
        setSignedLocalStorage(false);
        const errorMessage = (apiResponse.success === false
          ? 'Login/senha inválidos!'
          : 'Houve um erro interno e não foi possível realizar seu login'
        );
        toast.error(errorMessage, { toastId: 'loginFailes' });
      },
      catchAction: () => {
        setSigned(false);
        setSignedLocalStorage(false);
      },
      catchMessage: 'Erro, por favor, tente novamente.'
    })
  }, [apiCall, setSignedLocalStorage, setTokenLocalStorage, setUserDataLocalStorage]);

  const signOut = useCallback(() => {
    toast.error('Sessão encerrada, realize o login novamente', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      toastId: 'logout',
    });
    setSigned(false);
    setSignedLocalStorage(false);

    setTokenJwt('');
    setTokenLocalStorage('');

    setUserData({});
    setUserDataLocalStorage({});

    setTargetOptions([]);
    navigate('/');
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSignedLocalStorage, setUserDataLocalStorage, setTokenLocalStorage]);

  const appData = useMemo((): AppContextInterface => ({
    signed: signedLocalStorage || signed,
    user: userDataLocalStorage || userData,
    token: tokenJwt || tokenLocalStorage,
    signOut,
    login,
    loginWithGoogle,
  }), [signedLocalStorage, signed, userDataLocalStorage, userData, tokenJwt, tokenLocalStorage, signOut, login, loginWithGoogle]);

  return (
    <>
      <AuthContext.Provider value={appData}>
        {children}
      </AuthContext.Provider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={4}
      />
    </>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
