import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import DeletePushModal from "./components/DeletePushModal";
import ListHeader from "./components/header";
import List from "./components/list";
import Search from "./components/searchAtPage";
import useCancelPush from "./useCancelPush";

export default function CancelPush() {
  const {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    scheduledPushs,
    filteredScheduledPushs,
    doesListApiHasError,
    handleOpenDeleteModal,
    pushBeingDeleted,
    setDeleteModalShow,
    deletePush,
    deleteModalShow,
    handleTryAgain,
  } = useCancelPush();

  const hasScheduledPushs = scheduledPushs?.length !== 0 && !!scheduledPushs;
  const filteredListLength = filteredScheduledPushs?.length ?? 0;
  const searchNotFound = filteredListLength === 0 && hasScheduledPushs;
  const isListEmpty = !doesListApiHasError && (!isLoading && scheduledPushs.length === 0);

  return (
    <>
      <Sidebar active="CancelPush" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasScheduledPushs && (
          <Search
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasScheduledPushs}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="notificação agendada"
          pluralLabel="notificações agendadas"
        />

        {hasScheduledPushs && (
          <List
            filteredList={filteredScheduledPushs}
            onOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        <DeletePushModal
          pushBeingDeleted={pushBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deletePush={deletePush}
          deleteModalShow={deleteModalShow}
        />

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado para
                {' '}
                <strong>{searchTerm || '" "'}</strong>
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as notificações agendadas.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma notificação agendada.
              </>
)}
          />
        )}
      </Transitions>
    </>
  )
}
