import { useState } from "react"
import { InternalUserObject } from "../../types";

export default function useEdit() {
  const [editModalShow, setEditModalShow] = useState(false);
  const [internalUserObjectBeingEditted, setInternalUserObjectBeingEditted] = useState({} as InternalUserObject);

  function handleOpenEditModal(internalUserObjectBeingEditted: InternalUserObject) {
    setInternalUserObjectBeingEditted(internalUserObjectBeingEditted);
    setEditModalShow(true);
  }

  return {
    handleOpenEditModal,
    editModalShow,
    setEditModalShow,
    internalUserObjectBeingEditted,
    setInternalUserObjectBeingEditted,
  }
}
