import { Col, Container, Row } from 'react-bootstrap';
import activate from '../../../../../assets/images/icons/activate.svg';
import suspend from '../../../../../assets/images/icons/suspend.svg';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { DownloadRelatoryPermissionType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: DownloadRelatoryPermissionType[];
  onOpenChangePermissionModal: (relatoryToChangePermission: DownloadRelatoryPermissionType) => void;
}

export default function List({
  filteredList,
  onOpenChangePermissionModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((rl) => (
          <Col key={rl.partnerId}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{rl.partnerName}</strong>
                    <small className={rl.isAllowed ? 'green' : 'orange'}>
                      {rl.isAllowed ? 'Liberado' : 'Bloqueado'}
                    </small>
                  </div>
                </div>

                <div className="actions">
                  <button type='button' onClick={() => onOpenChangePermissionModal(rl)}>
                    <img src={rl.isAllowed ? suspend : activate} alt="" title={rl.isAllowed ? 'Bloquear Relatório' : 'Liberar Relatório'} />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
