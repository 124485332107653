import {
  ChangeEvent,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import splitArray from '../../../../utils/splitArray';
import { MappedCompany } from '../../types';

interface UseSearchesInterface {
  fullCompanies: MappedCompany[];
}

export default function useSearches({
  fullCompanies,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('Todos');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesSplitted, setCompaniesSplited] = useState<Array<MappedCompany[]>>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<MappedCompany[]>([]);

  const filterCompanies = useCallback(() => {
    const fullCompaniesFiltered = fullCompanies.filter((company) => {
      const searchTermFilter = (
        company.name?.toLowerCase().includes(searchTerm)
        || company.companyName?.toLowerCase().includes(searchTerm)
        || company.cnpj?.toLowerCase().includes(searchTerm)
      );
      const statusFilter = company.status === selectedStatus || selectedStatus === 'Todos';
      return searchTermFilter && statusFilter;
    });

    const companiesSplittedByFilters = splitArray(fullCompaniesFiltered);
    setCompaniesSplited(companiesSplittedByFilters);
    setFilteredCompanies(companiesSplittedByFilters[0]);
  }, [fullCompanies, searchTerm, selectedStatus]);

  function handleStatusChange(event: string) {
    setSelectedStatus(event);
  }

  function handleChangeSearchTerm(event: ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  useEffect(() => {
    filterCompanies();
  }, [filterCompanies]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredCompanies(companiesSplitted[page]);
  }

  const pagesQuantity = useMemo(() => companiesSplitted.length, [companiesSplitted.length]);

  return {
    searchTerm,
    setSearchTerm,
    selectedStatus,
    currentPage,
    companiesSplitted,
    filteredCompanies,
    setCompaniesSplited,
    setCurrentPage,
    setFilteredCompanies,
    handlePageChange,
    pagesQuantity,
    handleStatusChange,
    handleChangeSearchTerm,
  };
}
