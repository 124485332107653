import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UseDashboardDataInterface } from '../interfaces';
import { KidsAgeType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useKidsAges({ setHasError }: UseDashboardDataInterface) {
  const [kidsAge, setKidsAge] = useState<KidsAgeType>({} as KidsAgeType);

  const { apiCall } = useApiCall();

  const getKidsAges = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getKidsAges,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar as idades das crianças', {
            toastId: 'getKidsAgesDashboardFail',
          });
          return;
        }
        setKidsAge(apiResponse.age);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar as idades das crianças'
    })
  }, [apiCall, setHasError]);

  return {
    kidsAge,
    getKidsAges,
  };
}
