import { ChangeEvent, useState } from "react";
import Papa, { ParseResult } from 'papaparse';
import removeDuplicates from "../../../../../../utils/removeDuplicates";
import { toast } from "react-toastify";

interface CsvDataInterface {
  nome: string;
}

export default function useAssociatedCompanies() {
  const [associatedCompaniesModalShow, setAssociatedCompaniesModalShow] = useState(false);
  const [associatedCompanies, setAssociatedCompanies] = useState<string[]>([]);
  const [companyBeingAdded, setCompanyBeingAdded] = useState('');

  function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: (results: ParseResult<CsvDataInterface>) => {
          const allNewAssociatedCompanies = results.data.map((index) => String(index.nome).toLowerCase());

          console.log(results)
          setAssociatedCompanies((prevState) => {
            const allAssociatedCompanies = removeDuplicates([...prevState, ...allNewAssociatedCompanies]);

            toast.success("Empresas associadas com sucesso")

            return (allAssociatedCompanies.filter((name) => !!name));
          });
        },
      });
    }
  }

  function addOneAssociatedCompany(company: string) {
    setAssociatedCompanies((prevState) =>  removeDuplicates([...prevState, company]))

    setCompanyBeingAdded('');
    toast.success("Empresa adicionada com sucesso")
  }

  function removeAssociatedCompany(company: string) {
    setAssociatedCompanies((prevState) => {
      const filteredCompanies = prevState.filter((currentCompany) => currentCompany !== company)

      return filteredCompanies;
    })

    toast.success("Empresa removida com sucesso")
  }

  return {
    associatedCompaniesModalShow,
    setAssociatedCompaniesModalShow,
    associatedCompanies,
    setAssociatedCompanies,
    handleFileUpload,
    addOneAssociatedCompany,
    removeAssociatedCompany,
    companyBeingAdded,
    setCompanyBeingAdded,
  }
}
