import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
// import RegistrationData from '../pages/RegistrationData';
import NotFound from '../pages/NotFound';
// import ChangePassword from '../pages/RegistrationData/ChangePassword';



import Users from '../pages/Users';

import ChangePassword from '../pages/ChangePassword';
import Companies from '../pages/Companies';
import Collaborators from '../pages/Companies/Collaborators';
import CompanyDashboard from '../pages/Companies/Dashboard';
import EditCompany from '../pages/Companies/EditCompany';
import NewCompany from '../pages/Companies/NewCompany';
import Cupons from '../pages/Cupons';
import Dashboard from '../pages/Dashboard';
import ForumInternal from '../pages/ForumInternal';
import Subscribers from '../pages/Subscribers';
import EditUser from '../pages/Users/EditUser';

// import Financial from '../pages/Financial';

export default function ComercialRoute() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/forumInternal" element={<ForumInternal />} />
        <Route path="/subscribers" element={<Subscribers />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<EditUser />} />

        <Route path="/cupons" element={<Cupons />} />

        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/new" element={<NewCompany />} />
        <Route path="/companies/:id" element={<EditCompany />} />
        <Route path="/companies/dashboard/:companyId/:companyName" element={<CompanyDashboard />} />
        <Route path="/companies/collaborators/:companyId/:companyName" element={<Collaborators />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
