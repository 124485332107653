/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Select from 'react-select';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { ChangeEvent } from 'react';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';

interface BankDataCardInterface {
  pixType: { value: string, label: string };
  pixOptions: { value: string, label: string }[];
  handleChangePixType: (pix: { value: string, label: string }) => void;
  cnpjPix: string;
  cpfPix: string;
  emailPix: string;
  cellphonePix: string;
  randomPix: string;
  banksOptions: { value: string, label: string }[];
  selectedBank: { value: string, label: string };
  handleSelectBank: (bank: { value: string, label: string }) => void;
  handleCnpjPixChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCpfPixChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleEmailPixChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCellphonePixChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRandomPixChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  agency: string;
  handleAgencyChange: (event: ChangeEvent<HTMLInputElement>) => void;
  account: string;
  handleAccountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cardholder: string;
  handleCardholderChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cnpjBank: string;
  handleCnpjBankChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function BankDataCard({
  pixType,
  pixOptions,
  handleChangePixType,
  cnpjPix,
  cpfPix,
  emailPix,
  cellphonePix,
  randomPix,
  banksOptions,
  selectedBank,
  handleSelectBank,
  handleCnpjPixChange,
  handleCpfPixChange,
  handleEmailPixChange,
  handleCellphonePixChange,
  handleRandomPixChange,
  getErrorMessageByFieldName,
  agency,
  handleAgencyChange,
  account,
  handleAccountChange,
  cardholder,
  handleCardholderChange,
  cnpjBank,
  handleCnpjBankChange,
}: BankDataCardInterface) {
  const willBankDataBePix = !!(cpfPix || emailPix || cellphonePix || cnpjPix || randomPix) && (!selectedBank.value || !account || !agency || !cardholder)
  return (
    <StyledContainer>
      <div className="card-title">
        Dados bancários *
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Tipo PIX {willBankDataBePix && '*'}
          </div>
          <Select
            value={{ value: pixType?.value, label: pixType?.label }}
            options={pixOptions}
            onChange={(opt) => {
              handleChangePixType({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
            menuPortalTarget={document.body}
          />
        </Group>
        {pixType.label === 'CPF' && (
          <Group>
            <div className="title">
              Pix - CPF {willBankDataBePix && '*'}
            </div>
            <FormGroup error={getErrorMessageByFieldName('cpfPix')}>
              <Input
                value={cpfPix}
                onChange={handleCpfPixChange}
                error={getErrorMessageByFieldName('cpfPix')}
                placeholder="Informe a chave PIX"
                maxLength={14}
              />
            </FormGroup>
          </Group>
        )}
        {pixType.label === 'CNPJ' && (
          <Group>
            <div className="title">
              Pix - CNPJ {willBankDataBePix && '*'}
            </div>
            <FormGroup error={getErrorMessageByFieldName('cnpjPix')}>
              <Input
                value={cnpjPix}
                onChange={handleCnpjPixChange}
                error={getErrorMessageByFieldName('cnpjPix')}
                placeholder="Informe a chave PIX"
                maxLength={18}
              />
            </FormGroup>
          </Group>
        )}
        {pixType.label === 'E-mail' && (
          <Group>
            <div className="title">
              Pix - E-mail {willBankDataBePix && '*'}
            </div>
            <FormGroup error={getErrorMessageByFieldName('emailPix')}>
              <Input
                value={emailPix}
                onChange={handleEmailPixChange}
                error={getErrorMessageByFieldName('emailPix')}
                placeholder="Informe a chave PIX"
              />
            </FormGroup>
          </Group>
        )}
        {pixType.label === 'Celular' && (
          <Group>
            <div className="title">
              Pix - Celular {willBankDataBePix && '*'}
            </div>
            <FormGroup error={getErrorMessageByFieldName('cellphonePix')}>
              <Input
                value={cellphonePix}
                onChange={handleCellphonePixChange}
                error={getErrorMessageByFieldName('cellphonePix')}
                placeholder="Informe a chave PIX"
                maxLength={15}
              />
            </FormGroup>
          </Group>
        )}
        {pixType.label === 'Chave aleatória' && (
          <Group>
            <div className="title">
              Pix - Chave aleatória {willBankDataBePix && '*'}
            </div>
            <FormGroup error={getErrorMessageByFieldName('randomPix')}>
              <Input
                value={randomPix}
                onChange={handleRandomPixChange}
                error={getErrorMessageByFieldName('randomPix')}
                placeholder="Informe a chave PIX"
              />
            </FormGroup>
          </Group>
        )}
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Banco {!willBankDataBePix && '*'}
          </div>
          <Select
            value={{ value: selectedBank?.value, label: selectedBank?.label }}
            options={banksOptions}
            onChange={(opt) => {
              handleSelectBank({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </Group>
        <Group>
          <div className="title">
            Agência {!willBankDataBePix && '*'}
          </div>
          <FormGroup error={getErrorMessageByFieldName('agency')}>
            <Input
              value={agency}
              onChange={handleAgencyChange}
              error={getErrorMessageByFieldName('agency')}
              placeholder="Informe a agencia"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Conta Corrente {!willBankDataBePix && '*'}
          </div>
          <FormGroup error={getErrorMessageByFieldName('account')}>
            <Input
              value={account}
              onChange={handleAccountChange}
              error={getErrorMessageByFieldName('account')}
              placeholder="Informe a conta corrente"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome do titular {!willBankDataBePix && '*'}
          </div>
          <FormGroup error={getErrorMessageByFieldName('cardholder')}>
            <Input
              value={cardholder}
              onChange={handleCardholderChange}
              error={getErrorMessageByFieldName('cardholder')}
              placeholder="Informe o nome de titular"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            CNPJ
          </div>
          <FormGroup error={getErrorMessageByFieldName('cnpjBank')}>
            <Input
              value={cnpjBank}
              onChange={handleCnpjBankChange}
              error={getErrorMessageByFieldName('cnpjBank')}
              placeholder="Informe o CNPJ da conta"
              maxLength={18}
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
