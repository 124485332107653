import { ChangeEvent } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../../../components/CustomSelectStyle';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';
import { AsideContainer, Group, Container as StyledContainer } from '../styles';

interface GeneralDataCardInterface {
  name: string;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cpf: string;
  onCpfChange: (event: ChangeEvent<HTMLInputElement>) => void;
  birthDate: string;
  onBirthDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  gender: { value: string, label: string };
  onGenderChange: (gender: { value: string, label: string }) => void;
  email: string;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cellphone: string;
  onCellphoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  company: string;
  cupom: string;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
}

export default function GeneralDataCard({
  name,
  onNameChange,
  cpf,
  onCpfChange,
  birthDate,
  onBirthDateChange,
  gender,
  onGenderChange,
  email,
  onEmailChange,
  cellphone,
  onCellphoneChange,
  company,
  cupom,
  getErrorMessageByFieldName,
}: GeneralDataCardInterface) {
  const genderOptions = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Feminino', label: 'Feminino' },
    { value: 'Prefiro não informar', label: 'Prefiro não informar' },
  ];

  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome *
          </div>
          <FormGroup error={getErrorMessageByFieldName('name')}>
            <Input
              value={name}
              onChange={onNameChange}
              error={getErrorMessageByFieldName('name')}
              placeholder="Informe o nome do usuário"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Data de nascimento
          </div>
          <FormGroup error={getErrorMessageByFieldName('birthDate')}>
            <Input
              type="date"
              value={birthDate}
              onChange={onBirthDateChange}
              error={getErrorMessageByFieldName('birthDate')}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Gênero
          </div>
          <FormGroup>
            <Select
              options={genderOptions}
              onChange={(opt) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                onGenderChange(opt!);
              }}
              value={{ value: gender.value, label: gender.label || 'Selecione um gênero' }}
              placeholder="Selecione um gênero"
              styles={CustomStyle}
              classNamePrefix="react-select"
              className="react-select-container"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            CPF *
          </div>
          <FormGroup error={getErrorMessageByFieldName('cpf')}>
            <Input
              maxLength={14}
              value={cpf}
              onChange={onCpfChange}
              error={getErrorMessageByFieldName('cpf')}
              placeholder="Informe o CPF do usuário"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            E-mail *
          </div>
          <FormGroup error={getErrorMessageByFieldName('email')}>
            <Input
              value={email}
              onChange={onEmailChange}
              error={getErrorMessageByFieldName('email')}
              placeholder="Informe o email do usuário."
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Celular *
          </div>
          <FormGroup error={getErrorMessageByFieldName('cellphone')}>
            <Input
              maxLength={15}
              value={cellphone}
              onChange={onCellphoneChange}
              error={getErrorMessageByFieldName('cellphone')}
              placeholder="Informe o telefone do usuário"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Empresa
          </div>
          <FormGroup>
            <Input
              disabled
              value={company}
              placeholder="Nenhuma empresa associada"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Cupom / Identificador na empresa
          </div>
          <FormGroup>
            <Input
              disabled
              value={cupom}
              placeholder="Sem cupom utilizado"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
