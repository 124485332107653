import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import FilterRadioButton from '../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../components/FilterRadioButtonsContainer';
import { SecondaryButton } from '../../../../../components/SecondaryButton';

interface KindOfCompanyInterface {
  handleKindOfCompanyChange: (kind: 'default' | 'seller') => void;
  kindOfCompany: string;
  setAssociatedCompaniesModalShow: Dispatch<SetStateAction<boolean>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default function KindOfCompany({ handleKindOfCompanyChange, kindOfCompany, setAssociatedCompaniesModalShow }: KindOfCompanyInterface) {
  return (
    <Container>
      <FilterRadioButtonsContainer>
        <FilterRadioButton
          onClick={() => handleKindOfCompanyChange('default')}
          selected={kindOfCompany === 'default'}
        >
          Padrão (B2B)
        </FilterRadioButton>
        <FilterRadioButton
          onClick={() => handleKindOfCompanyChange('seller')}
          selected={kindOfCompany === 'seller'}
        >
          Distribuidor
        </FilterRadioButton>
      </FilterRadioButtonsContainer>

      {kindOfCompany === 'seller' && (
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.1, type: 'tween' }}
        >
          <SecondaryButton onClick={() => setAssociatedCompaniesModalShow(true)}>
            Gerenciar empresas associadas
          </SecondaryButton>
        </motion.div>
      )}
    </Container>
  );
}
