import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import financialService from '../../../../services/financialService';
import partnersService from '../../../../services/partnersService';
import { PartnerFinancialDetailsFromApi, SelectedPartnerType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function usePartnerData() {
  const [isPartnerDataLoading, setIsPartnerDataLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<SelectedPartnerType>({} as SelectedPartnerType);
  const [partnerData, setPartnerData] = useState<PartnerFinancialDetailsFromApi>({} as PartnerFinancialDetailsFromApi);
  const [partnerDataError, setPartnerDataError] = useState(false);
  const [classPrice, setClassPrice] = useState('');

  const { apiCall } = useApiCall();

  const reloadPartnerData = useCallback(async () => {
    try {
      setIsPartnerDataLoading(true);

      await apiCall({
        apiToCall: financialService.getPartnerData,
        queryParams: { partnerId: selectedPartner.value },
        actionAfterResponse: (apiResponse) => {
          setPartnerData(apiResponse.details);
        }
      });

      await apiCall({
        apiToCall: partnersService.getPartnerDetails,
        queryParams: { partnerId: selectedPartner.value },
        actionAfterResponse: (apiResponse) => {
          setClassPrice(apiResponse?.details?.valor ?? '');
        }
      })

      setPartnerDataError(false);
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros desse parceiro (${error})`);
      setPartnerDataError(true);
    } finally {
      setIsPartnerDataLoading(false);
    }
  }, [apiCall, selectedPartner.value]);

  const handleSelectedPartnerChange = useCallback(async (partner: SelectedPartnerType) => {
    try {
      setIsPartnerDataLoading(true);
      setSelectedPartner(partner);

      await apiCall({
        apiToCall: financialService.getPartnerData,
        queryParams: { partnerId: partner.value },
        actionAfterResponse: (apiResponse) => setPartnerData(apiResponse.details),
      })

      await apiCall({
        apiToCall: partnersService.getPartnerDetails,
        queryParams: { partnerId: partner.value },
        actionAfterResponse: (apiResponse) => setClassPrice(apiResponse?.details?.valor)
      })

      setPartnerDataError(false);
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros desse parceiro (${error})`);
      setPartnerDataError(true);
    } finally {
      setIsPartnerDataLoading(false);
    }
  }, [apiCall]);

  const handleTryGetPartnerDataAgain = useCallback(async () => {
    try {
      setIsPartnerDataLoading(true);

      await apiCall({
        apiToCall: financialService.getPartnerData,
        queryParams: { partnerId: selectedPartner.value },
        actionAfterResponse: (apiResponse) => setPartnerData(apiResponse.details),
      })

      await apiCall({
        apiToCall: partnersService.getPartnerDetails,
        queryParams: { partnerId: selectedPartner.value },
        actionAfterResponse: (apiResponse) => setClassPrice(apiResponse?.details?.valor)
      })

      setPartnerDataError(false);
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros desse parceiro (${error})`);
      setPartnerDataError(true);
    } finally {
      setIsPartnerDataLoading(false);
    }
  }, [apiCall, selectedPartner.value]);

  return {
    isPartnerDataLoading,
    selectedPartner,
    partnerData,
    partnerDataError,
    reloadPartnerData,
    handleSelectedPartnerChange,
    handleTryGetPartnerDataAgain,
    classPrice,
  };
}
