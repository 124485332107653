import { useState } from "react"

export default function useAddModal() {
  const [addModalShow, setAddModalShow] = useState(false);

  return {
    setAddModalShow,
    addModalShow,
  }
}
