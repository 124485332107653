/* eslint-disable max-len */
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import useDeleteActivity from './functions/useDeleteActivity';
import useDownloadExcel from './functions/useDownloadExcel';

import useLoadActivities from './functions/useLoadActivities';
import useSearches from './functions/useSearches';
import useUpdateActivityStatus from './functions/useUpdateActivityStatus';

export default function useActivities() {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoadLoading,
    fullActivities,
    doesListApiHasError,
    filteredActivities,
    setFilteredActivities,
    loadActivities,
    categoriesList,
    partnersList,
    activitiesSplited,
    setActivitiesSplited,
    subcategoriesList,
    loadPage,
  } = useLoadActivities({ setCurrentPage });

  const {
    searchTerm,
    handleChangeSearchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedPartner,
    selectedStatus,
    setSelectedStatus,
    selectedAgeRange,
    selectedKind,
    handlePartnerChange,
    handleCategoryChange,
    handleSubcategoryChange,
    handleStatusChange,
    handleAgeSelection,
    handleKindChange,
    setSelectedAgeRange,
    setSearchTerm,
    setSelectedCategory,
    setSelectedSubcategory,
    setSelectedPartner,
  } = useSearches({
    fullActivities,
    setActivitiesSplited,
    setFilteredActivities,
  });

  const resetFields = useCallback(() => {
    setSearchTerm('');
    setSelectedCategory({ value: '', label: 'Filtrar por categoria' });
    setSelectedSubcategory({ value: '', label: 'Filtrar por subcategoria' });
    setSelectedPartner({ value: '', label: 'Filtrar por parceiro' });
    setSelectedStatus('');
    setSelectedAgeRange([0, 13]);
  }, [setSearchTerm, setSelectedAgeRange, setSelectedCategory, setSelectedPartner, setSelectedStatus, setSelectedSubcategory]);

  const {
    isDeleting,
    deleteModalShow,
    activityBeingDeleted,
    deleteActivity,
    handleOpenDeleteModal,
    setDeleteModalShow,
  } = useDeleteActivity({
    resetFields,
    loadActivities,
  });

  const {
    isUpdatingStatus,
    changeStatusModalShow,
    setChangeStatusModalShow,
    activityChangingStatus,
    updateActivityStatus,
    handleOpenChangeStatusModal,
  } = useUpdateActivityStatus({ loadActivities });

  const { downloadExcel, downloadRatings, downloadAllRatingsExcel, downloadVideoViewsExcel } = useDownloadExcel();

  const pagesQuantity = useMemo(() => activitiesSplited.length, [activitiesSplited.length]);

  const subcategoriesOptions = useMemo(() => {
    const filteredSubcategoriesByCategory = subcategoriesList?.filter((sbctg) => (
      sbctg?.category === selectedCategory?.label
    ));
    const mappedSubcategories = selectedCategory?.label !== 'Todas'
      ? filteredSubcategoriesByCategory?.map((sbctg) => (
        { label: sbctg?.subcategory, value: sbctg?.subcategory }
      ))
      : subcategoriesList?.map((sbctg) => (
        { label: sbctg?.subcategory, value: sbctg?.subcategory }
      ));
    mappedSubcategories?.unshift({
      value: '', label: 'Todas',
    });

    return mappedSubcategories;
  }, [selectedCategory?.label, subcategoriesList]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredActivities(activitiesSplited[page]);
  }

  function handleTryAgain() {
    loadPage();
  }

  const isLoading = isLoadLoading || isUpdatingStatus || isDeleting;

  return {
    isLoading,
    fullActivities,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredActivities,
    handleTryAgain,
    loadActivities,
    pagesQuantity,
    currentPage,
    categoriesList,
    selectedCategory,
    subcategoriesOptions,
    selectedSubcategory,
    partnersList,
    selectedPartner,
    selectedStatus,
    setSelectedStatus,
    selectedAgeRange,
    handlePartnerChange,
    handleCategoryChange,
    handleSubcategoryChange,
    handleStatusChange,
    handlePageChange,
    setSelectedAgeRange,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    activityBeingDeleted,
    deleteActivity,
    handleOpenChangeStatusModal,
    changeStatusModalShow,
    setChangeStatusModalShow,
    activityChangingStatus,
    updateActivityStatus,
    downloadExcel,
    handleAgeSelection,
    downloadRatings,
    downloadRatingsExcel: downloadAllRatingsExcel,
    handleKindChange,
    selectedKind,
    downloadVideoViewsExcel,
  };
}
