/* eslint-disable max-len */
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import splitArray from '../../../../utils/splitArray';
import { MappedActivitiesListType } from '../../types';

interface UseSearchesInterface {
  fullActivities: MappedActivitiesListType[];
  setActivitiesSplited: Dispatch<SetStateAction<Array<MappedActivitiesListType[]>>>;
  setFilteredActivities: Dispatch<SetStateAction<MappedActivitiesListType[]>>;
}

export default function useSearches({
  fullActivities,
  setActivitiesSplited,
  setFilteredActivities,
}: UseSearchesInterface) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({
    value: '',
    label: 'Filtrar por categoria',
  });
  const [selectedSubcategory, setSelectedSubcategory] = useState({
    value: '',
    label: 'Filtrar por subcategoria',
  });
  const [selectedPartner, setSelectedPartner] = useState({
    value: '',
    label: 'Filtrar por parceiro',
  });
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedAgeRange, setSelectedAgeRange] = useState([0, 13]);
  const [choosedAgeRange, setChoosedAgeRange] = useState([0, 13]);
  const [selectedKind, setSelectedKind] = useState<'all' | 'Aula em vídeo' | 'Aula ao vivo'>('all');

  const filterActivities = useCallback(() => {
    console.log('filtrou parceiros')
    const filteredActivities = fullActivities.filter((act) => {
      const categoryFilter = !selectedCategory.value || act.generalData.category === selectedCategory.value;
      const subcategoryFilter = !selectedSubcategory.value || act.generalData.subcategory === selectedSubcategory.value;
      const partnerFilter = !selectedPartner.value || act.generalData.partner === selectedPartner.value || act.generalData.featPartner === selectedPartner.value;
      const statusFilter = !selectedStatus || act.generalData.status === selectedStatus;
      const ageFilter = (Number(choosedAgeRange[1]) <= Number(act.generalData.ageRange[1]) && Number(choosedAgeRange[1]) >= Number(act.generalData.ageRange[0])) || choosedAgeRange[1] === 13;
      const searchFilter = act.name.toLowerCase().includes(String(searchTerm).toLowerCase());
      const kindFilter = selectedKind === 'all' || act.generalData.kind === selectedKind;

      return categoryFilter && subcategoryFilter && partnerFilter && statusFilter && ageFilter && searchFilter && kindFilter;
    });

    const activitiesSplitedbyFilters = splitArray(filteredActivities);
    setActivitiesSplited(activitiesSplitedbyFilters);
    setFilteredActivities(activitiesSplitedbyFilters[0]);
  }, [fullActivities, setActivitiesSplited, setFilteredActivities, selectedCategory.value, selectedSubcategory.value, selectedPartner.value, selectedStatus, choosedAgeRange, searchTerm, selectedKind]);

  useEffect(() => {
    filterActivities();
  }, [filterActivities]);

  const handleCategoryChange = useCallback((category: { value: string, label: string }) => {
    setSelectedCategory(category);
    setSelectedSubcategory({ value: '', label: 'Todas' });
  }, []);

  const handleSubcategoryChange = useCallback((subcategory: { value: string, label: string }) => {
    setSelectedSubcategory(subcategory);
  }, []);

  const handlePartnerChange = useCallback((partner: { value: string, label: string }) => {
    setSelectedPartner(partner);
  }, []);

  const handleStatusChange = useCallback((status: string) => {
    setSelectedStatus(status);
  }, []);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleAgeSelection = useCallback((age: number[]) => {
    setChoosedAgeRange(age);
  }, []);

  const handleKindChange = useCallback((kind: 'all' | 'Aula em vídeo' | 'Aula ao vivo') => {
    setSelectedKind(kind);
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedPartner,
    selectedStatus,
    setSelectedStatus,
    selectedAgeRange,
    handlePartnerChange,
    handleCategoryChange,
    handleSubcategoryChange,
    handleStatusChange,
    handleAgeSelection,
    setSelectedAgeRange,
    setSearchTerm,
    setSelectedCategory,
    setSelectedSubcategory,
    setSelectedPartner,
    handleKindChange,
    selectedKind,
  };
}
