/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UseDashboardDataInterface } from '../interfaces';
import { CompaniesUsageType, PercentageKindsType, UserFromListApi } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useUsersQuantities({ setHasError }: UseDashboardDataInterface) {
  const [registeredUsersQty, setRegisteredusersQty] = useState(0);
  const [activeUsersQty, setActiveUsersQty] = useState(0);
  const [percentageKinds, setPercentageKinds] = useState<PercentageKindsType>({} as PercentageKindsType);
  const [companiesUsage, setCompaniesUsage] = useState<CompaniesUsageType>({} as CompaniesUsageType);

  const { apiCall } = useApiCall();

  const getUsersQuantities = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error('Não foi possível recuperar os dados dos usuários', {
            toastId: 'getUsersDashboardFail',
          });
          return;
        }
        const usersList: UserFromListApi[] = apiResponse.list;
        const activeUsers = usersList?.filter((us) => (
          us.assinatura_status === 'Ativa'
        ));
        const b2bActiveUsers = activeUsers.filter((us) => (
          !!us.empresa
        ));

        const onlyValidCompanies = b2bActiveUsers.filter((obj) => obj.empresa !== 'Kiddle Pass Company' && obj.empresa !== 'JaSouKiddle' && obj.empresa !== 'Kiddle Pass');
        const updatedArrayByMastercard = onlyValidCompanies.map((obj) => {
          if (obj.empresa === 'Master (migrado)') {
            obj.empresa = 'Mastercard';
          }
          if (obj.empresa === 'Minutrade (Bradesco)') {
            obj.empresa = 'Minutrade';
          }
          return obj;
        });
        const companyCount = updatedArrayByMastercard.reduce((obj: { [key: string]: number }, item) => {
          obj[item.empresa] = (obj[item.empresa] || 0) + 1;
          return obj;
        }, {});
        const totalLength = updatedArrayByMastercard.length;
        const companyPercent: { [key: string]: number } = {};
        for (const key in companyCount) {
          companyPercent[key] = Math.round((companyCount[key] / totalLength) * 100);
        }

        // Convert object to array of key-value pairs
        const companyPercentArray = Object.entries(companyPercent);

        // Sort array based on percentage value in descending order
        companyPercentArray.sort((a, b) => b[1] - a[1]);

        // Convert sorted array back to object
        const sortedCompanyPercent = Object.fromEntries(companyPercentArray);

        setCompaniesUsage(sortedCompanyPercent);
        setActiveUsersQty(activeUsers.length);
        setRegisteredusersQty(usersList.length);
        setPercentageKinds({
          b2b: {
            percentage: Math.round((b2bActiveUsers.length / activeUsers.length) * 100),
            qty: b2bActiveUsers.length,
          },
          b2c: {
            percentage: Math.round(((activeUsers.length - b2bActiveUsers.length) / activeUsers.length) * 100),
            qty: activeUsers.length - b2bActiveUsers.length,
          },
        });
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível carregar a lista dos usuários'
    })
  }, [apiCall, setHasError]);

  return {
    getUsersQuantities,
    activeUsersQty,
    registeredUsersQty,
    percentageKinds,
    companiesUsage,
  };
}
