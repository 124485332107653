import FileInput from '../../../../../components/FileInput';
import MyModal from '../../../../../components/Modal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

interface PaymentVoucherUploadModalInterface {
  paymentVoucherUploadModalShow: boolean;
  paymentVoucherFileName: string;
  handlePaymentVoucherFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePaymentVoucherFileSubmit: () => void;
  setPaymentVoucherUploadModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function PaymentVoucherUploadModal({
  paymentVoucherUploadModalShow,
  paymentVoucherFileName,
  handlePaymentVoucherFileUpload,
  handlePaymentVoucherFileSubmit,
  setPaymentVoucherUploadModalShow,
}: PaymentVoucherUploadModalInterface) {
  return (
    <MyModal
      show={paymentVoucherUploadModalShow}
      title="Upload de comprovante de pagamento"
      centeredBody
      modalBody={(
        <FileInput
          buttonLabel="Importar arquivo"
          fileNameChoosed={paymentVoucherFileName}
          isSubmitButtonDisabled={!paymentVoucherFileName}
          onFileUpload={handlePaymentVoucherFileUpload}
          onSubmitFile={() => handlePaymentVoucherFileSubmit()}
          acceptedFiles=".pdf"
        />
          )}
      closeButtonLabel="Fechar"
      onClose={() => setPaymentVoucherUploadModalShow(false)}
      type="info"
    />
  );
}
