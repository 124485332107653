import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import { useQuery } from "../../../../hooks/useQuery";
import givenClassesService from "../../../../services/givenClassesService";
import { ApiResponseType, DownloadRelatoryPermissionType, PartnerOptionType, PermissionsType } from "../types";

export default function useAllowRelatoriesDownloads() {
  const [isLoading, setIsLoading] = useState(false);

  const [downloadRelatoryPermissions, setDownloadRelatoryPermissions] = useState<DownloadRelatoryPermissionType[]>([]);
  const [filteredDownloadRelatoryPermissions, setFilteredDownloadRelatoryPermissions] = useState<DownloadRelatoryPermissionType[]>([])

  const [changePermissionModalShow, setChangePermissionModalShow] = useState(false);
  const [relatoryToChangePermission, setRelatoryToChangePermission] = useState<DownloadRelatoryPermissionType | null>(null);

  const [actionOfPermissionChange, setActionOfPermissionChange] = useState<'allow' | 'lock'>('allow');
  const [changeAllPermissionsModalShow, setChangeAllPermissionsModalShow] = useState(false);

  const [selectedPartner, setSelectedPartner] = useState<PartnerOptionType>({ value: '', label: 'Todos' });
  const [selectedPermission, setSelectedPermission] = useState<PermissionsType>('all');
  const [partnerOptions, setPartnerOptions] = useState<PartnerOptionType[]>([]);

  const query = useQuery();
  const month = Number(query.get("month")) || 0;
  const year = query.get("year") || "";
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  if (!month || !year) {
    toast.error('Link mal formatado');
    navigate('/givenClasses');
  }

  const monthsMap: { [key: number]: string } = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  const filterRelatoryDownloadsPermissions = useCallback(() => {
    const filteredRelDownloadsPermissions = downloadRelatoryPermissions.filter((rel) => {
      const partnerFilter = rel.partnerId === selectedPartner.value || !selectedPartner.value;
      const selectedPermissionFilter = selectedPermission === 'all' || (selectedPermission === 'allowed' && rel.isAllowed) || (selectedPermission === 'forbidden' && !rel.isAllowed);

      return selectedPermissionFilter && partnerFilter;
    });

    setFilteredDownloadRelatoryPermissions(filteredRelDownloadsPermissions);
  }, [downloadRelatoryPermissions, selectedPartner.value, selectedPermission]);

  const getDownloadPermissions = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.getRelatoriesDownloadsPermissionsByPeriod,
      queryParams: { month, year },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: ApiResponseType) => {
        if (!response.success) {
          toast.error('Erro ao buscar permissões de download de relatórios por período. Por favor, tente novamente');
          navigate('/givenClasses');
          return;
        }

        const partnersOpt = response.downloadRelatoryPermissions.map((rl) => ({ value: rl.partnerId, label: rl.partnerName }));
        setPartnerOptions([{ value: '', label: 'Todos' }, ...partnersOpt]);
        setDownloadRelatoryPermissions(response.downloadRelatoryPermissions);
      }
    })
  }, [apiCall, month, navigate, year]);

  const changeRelatoryDownloadPermission = useCallback(async (relatoryToChangePermissionPr: DownloadRelatoryPermissionType) => {
    await apiCall({
      apiToCall: givenClassesService.changeRelatoryDownloadPermission,
      reqBody: JSON.stringify({
        month,
        year,
        partnerId: relatoryToChangePermissionPr.partnerId,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: ApiResponseType) => {
        if (!response.success) {
          toast.error('Erro ao alterar permissão de download de relatório. Por favor, tente novamente');
          return;
        }

        toast.success('Permissão de download de relatório alterada com sucesso');
        getDownloadPermissions();
        setChangePermissionModalShow(false);
      },
    });
  }, [apiCall, getDownloadPermissions, month, year]);

  const changeAllDownloadPermissions = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.changeAllRelatoryDownloadPermission,
      reqBody: JSON.stringify({
        month,
        year,
        action: actionOfPermissionChange,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: ApiResponseType) => {
        if (!response.success) {
          toast.error('Erro ao alterar as permissões para download dos relatórios. Por favor, tente novamente');
          return;
        }

        toast.success('As permissões para download dos relatórios foram alteradas com sucesso');
        getDownloadPermissions();
        setChangeAllPermissionsModalShow(false);
      },
    });
  }, [actionOfPermissionChange, apiCall, getDownloadPermissions, month, year]);

  function handleOpenChangePermissionModal(relatoryToChangePermission: DownloadRelatoryPermissionType) {
    setChangePermissionModalShow(true);
    setRelatoryToChangePermission(relatoryToChangePermission);
  }

  function handleCloseChangePermissionModal() {
    setChangePermissionModalShow(false);
    setRelatoryToChangePermission(null);
  }

  function handleOpenChangeAllPermissionsModal(action: 'allow' | 'lock') {
    setActionOfPermissionChange(action);
    setChangeAllPermissionsModalShow(true);
  }

  useEffect(() => {
    getDownloadPermissions();
  }, [getDownloadPermissions]);

  useEffect(() => {
    filterRelatoryDownloadsPermissions();
  }, [filterRelatoryDownloadsPermissions]);

  return {
    isLoading,
    setIsLoading,
    monthsMap,
    month,
    year,
    downloadRelatoryPermissions,
    handleOpenChangePermissionModal,
    handleCloseChangePermissionModal,
    changePermissionModalShow,
    relatoryToChangePermission,
    partnerOptions,
    setSelectedPartner,
    selectedPartner,
    setSelectedPermission,
    selectedPermission,
    filteredDownloadRelatoryPermissions,
    changeRelatoryDownloadPermission,
    handleOpenChangeAllPermissionsModal,
    changeAllDownloadPermissions,
    setChangeAllPermissionsModalShow,
    changeAllPermissionsModalShow,
    actionOfPermissionChange,
  }
}
