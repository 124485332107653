import MyModal from '../../../components/Modal';
import { Dispatch, SetStateAction } from 'react';
import { InternalUserObject } from '../types';

interface DeleteModalInterface {
  internalUserBeingDeleted: InternalUserObject;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deleteInternalUser: (userId: string) => Promise<void>;
  deleteModalShow: boolean;
}

export default function DeleteModal({
  internalUserBeingDeleted,
  setDeleteModalShow,
  deleteInternalUser,
  deleteModalShow,
}: DeleteModalInterface) {
  return (
    <MyModal
      title={`Excluir usuário ${internalUserBeingDeleted.email}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja excluir o usuário
            {' '}
            <strong>{internalUserBeingDeleted.email}</strong>
            {' '}
            ?
          </div>
          <br />
          <strong>Atenção, esta ação é irreversível!</strong>
        </>
          )}
      onClose={() => setDeleteModalShow(false)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onAction={() => deleteInternalUser(internalUserBeingDeleted.id!)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
