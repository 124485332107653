import { useState } from "react"
import { CategoryBeingEditedType } from "../../types";

export default function useEditModal() {
  const [editModalShow, setEditModalShow] = useState(false);
  const [categoryBeingEditted, setCategoryBeingEditted] = useState({} as CategoryBeingEditedType);

  function handleOpenEditModal(category: CategoryBeingEditedType) {
    setCategoryBeingEditted(category)
    setEditModalShow(true);
  }

  return {
    handleOpenEditModal,
    categoryBeingEditted,
    setEditModalShow,
    editModalShow,
  }
}
