import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import ListHeader from './components/header';
import SearchAtPage from './components/searchAtPage';
import List from './components/list';
import PageHeader from '../../../components/PageHeader';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';
import ImportCsvModal from './components/ImportCsvModal';
import useCategories from './useCategories';
import DeleteModal from './components/DeleteModal';

export default function Categories() {
  const {
    isLoading,
    categories,
    searchTerm,
    handleChangeSearchTerm,
    selectedType,
    handleTypeChange,
    doesListApiHasError,
    filteredList,
    handleTryAgain,
    handleOpenEditModal,
    categoryBeingEditted,
    setEditModalShow,
    editModalShow,
    setAddModalShow,
    addModalShow,
    downloadExcel,
    loadCategories,
    setIsLoading,
    modalImportCsvShow,
    setModalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose,
    handleOpenDeleteModal,
    categoryBeingDeleted,
    setDeleteModalShow,
    deleteModalShow,
  } = useCategories();

  const hasCategories = categories?.length !== 0 && !!categories;
  const filteredListLength = filteredList?.length ?? 0;
  const searchNotFound = filteredListLength === 0 && hasCategories;
  const isListEmpty = !doesListApiHasError && (!isLoading && categories.length === 0);

  return (
    <>
      <Sidebar active="Activities" />
      <Transitions>
        <Loader isLoading={isLoading} />

        <PageHeader
          title={`Gerenciamento de categorias`}
          link={'/activities'}
        />

        {hasCategories && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
            selectedType={selectedType}
            handleTypeChange={handleTypeChange}
          />
        )}

        <ListHeader
          doesListExists={hasCategories}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel={'Categoria'}
          pluralLabel={'Categorias'}
          downloadExcel={downloadExcel}
          setAddModalShow={setAddModalShow}
          setModalImportCsvShow={setModalImportCsvShow}
        />

        {hasCategories && (
          <List
            filteredList={filteredList}
            onOpenEditModal={handleOpenEditModal}
            onOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        <AddModal
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
          loadCategories={loadCategories}
          setIsLoading={setIsLoading}
        />

        <EditModal
          editModalShow={editModalShow}
          setEditModalShow={setEditModalShow}
          setIsLoading={setIsLoading}
          loadCategories={loadCategories}
          categoryBeingEditted={categoryBeingEditted}
        />

        <DeleteModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          setIsLoading={setIsLoading}
          loadCategories={loadCategories}
          categoryBeingDeleted={categoryBeingDeleted}
        />

        <ImportCsvModal
          modalImportCsvShow={modalImportCsvShow}
          csvFileName={csvFileName}
          handleFileUpload={handleFileUpload}
          handleSubmitFile={handleSubmitFile}
          handleImportModalClose={handleImportModalClose}
        />

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado conforme filtros selecionados, por favor, tente alterar seus critérios de busca.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista de categorias.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma categoria cadastrada, clique no botão
                {' '}
                <strong>Nova Categoria.</strong>
                {' '}
                para cadastrar sua primeira!
              </>
)}
          />
        )}
      </Transitions>
    </>
  );
}
