import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
// import RegistrationData from '../pages/RegistrationData';
import NotFound from '../pages/NotFound';
// import ChangePassword from '../pages/RegistrationData/ChangePassword';

import Activities from '../pages/Activities';
import EditActivity from '../pages/Activities/EditActivity';
import NewActivity from '../pages/Activities/NewActivity';

import Partners from '../pages/Partners';
import EditPartner from '../pages/Partners/EditPartner';
import NewPartner from '../pages/Partners/NewPartner';

import Users from '../pages/Users';

import Categories from '../pages/Activities/Categories';
import ActivityPedagogicalProgram from '../pages/Activities/PedagogicalProgram';
import ActivityProgram from '../pages/Activities/Program';
import ActivitiesApprove from '../pages/ActivitiesApprove';
import ActivityCreateApprovement from '../pages/ActivitiesApprove/ActivityCreateApprovement';
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import Forum from '../pages/Forum';
import ForumInternal from '../pages/ForumInternal';
import Schedules from '../pages/Schedules';
import Subscribers from '../pages/Subscribers';
import EditUser from '../pages/Users/EditUser';

// import Financial from '../pages/Financial';

export default function CoordinatorRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forumInternal" element={<ForumInternal />} />
        <Route path="/subscribers" element={<Subscribers />} />

        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/:id" element={<EditActivity />} />
        <Route path="/activities/:id/program" element={<ActivityProgram />} />
        <Route path="/activities/:id/pedagogicalProgram" element={<ActivityPedagogicalProgram />} />
        <Route path="/activities/new" element={<NewActivity />} />
        <Route path="/activities/categories" element={<Categories />} />

        <Route path="/activitiesApprove" element={<ActivitiesApprove />} />
        <Route path="/activitiesApprove/:id" element={<ActivityCreateApprovement />} />

        <Route path="/partners" element={<Partners />} />
        <Route path="/partners/new" element={<NewPartner />} />
        <Route path="/partners/:id" element={<EditPartner />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<EditUser />} />

        <Route path="/schedules" element={<Schedules />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
