import { Dispatch, SetStateAction, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import classLink from '../../../../assets/images/icons/classLink.svg';
import kidsIcon from '../../../../assets/images/icons/kids.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import { ChildrenAtScheduleType, ScheduleKidsListBeingShown, ScheduleToRemoveBeingShown, ScheduleType, SchedulesToRemoveBeingShown } from '../../types';
import OpacityAnimation from '../../../../components/OpacityAnimation';

interface ListInterface {
  filteredList: ScheduleType[];
  setKidsModalShow: Dispatch<SetStateAction<boolean>>;
  setScheduleKidsListBeingShow: Dispatch<SetStateAction<ScheduleKidsListBeingShown>>;
  setSchedulesRemoveBeingShow: Dispatch<SetStateAction<SchedulesToRemoveBeingShown>>;
  setSchedulesRemoveModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function List({
  filteredList,
  setKidsModalShow,
  setScheduleKidsListBeingShow,
  setSchedulesRemoveBeingShow,
  setSchedulesRemoveModalShow,
}: ListInterface) {
  const groupedSchedulesById = useCallback((childrensArray: ChildrenAtScheduleType[]) => {
    const groupedArray = childrensArray.reduce((acc: ScheduleToRemoveBeingShown[], cur) => {
      const group = acc.find((accChildrenObj) => accChildrenObj.id === cur.id);
      if (group) {
        group.criancas.push({ nome: cur.nome, nascimento: cur.nascimento });
      } else {
        acc.push({
          id: cur.id,
          responsavel: cur.responsavel,
          criancas: [{ nome: cur.nome, nascimento: cur.nascimento }],
        });
      }
      return acc;
    }, []);

    return groupedArray;
  }, []);

  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((appointment) => (
          <Col key={appointment.id}>
            <OpacityAnimation>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{appointment.atividade}</strong>
                    <small>{appointment.horario}</small>
                  </div>
                  <span>
                    {appointment.criancas.length}
                    {' '}
                    {appointment.criancas.length == 1 ? 'criança' : 'crianças'}
                  </span>
                  {(appointment.remainingVacancies <= 5 && !!appointment.remainingVacancies) || appointment.remainingVacancies === 0
                  ? (
                    <strong className='red'>
                      {appointment.remainingVacancies}
                      {' '}
                      {appointment.remainingVacancies === 1 ? 'vaga restante' : 'vagas restantes'}
                    </strong>
                  )
                : (
                  <span>
                    {appointment.remainingVacancies ? `${appointment.remainingVacancies} vagas restantes`: 'Aula sem quantidade de vagas definidas'}
                  </span>
                )}
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => {
                      setKidsModalShow(true);
                      setScheduleKidsListBeingShow({
                        title: `${appointment.atividade} - ${appointment.horario}`,
                        list: appointment.criancas,
                      });
                    }}
                  >
                    <img src={kidsIcon} alt="kidsIcon" title="Visualizar lista de crianças" className="primaryColor" />
                  </button>
                  <a href={appointment.link} target="_blank" rel="noreferrer">
                    <img src={classLink} alt="linkAulaZoom" title="Acessar aula" />
                  </a>
                  <button
                    type="button"
                    onClick={() => {
                      setSchedulesRemoveModalShow(true);
                      setSchedulesRemoveBeingShow({
                        title: `${appointment.atividade} - ${appointment.horario}`,
                        list: groupedSchedulesById(appointment.criancas),
                      });
                    }}
                  >
                    <img src={trash} alt="kidsList" title="Selecionar agendamentos para cancelamento" />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
