import { Dispatch, SetStateAction } from "react";
import FormGroup from "../../../../../components/FormGroup";
import MyModal from "../../../../../components/Modal";
import OpacityAnimation from "../../../../../components/OpacityAnimation";
import Textarea from "../../../../../components/Textarea";
import { DateTimeOption } from "../../types";
import { Group, ModalContainer } from "./styles";
import useAddModal from "./useAddModal";
// import { FilterRadioButtonsContainer } from "../../../../../components/FilterRadioButtonsContainer";
import { Col, Container, Row } from "react-bootstrap";
import FilterRadioButton from "../../../../../components/FilterRadioButtons";

interface IAddModal {
  addModalShow: boolean;
  dateTimeOptions: DateTimeOption[];
  setAddModalShow: Dispatch<SetStateAction<boolean>>;
  loadProgram: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddModal({
  dateTimeOptions,
  addModalShow,
  setAddModalShow,
  loadProgram,
  setIsLoading,
}: IAddModal) {
  const {
    specificProgram,
    handleSpecificProgramChange,
    dateTime,
    setDateTime,
    getErrorMessageByFieldName,
    addProgram,
    isFormValid,
  } = useAddModal({
    loadProgram,
    setIsLoading,
    setAddModalShow,
  });

  console.log('dateTimeOptions', dateTimeOptions);

  return (
    <MyModal
    show={addModalShow}
    onClose={() => setAddModalShow(false)}
    title={`Adicionar programação`}
    modalBody={(
      <ModalContainer>
        <OpacityAnimation delay={0.1}>
          <Group>
            <div className="title">
              Data e hora
            </div>
            <Container>
              <Row xs={1} md={2} lg={6}>
                {dateTimeOptions.map((opt) => (
                  <Col key={opt.id}>
                    <FilterRadioButton nowrap smallText onClick={() => setDateTime(opt)} selected={opt.id === dateTime.id} key={opt.id}>
                      {`${opt.date} | ${opt.startTime} - ${opt.endTime}`}
                    </FilterRadioButton>
                  </Col>
                ))}
              </Row>
            </Container>

          </Group>

          <Group>
            <div className="title">
              Descreva a programação
            </div>
            <FormGroup error={getErrorMessageByFieldName('specificProgram')}>
              <Textarea
                value={specificProgram}
                onChange={handleSpecificProgramChange}
                placeholder="Descreva qual a programação específica para essa data, como materiais necessários para sua realização (máx. 310 caracteres)"
                maxLength={310}
              />
            </FormGroup>
          </Group>
        </OpacityAnimation>
      </ModalContainer>
            )}
    closeButtonLabel="Fechar"
    size="xl"
    type='action'
    actionButtonLabel="Adicionar"
    isActionButtonDisabled={!isFormValid}
    onAction={() => addProgram()}
  />
  )
}
