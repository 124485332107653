/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction, useState } from 'react';
import Select from 'react-select';
import { CustomStyle } from '../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../components/CustomSelectStyleDarkTheme';
import FormGroup from '../../../components/FormGroup';
import MyModal from '../../../components/Modal';
import useThemeContext from '../../../contexts/theme';
import { PartnerOptionType } from '../types';

interface IDownloadRelatoryModal {
  downloadPdfRelatory: () => Promise<void>;
  downloadPdfRelatoryByPartner: (partnerId: string) => Promise<void>;
  partnerOptions: PartnerOptionType[];
  setDownloadRelatoryModalShow: Dispatch<SetStateAction<boolean>>;
  downloadRelatoryModalShow: boolean;
}

export default function DownloadRelatoryModal({
  downloadPdfRelatory,
  downloadPdfRelatoryByPartner,
  partnerOptions,
  setDownloadRelatoryModalShow,
  downloadRelatoryModalShow,
}: IDownloadRelatoryModal) {
  const [selectedPartner, setSelectedPartner] = useState<{ value: string, label: string }>({ value: '', label: 'Todos' });
  const { selectedTheme } = useThemeContext();

  const mappedPartnerOptions = partnerOptions.map((pt) => ({ value: pt.value ?? '', label: pt.label })).filter((pt) => pt.label !== 'Filtrar por Parceiro');

  return (
    <MyModal
      title="Escolha o demonstrativo a ser baixado"
      closeButtonLabel="Cancelar"
      actionButtonLabel="Baixar"
      modalBody={(
        <FormGroup>
          <label htmlFor='relatory'>
            Selecione o Demonstrativo
          </label>
          <Select
            value={{ value: selectedPartner?.value, label: selectedPartner?.label }}
            options={[{ value: '', label: 'Todos' }, ...mappedPartnerOptions]}
            onChange={(opt) => {
              setSelectedPartner(opt!);
            }}
            styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </FormGroup>
)}
      onClose={() => setDownloadRelatoryModalShow(false)}
      onAction={() => selectedPartner.value ? downloadPdfRelatoryByPartner(selectedPartner.value) : downloadPdfRelatory()}
      show={downloadRelatoryModalShow}
      type="action"
      minHeight='350px'
    />
  )
}
