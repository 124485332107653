import NoData from '../../../components/NoData';
import { Card } from '../styles';

interface KidsAverageCardInterface {
  average: number
}

export default function KidsAverageCard({ average }: KidsAverageCardInterface) {
  return (
    <Card>
      <div className="card-title">
        <div>Média crianças / usuário</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!average && (
      <div className="card-main">
        <div>
          {Math.ceil(average)}
        </div>
        <small>
          {Math.ceil(average) > 1 ? 'Crianças por usuário.' : 'Criança por usuário.'}
        </small>
      </div>
      )}

      {(!average) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da média de crianças cadastradas por usuário
          </>
                    )}
      />
      )}
    </Card>
  );
}
