import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface CuponsServiceInterface {
  id?: string;
  token: string;
  reqBody?: ReqBodyType;
  planId?: number | null;
  active?: boolean;
}

class CuponsService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getCuponsList = async ({
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.get({
      path: '/cupons',
      token,
    });
  }

  getCupomDetails = async ({
    id,
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.get({
        path: `/cupons/${id}`,
        token,
      });
  }

  updateCupom = async ({
    id,
    reqBody,
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.put({
      path: `/cupons/${id}`,
      reqBody,
      token,
    });
  }

  updateCupomStatus = async ({
    id,
    active,
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.patch({
      path: `/cupons/${id}?active=${active}`,
      token,
    });
  }

  createCupom = async({
    reqBody,
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.post({
      path: '/cupons',
      reqBody,
      token,
    });
  }

  deleteCupom = ({
    id,
    planId,
    token,
  }: CuponsServiceInterface) => {
    return this.httpClient.delete({
      path: `/cupons/${id}${planId ? `?planId=${planId}` : ''}`,
      token,
    });
  }
}

export default new CuponsService();
