import { format } from 'date-fns';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import activitiesService from '../../../../services/activitiesService';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { FullActivitiesListFromApi, RatingsFromApi, VideoViewsFromApi } from '../../types';

export default function useDownloadExcel() {
  const { apiCall } = useApiCall();

  const downloadExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getFullActivitiesList,
      actionAfterResponse: (apiResponse) => {
          const activitiesList: FullActivitiesListFromApi = apiResponse.list;
          if (!activitiesList) {
            toast.error(`Não foi possível baixar a lista de atividades (${apiResponse.error})`, {
              toastId: 'downloadActivitiesFail',
            });
            return;
          }
          const mappedActivitiesList = activitiesList.map((act) => ({
            Atividade: act.nome,
            Parceiro: act.partner?.nome_fantasia || 'Não informado',
            'Parceiro Secundário': act.featPartner?.nome_fantasia || 'Não tem',
            Categoria: act.categoria,
            Subcategoria: act.subcategoria,
            'Idade Mínima': `${act.minima} anos` || 'Não Informado',
            'Idade Máxima': act.subcategoria === 'Educação parental' ? 'Atividade direcionada para os pais' : `${act.maxima} anos` || 'Não Informado',
            Status: (act.ativo ? 'Ativo' : 'Inativo'),
            Tipo: act.tipo === 'record' ? 'Aula Gravada' : 'Aula ao-vivo',
          }));
          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Atividades Kiddlepass - ${currentDate}`,
            data: mappedActivitiesList,
          });
      },
      catchMessage: 'Não foi possível baixar a lista de atividades'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  const downloadRatings = useCallback(async (activityId: string) => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getActivityRatings,
      queryParams: { activityId },
      actionAfterResponse: (apiResponse: RatingsFromApi) => {
          const ratings = apiResponse.ratings;
          if (!ratings) {
            toast.error(`Não foi possível baixar a lista de avaliações dessa atividade`, {
              toastId: 'downloadActivitiesRatingsFail',
            });
            return;
          }

          const mappedRatingsList = ratings.map((rt) => ({
            Usuário: rt.userName,
            Celular: rt.userPhone,
            'Data Avaliação': format(new Date(rt.ratedAt), "dd/MM/yyyy 'às' HH'h'mm'm'"),
            'Nota Professor (1 a 5)': rt.teacherRate,
            'Nota Conteúdo (1 a 5)': rt.contentRate,
            'Duração da aula': rt.durationRate ?? 'Não informado',
            'Comentário Adicional': rt.comment ?? '',
          }));

          const { activity, partner } = ratings[0];

          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Avaliações ${activity} (${partner}) - ${currentDate}`,
            data: mappedRatingsList,
          });
      },
      catchMessage: 'Não foi possível baixar a lista de avaliações'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  const downloadAllRatingsExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getAllActivitiesRatings,
      actionAfterResponse: (apiResponse: RatingsFromApi) => {
          const ratings = apiResponse.ratings;
          if (!ratings) {
            toast.error(`Não foi possível baixar a lista de avaliações dessa atividade`, {
              toastId: 'downloadActivitiesRatingsFail',
            });
            return;
          }

          const mappedRatingsList = ratings.map((rt) => ({
            Atividade: rt.activity,
            Parceiro: rt.partner,
            Usuário: rt.userName,
            Celular: rt.userPhone,
            'Data Avaliação': format(new Date(rt.ratedAt), "dd/MM/yyyy 'às' HH'h'mm'm'"),
            'Nota Professor (1 a 5)': rt.teacherRate,
            'Nota Conteúdo (1 a 5)': rt.contentRate,
            'Duração da aula': rt.durationRate ?? 'Não informado',
            'Comentário Adicional': rt.comment ?? '',
          }));

          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Avaliações todas as atividades - ${currentDate}`,
            data: mappedRatingsList,
          });
      },
      catchMessage: 'Não foi possível baixar a lista de avaliações'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  const downloadVideoViewsExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: activitiesService.getActivityVideosViews,
      actionAfterResponse: (apiResponse: VideoViewsFromApi) => {
          const { views, success } = apiResponse;
          if (!success) {
            toast.error(`Não foi possível baixar o relatório de visualizações de vídeos.`, {
              toastId: 'downloadActivitiesVideoViewsFail',
            });
            return;
          }

          if (views.length === 0) {
            toast.info(`Ainda não há nenhuma visualização de vídeo.`, {
              toastId: 'downloadActivitiesVideoViewsFail',
            });
            return;
          }

          const mappedViewsList = views.map((vw) => ({
            Atividade: vw.activity.nome,
            Usuário: vw.user?.nome ?? 'Usuário não identificado',
            Email: vw.user?.email ?? 'Usuário não identificado',
            'Data Visualização': format(new Date(vw.date), "dd/MM/yyyy 'às' HH'h'mm'm'"),
          }));

          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Relatório de Visualizações de Vídeos - ${currentDate}`,
            data: mappedViewsList,
          });
      },
      catchMessage: 'Não foi possível baixar o relatório de visualizações de vídeos'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  return {
    downloadExcel,
    downloadRatings,
    downloadAllRatingsExcel,
    downloadVideoViewsExcel,
  };
}
