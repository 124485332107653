/* eslint-disable max-len */
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import edit from '../../../../../../assets/images/icons/edit.svg';
import plus from '../../../../../../assets/images/icons/plus.svg';
import trash from '../../../../../../assets/images/icons/trash.svg';
import NoData from '../../../../../../components/NoData';
import {
  Card, Container as StyledContainer,
} from './styles';

import FormGroup from '../../../../../../components/FormGroup';
import Input from '../../../../../../components/Input';
import MyModal from '../../../../../../components/Modal';

import { Dispatch, SetStateAction } from 'react';
import { CustomStyle } from '../../../../../../components/CustomSelectStyle';
import FilterRadioButton from '../../../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../../../components/FilterRadioButtonsContainer';
import { NoDataContainer } from '../../../../../../components/NoData/styles';
import { SecondaryButton } from '../../../../../../components/SecondaryButton';
import differenceBetweenDates from '../../../../../../utils/differenceBetweenDates';
import formatDate from '../../../../../../utils/formatDate';
import renderArray from '../../../../../../utils/renderArrayWithComa';
import { UserChildType } from '../../../../types';
import { ChildrenInfoBeingShownType } from '../../types';

interface ChildrensCardInterface {
  childrens: UserChildType[];
  addChildrenModalShow: boolean;
  setAddChildrenModalShow: Dispatch<SetStateAction<boolean>>;
  childrenDetailsModalShow: boolean;
  childrenInfoBeingShow: ChildrenInfoBeingShownType;
  addNewChildren: () => void;
  onEditChildren: () => void;
  removeChildren: (children: string) => void;
  onOpenChildrenDetailsModal: (
    { name, birthDate, preferredCategories, preferredPeriods, id, needsInterpreter }: ChildrenInfoBeingShownType
  ) => void;
  interestsOptions: { value: string, label: string }[];
  childrenNameBeingAdded: string;
  setChildrenNameBeingAdded: Dispatch<SetStateAction<string>>;
  childrenBirthDateBeingAdded: string;
  setChildrenBirthDateBeingAdded: Dispatch<SetStateAction<string>>;
  childrenPeriodBeingAdded: { value: string, label: string }[];
  setChildrenPeriodBeingAdded: Dispatch<SetStateAction<{ value: string, label: string }[]>>;
  childrenInterestsBeingAdded: { value: string, label: string }[];
  setChildrenInterestsBeingAdded: Dispatch<SetStateAction<{ value: string, label: string }[]>>;
  periodOptions: { value: string, label: string }[];
  onOpenChildrenRemoveModal: (children: string) => void;
  childrenBeingRemoved: string;
  childrenRemoveModalShow: boolean;
  childrenNameBeingEditted: string;
  setChildrenNameBeingEditted: Dispatch<SetStateAction<string>>;
  childrenBirthDateBeingEditted: string;
  setChildrenBirthDateBeingEditted: Dispatch<SetStateAction<string>>;
  childrenPeriodBeingEditted: { value: string, label: string }[];
  setChildrenInterestsBeingEditted: Dispatch<SetStateAction<{ value: string, label: string }[]>>;
  childrenInterestsBeingEditted: { value: string, label: string }[];
  setChildrenPeriodBeingEditted: Dispatch<SetStateAction<{ value: string, label: string }[]>>;
  setChildrenRemoveModalShow: Dispatch<SetStateAction<boolean>>;
  setChildrenDetailsModalShow: Dispatch<SetStateAction<boolean>>;
  setChildrenObservationBeingAdded: Dispatch<SetStateAction<string>>;
  childrenObservationBeingAdded: string;
  childrenObservationBeingEditted: string;
  setChildrenObservationBeingEditted: Dispatch<SetStateAction<string>>;
  childrenneedsInterpreterBeingAdded: boolean | undefined;
  childrenneedsInterpreterBeingEditted: boolean | undefined;
  setChildrenneedsInterpreterBeingAdded: Dispatch<SetStateAction<boolean | undefined>>;
  setChildrenneedsInterpreterBeingEditted: Dispatch<SetStateAction<boolean | undefined>>;
}

export default function ChildrensCard({
  childrens,
  addChildrenModalShow,
  setAddChildrenModalShow,
  childrenDetailsModalShow,
  childrenInfoBeingShow,
  addNewChildren,
  onEditChildren,
  removeChildren,
  onOpenChildrenDetailsModal,
  interestsOptions,
  childrenNameBeingAdded,
  setChildrenNameBeingAdded,
  childrenBirthDateBeingAdded,
  setChildrenBirthDateBeingAdded,
  childrenPeriodBeingAdded,
  setChildrenPeriodBeingAdded,
  childrenInterestsBeingAdded,
  setChildrenInterestsBeingAdded,
  periodOptions,
  onOpenChildrenRemoveModal,
  childrenBeingRemoved,
  childrenRemoveModalShow,
  childrenNameBeingEditted,
  setChildrenNameBeingEditted,
  childrenBirthDateBeingEditted,
  setChildrenBirthDateBeingEditted,
  childrenPeriodBeingEditted,
  setChildrenInterestsBeingEditted,
  childrenInterestsBeingEditted,
  setChildrenPeriodBeingEditted,
  setChildrenRemoveModalShow,
  setChildrenDetailsModalShow,
  setChildrenObservationBeingAdded,
  childrenObservationBeingAdded,
  childrenObservationBeingEditted,
  setChildrenObservationBeingEditted,
  childrenneedsInterpreterBeingAdded,
  childrenneedsInterpreterBeingEditted,
  setChildrenneedsInterpreterBeingAdded,
  setChildrenneedsInterpreterBeingEditted,
}: ChildrensCardInterface) {
  const animatedComponents = makeAnimated();
  const hasChildrens = childrens.length > 0;
  const hasLimitOfChildrensRegistered = childrens.length >= 3;

  return (
    <>
      <StyledContainer>
        <div className="card-title">
          <div>
            Crianças cadastradas
          </div>
          {hasChildrens && !hasLimitOfChildrensRegistered && (
            <img
              src={plus}
              alt="add"
              className="primaryColor"
              title="Adicionar nova criança"
              onClick={() => setAddChildrenModalShow(true)}
            />
          )}
        </div>
        <Container>
          <Row xs={1} md={1} lg={2}>
            {hasChildrens && childrens.map((child) => (
              <Col key={child.id}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.3, type: 'tween', stiffness: 10000 }}
                >
                  <Card>
                    <div className="info">
                      <div className="card-title">
                        <strong>{child.name}</strong>
                        {child.birthDate && (
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          <small>{`${differenceBetweenDates(formatDate(child.birthDate)!).years} anos`}</small>
                        )}
                      </div>
                      <span>
                        Períodos:
                        {' '}
                        {renderArray(child.preferredPeriods, 'nenhum')}
                      </span>
                      <span>
                        Áreas de interesse:
                        {' '}
                        {renderArray(child.preferredCategories.map((ctg) => ctg.name), 'nenhuma')}
                      </span>
                      <span>
                        Observação:
                        {' '}
                        {child.observation ?? 'Sem observação'}
                      </span>
                    </div>
                    <div className="actions">
                      <button
                        type="button"
                        onClick={() => {
                          onOpenChildrenDetailsModal({
                            id: child.id,
                            name: child.name,
                            birthDate: child.birthDate,
                            preferredPeriods: child.preferredPeriods,
                            preferredCategories: child.preferredCategories,
                            observation: child.observation,
                            needsInterpreter: child.needsInterpreter,
                          });
                        }}
                      >
                        <img src={edit} alt="crianca" title="Editar criança" className="primaryColor" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          onOpenChildrenRemoveModal(child.id);
                        }}
                      >
                        <img src={trash} alt="crianca" title="Remover criança" />
                      </button>
                    </div>
                  </Card>
                </motion.div>
              </Col>
            ))}

            {!hasChildrens && (
              <NoDataContainer timetable>
                <SecondaryButton onClick={() => setAddChildrenModalShow(true)}>
                  Adicionar nova criança
                </SecondaryButton>
                <NoData
                  icon="emptyBox"
                  label={(
                    <>
                      Este usuário não possui nenhum criança cadastrada. Cadastre a primeira clicando no botão
                      {' '}
                      <strong>Adicionar nova criança</strong>
                      {' '}
                      acima.
                      Se atente para não inserir horários que conflitem entre si.
                    </>
  )}
                />
              </NoDataContainer>
            )}

          </Row>
        </Container>
      </StyledContainer>

      <MyModal
        show={addChildrenModalShow}
        onClose={() => setAddChildrenModalShow(false)}
        onAction={addNewChildren}
        title="Adicionar nova criança"
        type="action"
        closeButtonLabel="Fechar"
        actionButtonLabel="Adicionar"
        modalBody={(
          <>
            <FormGroup>
              <label htmlFor="contactEmail">Nome</label>
              <Input
                placeholder="Insira o nome da criança"
                value={childrenNameBeingAdded}
                onChange={(evt) => setChildrenNameBeingAdded(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Data de nascimento</label>
              <Input
                type="date"
                value={childrenBirthDateBeingAdded}
                onChange={(evt) => setChildrenBirthDateBeingAdded(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Períodos</label>
              <Select
                value={childrenPeriodBeingAdded}
                options={periodOptions}
                onChange={(opt) => {
                  setChildrenPeriodBeingAdded(opt as { value: string; label: string; }[]);
                }}
                placeholder="Selecione os períodos"
                isMulti
                styles={CustomStyle}
                components={animatedComponents}
                closeMenuOnSelect={false}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Áreas de interesse</label>
              <Select
                value={childrenInterestsBeingAdded}
                options={interestsOptions}
                onChange={(opt) => {
                  setChildrenInterestsBeingAdded(opt as { value: string; label: string; }[]);
                }}
                placeholder="Selecione as áreas de interesse"
                isMulti
                styles={CustomStyle}
                components={animatedComponents}
                closeMenuOnSelect={false}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactEmail">Observação</label>
              <Input
                placeholder="Insira alguma observação, caso haja, para a criança"
                value={childrenObservationBeingAdded}
                onChange={(evt) => setChildrenObservationBeingAdded(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactEmail">Intérprete de Libras</label>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={!!childrenneedsInterpreterBeingAdded} onClick={() => setChildrenneedsInterpreterBeingAdded(true)}>
                  Necessário
                </FilterRadioButton>
                <FilterRadioButton selected={!childrenneedsInterpreterBeingAdded} onClick={() => setChildrenneedsInterpreterBeingAdded(false)}>
                  Não Necessário
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </FormGroup>
          </>
)}
      />

      <MyModal
        show={childrenDetailsModalShow}
        onClose={() => setChildrenDetailsModalShow(false)}
        onAction={onEditChildren}
        title={`Editar criança ${childrenInfoBeingShow.name}`}
        type="action"
        closeButtonLabel="Fechar"
        actionButtonLabel="Editar"
        modalBody={(
          <>
            <FormGroup>
              <label htmlFor="contactEmail">Nome</label>
              <Input
                placeholder="Insira o nome da criança"
                value={childrenNameBeingEditted}
                onChange={(evt) => setChildrenNameBeingEditted(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Data de nascimento</label>
              <Input
                type="date"
                value={childrenBirthDateBeingEditted}
                onChange={(evt) => setChildrenBirthDateBeingEditted(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Períodos</label>
              <Select
                value={childrenPeriodBeingEditted}
                options={periodOptions}
                onChange={(opt) => {
                  setChildrenPeriodBeingEditted(opt as { value: string; label: string; }[]);
                }}
                placeholder="Selecione os períodos"
                isMulti
                styles={CustomStyle}
                components={animatedComponents}
                closeMenuOnSelect={false}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactName">Áreas de interesse</label>
              <Select
                value={childrenInterestsBeingEditted}
                options={interestsOptions}
                onChange={(opt) => {
                  setChildrenInterestsBeingEditted(opt as { value: string; label: string; }[]);
                }}
                placeholder="Selecione as áreas de interesse"
                isMulti
                styles={CustomStyle}
                components={animatedComponents}
                closeMenuOnSelect={false}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactEmail">Observação</label>
              <Input
                placeholder="Insira alguma observação, caso haja, para a criança"
                value={childrenObservationBeingEditted}
                onChange={(evt) => setChildrenObservationBeingEditted(evt.target.value)}
                autoComplete="new-password"
                darkBorder
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="contactEmail">Intérprete de Libras</label>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={!!childrenneedsInterpreterBeingEditted} onClick={() => setChildrenneedsInterpreterBeingEditted(true)}>
                  Necessário
                </FilterRadioButton>
                <FilterRadioButton selected={!childrenneedsInterpreterBeingEditted} onClick={() => setChildrenneedsInterpreterBeingEditted(false)}>
                  Não Necessário
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </FormGroup>
          </>
)}

      />

      <MyModal
        title={`Remover criança`}
        closeButtonLabel="Cancelar"
        actionButtonLabel="Remover"
        modalBody={(
          <div>
            Tem certeza que deseja remover esta criança?
          </div>
)}
        onClose={() => setChildrenRemoveModalShow(false)}
        onAction={() => removeChildren(childrenBeingRemoved)}
        show={childrenRemoveModalShow}
        type="deleteAction"
      />
    </>
  );
}
