import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import trash from '../../../../../../../../assets/images/icons/trash.svg';
import NoData from '../../../../../../../../components/NoData';

interface ListInterface {
  list: string[];
  onDeleteAssociatedCompany: (company: string) => void;
}

export default function List({
  list,
  onDeleteAssociatedCompany,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {list?.map((company) => (
          <Col key={company}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{ delay: 0.1, type: 'tween' }}
            >
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{company}</strong>
                  </div>
                </div>
                <div className="actions">
                  <button
                    type="button"
                    onClick={() => onDeleteAssociatedCompany(company)}
                  >
                    <img src={trash} alt="" title="Remover empresa associada" />
                  </button>
                </div>
              </Card>
            </motion.div>
          </Col>
        ))}

        {list.length === 0 && (
          <NoData
            icon='emptyBox'
            label={<>Sem empresas associadas</>}
          />
        )}
      </Row>
    </Container>
  );
}
