import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import NoData from '../../../../components/NoData';
import { VideoViewsInLast30DaysType } from '../../types';
import { Container as CustomContainer, Group } from './styles';

interface VideoViewsListInterface {
  list: VideoViewsInLast30DaysType[];
}

export default function VideoViewsList({ list }: VideoViewsListInterface) {
  const hasVideoViews = !list || list.length !== 0;

  return (
    <CustomContainer>
      {hasVideoViews && (
        <Container>
          <Row xs={1} md={list.length === 1 ? 1 : 2} lg={list.length === 1 ? 1 : 2}>
            {list.map((sch) => (
              <Col key={sch.id}>
                <Group>
                  <div className="info">
                    <div className="card-title">
                      <strong>{sch.activity.nome}</strong>
                    </div>
                    <span>
                      Visualizado em
                      {' '}
                      {format(new Date(sch.date), 'dd/MM/yyyy')}
                    </span>
                  </div>
                </Group>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {!hasVideoViews && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Este assinante não viu nenhum vídeo nos últimos 30 dias.
              .
            </>
          )}
        />
      )}
    </CustomContainer>
  );
}
