/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import FormGroup from "../../../../../components/FormGroup";
import MyModal from "../../../../../components/Modal";
import OpacityAnimation from "../../../../../components/OpacityAnimation";
import Textarea from "../../../../../components/Textarea";
import { ProgramToBeSentFeedbackType } from "../../types";
import { Group } from "../AddModal/styles";
import { ModalContainer } from "./styles";
import useFeedbackModal from "./useFeedbackModal";

interface IFeedbackModal {
  feedbackModalShow: boolean;
  setFeedbackModalShow: Dispatch<SetStateAction<boolean>>;
  programToBeSentFeedback: ProgramToBeSentFeedbackType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function FeedbackModal({
  feedbackModalShow,
  setFeedbackModalShow,
  setIsLoading,
  programToBeSentFeedback,
}: IFeedbackModal) {
  const {
    sendFeedback,
    feedback,
    handleFeedbackChange,
    getErrorMessageByFieldName,
    isSendFeedbackFormValid,
  } = useFeedbackModal({ setIsLoading, setFeedbackModalShow, programToBeSentFeedback });

  return (
    <MyModal
      show={feedbackModalShow}
      onClose={() => setFeedbackModalShow(false)}
      title={`Enviar devolutiva sobre a programação pedagógica de ${programToBeSentFeedback.date} | ${programToBeSentFeedback.startTime} - ${programToBeSentFeedback.endTime}`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
          <Group>
            <div className="title">
              Devolutiva
            </div>
            <FormGroup error={getErrorMessageByFieldName('feedback')}>
              <Textarea
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder="Digite aqui uma devolutiva para o parceiro, explicando o porquê essa programação pedagógica não está válida"
              />
            </FormGroup>
          </Group>
          </OpacityAnimation>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Enviar devolutiva"
      isActionButtonDisabled={!isSendFeedbackFormValid}
      onAction={() => sendFeedback()}
    />
  )
}
