/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    useState,
} from 'react';

import useInputChanges from './functions/useInputChanges';
import useLoadForm from './functions/useLoadForm';

import { UserChildType } from '../../../types';
import useUpdateUser from './functions/useUpdateUser';
import useUsersChildrens from './functions/useUsersChildrens';

export default function useUserForm() {
  const [interestsOptions, setInterestsOptions] = useState<{ value: string, label: string }[]>([]);
  const [childrens, setChildrens] = useState<UserChildType[]>([]);


  const {
    registerDate,
    setRegisterDate,
    activateDate,
    setActivateDate,
    status,
    setStatus,
    name,
    setName,
    cpf,
    setCpf,
    company,
    setCompany,
    cupom,
    setCupom,
    email,
    setEmail,
    birthDate,
    setBirthDate,
    cellphone,
    setCellphone,
    gender,
    setGender,
    cep,
    setCep,
    streetName,
    setStreetName,
    district,
    setDistrict,
    city,
    setCity,
    uf,
    setUf,
    getErrorMessageByFieldName,
    errors,
    handleCpfChange,
    handleCellphoneChange,
    handleNameChange,
    handleEmailChange,
    handleBirthDateChange,
    handleCepChange,
    isGettingCepInfo,
  } = useInputChanges();

  const {
    isLoadLoading,
    periodOptions,
    loadForm
  } = useLoadForm({
    setStatus,
    setEmail,
    setRegisterDate,
    setActivateDate,
    setCpf,
    setName,
    setCep,
    setStreetName,
    setDistrict,
    setCity,
    setUf,
    setCompany,
    setCupom,
    setBirthDate,
    setGender,
    setChildrens,
    setCellphone,
    setInterestsOptions,
  });

  const {
    addChildrenModalShow,
    setAddChildrenModalShow,
    childrenDetailsModalShow,
    childrenInfoBeingShow,
    addNewChildren,
    removeChildren,
    handleOpenChildrenDetailsModal,
    setChildrenNameBeingAdded,
    setChildrenObservationBeingAdded,
    setChildrenBirthDateBeingAdded,
    setChildrenPeriodBeingAdded,
    setChildrenInterestsBeingAdded,
    childrenNameBeingAdded,
    childrenObservationBeingAdded,
    childrenBirthDateBeingAdded,
    childrenPeriodBeingAdded,
    childrenInterestsBeingAdded,
    handleOpenChildrenRemoveModal,
    childrenBeingRemoved,
    childrenRemoveModalShow,
    editChildren,
    childrenNameBeingEditted,
    setChildrenNameBeingEditted,
    childrenObservationBeingEditted,
    setChildrenObservationBeingEditted,
    childrenBirthDateBeingEditted,
    setChildrenBirthDateBeingEditted,
    childrenPeriodBeingEditted,
    setChildrenInterestsBeingEditted,
    childrenInterestsBeingEditted,
    setChildrenPeriodBeingEditted,
    setChildrenRemoveModalShow,
    setChildrenDetailsModalShow,
    isLoadingAfterChildrenAction,
    childrenneedsInterpreterBeingAdded,
    childrenneedsInterpreterBeingEditted,
    setChildrenneedsInterpreterBeingAdded,
    setChildrenneedsInterpreterBeingEditted,
  } = useUsersChildrens({ loadForm, childrens, setChildrens });

  const {
    isUpdating,
    updateUser,
  } = useUpdateUser({
    email,
    name,
    cpf,
    birthDate,
    cellphone,
    gender,
    cep,
    streetName,
    district,
    city,
    uf,
  });

  const isFormValid = (
    name && cpf && email && cellphone
    && errors.length === 0
  );

  const isLoading = isLoadLoading || isUpdating || isLoadingAfterChildrenAction;

  return {
    isLoading,
    registerDate,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    email,
    cellphone,
    company,
    cep,
    handleCepChange,
    streetName,
    district,
    city,
    uf,
    isFormValid,
    cupom,
    birthDate,
    handleBirthDateChange,
    gender,
    setGender,
    addChildrenModalShow,
    setAddChildrenModalShow,
    childrenDetailsModalShow,
    childrenInfoBeingShow,
    addNewChildren,
    removeChildren,
    handleOpenChildrenDetailsModal,
    handleCellphoneChange,
    handleEmailChange,
    interestsOptions,
    setChildrenNameBeingAdded,
    setChildrenBirthDateBeingAdded,
    setChildrenPeriodBeingAdded,
    setChildrenInterestsBeingAdded,
    activateDate,
    status,
    childrenNameBeingAdded,
    childrenBirthDateBeingAdded,
    childrenPeriodBeingAdded,
    childrenInterestsBeingAdded,
    childrens,
    periodOptions,
    handleOpenChildrenRemoveModal,
    childrenBeingRemoved,
    childrenRemoveModalShow,
    editChildren,
    childrenNameBeingEditted,
    setChildrenNameBeingEditted,
    childrenBirthDateBeingEditted,
    setChildrenBirthDateBeingEditted,
    childrenPeriodBeingEditted,
    setChildrenInterestsBeingEditted,
    childrenInterestsBeingEditted,
    setChildrenPeriodBeingEditted,
    setChildrenRemoveModalShow,
    setChildrenDetailsModalShow,
    updateUser,
    isGettingCepInfo,
    setChildrenObservationBeingAdded,
    childrenObservationBeingAdded,
    childrenObservationBeingEditted,
    setChildrenObservationBeingEditted,
    childrenneedsInterpreterBeingAdded,
    childrenneedsInterpreterBeingEditted,
    setChildrenneedsInterpreterBeingAdded,
    setChildrenneedsInterpreterBeingEditted,
  };
}
