import { useState } from "react"
import { CupomFromApiObject } from "../../types";

export default function useEdit() {
  const [editCupomModalShow, setEditCupomModalShow] = useState(false);
  const [cupomObjectBeingEditted, setCupomObjectBeingEditted] = useState<CupomFromApiObject>({} as CupomFromApiObject);

  function handleOpenEditModal(cupomObject: CupomFromApiObject) {
    setCupomObjectBeingEditted(cupomObject);
    setEditCupomModalShow(true);
  }

  return {
    handleOpenEditModal,
    editCupomModalShow,
    setEditModalShow: setEditCupomModalShow,
    cupomObjectBeingEditted,
    setCupomObjectBeingEditted,
  }
}
