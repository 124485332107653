import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import NoData from '../../../../components/NoData';
import { JoinedClassesInLast30DaysType } from '../../types';
import { Container as CustomContainer, Group } from './styles';

interface JoinedClassesListInterface {
  list: JoinedClassesInLast30DaysType[];
}

export default function JoinedClassesList({ list }: JoinedClassesListInterface) {
  const hasJoinedClasses = !list || list.length !== 0;

  return (
    <CustomContainer>
      {hasJoinedClasses && (
        <Container>
          <Row xs={1} md={list.length === 1 ? 1 : 2} lg={list.length === 1 ? 1 : 2}>
            {list.map((sch) => (
              <Col key={sch.id}>
                <Group>
                  <div className="info">
                    <div className="card-title">
                      <strong>{sch.activity.nome}</strong>
                    </div>
                    <span>
                      Entrou em
                      {' '}
                      {format(new Date(sch.joinedAt), 'dd/MM/yyyy - HH:mm')}
                    </span>
                    <span>
                      Dia da aula:
                      {' '}
                      {sch.classDate}
                    </span>
                    <span>
                      Horário:
                      {' '}
                      {`Das ${sch.classStartTime} ás ${sch.classEndTime}`}
                    </span>
                  </div>
                </Group>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {!hasJoinedClasses && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Este assinante não entrou em nenhuma aula nos últimos 30 dias.
              .
            </>
          )}
        />
      )}
    </CustomContainer>
  );
}
