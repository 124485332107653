import useActivitySubmit from './functions/useActivitySubmit';
import useDisableDates from './functions/useDisableDates';
import useInputChanges from './functions/useInputChanges';
import useLoadForm from './functions/useLoadForm';
import useTimetable from './functions/useTimetable';

interface UseActivityFormInterface {
  approvement?: boolean;
}

export default function useActivityForm({ approvement }: UseActivityFormInterface) {
  const {
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    category,
    setCategory,
    subcategory,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    setNeedsCompanion,
    level,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    minAge,
    maxAge,
    description,
    isRecurrent,
    setIsRecurrent,
    partner,
    setPartner,
    setName,
    setDescription,
    setMethodology,
    setOrientations,
    setVideoLink,
    setNecessaryMaterials,
    setClothes,
    setNecessaryKnowLedge,
    errors,
    isRecordedActivity,
    setKindOfActivity,
    kindOfActivity,
    featPartner,
    setFeatPartner,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
    setSpecificPeriod,
    setSpecificPeriodLabel,
    selectedTags,
    setSelectedTags,
    handleTagsChange,
    isFree,
    setIsFree,
    classValue,
    handleClassValueChange,
    setClassValue,
  } = useInputChanges();

  const {
    timetable,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    duration,
    setDuration,
    handleDurationChange,
    setStartTimeBeingAdded,
    setEndTimeBeingAdded,
    setVacanciesQty,
    setTimetable,
  } = useTimetable();

  const {
    updatedDates,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    finalDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    resetDisabledDates,
    hasOriginalDatesChanged,
    setOriginalDates,
    setUpdatedDates,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
  } = useDisableDates({ timetable });

  const {
    isLoadLoading,
    categories,
    partners,
    subcategoriesOptions,
    companionOptions,
    recurrentOptions,
    levelOptions,
  } = useLoadForm({
    setKindOfActivity,
    setName,
    setPartner,
    setCategory,
    setSubcategory,
    setDescription,
    setMethodology,
    setOrientations,
    setVideoLink,
    setIsRecurrent,
    setVideoFile,
    setImageFile,
    setMinAge,
    setMaxAge,
    setNeedsCompanion,
    setNecessaryMaterials,
    setClothes,
    setLevel,
    setNecessaryKnowLedge,
    setDayInfoBeingShow,
    setDayBeingAddedAtTimetable,
    setStartTimeBeingAdded,
    setEndTimeBeingAdded,
    setVacanciesQty,
    setTimetable,
    setOriginalDates,
    setUpdatedDates,
    setDuration,
    category,
    approvement,
    setFeatPartner,
    setSpecificPeriod,
    setSpecificPeriodLabel,
    setSelectedTags,
    setIsFree,
    setClassValue,
  });

  const {
    isSubmitting,
    handleApprovementActivityDecision,
    createActivity,
    updateActivity,
    isFormValid,
    requiredFieldsErrorsLabel,
  } = useActivitySubmit({
    partner,
    featPartner,
    kindOfActivity,
    name,
    description,
    subcategory,
    minAge,
    maxAge,
    methodology,
    necessaryMaterials,
    needsCompanion,
    orientations,
    clothes,
    isRecurrent,
    level,
    necessaryKnowledge,
    videoLink,
    timetable,
    imageFile,
    videoFile,
    updatedDates,
    duration,
    isRecordedActivity,
    hasClasses,
    specificPeriod,
    selectedTags,
    isFree,
    classValue,
    errors,
  });

  const isLoading = isLoadLoading || isSubmitting;

  return {
    isLoading,
    setKindOfActivity,
    kindOfActivity,
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    category,
    categories,
    setCategory,
    subcategory,
    subcategoriesOptions,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    companionOptions,
    setNeedsCompanion,
    level,
    levelOptions,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    isFormValid,
    minAge,
    maxAge,
    description,
    isRecurrent,
    setIsRecurrent,
    recurrentOptions,
    createActivity,
    partners,
    partner,
    setPartner,
    duration,
    handleDurationChange,
    updateActivity,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    finalDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    resetDisabledDates,
    hasOriginalDatesChanged,
    handleApprovementActivityDecision,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
    featPartner,
    setFeatPartner,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
    selectedTags,
    handleTagsChange,
    requiredFieldsErrorsLabel,
    isFree,
    setIsFree,
    handleClassValueChange,
    classValue,
  };
}
