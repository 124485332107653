import { ChangeEvent, useCallback, useState } from "react"
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import partnersService from "../../../../services/partnersService";

interface UseUploadImageI {
  loadPartners: () => Promise<void>
}

export default function useUploadImage({ loadPartners }: UseUploadImageI) {
  const [uploadImageModalShow, setUploadImageModalShow] = useState(false);
  const [partnerIdBeingUploadingImage, setParnerIdBeingUploadingImage] = useState('');
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [imageFileName, setImageFileName] = useState('');
  const [imageFile, setImageFile] = useState<File | string | undefined>();

  const { apiCall } = useApiCall();

  function handleOpenUploadImageModal(partnerId: string) {
    if (!partnerId) {
      toast.error('Nãi foi possível abrir o quadro para alterar/incluir uma imagem ao parceiro')
      return;
    }
    setUploadImageModalShow(true);
    setParnerIdBeingUploadingImage(partnerId);
  }

  function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      toast.error('Não foi possível subir essa imagem. Por favor, tente novamente')
      return;
    }
    setImageFile(files[0])
    setImageFileName(files[0].name)
  }

  function handleUploadImageModalClose() {
    setUploadImageModalShow(false);
    setParnerIdBeingUploadingImage('');
    setImageFileName('');
    setImageFile(undefined);
  }

  const handleSubmitFile = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.createOrUpdatePartnerImage,
      onStartLoad: () => setIsUploadingImage(true),
      onEndLoad: () => setIsUploadingImage(false),
      reqBody: [{ key: 'image', value: imageFile }],
      queryParams: { partnerId: partnerIdBeingUploadingImage },
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível alterar/incluir essa imagem ao parceiro. Por favor, tente novamente.');
          return;
        }

        toast.success('Imagem atribuída ao parceiro com sucesso!');
        setUploadImageModalShow(false);
        loadPartners()
      }
    });
  }, [apiCall, imageFile, loadPartners, partnerIdBeingUploadingImage]);

  return {
    isUploadingImage,
    handleOpenUploadImageModal,
    uploadImageModalShow,
    imageFileName,
    handleFileUpload,
    handleSubmitFile,
    handleUploadImageModalClose,
  }
}
