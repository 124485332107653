import { Link } from 'react-router-dom';
import info from '../../../../../../../../assets/images/icons/info.svg';
import { Container, InfoContainer } from './styles';

export default function RedirectToCuponsPage() {
  return (
    <Container>
      <InfoContainer>
        <img src={info} alt="" />

        <div>
          Para editar, remover, ou adicionar um novo cupom para essa empresa, vá para a página de
        </div>
      </InfoContainer>
      <Link to="/cupons">
        Cupons
      </Link>
    </Container>
  )
}
