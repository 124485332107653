/* eslint-disable max-len */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface UseLoadDashboardInterface {
  getUsersQuantities: () => void;
  getPartnersQuantity: () => void;
  getActivitiesQuantity: () => void;
  getKidsAverage: () => void;
  getSchedulesPerformance: () => void;
  getUsersRegions: () => void;
  getActivitiesRanking: () => void;
  getUsersRatings: () => void;
  getKidsAges: () => void;
  getCanceleds: () => void;
  getChurn: () => void;
  getActivitiesVideosViewsRanking: () => void;
  getUsersSo: () => void;
  getActiveUsersByPeriod: () => void;
  setHasError: Dispatch<SetStateAction<boolean>>;
}

export default function useLoadDashboard({
  getUsersQuantities,
  getPartnersQuantity,
  getActivitiesQuantity,
  getKidsAverage,
  getSchedulesPerformance,
  getUsersRegions,
  getActivitiesRanking,
  getUsersRatings,
  getKidsAges,
  getCanceleds,
  getChurn,
  getActivitiesVideosViewsRanking,
  getUsersSo,
  getActiveUsersByPeriod,
  setHasError,
}: UseLoadDashboardInterface) {
  const [isLoading, setIsLoading] = useState(true);

  const loadDashboard = useCallback(async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      await Promise.all([
        getUsersQuantities(),
        getPartnersQuantity(),
        getActivitiesQuantity(),
        getKidsAverage(),
        getSchedulesPerformance(),
        getUsersRegions(),
        getActivitiesRanking(),
        getUsersRatings(),
        getKidsAges(),
        getCanceleds(),
        getChurn(),
        getActivitiesVideosViewsRanking(),
        getActiveUsersByPeriod(),
        getUsersSo(),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar os dados do dashboard (${error})`, {
        toastId: 'loadDashboardFail',
      });
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [setHasError, getUsersQuantities, getPartnersQuantity, getActivitiesQuantity, getKidsAverage, getSchedulesPerformance, getUsersRegions, getActivitiesRanking, getUsersRatings, getKidsAges, getCanceleds, getChurn, getActivitiesVideosViewsRanking, getActiveUsersByPeriod, getUsersSo]);

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard]);

  return {
    isLoading,
    loadDashboard,
  };
}
