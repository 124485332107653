import PropTypes, { shape } from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Container as StyledContainer } from '../../styles';

import NoData from '../../../NoData';

import plus from '../../../../assets/images/icons/plus.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import Title from './components/Title';
import DateCard from './components/DateCard';
import RehabilitateDateModal from './components/RehabilitateDateModal';
import DisableDateModal from './components/DisableDateModal';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { DatesCardContainer } from './styles';

interface DisabledDatesCardInterface {
  setDisableDateModalShow: Dispatch<SetStateAction<boolean>>;
  hasDatesToBeRendered: boolean;
  finalDates: string[];
  setRehabilitateDate: Dispatch<SetStateAction<string>>;
  setRehabilitateDateModalShow: Dispatch<SetStateAction<boolean>>;
  selectedRehabilitateDate: string;
  rehabilitateDate: () => void;
  disableDateModalShow: boolean;
  selectedDisableDate: string;
  handleDisableDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disableDate: () => void;
  isFutureDateChoosed: boolean;
  rehabilitateDateModalShow: boolean;
  resetDisabledDates: () => void;
  hasOriginalDatesChanged: boolean;
  isSpecificTime: boolean;
  setIsSpecificTime: Dispatch<SetStateAction<boolean>>;
  timesOptions: { value: string, label: string }[];
  selectedDisableTime: { value: string, label: string };
  setSelectedDisableTime: Dispatch<SetStateAction<{ value: string, label: string }>>;
}

export default function DisabledDatesCard({
  setDisableDateModalShow,
  hasDatesToBeRendered,
  finalDates,
  setRehabilitateDate,
  setRehabilitateDateModalShow,
  selectedRehabilitateDate,
  rehabilitateDate,
  disableDateModalShow,
  selectedDisableDate,
  handleDisableDateChange,
  disableDate,
  isFutureDateChoosed,
  rehabilitateDateModalShow,
  resetDisabledDates,
  hasOriginalDatesChanged,
  isSpecificTime,
  setIsSpecificTime,
  timesOptions,
  selectedDisableTime,
  setSelectedDisableTime,
}: DisabledDatesCardInterface) {
  const onlyDisableActionDates = finalDates;

  return (
    <>
      <StyledContainer>
        <Title
          hasOriginalDatesChanged={hasOriginalDatesChanged}
          resetDisabledDates={resetDisabledDates}
          addImage={plus}
          setDisableDateModalShow={setDisableDateModalShow}
        />
        {hasDatesToBeRendered && (
          <DatesCardContainer>
            <Container>
              <Row xs={1} md={1} lg={1}>
                {onlyDisableActionDates?.map((date) => (
                  <Col key={date}>
                    <DateCard
                      date={date}
                      setRehabilitateDate={setRehabilitateDate}
                      setRehabilitateDateModalShow={setRehabilitateDateModalShow}
                      deleteImage={trash}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </DatesCardContainer>
        )}

        {!hasDatesToBeRendered && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Não há nenhuma data desabilitada. Para desabilitar uma data, clique no ícone
              {' '}
              <img src={plus} alt="add" className="primaryColor" />
              {' '}
              acima
            </>
)}
        />
        )}
      </StyledContainer>

      <RehabilitateDateModal
        rehabilitateDateModalShow={rehabilitateDateModalShow}
        selectedRehabilitateDate={selectedRehabilitateDate}
        setRehabilitateDateModalShow={setRehabilitateDateModalShow}
        rehabilitateDate={rehabilitateDate}
      />

      <DisableDateModal
        disableDateModalShow={disableDateModalShow}
        selectedDisableDate={selectedDisableDate}
        handleDisableDateChange={handleDisableDateChange}
        setDisableDateModalShow={setDisableDateModalShow}
        disableDate={disableDate}
        isFutureDateChoosed={isFutureDateChoosed}
        isSpecificTime={isSpecificTime}
        setIsSpecificTime={setIsSpecificTime}
        timesOptions={timesOptions}
        selectedDisableTime={selectedDisableTime}
        setSelectedDisableTime={setSelectedDisableTime}
      />
    </>
  );
}

DisabledDatesCard.propTypes = {
  setDisableDateModalShow: PropTypes.func.isRequired,
  hasDatesToBeRendered: PropTypes.bool.isRequired,
  finalDates: PropTypes.arrayOf(shape({
    data: PropTypes.string,
    acao: PropTypes.string,
  })).isRequired,
  setRehabilitateDate: PropTypes.func.isRequired,
  setRehabilitateDateModalShow: PropTypes.func.isRequired,
  selectedRehabilitateDate: PropTypes.string.isRequired,
  rehabilitateDate: PropTypes.func.isRequired,
  disableDateModalShow: PropTypes.bool.isRequired,
  selectedDisableDate: PropTypes.string.isRequired,
  handleDisableDateChange: PropTypes.func.isRequired,
  disableDate: PropTypes.func.isRequired,
  isFutureDateChoosed: PropTypes.bool.isRequired,
  rehabilitateDateModalShow: PropTypes.bool.isRequired,
  resetDisabledDates: PropTypes.func.isRequired,
  hasOriginalDatesChanged: PropTypes.bool.isRequired,
};
