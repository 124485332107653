import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';
import { ActivityChangingStatusType } from '../types';

interface UpdateActivityStatusInterface {
  activityId: string;
  ativo: boolean;
}

interface UpdateStatusModal {
  activityChangingStatus: ActivityChangingStatusType;
  setChangeStatusModalShow: Dispatch<SetStateAction<boolean>>;
  updateActivityStatus: ({activityId, ativo}: UpdateActivityStatusInterface) => void;
  changeStatusModalShow: boolean;
}

export default function UpdateStatusModal({
  activityChangingStatus,
  setChangeStatusModalShow,
  updateActivityStatus,
  changeStatusModalShow,
}: UpdateStatusModal) {
  return (
    <MyModal
      title={activityChangingStatus.status === 'Ativo' ? `Inativar atividade ${activityChangingStatus.name}` : `Reativar atividade ${activityChangingStatus.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={activityChangingStatus.status === 'Ativo' ? 'Inativar' : 'Reativar'}
      modalBody={(
        <div>
          {activityChangingStatus.status === 'Ativo' ? 'Tem certeza que deseja inativar a atividade' : 'Tem certeza que deseja reativar a atividade'}
          {' '}
          <strong>{activityChangingStatus.name}</strong>
          {' '}
          ?
        </div>
          )}
      onClose={() => setChangeStatusModalShow(false)}
      onAction={() => updateActivityStatus({
        activityId: activityChangingStatus.id,
        ativo: activityChangingStatus.status !== 'Ativo',
      })}
      show={changeStatusModalShow}
      type={activityChangingStatus.status === 'Ativo' ? 'suspendAction' : 'activateAction'}
    />
  );
}
