import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../services/activitiesService';
import { UseDashboardDataInterface } from '../interfaces';
import useApiCall from '../../../../hooks/useApiCall';

export default function useActivitiesQuantity({ setHasError }: UseDashboardDataInterface) {
  const [activitiesQty, setActivitiesQty] = useState(0);

  const { apiCall } = useApiCall();

  const getActivitiesQuantity = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de atividades (${apiResponse.error})`, {
            toastId: 'getActivitiesDashboardFail',
          });
          return;
        }
        const activitiesList = apiResponse.list;
        setActivitiesQty(activitiesList.length);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível carregar a lista de atividades',
    })
  }, [apiCall, setHasError]);

  return {
    activitiesQty,
    getActivitiesQuantity,
  };
}
