import { Dispatch, SetStateAction } from 'react';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { Header } from './styles';

interface ListHeaderInterface {
  doesListExists: boolean;
  hasError: boolean;
  filteredListLength: number;
  singularLabel: string;
  pluralLabel: string;
  downloadPageExcel: () => void;
  downloadFullExcel: () => void;
  appointmentsQuantity: number;
  warnings: string[];
  setWarningsModalShow: Dispatch<SetStateAction<boolean>>;
  setNewScheduleModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function ListHeader({
  doesListExists,
  hasError,
  filteredListLength,
  appointmentsQuantity,
  singularLabel,
  pluralLabel,
  downloadFullExcel,
  downloadPageExcel,
  warnings,
  setWarningsModalShow,
  setNewScheduleModalShow,
}: ListHeaderInterface) {
  const hasSomeWarning = warnings.length > 0;
  return (
    <OpacityAnimation delay={0.1}>
      <Header doesListExists={doesListExists} apiFetched={!hasError}>
        {(doesListExists) && (
          <strong>
              {filteredListLength}
              {' '}
              {filteredListLength === 1 ? singularLabel : pluralLabel}
              {' '}
              {`(${appointmentsQuantity} ${appointmentsQuantity == 1 ? 'agendamento' : 'agendamentos'})`}
          </strong>
        )}
        <div className="actionButtons">
          {doesListExists && (
            <button type="button" onClick={downloadPageExcel}>
              ⬇️ XLSX (data selecionada)
            </button>
          )}
          <button type="button" onClick={downloadFullExcel}>
            ⬇️ XLSX (todo histórico)
          </button>
          <button type="button" onClick={() => setNewScheduleModalShow(true)}>
            + Novo Agendamento
          </button>
          {hasSomeWarning && (
            <button type="button" onClick={() => setWarningsModalShow(true)}>
              ⚠️ Avisos ({warnings.length})
            </button>
          )}
        </div>
      </Header>
    </OpacityAnimation>
  );
}
