import { Dispatch, SetStateAction, useCallback } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import forumService from "../../../../services/forumService";

interface IUseEditPostModal {
  loadForum: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  handleCloseDeleteModal: () => void;
  postIdBeingDeleted: string;
}

export default function useDeletePostModal({
  loadForum,
  setIsLoading,
  handleCloseDeleteModal,
  postIdBeingDeleted,
}: IUseEditPostModal) {
  const { apiCall } = useApiCall();

  const deletePost = useCallback(async () => {
    await apiCall({
      apiToCall: forumService.deletePost,
      queryParams: { id: postIdBeingDeleted },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response) => {
        if (!response.success) {
          toast.error('Não foi possível remover o post. Por favor, tente novamente');
          return;
        }
        handleCloseDeleteModal();
        await loadForum();
        toast.success('Post removido com sucesso!');
      },
    })
  }, [apiCall, handleCloseDeleteModal, loadForum, postIdBeingDeleted, setIsLoading]);

  return {
    deletePost,
  }
}
