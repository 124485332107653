import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-size: 14px;
    opacity: 0.9;
    /* margin-bottom: 8px; */
    background: ${({ theme }) => theme.colors.blue[50]};
    color: ${({ theme }) => theme.colors.blue[900]};
    font-weight: bold;
    /* text-transform: uppercase; */
    padding: 8px;
    border-radius: 8px;
    margin-left: 8px;
    width: fit-content;
  }

  h1.green {
          background: ${({ theme }) => theme.colors.green[100]};
          color: ${({ theme }) => theme.colors.green[500]};
      }

      h1.orange {
          background: ${({ theme }) => theme.colors.orange[100]};
          color: ${({ theme }) => theme.colors.orange[500]};
        }

      h1.gray {
        background: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[900]};
      }
`;
