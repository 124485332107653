import { pathName } from '../pathName';
import HttpClient from './utils/HttpClient';

interface SubscribersServiceInterface {
  id?: string;
  token: string;
}

class SubscribersService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getSuscribersList = async ({
    token,
  }: SubscribersServiceInterface) => {
    return this.httpClient.get({
      path: `/subscribers`,
      token,
    });
  }

  getAllActiveSubscribers = async ({ token }: SubscribersServiceInterface) => {
    return this.httpClient.get({
      path: `/subscribers/active`,
      token,
    });
  }

  getSuscriberDetails = async ({
    id,
    token,
  }: SubscribersServiceInterface) => {
    return this.httpClient.get({
      path: `/subscribers/${id}`,
      token,
    });
  }

  getSubscribersCanceled = async ({
    token,
  }: SubscribersServiceInterface) => {
    return this.httpClient.get({
      path: '/subscribers-canceled',
      token,
    });
  }

  getChurn = async ({
    token,
  }: SubscribersServiceInterface) => {
    return this.httpClient.get({
      path: '/subscribers-churn',
      token,
    });
  }
}

export default new SubscribersService();
