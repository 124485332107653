import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 400px;
`;
