import { ChangeEvent, useCallback, useState } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import removeDuplicates from '../../../../utils/removeDuplicates';
import isValidBrDateString from '../../../../utils/isValidBrDateString';
import disabledDatesService from '../../../../services/disabledDatesService';
import formatDateToUsa from '../../../../utils/formatDateToUsa';

interface UseUploadFileInterface {
  loadDates: () => void;
}

interface CsvDataInterface {
  data: string;
}

export default function useUploadFile({ loadDates }: UseUploadFileInterface) {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [csvFileName, setCsvFileName] = useState('');
  const [csvParsed, setCsvParsed] = useState<string[]>([]);
  const [modalImportCsvShow, setModalImportCsvShow] = useState(false);
  const [csvAction, setCsvAction] = useState('');

  const { apiCall } = useApiCall();

  function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setCsvFileName(files[0].name);
      Papa.parse(files[0], {
        header: true,
        complete: (results: ParseResult<CsvDataInterface>) => {
          setCsvParsed(results.data.map((index) => (index.data)));
        },
      });
    }
  }

  const handleSubmitFile = useCallback(async () => {
    const csvParsedWithoutNullValues = csvParsed.filter((dates) => !!dates);
    const removedDuplicatedDates = removeDuplicates(csvParsedWithoutNullValues);
    const areAllDatesValid = removedDuplicatedDates.every((date) => isValidBrDateString(date));
    const formattedDates = removedDuplicatedDates.map((date) => formatDateToUsa(date));

    if (!areAllDatesValid) {
      toast.error('Todas as datas do arquivo devem estar no formato dd/MM/yyyy')
      return;
    }

    await apiCall({
      apiToCall: (
        csvAction === 'add'
          ? disabledDatesService.disableDates
          : disabledDatesService.deleteDisabledDates
      ),
      reqBody: JSON.stringify({
        dates: formattedDates,
      }),
      onStartLoad: () => {
        setModalImportCsvShow(false);
        setIsSubmittingFile(true);
      },
      onEndLoad: () => setIsSubmittingFile(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsSubmittingFile(false);
          toast.error(csvAction === 'add'
            ? 'Não foi possível desabilitar as datas'
            : 'Não foi possível reabilitar as datas');
          return;
        }
        loadDates();
        setIsSubmittingFile(false);
        toast.success(csvAction === 'add'
          ? 'Datas desabilitadas com sucesso'
          : 'Datas reabilitadas com sucesso');
      },
      catchMessage: (csvAction === 'add'
        ? 'Não foi possível desabilitar as datas'
        : 'Não foi possível reabilitar as datas'),
      catchAction: () => setIsSubmittingFile(false),
    });
  }, [csvParsed, apiCall, csvAction, loadDates]);

  return {
    isSubmittingFile,
    csvFileName,
    modalImportCsvShow,
    csvAction,
    setCsvAction,
    handleFileUpload,
    handleSubmitFile,
    setModalImportCsvShow,
  };
}
