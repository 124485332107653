import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../services/activitiesService';
import { ActivityChangingStatusType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseUpdateActivityStatusInterface {
  loadActivities: () => void;
}

interface UpdateActivityStatusInterface {
  activityId: string;
  ativo: boolean;
}

interface HandleOpenChangeStatusModalInterface {
  name: string;
  id: string;
  status: string;
}

export default function useUpdateActivityStatus({
  loadActivities,
}: UseUpdateActivityStatusInterface) {
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [activityChangingStatus, setActivityChangingStatus] = useState({} as ActivityChangingStatusType);

  const { apiCall } = useApiCall();

  const updateActivityStatus = useCallback(async ({ activityId, ativo }: UpdateActivityStatusInterface) => {
    await apiCall({
      apiToCall: activitiesService.updateActivityStatus,
      queryParams: { id: activityId, ativo },
      onStartLoad: () => setIsUpdatingStatus(true),
      onEndLoad: () => {
        setChangeStatusModalShow(false);
        setIsUpdatingStatus(false);
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível alterar o status da atividade. Por favor, tente novamente', {
            toastId: 'updateActivityFail',
          });
          return;
        }
        toast.success(`A atividade foi ${!ativo ? 'inativada' : 'reativada'} com sucesso`, {
          toastId: 'updateActivitySuccess',
        });
        setChangeStatusModalShow(false);
        loadActivities();
      },
      catchMessage: 'Não foi possível alterar o status da atividade. Por favor, tente novamente',
    })
  }, [apiCall, loadActivities]);

  function handleOpenChangeStatusModal({ name, id, status }: HandleOpenChangeStatusModalInterface) {
    setChangeStatusModalShow(true);
    setActivityChangingStatus({
      name,
      id,
      status,
    });
  }

  return {
    isUpdatingStatus,
    changeStatusModalShow,
    setChangeStatusModalShow,
    activityChangingStatus,
    updateActivityStatus,
    handleOpenChangeStatusModal,
  };
}
