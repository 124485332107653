/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import { useQuery } from '../../hooks/useQuery';

import ActivityForm from '../../components/ActivityForm';

export default function EditActivity() {
  const query = useQuery();
  const activeNavItem = query.has('return') ? query.get('return')!.charAt(0).toUpperCase() + query.get('return')?.slice(1) : 'Activities';

  return (
    <>
      <Sidebar active={activeNavItem} />
      <Transitions>
        <ActivityForm edit />
      </Transitions>
    </>
  );
}
