import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import companiesService from '../../../../services/companiesService';
import partnersService from '../../../../services/partnersService';
import { CompaniesListFromApiType, CompaniesOptionsType, PartnerListFromApiType, PartnersOptionsType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useLoadFinancial() {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<CompaniesOptionsType>([]);
  const [partnersOptions, setPartnersOptions] = useState<PartnersOptionsType>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { apiCall } = useApiCall();

  const getCompaniesList = useCallback(async () => {
    await apiCall({
      apiToCall: companiesService.getCompaniesList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error(`Não foi possível carregar a lista de empresas (${apiResponse.erro})`, {
            toastId: 'getCompaniesFail',
          });
          setDoesListApiHasError(true);
          return;
        }
        const companiesList: CompaniesListFromApiType = apiResponse.list;
        const mappedCompaniesList = companiesList?.map((company) => ({
          value: company.id,
          label: company.nome_fantasia || company.nome,
        }));
        setCompaniesOptions(mappedCompaniesList);
        setDoesListApiHasError(false);
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar a lista das empresas'
    })
  }, [apiCall]);

  const getPartnersList = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getResumedPartnersList,
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.list) {
          toast.error(`Não foi possível carregar a lista de parceiros (${apiResponse.erro})`, {
            toastId: 'getPartnersFail',
          });
          setDoesListApiHasError(true);
          return;
        }
        const partnersList: PartnerListFromApiType = apiResponse.list;
        const filteredPartnersList = partnersList.filter((partner) => (!!partner.id));
        const mappedPartnersList = filteredPartnersList.map((partner) => ({
          value: partner.id, label: partner.nome_fantasia,
        }));
        setPartnersOptions(mappedPartnersList);
        setDoesListApiHasError(false);
      },
      catchAction: () => setDoesListApiHasError(true),
      catchMessage: 'Não foi possível carregar a lista de parceiros',
    })
  }, [apiCall]);

  const loadFinancial = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      await Promise.all([
        getCompaniesList(),
        getPartnersList(),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar a página (${error})`, {
        toastId: 'loadFinancialFail',
      });
    } finally {
      setIsLoadLoading(false);
    }
  }, [getCompaniesList, getPartnersList]);

  useEffect(() => {
    loadFinancial();
  }, [loadFinancial]);

  return {
    isLoadLoading,
    companiesOptions,
    partnersOptions,
    doesListApiHasError,
  };
}
