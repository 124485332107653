/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
import {
  VictoryBar,
  VictoryChart,
} from 'victory';
import NoData from '../../../components/NoData';
import { Card } from '../styles';
import { UsersRegionsType } from '../types';

interface UsersRegionsCardInterface {
  regionsData: UsersRegionsType;
}

export default function UsersRegionsCard({
  regionsData,
}: UsersRegionsCardInterface) {
  const resumedRegionsData: { [key: string]: number } = {};
  let count = 0;
  let others = 0;
  for (const [key, value] of Object.entries(regionsData)) {
    if (count < 4) {
      resumedRegionsData[key] = Number(value);
    } else {
      others += Number(parseFloat(String(value)));
    }
    count++;
  }
  resumedRegionsData.Outros = others;

  const regionsDataToArray = resumedRegionsData && Object.entries(resumedRegionsData);

  const domainPaddingValue = 108 / regionsDataToArray.length;

  return (
    <Card>
      <div className="card-title">
        <div>Cidades com maior utilização KiddlePass</div>
      </div>

      {(!!regionsData && regionsDataToArray.length > 0) && (
        <div className="card-main-graph">
          <VictoryChart
            height={200}
            width={400}
            padding={{
              top: 25, bottom: 30, left: 30, right: 30,
            }}
            domainPadding={domainPaddingValue}
            maxDomain={{ y: 100 }}
          >
            <VictoryBar
              style={{
                data: { fill: '#4aafff' },
                parent: { border: 'none' },
                labels: {
                  fontSize: 15,
                },
              }}
              categories={
                { x: regionsDataToArray.map((data) => data[0]) }
              }
              data={regionsDataToArray.map((data) => (
                { x: `${data[0]}`, y: data[1], label: `${data[1].toFixed(1)} %` }
              ))}
              animate={{
                duration: 3000,
                onLoad: { duration: 3000 },
              }}
            />
          </VictoryChart>
        </div>
      )}

      {(!regionsData || regionsDataToArray.length === 0) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre os estados dos colaboradores
          </>
                  )}
      />
      )}
    </Card>
  );
}
