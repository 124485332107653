/* eslint-disable no-nested-ternary */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Select from 'react-select';

import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import useThemeContext from '../../../../contexts/theme';
import { Container, Group, RadioButtonsFilterGroup } from './styles';

import { Dispatch, SetStateAction } from 'react';
import filter from '../../../../assets/images/icons/filter.svg';
import { CategoriesType, PartnerType, SubcategoryType } from '../../../../components/ActivityForm/types/FormTypes';

interface HandlePartnerTypeChangeInterface {
  value: string;
  label: string;
}

interface FiltersInterface {
  categoriesList: CategoriesType[];
  selectedCategory: CategoriesType;
  subcategoriesOptions: SubcategoryType[];
  selectedSubcategory: SubcategoryType;
  partnersList: PartnerType[];
  selectedPartner: PartnerType;
  selectedStatus: string;
  handleStatusChange: (status: string) => void;
  selectedAgeRange: number[];
  setSelectedAgeRange: Dispatch<SetStateAction<number[]>>;
  handlePartnerChange: ({ value, label }: HandlePartnerTypeChangeInterface) => void;
  handleCategoryChange: ({ value, label }: CategoriesType) => void;
  handleSubcategoryChange: ({ value, label}: SubcategoryType) => void;
  handleAgeSelection: (ageRange: number[]) => void;
  handleKindChange: (kind: 'all' | 'Aula ao vivo' | 'Aula em vídeo') => void;
  selectedKind: 'all' | 'Aula ao vivo' | 'Aula em vídeo';
}

export default function Filters({
  categoriesList,
  selectedCategory,
  subcategoriesOptions,
  selectedSubcategory,
  partnersList,
  selectedPartner,
  selectedStatus,
  handleStatusChange,
  selectedAgeRange,
  setSelectedAgeRange,
  handlePartnerChange,
  handleCategoryChange,
  handleSubcategoryChange,
  handleAgeSelection,
  handleKindChange,
  selectedKind,
}: FiltersInterface) {
  const { selectedTheme } = useThemeContext();
  const partnersOptions = partnersList;
  const categoriesOptions = categoriesList;
  const subcategories = subcategoriesOptions;

  return (
    <Container>
      <header>
        <img src={filter} alt="filter" className="primaryColor" />
        <div>Pesquise através dos filtros abaixo</div>
      </header>

      <div>
        <Select
          value={{ value: selectedPartner?.value, label: selectedPartner?.label === 'Todos' ? 'Filtrar por parceiro' : selectedPartner?.label }}
          options={partnersOptions}
          onChange={(partner) => handlePartnerChange(partner as HandlePartnerTypeChangeInterface)}
          placeholder="Filtrar por parceiro"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedCategory?.value, label: selectedCategory?.label === 'Todas' ? 'Filtrar por categoria' : selectedCategory?.label }}
          options={categoriesOptions}
          onChange={(category) => handleCategoryChange(category as CategoriesType)}
          placeholder="Filtrar por categoria"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />
        <Select
          value={{ value: selectedSubcategory?.value, label: selectedSubcategory?.label === 'Todas' ? 'Filtrar por subcategoria' : selectedSubcategory?.label }}
          options={subcategories}
          onChange={(subcategory) => handleSubcategoryChange(subcategory as SubcategoryType)}
          isDisabled={selectedCategory?.value === '' && selectedCategory?.label !== 'Todas'}
          placeholder="Filtrar por subcategoria"
          styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
          // menuPortalTarget={document.body}
          classNamePrefix="react-select"
          className="react-select-container-as-filter"
        />

        <Group>
          <header>
            Idade:
            {' '}
            {selectedAgeRange[1] === 13 ? 'Todas' : (
              selectedAgeRange[1] === 1
                ? `${selectedAgeRange[1]} ano`
                : `${selectedAgeRange[1]} anos`
            )}
          </header>
          <RangeSlider
            min={0}
            max={13}
            value={selectedAgeRange}
            onThumbDragEnd={() => handleAgeSelection(selectedAgeRange)}
            onInput={(evt: number[]) => setSelectedAgeRange(evt)}
            className="single-thumb"
            thumbsDisabled={[true, false]}
            rangeSlideDisabled
          />
        </Group>

      <RadioButtonsFilterGroup>
        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleStatusChange('')}
            selected={selectedStatus === ''}
          >
            Todas
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleStatusChange('Ativo')}
            selected={selectedStatus === 'Ativo'}
          >
            Ativas
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleStatusChange('Inativo')}
            selected={selectedStatus === 'Inativo'}
          >
            Inativas
          </FilterRadioButton>
        </FilterRadioButtonsContainer>

        <FilterRadioButtonsContainer>
          <FilterRadioButton
            onClick={() => handleKindChange('all')}
            selected={selectedKind === 'all'}
          >
            Todas
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleKindChange('Aula ao vivo')}
            selected={selectedKind === 'Aula ao vivo'}
          >
            Grupo
          </FilterRadioButton>
          <FilterRadioButton
            onClick={() => handleKindChange('Aula em vídeo')}
            selected={selectedKind === 'Aula em vídeo'}
          >
            Gravadas
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
      </RadioButtonsFilterGroup>
      </div>
    </Container>
  );
}
