import dateWith0 from '../../../../utils/dateWith0';
import { SubscriberDetailsType } from '../../types';
import { Card } from './styles';

interface DetailsCardInterface {
  details: SubscriberDetailsType;
}

export default function DetailsCard({ details }: DetailsCardInterface) {
  const smallTagColorByStatusLiterals: { [key: string]: string } = {
    Ativo: 'green',
    Cancelado: 'orange',
    Teste: '',
    Suspenso: 'gray',
  };
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{details.name}</strong>
          <small className={smallTagColorByStatusLiterals[details.status]}>
            {details.status}
          </small>
        </div>
        <span>
          {details.lastAppointmentDate ? 'Último agendamento realizado em ' : 'Nunca realizou um agendamento'}
          {details.lastAppointmentDate && (
            <>{dateWith0(details.lastAppointmentDate.split(' ')[0])}</>
          )}
        </span>
        <span>
          {details.age}
          {' '}
          anos, gênero
          {' '}
          {details.gender}
          ,
          {' '}
          {details.cityUf}
        </span>
        <span>
          CPF:
          {' '}
          {details.cpf}
        </span>
        <span>
          Celular:
          {' '}
          {details.cellphone || 'Não informado'}
        </span>
        <span>
          E-mail:
          {' '}
          {details.email || 'Não informado'}
        </span>
        <span>
          Empresa:
          {' '}
          {details.company || 'B2C'}
        </span>
        <span>
          Cupom:
          {' '}
          {details.cupom ?? 'Não utilizado'}
        </span>
        <span>
          Identificador na empresa:
          {' '}
          {details.collaboratorId ?? 'Sem identificador na empresa'}
        </span>
        <span>
          Método de pagamento:
          {' '}
          {details.paymentMethod || 'Não informado'}
        </span>
        <span>
          Registrado em
          {' '}
          {dateWith0(details.registerDate)}
          {' '}
          e ativado em
          {' '}
          {dateWith0(details.activateDate)}
        </span>
      </div>
    </Card>
  );
}
