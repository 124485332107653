import { Col, Container, Row } from 'react-bootstrap';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { MappedPushType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: MappedPushType[];
  onOpenDeleteModal: (pushBeingDeleted: MappedPushType) => void;
}

export default function List({
  filteredList,
  onOpenDeleteModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((push) => (
          <Col key={push.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes">
                <div className="info">
                  <div className="card-title">
                    <strong>{push.message}</strong>
                  </div>
                </div>
                <div className="actions">
                <button
                  type="button"
                  onClick={() => onOpenDeleteModal(push)}
                >
                  <img src={trash} alt="" title={`Cancelar envio`} />
                </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
