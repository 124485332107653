import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import partnersService from '../../../../services/partnersService';
import { PartnerBeingDeletedType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseDeletePartnerInterface {
  setSearchTerm: Dispatch<SetStateAction<string>>;
  loadPartners: () => void;
}

export default function useDeletePartner({
  setSearchTerm,
  loadPartners,
}: UseDeletePartnerInterface) {
  const [isDeletingPartner, setIsDeletingPartner] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [partnerBeingDeleted, setPartnerBeingDeleted] = useState<PartnerBeingDeletedType>({} as PartnerBeingDeletedType);

  const { apiCall } = useApiCall();

  function handleOpenDeleteModal({ name, id }: PartnerBeingDeletedType) {
    setDeleteModalShow(true);
    setPartnerBeingDeleted({
      name,
      id,
    });
  }

  const deletePartner = useCallback(async (id: string) => {
    await apiCall({
      apiToCall: partnersService.deletePartner,
      queryParams: { partnerId: id },
      onStartLoad: () => setIsDeletingPartner(true),
      onEndLoad: () => setIsDeletingPartner(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível remover o parceiro. Por favor, tente novamente');
          return;
        }
        toast.success('Parceiro removido com sucesso!');
        setDeleteModalShow(false);
        setSearchTerm('');
        loadPartners();
      },
      catchMessage: 'Não foi possível remover o parceiro. Por favor, tente novamente'
    })
  }, [apiCall, loadPartners, setSearchTerm]);

  return {
    isDeletingPartner,
    deleteModalShow,
    setDeleteModalShow,
    partnerBeingDeleted,
    handleOpenDeleteModal,
    deletePartner,
  };
}
