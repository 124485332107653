import useAssociatedCompanies from './functions/useAssociatedCompanies';
import useCupons from './functions/useCupons';
import useFormSubmit from './functions/useFormSubmit';
import useInputChanges from './functions/useInputChanges';
import useLoadForm from './functions/useLoadForm';

export default function useCompaniesForm({ edit }: { edit: boolean }) {
  const {
    associatedCompaniesModalShow,
    setAssociatedCompaniesModalShow,
    associatedCompanies,
    setAssociatedCompanies,
    handleFileUpload,
    addOneAssociatedCompany,
    removeAssociatedCompany,
    companyBeingAdded,
    setCompanyBeingAdded,
  } = useAssociatedCompanies();

  const {
    setCuponsModalShow,
    cuponsModalShow,
    cupons,
    addCupom,
    removeCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    handleCupomNameChange,
    setCupomPlanKind,
    getErrorMessageByFieldNameCupomForm,
    isCupomFormValid,
    setCupons,
    setCupomName,
    setCupomPlanTrial,
    setCupomPlanValue,
    isGettingCuponsOptions,
    cuponsOptions,
    associatedCuponsIds,
    setAssociatedCuponsIds,
    expires,
    setExpires,
    handleExpirationDateChange,
    expirationDate,
    willLimitUsage,
    setWillLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  } = useCupons();

  const {
    getErrorMessageByFieldName,
    loginEmail,
    setLoginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    employeesQuantity,
    handleEmployeesQuantityChange,
    elegiblesQuantity,
    handleEligibleQuantityChange,
    adherenceGoal,
    handleAderenceGoalChange,
    resetFields,
    errors,
    setEmployeesQuantity,
    setElegiblesQuantity,
    setAdherenceGoal,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    isGettingCepInfo,
    monthlyFee,
    handleMonthlyFeeChange,
    contractDate,
    handleContractDateChange,
    startDate,
    handleStartDateChange,
    setStartDate,
    setContractDate,
    setMonthlyFee,
    handleKindOfCompanyChange,
    kindOfCompany,
    setKindOfCompany,
    setAuthenticationType,
    authenticationType,
    setIsReadOnly,
    isReadOnly,
    setPlanKind,
    planValue,
    handlePlanValueChange,
    planKind,
    planTrial,
    handlePlanTrialChange,
    setPlanValue,
    setPlanTrial,
    planId,
    setPlanId,
    setShowAtCompaniesListAppRegister,
    showAtCompaniesListAppRegister,
    setViewByAssociatedCompanies,
    viewByAssociatedCompanies,
  } = useInputChanges({
    setAssociatedCompanies,
    setCupons,
    setCupomName,
    setCupomPlanTrial,
    setCupomPlanValue,
    setCupomPlanKind,
  });

  const {
    isLoadLoading,
    setIsLoadLoading,
    pixOptions,
    banksOptions,
  } = useLoadForm({
    setPlanId,
    setEmployeesQuantity,
    setElegiblesQuantity,
    setAdherenceGoal,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    setStartDate,
    setContractDate,
    setMonthlyFee,
    setAssociatedCompanies,
    setAuthenticationType,
    setKindOfCompany,
    setIsReadOnly,
    setLoginEmail,
    setCupons,
    setPlanKind,
    setPlanValue,
    setPlanTrial,
    setShowAtCompaniesListAppRegister,
    setViewByAssociatedCompanies,
  });

  const {
    isSubmitting,
    isFormValid,
    createCompany,
    updateCompany,
    requiredFieldsErrorsLabel,
  } = useFormSubmit({
    fantasyName,
    corporateName,
    cnpj,
    contactName,
    contactCellphone,
    contactEmail,
    elegiblesQuantity,
    employeesQuantity,
    adherenceGoal,
    cep,
    streetName,
    district,
    city,
    uf,
    number,
    complement,
    selectedBank,
    agency,
    account,
    cardholder,
    cnpjBank,
    pixType,
    cnpjPix,
    cpfPix,
    cellphonePix,
    emailPix,
    randomPix,
    monthlyFee,
    contractDate,
    startDate,
    kindOfCompany,
    authenticationType,
    isReadOnly,
    associatedCompanies,
    loginEmail,
    password,
    resetFields,
    errors,
    cupons,
    planKind,
    planTrial,
    planValue,
    plan_id: planId,
    viewByAssociatedCompanies,
    showAtCompaniesListAppRegister,
    edit,
    associatedCuponsIds,
  });

  const isLoading = isLoadLoading || isSubmitting || isGettingCuponsOptions;

  return {
    isLoading,
    setIsLoadLoading,
    getErrorMessageByFieldName,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    isGettingCepInfo,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    pixOptions,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    banksOptions,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    isFormValid,
    employeesQuantity,
    handleEmployeesQuantityChange,
    elegiblesQuantity,
    handleEligibleQuantityChange,
    adherenceGoal,
    handleAderenceGoalChange,
    createCompany,
    updateCompany,
    monthlyFee,
    handleMonthlyFeeChange,
    contractDate,
    handleContractDateChange,
    startDate,
    handleStartDateChange,
    handleKindOfCompanyChange,
    kindOfCompany,
    associatedCompaniesModalShow,
    setAssociatedCompaniesModalShow,
    associatedCompanies,
    setAssociatedCompanies,
    handleFileUpload,
    addOneAssociatedCompany,
    removeAssociatedCompany,
    authenticationType,
    setAuthenticationType,
    isReadOnly,
    setIsReadOnly,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    companyBeingAdded,
    setCompanyBeingAdded,
    setCuponsModalShow,
    setPlanKind,
    planValue,
    handlePlanValueChange,
    planKind,
    planTrial,
    handlePlanTrialChange,
    cuponsModalShow,
    cupons,
    addCupom,
    removeCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    handleCupomNameChange,
    setCupomPlanKind,
    getErrorMessageByFieldNameCupomForm,
    isCupomFormValid,
    setShowAtCompaniesListAppRegister,
    showAtCompaniesListAppRegister,
    setViewByAssociatedCompanies,
    viewByAssociatedCompanies,
    requiredFieldsErrorsLabel,
    cuponsOptions,
    associatedCuponsIds,
    setAssociatedCuponsIds,
    expires,
    setExpires,
    handleExpirationDateChange,
    expirationDate,
    willLimitUsage,
    setWillLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  };
}
