/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import companiesService from '../../../../../services/companiesService';
import { UserBeingDeletedInterface } from '../../interfaces/UserBeingDeletedInterface';
import useApiCall from '../../../../../hooks/useApiCall';
import { useNavigate, useParams } from 'react-router-dom';

interface UseDeleteCollaboratorInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
}

export default function useDeleteCollaborator({
  loadUsers,
  currentPage,
}: UseDeleteCollaboratorInterface) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [userBeingDeleted, setUserBeingDeleted] = useState<UserBeingDeletedInterface>({});

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();

  const deleteCollaborator = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await apiCall({
      apiToCall: companiesService.deleteUser,
      queryParams: { companyId },
      reqBody: JSON.stringify({
        list: [userBeingDeleted.id],
      }),
      onStartLoad: () => {
        setModalDeleteShow(false);
        setIsDeleting(true);
      },
      onEndLoad: () => setIsDeleting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível excluir o colaborador. Por favor, tente novamente');
          setIsDeleting(false);
          return;
        }
        toast.success('Colaborador excluído com sucesso!');
        loadUsers(currentPage);
      },
      catchMessage: 'Não foi possível excluir o colaborador. Por favor, tente novamente'
    });
  }, [companyId, apiCall, userBeingDeleted.id, navigate, loadUsers, currentPage]);

  return {
    isDeleting,
    modalDeleteShow,
    setModalDeleteShow,
    userBeingDeleted,
    setUserBeingDeleted,
    deleteCollaborator,
  };
}
