import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiCall from '../../../../../hooks/useApiCall';
import companiesService from '../../../../../services/companiesService';

type CollaboratorsInfoType = {
  active?: number | string,
  UF?: { [key: string]: number },
  average?: number | string,
}

export default function useCollaboratorsInfo() {
  const [collaboratorsInfo, setCollaboratorsInfo] = useState<CollaboratorsInfoType>({});
  const [hasCollaboratorsInfoError, setHasCollaboratorsInfoError] = useState(false);

  const { companyId } = useParams();
  const { apiCall } = useApiCall();

  const getCollaboratorsInfo = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: companiesService.getCollaboratorsInfo,
      queryParams: { companyId, associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setCollaboratorsInfo(apiResponse);
        setHasCollaboratorsInfoError(false);
      },
      catchAction: () => setHasCollaboratorsInfoError(true),
      catchMessage: 'Não foi possível carregar a informação dos colaboradores',
    })
  }, [apiCall, companyId]);

  return {
    collaboratorsInfo,
    hasCollaboratorsInfoError,
    getCollaboratorsInfo,
  };
}
