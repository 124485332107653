import { useCallback, useEffect, useState } from "react"
import useApiCall from "../../../../hooks/useApiCall";
import disabledDatesService from "../../../../services/disabledDatesService";
import { toast } from "react-toastify";
import formatDate from "../../../../utils/formatDate";

export default function useLoadDates() {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [fullDates, setFullDates] = useState<string[]>([]);
  const [filteredDates, setFilteredDates] = useState<string[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { apiCall } = useApiCall();

  const loadDates = useCallback(async () => {
    await apiCall({
      apiToCall: disabledDatesService.getDates,
      onStartLoad: () => setIsLoadLoading(true),
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (response: { dates: { id: string, date: string }[], success: boolean }) => {
        if (!response.success) {
          toast.error(`Não foi possível carregar a lista de datas desabilitadas`);
          setDoesListApiHasError(true);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const dates = response.dates.map((date) => formatDate(date.date)!);
        setFullDates(dates);
        setFilteredDates(dates);
        setDoesListApiHasError(false);
      }
    })
  }, [apiCall]);

  function handleTryAgain() {
    loadDates()
  }

  useEffect(() => {
    loadDates()
  }, [loadDates])

  return {
    fullDates,
    filteredDates,
    isLoadLoading,
    doesListApiHasError,
    setFilteredDates,
    handleTryAgain,
    loadDates,
  }
}
