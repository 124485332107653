import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import subscribersService from '../../../../services/subscribersService';
import { UseDashboardDataInterface } from '../interfaces';
import { ChurnType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useChurn({ setHasError }: UseDashboardDataInterface) {
  const [churnData, setChurnData] = useState<ChurnType>({} as ChurnType);

  const { apiCall } = useApiCall();

  const getChurn = useCallback(async () => {
    await apiCall({
      apiToCall: subscribersService.getChurn,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar o churn', {
            toastId: 'getChurnDashboardFail',
          });
          return;
        }
        const churn = apiResponse;
        setChurnData(churn);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar o churn',
    })
  }, [apiCall, setHasError]);

  return {
    churnData,
    getChurn,
  };
}
