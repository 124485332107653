import { Link } from 'react-router-dom';
import { ActiveItem, MenuItem } from '../styles';

import chartPie from '../../../assets/images/icons/chartPie.svg';
import comments from '../../../assets/images/icons/comments.svg';
import company from '../../../assets/images/icons/company.svg';
import cupom from '../../../assets/images/icons/cupom.svg';
import lock from '../../../assets/images/icons/lock.svg';
import subscribers from '../../../assets/images/icons/subscribers.svg';
import user from '../../../assets/images/icons/user.svg';

import { SidebarInterface } from '../interface';

export default function ComercialSidebar({ active }: SidebarInterface) {

  return (
    <>
      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

      {active === 'ForumInternal'
        ? (
          <ActiveItem>
            <Link to="/forumInternal">
              <MenuItem>
                <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
                Forum interno
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forumInternal">
            <MenuItem>
              <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
              Forum interno
            </MenuItem>
          </Link>
        )}

      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}

      {active === 'Subscribers'
        ? (
          <ActiveItem>
            <Link to="/subscribers">
              <MenuItem>
                <img src={subscribers} alt="subscribers" />
                Assinantes
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/subscribers">
            <MenuItem>
              <img src={subscribers} alt="subscribers" />
              Assinantes
            </MenuItem>
          </Link>
        )}

      {active === 'Cupons'
        ? (
          <ActiveItem>
            <Link to="/cupons">
              <MenuItem>
                <img src={cupom} alt="cupom" />
                Cupons
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/cupons">
            <MenuItem>
              <img src={cupom} alt="cupom" />
              Cupons
            </MenuItem>
          </Link>
        )}

      {active === 'Companies'
        ? (
          <ActiveItem>
            <Link to="/companies">
              <MenuItem>
                <img src={company} alt="schedule" />
                Empresas (B2B/Distribuidor)
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/companies">
            <MenuItem>
              <img src={company} alt="schedule" />
              Empresas (B2B/Distribuidor)
            </MenuItem>
          </Link>
        )}

      {active === 'Users'
        ? (
          <ActiveItem>
            <Link to="/users">
              <MenuItem>
                <img src={user} alt="user" />
                Usuários
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/users">
            <MenuItem>
              <img src={user} alt="user" />
              Usuários
            </MenuItem>
          </Link>
        )}
    </>
  );
}
