/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Col, Row } from 'react-bootstrap';
import Header from './components/Header';

import DueDateCard from './components/DueDateCard';
import PaymentStatusCard from './components/PaymentStatusCard';
import NfDateCard from './components/NfDateCard';
import StartDateCard from './components/StartDateCard';
import AgreedAmounthCard from './components/AgreedAmounthCard';
import useCompanyView from './useCompanyView';
import Loader from '../../../../components/Loader';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import ChangeDateModal from './components/ChangeDateModal';
import NfUploadModal from './components/NfUploadModal';
import formatDate from '../../../../utils/formatDate';
import { CompanyDetailsFromApi, CurrrentMonthBeingShowType } from '../../types';

interface PartnerViewInterface {
  companyData: CompanyDetailsFromApi;
  reloadCompanyData: () => void;
  agreedAmounth: string;
  startDate: string;
}

export default function CompanyView({
  companyData,
  reloadCompanyData,
  startDate,
  agreedAmounth,
}: PartnerViewInterface) {
  const {
    currentYear,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    nfUploadModalShow,
    setNfUploadModalShow,
    handleNfFileSubmit,
    handleNfFileUpload,
    nfFileName,
    isLoading,
  } = useCompanyView({
    reloadCompanyData,
    companyId: companyData.company_id,
  });

  const dueDate = `08/${String(currentMonthBeingShow).length === 2 ? currentMonthBeingShow : `0${currentMonthBeingShow}`}/${currentYearBeingShow}`;

  const paymentInfoAtSelectedDate = companyData.comps.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const paymentDateInJsFormat = (paymentInfoAtSelectedDate
    ? new Date(paymentInfoAtSelectedDate?.data_envio) : null);
  const paymentDate = paymentDateInJsFormat?.toLocaleDateString('pt-BR');

  const nfInfoAtSelectedDate = companyData.nfs.find((prt) => {
    const month = Number(prt.data.split('_')[0]);
    const year = Number(prt.data.split('_')[1]);

    return (month === currentMonthBeingShow && year === currentYearBeingShow);
  });
  const nfDateInJsFormat = (nfInfoAtSelectedDate
    ? new Date(nfInfoAtSelectedDate?.data_envio) : null);
  const nfDate = nfDateInJsFormat?.toLocaleDateString('pt-BR');

  return (
    <>
      <Loader isLoading={isLoading} />
      <Header
        currentMonthBeingShow={currentMonthBeingShow as CurrrentMonthBeingShowType}
        currentYearBeingShow={currentYearBeingShow}
        handleDateModalShow={setDateModalShow}
        isNfUploaded={!!nfInfoAtSelectedDate}
        nfDownloadLink={nfInfoAtSelectedDate?.link}
        isPaymentVoucherUploaded={!!paymentInfoAtSelectedDate}
        paymentVoucherLink={paymentInfoAtSelectedDate?.link}
        handleNfModalShow={setNfUploadModalShow}
      />

      <Row xs={1} md={2} lg={2}>
        <Col>
          <OpacityAnimation delay={0.15}>
            <StartDateCard
              startDate={formatDate(startDate)}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.3}>
            <AgreedAmounthCard
              agreedAmounth={agreedAmounth}
            />
          </OpacityAnimation>
        </Col>
      </Row>

      <Row xs={1} md={2} lg={3}>
        <Col>
          <OpacityAnimation delay={0.45}>
            <DueDateCard
              dueDate={dueDate}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.6}>
            <PaymentStatusCard
              paymentDate={paymentDate}
            />
          </OpacityAnimation>
        </Col>
        <Col>
          <OpacityAnimation delay={0.75}>
            <NfDateCard
              nfDate={nfDate}
            />
          </OpacityAnimation>
        </Col>
      </Row>

      {startDate && (
        <ChangeDateModal
          currentYear={currentYear}
          dateModalShow={dateModalShow}
          setSelectedYear={setSelectedYear}
          setSelectedMonth={setSelectedMonth}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          currentMonth={currentMonth}
          setCurrentMonthBeingShow={setCurrentMonthBeingShow}
          setCurrentYearBeingShow={setCurrentYearBeingShow}
          setDateModalShow={setDateModalShow}
          startDate={formatDate(startDate)!}
        />
      )}

      <NfUploadModal
        nfUploadModalShow={nfUploadModalShow}
        nfFileName={nfFileName}
        handleNfFileUpload={handleNfFileUpload}
        handleNfFileSubmit={handleNfFileSubmit}
        setNfUploadModalShow={setNfUploadModalShow}
      />

    </>
  );
}
