import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import { Container, SelectContainer } from './styles';
import { Dispatch, SetStateAction } from 'react';
import { CompaniesOptionsType, PartnersOptionsType, SelectedCompanyType, SelectedPartnerType } from '../../types';

interface ViewKindInterface {
  viewKind: string;
  setViewKind: Dispatch<SetStateAction<string>>;
  companiesOptions: CompaniesOptionsType;
  partnersOptions: PartnersOptionsType;
  handleSelectedCompanyChange: (company: SelectedCompanyType) => void;
  handleSelectedPartnerChange: (partner: SelectedPartnerType) => void;
  selectedCompany: SelectedCompanyType;
  selectedPartner: SelectedPartnerType;
}

export default function ViewKind({
  viewKind,
  setViewKind,
  companiesOptions,
  partnersOptions,
  handleSelectedCompanyChange,
  handleSelectedPartnerChange,
  selectedCompany,
  selectedPartner,
}: ViewKindInterface) {
  return (
    <Container>
      <FilterRadioButtonsContainer>
        <FilterRadioButton selected={viewKind === 'company'} onClick={() => setViewKind('company')}>
          Empresas
        </FilterRadioButton>
        <FilterRadioButton selected={viewKind === 'partner'} onClick={() => setViewKind('partner')}>
          Parceiros
        </FilterRadioButton>
      </FilterRadioButtonsContainer>

      {viewKind === 'company' && (
        <SelectContainer>
          <Select
            value={
              {
                value: selectedCompany.value || '',
                label: selectedCompany.label || 'Selecione uma empresa',
              }
            }
            options={companiesOptions}
            onChange={(opt) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              handleSelectedCompanyChange({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </SelectContainer>
      )}

      {viewKind === 'partner' && (
        <SelectContainer>
          <Select
            value={
            {
              value: selectedPartner.value || '',
              label: selectedPartner.label || 'Selecione um parceiro',
            }
          }
            options={partnersOptions}
            onChange={(opt) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              handleSelectedPartnerChange({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </SelectContainer>
      )}
    </Container>
  );
}
