import PropTypes from 'prop-types';
import { FilterRadioButtonsContainer } from '../../FilterRadioButtonsContainer';
import FilterRadioButton from '../../FilterRadioButtons';
import { Dispatch, SetStateAction } from 'react';

interface KindOfActivityInterface {
  setKindOfActivity: Dispatch<SetStateAction<string>>;
  kindOfActivity: string;
}

export default function KindOfActivity({
  setKindOfActivity,
  kindOfActivity
}: KindOfActivityInterface) {
  return (
    <FilterRadioButtonsContainer>
      <FilterRadioButton
        onClick={() => setKindOfActivity('online')}
        selected={kindOfActivity === 'online'}
      >
        Atividade ao vivo
      </FilterRadioButton>
      <FilterRadioButton
        onClick={() => setKindOfActivity('video')}
        selected={kindOfActivity === 'video'}
      >
        Atividade gravada (vídeo)
      </FilterRadioButton>
    </FilterRadioButtonsContainer>
  );
}

KindOfActivity.propTypes = {
  setKindOfActivity: PropTypes.func.isRequired,
  kindOfActivity: PropTypes.string.isRequired,
};
