import { Col, Container, Row } from 'react-bootstrap';

import Loader from '../../../../components/Loader';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import PageHeader from '../../../../components/PageHeader';

import GeneralDataCard from './components/GeneralDataCard';
import KindOfPartner from './components/KindOfPartner';

import Button from '../../../../components/Button';
import { ButtonContainer } from '../../../../components/Form';
import renderArray from '../../../../utils/renderArrayWithComa';
import AddressDataCard from './components/AddressDataCard';
import BankDataCard from './components/BankDataCard';
import ComplementaryData from './components/ComplementaryData';
import ContactDataCard from './components/ContactDataCard';
import ResponsibleDataCard from './components/ResponsibleDataCard';
import SocialMediaDataCard from './components/SocialMediaDataCard';
import usePartnersForm from './usePartnersForm';

interface PartnersFormInterface {
  edit?: boolean
}

export default function PartnersForm({ edit = false }: PartnersFormInterface) {
  const {
    isLoading,
    kindOfPartner,
    handlePartnerChange,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    description,
    handleDescriptionChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    pixOptions,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    banksOptions,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    responsibleName,
    handleResponsibleNameChange,
    responsibleRole,
    handleResponsibleRoleChange,
    responsibleCellphone,
    handleResponsibleCellphoneChange,
    responsibleEmail,
    handleResponsibleEmailChange,
    facebook,
    handleFacebookChange,
    site,
    handleSiteChange,
    instagram,
    handleInstagramChange,
    tiktok,
    handleTiktokChange,
    isFormValid,
    createPartner,
    updatePartner,
    isGettingCepInfo,
    classPrice,
    handleClassPriceChange,
    contractDate,
    handleContractDateChange,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    requiredFieldsErrorsLabel,
  } = usePartnersForm();

  return (
    <>
      <Loader isLoading={isLoading} />
      {edit && (
        <PageHeader
          title={`Editar Parceiro ${name}`}
          link="/partners"
        />
      )}
      <KindOfPartner kindOfPartner={kindOfPartner} handlePartnerChange={handlePartnerChange} />
      <Container>
        <Row xs={1} md={1} lg={2}>
          <Col>
            <OpacityAnimation>
              <GeneralDataCard
                kindOfPartner={kindOfPartner}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                cpf={cpf}
                handleCpfChange={handleCpfChange}
                name={name}
                handleNameChange={handleNameChange}
                cnpj={cnpj}
                handleCnpjChange={handleCnpjChange}
                corporateName={corporateName}
                handleCorporateNameChange={handleCorporateNameChange}
                fantasyName={fantasyName}
                handleFantasyNameChange={handleFantasyNameChange}
                description={description}
                handleDescriptionChange={handleDescriptionChange}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <ContactDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                contactName={contactName}
                handleContactNameChange={handleContactNameChange}
                contactCellphone={contactCellphone}
                handleContactCellphoneChange={handleContactCellphoneChange}
                contactEmail={contactEmail}
                handleContactEmailChange={handleContactEmailChange}
              />
            </OpacityAnimation>
          </Col>
        </Row>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <OpacityAnimation>
              <AddressDataCard
                cep={cep}
                handleCepChange={handleCepChange}
                streetName={streetName}
                number={number}
                handleNumberChange={handleNumberChange}
                complement={complement}
                handleComplementChange={handleComplementChange}
                district={district}
                city={city}
                uf={uf}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                isGettingCepInfo={isGettingCepInfo}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <BankDataCard
                pixType={pixType}
                pixOptions={pixOptions}
                handleChangePixType={handleChangePixType}
                cnpjPix={cnpjPix}
                cpfPix={cpfPix}
                emailPix={emailPix}
                cellphonePix={cellphonePix}
                randomPix={randomPix}
                banksOptions={banksOptions}
                selectedBank={selectedBank}
                handleSelectBank={handleSelectBank}
                handleCnpjPixChange={handleCnpjPixChange}
                handleCpfPixChange={handleCpfPixChange}
                handleEmailPixChange={handleEmailPixChange}
                handleCellphonePixChange={handleCellphonePixChange}
                handleRandomPixChange={handleRandomPixChange}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                agency={agency}
                handleAgencyChange={handleAgencyChange}
                account={account}
                handleAccountChange={handleAccountChange}
                cardholder={cardholder}
                handleCardholderChange={handleCardholderChange}
                cnpjBank={cnpjBank}
                handleCnpjBankChange={handleCnpjBankChange}
              />
            </OpacityAnimation>
          </Col>
        </Row>
        <Row xs={1} md={1} lg={2}>
          <Col>
            <OpacityAnimation>
              <ResponsibleDataCard
                responsibleName={responsibleName}
                handleResponsibleNameChange={handleResponsibleNameChange}
                responsibleRole={responsibleRole}
                handleResponsibleRoleChange={handleResponsibleRoleChange}
                responsibleCellphone={responsibleCellphone}
                handleResponsibleCellphoneChange={handleResponsibleCellphoneChange}
                responsibleEmail={responsibleEmail}
                handleResponsibleEmailChange={handleResponsibleEmailChange}
                getErrorMessageByFieldName={getErrorMessageByFieldName}
              />
            </OpacityAnimation>
          </Col>
          <Col>
            <OpacityAnimation>
              <SocialMediaDataCard
                facebook={facebook}
                handleFacebookChange={handleFacebookChange}
                site={site}
                handleSiteChange={handleSiteChange}
                instagram={instagram}
                handleInstagramChange={handleInstagramChange}
                tiktok={tiktok}
                handleTiktokChange={handleTiktokChange}
              />
            </OpacityAnimation>
          </Col>
        </Row>

        <Row xs={1} md={1} lg={1}>
          <Col>
            <ComplementaryData
              classPrice={classPrice}
              handleClassPriceChange={handleClassPriceChange}
              contractDate={contractDate}
              handleContractDateChange={handleContractDateChange}
              getErrorMessageByFieldName={getErrorMessageByFieldName}
              loginEmail={loginEmail}
              handleLoginEmailChange={handleLoginEmailChange}
              edit={edit}
              password={password}
              handlePasswordChange={handlePasswordChange}
              passwordConfirmation={passwordConfirmation}
              handlePasswordConfirmationChange={handlePasswordConfirmationChange}
            />
          </Col>
        </Row>

        <ButtonContainer>
          {!edit && (
            <Button
              disabled={!isFormValid}
              onClick={createPartner}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Adicionar parceiro ${name ?? ''}`}
            </Button>
          )}

          {edit && (
            <Button
              disabled={!isFormValid}
              onClick={() => updatePartner()}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Editar parceiro ${name ?? ''}`}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}
