import PropTypes from 'prop-types';
import { Card } from '../styles';
import NoData from '../../../components/NoData';

interface PartnersQuantityCardInterface {
  partnersQty: number;
}

export default function PartnersQuantityCard({
  partnersQty,
}: PartnersQuantityCardInterface) {
  const hasInfo = partnersQty !== 0;

  return (
    <Card>
      <div className="card-title">
        <div>Parceiros</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {partnersQty}
        </div>
        <small>
          Parceiros / professores
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da quantidade de parceiros
          </>
                    )}
      />
      )}
    </Card>
  );
}

PartnersQuantityCard.propTypes = {
  partnersQty: PropTypes.number.isRequired,
};
