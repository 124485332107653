import { Dispatch, SetStateAction } from 'react';
import FilterRadioButton from '../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../components/FilterRadioButtonsContainer';
import MyModal from '../../../components/Modal';
import OpacityAnimation from '../../../components/OpacityAnimation';
import { ModalContainer } from '../styles';
import { SubscriberDetailsType } from '../types';
import DetailsCard from './DetailsCard';
import JoinedClassesList from './joinedClassesList';
import KidsList from './kidsList';
import RatingsList from './ratingsList';
import SchedulesList from './schedulesList';
import VideoViewsList from './videoViewsList';

interface SubscriberDetailsModalInterface {
  detailModalShow: boolean;
  handleCloseDetailModal: () => void;
  subscriberDetailsBeingShow: SubscriberDetailsType;
  setDetailView: Dispatch<SetStateAction<string>>;
  detailView: string;
}

export default function SubscriberDetailsModal({
  detailModalShow,
  handleCloseDetailModal,
  subscriberDetailsBeingShow,
  setDetailView,
  detailView,
}: SubscriberDetailsModalInterface) {
  return (
    <MyModal
      show={detailModalShow}
      onClose={() => handleCloseDetailModal()}
      title={`Detalhes do assinante ${subscriberDetailsBeingShow.name}`}
      modalBody={(
        <ModalContainer>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => setDetailView('subscriber')}
              selected={detailView === 'subscriber'}
            >
              Dados do assinante
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setDetailView('kids')}
              selected={detailView === 'kids'}
            >
              Crianças cadastradas
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setDetailView('lastSchedules')}
              selected={detailView === 'lastSchedules'}
            >
              Últimos Agendamentos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setDetailView('lastVideoViews')}
              selected={detailView === 'lastVideoViews'}
            >
              Últimos Vídeos Assistidos
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setDetailView('lastJoinedClasses')}
              selected={detailView === 'lastJoinedClasses'}
            >
              Últimas Aulas que Entrou
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setDetailView('ratings')}
              selected={detailView === 'ratings'}
            >
              Histórico Avaliações
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
          {detailView === 'subscriber' && (
          <OpacityAnimation delay={0.1}>
            <DetailsCard
              details={subscriberDetailsBeingShow}
            />
          </OpacityAnimation>
          )}
          {detailView === 'kids' && (
          <OpacityAnimation delay={0.1}>
            <KidsList
              list={subscriberDetailsBeingShow.childrens}
            />
          </OpacityAnimation>
          )}
          {detailView === 'lastSchedules' && (
          <OpacityAnimation delay={0.1}>
            <SchedulesList
              list={subscriberDetailsBeingShow.schedulesInLast30Days}
            />
          </OpacityAnimation>
          )}
          {detailView === 'lastVideoViews' && (
          <OpacityAnimation delay={0.1}>
            <VideoViewsList
              list={subscriberDetailsBeingShow.videosViewsInLast30Days}
            />
          </OpacityAnimation>
          )}
          {detailView === 'lastJoinedClasses' && (
          <OpacityAnimation delay={0.1}>
            <JoinedClassesList
              list={subscriberDetailsBeingShow.joinedClassesInLast30Days}
            />
          </OpacityAnimation>
          )}
          {detailView === 'ratings' && (
          <OpacityAnimation delay={0.1}>
            <RatingsList
              list={subscriberDetailsBeingShow.ratings}
            />
          </OpacityAnimation>
          )}
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="xl"
    />
  );
}
