/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import { addMinutes, parse } from 'date-fns';
import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import onlyNumbers from '../../../../utils/onlyNumbers';
import { TimesInSomeDayType, TimetableTranslatedType } from '../../types/TimetableTranslatedType';
import { DayBeingAddedAtTimetableType, DayInfoBeingShowType } from '../../types/TimetableTypes';

export default function useTimetable() {
  const [addTimetableModalShow, setAddTimetableModalShow] = useState(false);
  const [dayTimesModalShow, setDayTimesModalShow] = useState(false);
  const [dayInfoBeingShow, setDayInfoBeingShow] = useState({} as DayInfoBeingShowType);
  const [dayBeingAddedAtTimetable, setDayBeingAddedAtTimetable] = useState<DayBeingAddedAtTimetableType>({ value: '', label: 'Selecione um dia' });
  const [startTimeBeingAdded, setStartTimeBeingAdded] = useState('');
  const [endTimeBeingAdded, setEndTimeBeingAdded] = useState('');
  const [vacanciesQty, setVacanciesQty] = useState<number | string>('');
  const [timetable, setTimetable] = useState<TimetableTranslatedType>({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    vacation: [],
  });
  const [duration, setDuration] = useState(30);

  const mondayClasses = timetable?.monday;
  const quantityOfMondayClasses = mondayClasses?.length;

  const tuesdayClasses = timetable?.tuesday;
  const quantityOfTuesdayClasses = tuesdayClasses?.length;

  const wednesdayClasses = timetable?.wednesday;
  const quantityOfWednesdayClasses = wednesdayClasses?.length;

  const thursdayClasses = timetable?.thursday;
  const quantityOfThursdayClasses = thursdayClasses?.length;

  const fridayClasses = timetable?.friday;
  const quantityOfFridayClasses = fridayClasses?.length;

  const saturdayClasses = timetable?.saturday;
  const quantityOfSaturdayClasses = saturdayClasses?.length;

  const sundayClasses = timetable?.sunday;
  const quantityOfSundayClasses = sundayClasses?.length;

  const hasClasses = (
    !!quantityOfMondayClasses
    || !!quantityOfTuesdayClasses
    || !!quantityOfWednesdayClasses
    || !!quantityOfThursdayClasses
    || !!quantityOfFridayClasses
    || !!quantityOfSaturdayClasses
    || !!quantityOfSundayClasses);

  const timetableDaysOptions = [
    { value: 'monday', label: 'Segunda-feira' },
    { value: 'tuesday', label: 'Terça-feira' },
    { value: 'wednesday', label: 'Quarta-feira' },
    { value: 'thursday', label: 'Quinta-feira' },
    { value: 'friday', label: 'Sexta-feira' },
    { value: 'saturday', label: 'Sábado' },
    { value: 'sunday', label: 'Domingo' },
  ];

  function handleStartTimeBeingAddedChange(event: ChangeEvent<HTMLInputElement>) {
    const startTimeBeingChanged = event.target.value;
    setStartTimeBeingAdded(startTimeBeingChanged);

    // Parse the start time string into a Date object
    const parsedStartTime = parse(`2020-01-01T${startTimeBeingChanged}:00`, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date());

    // Add the duration in minutes to the parsed start time
    const endTime = addMinutes(parsedStartTime, duration);

    // Format the end time back into a string in the 'HH:mm' format
    const formattedEndTime = endTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    setEndTimeBeingAdded(formattedEndTime);
  }

  function handleVacanciesQtyChange(event: ChangeEvent<HTMLInputElement>) {
    setVacanciesQty(onlyNumbers(event.target.value));
  }

  const handleDurationChange = useCallback((drt: number) => {
    setDuration(drt);
    const clonedTimetable: TimetableTranslatedType = JSON.parse(JSON.stringify(timetable));

    for (const [day] of Object.entries(clonedTimetable)) {
      if (clonedTimetable[day].length > 0) {
        const newTimetableDay = clonedTimetable[day].map((dayTimes) => {
          const { startTime } = dayTimes;

          const parsedStartTime = parse(`2020-01-01T${startTime}:00`, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date());

          const endTime = addMinutes(parsedStartTime, drt);

          const newEndTime = endTime.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

          return {
            ...dayTimes, endTime: newEndTime,
          };
        });
        clonedTimetable[day] = newTimetableDay;
      }
    }

    setTimetable(clonedTimetable);
    if (hasClasses) {
      toast.info('Os horários de término das suas aulas na grade horário sofreram alteração devido à mudança da duração');
    }
  }, [hasClasses, timetable]);

  const addNewTimeAtTimetable = useCallback(() => {
    const clonedTimetable: TimetableTranslatedType = JSON.parse(JSON.stringify(timetable));
    const daySelected = clonedTimetable[dayBeingAddedAtTimetable.value!];
    const timetableStartTimesDaySelected = daySelected.map((time) => time.startTime);
    const doesStartTimeAlreadyExists = timetableStartTimesDaySelected.includes(startTimeBeingAdded);
    const isSomeFieldNull = (!startTimeBeingAdded || !endTimeBeingAdded || !vacanciesQty);

    if (doesStartTimeAlreadyExists) {
      toast.error('Já existe um horário cadastrado com o mesmo horário de início!');
      return;
    }

    if (isSomeFieldNull) {
      toast.error('Todos os campos devem ser preenchidos');
      return;
    }

    if (Number(vacanciesQty) < 10) {
      toast.error('Quantidade de vagas deve ser no mínimo 10');
      return;
    }

    clonedTimetable[dayBeingAddedAtTimetable.value!].push(
      {
        startTime: startTimeBeingAdded,
        endTime: endTimeBeingAdded,
        vacanciesQty: Number(vacanciesQty),
        id: `${startTimeBeingAdded}${endTimeBeingAdded}${vacanciesQty}`,
      },
    );
    setTimetable(clonedTimetable);
    toast.success('Horário adicionado com sucesso!');
  }, [dayBeingAddedAtTimetable.value,
    endTimeBeingAdded,
    startTimeBeingAdded,
    timetable,
    vacanciesQty]);

  const removeTimeAtTimetable = useCallback((time: TimesInSomeDayType, day: string) => {
    const clonedTimetable: TimetableTranslatedType = JSON.parse(JSON.stringify(timetable));
    const filteredTimesDayAtTimetable = clonedTimetable[day].filter(
      (currentDayTimes) => !(currentDayTimes.id === time.id),
    );
    clonedTimetable[day] = filteredTimesDayAtTimetable;
    setTimetable(clonedTimetable);
    setDayTimesModalShow(false);
    toast.success('Horário removido com sucesso');
  }, [timetable]);

  return {
    timetable,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    duration,
    handleDurationChange,
    setStartTimeBeingAdded,
    setEndTimeBeingAdded,
    setVacanciesQty,
    setTimetable,
    setDuration,
  };
}
