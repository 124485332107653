import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import useErrors from "../../../../hooks/useErrors";
import internalUsersService from "../../../../services/internalUsersService";
import isEmailValid from "../../../../utils/isEmailValid";
import { PermissionTypes } from "../../types";

interface UseAddModalI {
  loadInternalUsers: () => Promise<void>;
  setAddInternalUserModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function useAddModal({ loadInternalUsers, setAddInternalUserModalShow, setIsLoading }: UseAddModalI) {
  const [permission, setPermission] = useState<PermissionTypes>({ value: '', label: 'Selecione um perfil' });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  const { apiCall } = useApiCall();

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);

    if (!isEmailValid(event.target.value)) {
      setError({ field: 'email', message: 'E-mail inválido' });
    } else {
      removeError('email');
    }
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);

    if (event.target.value.length < 8) {
      setError({ field: 'password', message: 'Senha deve conter ter 8 caracteres!' });
    } else if (event.target.value) {
      removeError('password');
    } if (event.target.value !== passwordConfirmation) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else if (event.target.value === passwordConfirmation) {
      removeError('passwordConfirmation');
    }
  }

  function handlePasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setPasswordConfirmation(event.target.value);

    if (event.target.value !== password) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else {
      removeError('passwordConfirmation');
    }
  }

  const addInternalUser = useCallback(async () => {
    await apiCall({
      apiToCall: internalUsersService.createInternalUser,
      onStartLoad: () => { setIsLoading(true) },
      reqBody: JSON.stringify({
        email,
        permission: permission.value,
        password,
      }),
      actionAfterResponse: (response) => {
        if (response.success) {
          if (response.isEmailAlreadyAtFirebase) {
            toast.warn('Usuário adicionado com sucesso. ATENÇÃO: O usuário já possui uma conta no App, portanto o mesmo deverá realizar seu login no Admin com a mesma senha do App, e não com a senha colocada aqui!')
          } else {
            toast.success('Usuário adicionado com sucesso!');
          }
          setAddInternalUserModalShow(false);
          setEmail('');
          setPermission({ value: '', label: 'Selecione um perfil' });
          setPassword('');
          setPasswordConfirmation('')
          loadInternalUsers();
          return;
        }
        toast.error('Não foi possível adicionar o usuário. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível adicionar o usuário. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, email, loadInternalUsers, password, permission.value, setAddInternalUserModalShow, setIsLoading])

  const isInternalUserFormValid = errors.length === 0 && !!permission.value && !!password && !!passwordConfirmation;

  return {
    addInternalUser,
    email,
    password,
    passwordConfirmation,
    permission,
    handleEmailChange,
    handlePasswordChange,
    handlePasswordConfirmationChange,
    setPermission,
    getErrorMessageByFieldName,
    isInternalUserFormValid,
  }
}
