import Sidebar from '../../../components/Sidebar';
import Transitions from '../../../components/Transition';
import PageHeader from '../../../components/PageHeader';
import PartnersForm from '../components/PartnersForm';

export default function NewPartner() {
  return (
    <>
      <Sidebar active="Partners" />
      <Transitions>
        <PageHeader
          title="Novo Parceiro"
          link="/partners"
        />
        <PartnersForm />
      </Transitions>
    </>
  );
}
