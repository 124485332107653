/* eslint-disable max-len */
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import { ErrorsInterface } from '../../../../../../hooks/useErrors';
import partnersService from '../../../../../../services/partnersService';

interface UseFormSubmitInterface {
  resetFields: () => void;
  account: string;
  agency: string;
  cardholder: string;
  cellphonePix: string;
  cep: string;
  city: string;
  cnpj: string;
  cnpjBank: string;
  cnpjPix: string;
  complement: string;
  contactCellphone: string;
  contactEmail: string;
  contactName: string;
  corporateName: string;
  cpf: string;
  cpfPix: string;
  description: string;
  district: string;
  emailPix: string;
  facebook: string;
  fantasyName: string;
  instagram: string;
  kindOfPartner: string;
  name: string;
  number: string | number;
  pixType: { value: string, label: string };
  randomPix: string;
  responsibleCellphone: string;
  responsibleEmail: string;
  responsibleName: string;
  responsibleRole: string;
  selectedBank: { value: string, label: string };
  site: string;
  streetName: string;
  tiktok: string;
  uf: string;
  errors: ErrorsInterface[];
  classPrice: string;
  contractDate: string;
  loginEmail: string;
  password: string;
}

export default function useFormSubmit({
  resetFields,
  account,
  agency,
  cardholder,
  cellphonePix,
  cep,
  city,
  cnpj,
  cnpjBank,
  cnpjPix,
  complement,
  contactCellphone,
  contactEmail,
  contactName,
  corporateName,
  cpf,
  cpfPix,
  description,
  district,
  emailPix,
  facebook,
  fantasyName,
  instagram,
  kindOfPartner,
  name,
  number,
  pixType,
  randomPix,
  responsibleCellphone,
  responsibleEmail,
  responsibleName,
  responsibleRole,
  selectedBank,
  site,
  streetName,
  tiktok,
  uf,
  errors,
  classPrice,
  contractDate,
  loginEmail,
  password,
}: UseFormSubmitInterface) {
  console.log(cellphonePix);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();
  const { apiCall } = useApiCall();

  const createPartner = useCallback(async () => {
    const selectedPixTypeMap: { [key: string]: string } = {
      CNPJ: cnpjPix,
      CPF: cpfPix,
      Celular: cellphonePix,
      Email: emailPix,
      Chave: randomPix,
    };
    await apiCall({
      apiToCall: partnersService.createPartner,
      reqBody: JSON.stringify({
        nome_fantasia: kindOfPartner === 'cnpj' ? fantasyName : name,
        razao_social: kindOfPartner === 'cnpj' ? corporateName : '',
        cnpj: kindOfPartner === 'cnpj' ? cnpj : '',
        cpf: kindOfPartner === 'cnpj' ? '' : cpf,
        descricao: description,
        responsavel_nome: responsibleName,
        responsavel_cargo: responsibleRole,
        responsavel_telefone: responsibleCellphone,
        responsavel_email: responsibleEmail,
        contato_nome: contactName,
        contato_email: contactEmail,
        contato_celular: contactCellphone,
        end_cep: cep,
        end_rua: streetName,
        end_bairro: district,
        end_numero: String(number),
        end_complemento: complement,
        end_cidade: city,
        end_uf: uf,
        banco_nome: selectedBank.value,
        banco_agencia: agency,
        banco_conta: account,
        banco_titular: cardholder,
        banco_doc: cnpjBank,
        tipo_pix: pixType.value,
        pix: selectedPixTypeMap[pixType.value],
        facebook,
        instagram,
        site,
        tiktok,
        valor: classPrice,
        inicio_contrato_atual: '2023-04-06',
        loginEmail,
        password,
      }),
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível adicionar o parceiro ${name}. Por favor, tente novamente.`);
          return;
        }
        if (apiResponse.useSamePasswordFromApp) {
          toast.success(`O parceiro ${name} foi adicionado com sucesso!`);
          toast.warn('Como o e-mail de login já está cadastrado no aplicativo, a senha de acesso ao sistema administrativo é a mesma do aplicativo!');
          resetFields();
          return;
        }
        toast.success(`O parceiro ${name} foi adicionado com sucesso!`);
        resetFields();
      },
      catchMessage: `Não foi possível adicionar o parceiro ${name}. Por favor, tente novamente`
    })
  }, [account, agency, apiCall, cardholder, cellphonePix, cep, city, classPrice, cnpj, cnpjBank, cnpjPix, complement, contactCellphone, contactEmail, contactName, corporateName, cpf, cpfPix, description, district, emailPix, facebook, fantasyName, instagram, kindOfPartner, loginEmail, name, number, password, pixType.value, randomPix, resetFields, responsibleCellphone, responsibleEmail, responsibleName, responsibleRole, selectedBank.value, site, streetName, tiktok, uf]);

  const updatePartner = useCallback(async () => {
    const selectedPixTypeMap: { [key: string]: string } = {
      CNPJ: cnpjPix,
      CPF: cpfPix,
      Celular: cellphonePix,
      Email: emailPix,
      Chave: randomPix,
    };
    await apiCall({
      apiToCall: partnersService.updatePartner,
      queryParams: { partnerId: id },
      reqBody: JSON.stringify({
        nome_fantasia: kindOfPartner === 'cnpj' ? fantasyName : name,
        razao_social: kindOfPartner === 'cnpj' ? corporateName : '',
        cnpj: kindOfPartner === 'cnpj' ? cnpj : '',
        cpf: kindOfPartner === 'cnpj' ? '' : cpf,
        descricao: description,
        responsavel_nome: responsibleName,
        responsavel_cargo: responsibleRole,
        responsavel_telefone: responsibleCellphone,
        responsavel_email: responsibleEmail,
        contato_nome: contactName,
        contato_email: contactEmail,
        contato_celular: contactCellphone,
        end_cep: cep,
        end_rua: streetName,
        end_bairro: district,
        end_numero: String(number),
        end_complemento: complement,
        end_cidade: city,
        end_uf: uf,
        banco_nome: selectedBank.value,
        banco_agencia: agency,
        banco_conta: account,
        banco_titular: cardholder,
        banco_doc: cnpjBank,
        tipo_pix: pixType.value,
        pix: selectedPixTypeMap[pixType.value],
        facebook,
        instagram,
        site,
        tiktok,
        valor: classPrice,
        inicio_contrato_atual: contractDate,
      }),
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível editar o parceiro ${name}. Por favor, tente novamente.`);
          return;
        }
        toast.success(`O parceiro ${name} foi editado com sucesso!`);
        navigate('/partners');
      },
      catchMessage: `Não foi possível editar o parceiro ${name}. Por favor, tente novamente`,
    })
  }, [account, agency, apiCall, cardholder, cellphonePix, cep, city, classPrice, cnpj, cnpjBank, cnpjPix, complement, contactCellphone, contactEmail, contactName, contractDate, corporateName, cpf, cpfPix, description, district, emailPix, facebook, fantasyName, id, instagram, kindOfPartner, name, navigate, number, pixType.value, randomPix, responsibleCellphone, responsibleEmail, responsibleName, responsibleRole, selectedBank.value, site, streetName, tiktok, uf]);

  const isFormValid = (
    ((cpf && name) || (cnpj && corporateName))
    && (contactName && contactCellphone && contactEmail)
    && (cep && streetName && number && district && uf && city)
    && (
      ((pixType.value) && (cnpjPix || cpfPix || emailPix || randomPix || cellphonePix))
      || (agency && account && selectedBank.value))
    && (responsibleCellphone && responsibleEmail && responsibleName)
    && errors.length === 0 && loginEmail && password
  );

  const requiredFieldsErrorsLabel: string[] = useMemo(() => {
    return [
      (!cpf && !cnpj) ? 'CPF ou CNPJ' : '',
      (!name && !corporateName) ? 'Nome ou Nome Fantasia' : '',
      !contactName ? 'Nome do Contato' : '',
      !contactCellphone ? 'Telefone do Contato' : '',
      !contactEmail ? 'Email do Contato' : '',
      !cep ? 'CEP' : '',
      !streetName ? 'Logradouro' : '',
      !number ? 'Número' : '',
      !district ? 'Bairro' : '',
      !uf ? 'UF' : '',
      !city ? 'Cidade' : '',
      (!cnpjPix && !cpfPix && !cellphonePix && !emailPix && !randomPix && (!selectedBank.value || !agency || !account || !cardholder || !cnpjBank)) ? 'Dados bancários (Algum PIX, ou detalhes da conta)' : '',
      !responsibleCellphone ? 'Telefone do Responsável' : '',
      !responsibleEmail ? 'Email do Responsável' : '',
      !responsibleName ? 'Nome do Responsável' : '',
      !loginEmail ? 'Email de Login' : '',
      !password ? 'Senha de Login (Primeiro Acesso)' : '',
    ].filter((error) => !!error);
  }, [account, agency, cardholder, cellphonePix, cep, city, cnpj, cnpjBank, cnpjPix, contactCellphone, contactEmail, contactName, corporateName, cpf, cpfPix, district, emailPix, loginEmail, name, number, password, randomPix, responsibleCellphone, responsibleEmail, responsibleName, selectedBank.value, streetName, uf]);

  return {
    isSubmitting,
    createPartner,
    isFormValid,
    updatePartner,
    requiredFieldsErrorsLabel,
  };
}
