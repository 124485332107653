import { useState } from 'react';
import useChangeHighlight from './functions/useChangeHighlight';
import useDeleteHighlight from './functions/useDeleteHighlight';
import useLoadHighlightsPage from './functions/useLoadHighlightsPage';

export default function useHighlights() {
  const [selectedType, setSelectedType] = useState<'live' | 'record'>('live');

  const {
    isLoadLoading,
    hasError,
    list,
    loadHighlightsPage,
    fullActivitesList,
  } = useLoadHighlightsPage({ selectedType });

  const {
    isChangingHighlight,
    manageHighlightModalShow,
    setManageHighlightModalShow,
    manageHighlight,
    selectedNewHighlight,
    setSelectedNewHighlight,
  } = useChangeHighlight({
    loadHighlightsPage,
    selectedType,
  });

  const {
    isDeleting,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    highlightBeingDeleted,
    deleteHighlight
  } = useDeleteHighlight({ loadHighlightsPage });

  function handleTryAgain() {
    loadHighlightsPage();
  }

  const isLoading = isLoadLoading || isChangingHighlight || isDeleting;

  return {
    isLoading,
    hasError,
    activitiesList: fullActivitesList,
    list,
    modalShow: manageHighlightModalShow,
    handleTryAgain,
    setManageHighlightModalShow,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    manageHighlight,
    selectedNewHighlight,
    setSelectedNewHighlight,
    highlightBeingDeleted,
    deleteHighlight,
    selectedActType: selectedType,
    setSelectedActType: setSelectedType,
  };
}
