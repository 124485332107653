/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format, parse } from "date-fns";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import activitiesService from "../../../../../services/activitiesService";
import schedulesService from "../../../../../services/schedulesService";
import subscribersService from "../../../../../services/subscribersService";
import getDiffereceInYears from "../../../../../utils/getDiffereceInYears";
import getStringWeekDayByIndex from "../../../../../utils/getStringWeekDayByIndex";
import { ActivitiesFromApiResponse, ActivityType, SelectOptionType, UsersFromApiResponse, UserType } from "../types";

interface IUseNewScheduleModal {
  setNewScheduleModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  reloadPage: () => Promise<void>;
}

export default function useNewScheduleModal({ setNewScheduleModalShow, setIsLoading, reloadPage }: IUseNewScheduleModal) {
  const [users, setUsers] = useState<UserType[]>([]);
  const [activities, setActivities] = useState<ActivityType[]>([]);

  const [user, setUser] = useState<SelectOptionType>({} as SelectOptionType);
  const [kid, setKid] = useState<SelectOptionType>({} as SelectOptionType);
  const [activity, setActivity] = useState<SelectOptionType>({} as SelectOptionType);
  const [time, setTime] = useState<SelectOptionType>({} as SelectOptionType);
  const [choosedDate, setChoosedDate] = useState<Date | null>(null);

  const usersOptions: SelectOptionType[] = useMemo(() => users.map((x) => ({ value: `${x.id}|${x.email}|${x.phone}`, label: x.nome })), [users]);

  const kids: SelectOptionType[] = useMemo(() => {
    if (!user.value) return [];
    const selectedUser = users.find((x) => x.id === user.value.split('|')[0]);
    return selectedUser?.kids.map((x) => ({ value: `${x.id}|${x.birthDate}`, label: x.name })) ?? [];
  }, [user.value, users]);

  const activitiesOptions: SelectOptionType[] = useMemo(() => {
    const filteredActivities = activities.filter((act) => {
      const selectedKidBirthDate = kid.value ? (kid.value.split('|')[1]) : '';
      const selectedKidAge = kid.value ? getDiffereceInYears(new Date(), parse(selectedKidBirthDate, 'dd/MM/yyyy', new Date())) ?? 0 : 0;

      const filterByKidAge = !kid.value || !selectedKidAge || (selectedKidAge >= act.minima && selectedKidAge <= act.maxima);

      const weekDay = choosedDate ? getStringWeekDayByIndex(choosedDate.getDay()) : 0;
      const filterByChoosedDate = !choosedDate || !weekDay || act.grade.some((tt) => {
        return (tt.dia === weekDay && tt.horarios.length > 0);
      });

      return filterByKidAge && filterByChoosedDate;
    })

    return filteredActivities.map((x) => ({ value: x.id, label: x.nome }));
  }, [activities, choosedDate, kid.value]);

  const times: SelectOptionType[] = useMemo(() => {
    const selectedWeekDay = choosedDate ? getStringWeekDayByIndex(choosedDate.getDay()) : 0;
    const selectedAcivity = activities.find((x) => x.id === activity.value);
    const activityTimesAtDay = selectedAcivity?.grade.find((x) => x.dia === selectedWeekDay)?.horarios ?? [];

    return activityTimesAtDay.map((x) => ({ value: `${x.horarioInicio}-${x.horarioTermino}`, label: `Das ${x.horarioInicio} ás ${x.horarioTermino}` }));
  }, [activities, activity.value, choosedDate]);

  const { apiCall } = useApiCall();

  const loadNewScheduleModal = useCallback(async () => {
    const loadUsers = async () => {
      await apiCall({
        apiToCall: subscribersService.getAllActiveSubscribers,
        onStartLoad: () => setIsLoading(true),
        onEndLoad: () => setIsLoading(false),
        catchAction: () => { toast.error('Erro ao carregar usuários'); setNewScheduleModalShow(false) },
        actionAfterResponse: (response: UsersFromApiResponse) => {
          if (!response.success) {
            toast.error('Erro ao carregar usuários');
            setNewScheduleModalShow(false);
            return;
          }
          setUsers(response.list);
        },
      })
    };

    const loadActivities = async () => {
      await apiCall({
        apiToCall: activitiesService.getAllSchedulable,
        onStartLoad: () => setIsLoading(true),
        onEndLoad: () => setIsLoading(false),
        catchAction: () => { toast.error('Erro ao carregar atividades'); setNewScheduleModalShow(false) },
        actionAfterResponse: (response: ActivitiesFromApiResponse) => {
          if (!response.success) {
            toast.error('Erro ao carregar atividades');
            setNewScheduleModalShow(false);
            return;
          }
          setActivities(response.list);
        },
      })
    };

    await Promise.all([loadUsers(), loadActivities()]);
  }, [apiCall, setIsLoading, setNewScheduleModalShow]);

  const createSchedule = useCallback(async () => {
    await apiCall({
      apiToCall: schedulesService.createSchedule,
      queryParams: { userId: user.value.split('|')[0] },
      reqBody: JSON.stringify({
        kidId: kid.value.split('|')[0],
        atividade_id: activity.value,
        data: format(choosedDate!, 'd/M/yyyy'),
        horario: `${time.value.split('-')[0]}-${time.value.split('-')[1]}`,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      catchMessage: 'Erro ao criar agendamento',
      actionAfterResponse: (response: { success: boolean, message?: string }) => {
        if (!response.success) {
          toast.error(response.message);
          return;
        }
        toast.success('Agendamento criado com sucesso');
        setNewScheduleModalShow(false);
        setChoosedDate(null);
        setUser({} as SelectOptionType);
        setKid({} as SelectOptionType);
        setActivity({} as SelectOptionType);
        setTime({} as SelectOptionType);
        reloadPage();
      }
      })
    }, [activity.value, apiCall, choosedDate, kid.value, reloadPage, setIsLoading, setNewScheduleModalShow, time.value, user.value]);

  useEffect(() => {
    loadNewScheduleModal();
  }, [loadNewScheduleModal]);

  const canSchedule = useMemo(() => !!user.value && !!kid.value && !!choosedDate && !!time.value, [choosedDate, kid, time, user]);

  return {
    user,
    users: usersOptions,
    setUser,
    kid,
    kids,
    setKid,
    activity,
    activities: activitiesOptions,
    setActivity,
    setChoosedDate,
    choosedDate,
    time,
    times,
    setTime,
    canSchedule,
    createSchedule,
  }
}
