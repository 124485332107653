/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { CustomStyle } from "../../../../components/CustomSelectStyle";
import MyModal from "../../../../components/Modal";
import { ReactCalendarCustomStyle } from "../../../../components/ReactCalendarCustomStyle";
import useThemeContext from "../../../../contexts/theme";
import useNewScheduleModal from "./functions";
import { AsideContainer, Group, Container as StyledContainer } from './styles';

interface INewScheduleModal {
  newScheduleModalShow: boolean;
  setNewScheduleModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  reloadPage: () => Promise<void>;
}

export default function NewScheduleModal({ newScheduleModalShow, setNewScheduleModalShow, setIsLoading, reloadPage }: INewScheduleModal) {
  const {
    user,
    users,
    setUser,
    kid,
    kids,
    setKid,
    activity,
    activities,
    setActivity,
    setChoosedDate,
    choosedDate,
    time,
    times,
    setTime,
    canSchedule,
    createSchedule,
  } = useNewScheduleModal({
    setNewScheduleModalShow,
    setIsLoading,
    reloadPage,
  });

  const { selectedTheme } = useThemeContext();

  return (
    <MyModal
      show={newScheduleModalShow}
      title="Realizar Novo Agendamento"
      modalBody={(
        <Container>
          <Row xs={1} md={1} lg={1}>
            <Col>
              <StyledContainer>
                <div className="card-title">
                  Usuário e Criança
                </div>
                <AsideContainer>
                  <Group>
                    <div className="title">
                      Usuário *
                    </div>
                    <Select
                      value={{ value: user?.value, label: user?.label ?? 'Selecione um usuário' }}
                      options={users}
                      onChange={(opt) => {
                        setUser({ value: opt!.value, label: opt!.label });
                        setKid({ value: '', label: 'Selecione uma criança' });
                        setActivity({ value: '', label: 'Selecione uma atividade' });
                        setTime({ value: '', label: 'Selecione um horário' });
                        setChoosedDate(null);
                      }}
                      placeholder="Selecione um usuário"
                      styles={CustomStyle}
                      classNamePrefix="react-select"
                      className="react-select-container"
                    />
                  </Group>
                  <Group>
                    <div className="title">
                      Criança *
                    </div>
                    <Select
                      value={{ value: kid?.value, label: kid?.label ?? 'Selecione uma criança' }}
                      options={kids}
                      onChange={(opt) => {
                        setKid({ value: opt!.value, label: opt!.label });
                        setActivity({ value: '', label: 'Selecione uma atividade' });
                        setTime({ value: '', label: 'Selecione um horário' });
                        setChoosedDate(null);
                      }}
                      placeholder="Selecione um parceiro"
                      styles={CustomStyle}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      isDisabled={!user.value}
                    />
                  </Group>
                </AsideContainer>
              </StyledContainer>
            </Col>

            <Col>
              <StyledContainer>
                <div className="card-title">
                  Atividade e Horário
                </div>
                <AsideContainer>
                  <Group>
                    <div className="title">
                      Atividade *
                    </div>
                    <Select
                      value={{ value: activity?.value, label: activity?.label ?? 'Selecione uma atividade' }}
                      options={activities}
                      onChange={(opt) => {
                        setActivity({ value: opt!.value, label: opt!.label });
                        setTime({ value: '', label: 'Selecione um horário' });
                      }}
                      placeholder="Selecione um parceiro"
                      styles={CustomStyle}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      isDisabled={!user.value || !kid.value}
                    />
                  </Group>
                  <Group>
                    <div className="title">
                      Data *
                    </div>
                    {selectedTheme === 'dark' ? (
                      <ReactCalendarCustomStyle>
                        <Calendar
                          onClickDay={(date) => {
                            setChoosedDate(date);
                            setTime({ value: '', label: 'Selecione um horário' });
                          }}
                          value={choosedDate}
                          minDate={new Date()}
                        />
                      </ReactCalendarCustomStyle>
                      ) : (
                        <Calendar
                          onClickDay={(date) => {
                            setChoosedDate(date);
                            setTime({ value: '', label: 'Selecione um horário' });
                          }}
                          value={choosedDate}
                          minDate={new Date()}
                        />
                      )}
                  </Group>
                  <Group>
                    <div className="title">
                      Horário *
                    </div>
                    <Select
                      value={{ value: time?.value, label: time?.label ?? 'Selecione um horário' }}
                      options={times}
                      onChange={(opt) => {
                        setTime({ value: opt!.value, label: opt!.label });
                      }}
                      placeholder="Selecione um parceiro"
                      styles={CustomStyle}
                      classNamePrefix="react-select"
                      className="react-select-container"
                      isDisabled={!user.value || !kid.value || !activity.value || !choosedDate}
                    />
                  </Group>
                </AsideContainer>
              </StyledContainer>
            </Col>
          </Row>
        </Container>
          )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Agendar"
      onClose={() => setNewScheduleModalShow(false)}
      onAction={() => {
        createSchedule();
      }}
      isActionButtonDisabled={!canSchedule}
      type="action"
      size="xl"
  />
  )
}
