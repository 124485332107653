import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useErrors from '../../../../../../hooks/useErrors';
import formatCep from '../../../../../../utils/formatCep';
import formatCnpj from '../../../../../../utils/formatCnpj';
import formatCpf from '../../../../../../utils/formatCpf';
import formatCurrency from '../../../../../../utils/formatCurrency';
import formatPercentage from '../../../../../../utils/formatPercentage';
import formatPhone from '../../../../../../utils/formatPhone';
import isCnpjValid from '../../../../../../utils/isCnpjValid';
import isCpfvalid from '../../../../../../utils/isCpfValid';
import isDateBeforeToday from '../../../../../../utils/isDateBeforeToday';
import isEmailValid from '../../../../../../utils/isEmailValid';
import onlyNumbers from '../../../../../../utils/onlyNumbers';
import { AuthenticationTypeTypes, CupomObject, PlanKindTypes } from '../../types';
import parseCurrencyStringToFloat from '../../../../../../utils/parseCurrencyStringToFloat';

interface IuseInputChanges {
  setAssociatedCompanies: Dispatch<SetStateAction<string[]>>;
  setCupons: Dispatch<SetStateAction<CupomObject[]>>;
  setCupomName: Dispatch<SetStateAction<string>>;
  setCupomPlanTrial: Dispatch<SetStateAction<string | number>>;
  setCupomPlanValue: Dispatch<SetStateAction<string>>;
  setCupomPlanKind: Dispatch<SetStateAction<PlanKindTypes>>;
}

export default function useInputChanges({
  setAssociatedCompanies,
  setCupons,
  setCupomName,
  setCupomPlanTrial,
  setCupomPlanValue,
  setCupomPlanKind,
}: IuseInputChanges) {
  const [kindOfCompany, setKindOfCompany] = useState<'default' | 'seller'>('default');
  const [authenticationType, setAuthenticationType] = useState<AuthenticationTypeTypes>({ value: 'cpf', label: 'CPF' });
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [cnpj, setCnpj] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [employeesQuantity, setEmployeesQuantity] = useState<number | ''>('');
  const [elegiblesQuantity, setElegiblesQuantity] = useState<number | ''>('');
  const [adherenceGoal, setAdherenceGoal] = useState<number | ''>('');

  const [contactName, setContactName] = useState('');
  const [contactCellphone, setContactCellphone] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const [cep, setCep] = useState('');
  const [streetName, setStreetName] = useState('');
  const [number, setNumber] = useState<number | ''>('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [isGettingCepInfo, setIsGettingCepInfo] = useState(false);

  const [pixType, setPixType] = useState({ value: 'E-mail', label: 'E-mail' });
  const [cnpjPix, setCnpjPix] = useState('');
  const [cpfPix, setCpfPix] = useState('');
  const [emailPix, setEmailPix] = useState('');
  const [cellphonePix, setCellphonePix] = useState('');
  const [randomPix, setRandomPix] = useState('');
  const [agency, setAgency] = useState('');
  const [account, setAccount] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [cnpjBank, setCnpjBank] = useState('');
  const [selectedBank, setSelectedBank] = useState({ value: '', label: 'Selecione um banco' });

  const [monthlyFee, setMonthlyFee] = useState('');
  const [startDate, setStartDate] = useState('');
  const [contractDate, setContractDate] = useState('');

  const [loginEmail, setLoginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [planValue, setPlanValue] = useState('');
  const [planTrial, setPlanTrial] = useState<number | string>('');
  const [planKind, setPlanKind] = useState({ value: '', label: 'Selecione um tipo de benefício' });
  const [planId, setPlanId] = useState<number | null>(null);

  const [showAtCompaniesListAppRegister, setShowAtCompaniesListAppRegister] = useState(true);
  const [viewByAssociatedCompanies, setViewByAssociatedCompanies] = useState(false);

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  function handleLoginEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setLoginEmail(event.target.value);

    if (!isEmailValid(event.target.value)) {
      setError({ field: 'loginEmail', message: 'E-mail inválido' });
    } else {
      removeError('loginEmail');
    }
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);

    if (event.target.value.length < 8) {
      setError({ field: 'password', message: 'Senha deve conter ter 8 caracteres!' });
    } else if (event.target.value) {
      removeError('password');
    } if (event.target.value !== passwordConfirmation) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else if (event.target.value === passwordConfirmation) {
      removeError('passwordConfirmation');
    }
  }

  function handlePasswordConfirmationChange(event: ChangeEvent<HTMLInputElement>) {
    setPasswordConfirmation(event.target.value);

    if (event.target.value !== password) {
      setError({ field: 'passwordConfirmation', message: 'Senhas divergentes' });
    } else {
      removeError('passwordConfirmation');
    }
  }

  function handleCnpjChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpj(formatCnpj(event.target.value));
    if (!event.target.value) {
      setError({ field: 'cnpj', message: 'CNPJ é obrigatório!' });
    } else if (!isCnpjValid(formatCnpj(event.target.value))) {
      setError({ field: 'cnpj', message: 'CNPJ inválido!' });
    } else {
      removeError('cnpj');
    }
  }

  function handleCorporateNameChange(event: ChangeEvent<HTMLInputElement>) {
    setCorporateName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'corporateName', message: 'Razão Social é obrigatório!' });
    } else {
      removeError('corporateName');
    }
  }

  function handleFantasyNameChange(event: ChangeEvent<HTMLInputElement>) {
    setFantasyName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'fantasyName', message: 'Nome fantasia é obrigatório!' });
    } else {
      removeError('fantasyName');
    }
  }

  function handleContactNameChange(event: ChangeEvent<HTMLInputElement>) {
    setContactName(event.target.value);
    if (!event.target.value) {
      setError({ field: 'contactName', message: 'Nome do contato é obrigatório!' });
    } else {
      removeError('contactName');
    }
  }

  function handleContactCellphoneChange(event: ChangeEvent<HTMLInputElement>) {
    setContactCellphone(formatPhone(event.target.value));
    if (!event.target.value) {
      setError({ field: 'contactCellphone', message: 'Celular do contato é obrigatório!' });
    } else {
      removeError('contactCellphone');
    }
  }

  function handleContactEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setContactEmail(event.target.value);
    if (!event.target.value) {
      setError({ field: 'contactEmail', message: 'E-mail de contato é obrigatório!' });
    } else if (!isEmailValid(event.target.value)) {
      setError({ field: 'contactEmail', message: 'E-mail inválido!' });
    } else {
      removeError('contactEmail');
    }
  }

  function handleNumberChange(event: ChangeEvent<HTMLInputElement>) {
    setNumber(onlyNumbers(event.target.value));
    if (!event.target.value) {
      setError({ field: 'number', message: 'Número é obrigatório!' });
    } else {
      removeError('number');
    }
  }

  function handleComplementChange(event: ChangeEvent<HTMLInputElement>) {
    setComplement(event.target.value);
  }

  function handleChangePixType(event: { value: string, label: string }) {
    setPixType(event);
    setCnpjPix('');
    setCpfPix('');
    setEmailPix('');
    setCellphonePix('');
    setRandomPix('');
  }

  function handleSelectBank(event: { value: string, label: string }) {
    setSelectedBank(event);
  }

  function handleCnpjPixChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpjPix(formatCnpj(event.target.value));
    if (!isCnpjValid(formatCnpj(event.target.value)) && event.target.value) {
      setError({ field: 'cnpjPix', message: 'Informe um CNPJ válido!' });
    } else {
      removeError('cnpjPix');
    }
  }

  function handleCpfPixChange(event: ChangeEvent<HTMLInputElement>) {
    setCpfPix(formatCpf(event.target.value));
    if (!isCpfvalid(formatCpf(event.target.value)) && event.target.value) {
      setError({ field: 'cpfPix', message: 'Informe um CPF válido!' });
    } else {
      removeError('cpfPix');
    }
  }

  function handleCellphonePixChange(event: ChangeEvent<HTMLInputElement>) {
    setCellphonePix(formatPhone(event.target.value));
  }

  function handleEmailPixChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailPix(event.target.value);
    if (!isEmailValid(event.target.value) && event.target.value) {
      setError({ field: 'emailPix', message: 'Informe um e-mail válido!' });
    } else {
      removeError('emailPix');
    }
  }

  function handleRandomPixChange(event: ChangeEvent<HTMLInputElement>) {
    setRandomPix(event.target.value);
  }

  function handleAgencyChange(event: ChangeEvent<HTMLInputElement>) {
    setAgency(event.target.value);
    if (!event.target.value) {
      setError({ field: 'agency', message: 'Agência é um campo obrigatório!' });
    } else {
      removeError('agency');
    }
  }

  function handleAccountChange(event: ChangeEvent<HTMLInputElement>) {
    setAccount(event.target.value);
    if (!event.target.value) {
      setError({ field: 'account', message: 'Conta é um campo obrigatório!' });
    } else {
      removeError('account');
    }
  }

  function handleCardholderChange(event: ChangeEvent<HTMLInputElement>) {
    setCardholder(event.target.value);
  }

  function handleCnpjBankChange(event: ChangeEvent<HTMLInputElement>) {
    setCnpjBank(formatCnpj(event.target.value));
    if (!isCnpjValid(formatCnpj(event.target.value)) && event.target.value) {
      setError({ field: 'cnpjBank', message: 'Informe um CNPJ válido!' });
    } else {
      removeError('cnpjBank');
    }
  }

  function handleEmployeesQuantityChange(event: ChangeEvent<HTMLInputElement>) {
    setEmployeesQuantity(onlyNumbers(event.target.value));
  }

  function handleEligibleQuantityChange(event: ChangeEvent<HTMLInputElement>) {
    setElegiblesQuantity(onlyNumbers(event.target.value));
  }

  function handleAderenceGoalChange(event: ChangeEvent<HTMLInputElement>) {
    const input = event.target.value;
    const percentage = formatPercentage(input);
    setAdherenceGoal(percentage);
  }

  function handleMonthlyFeeChange(event: ChangeEvent<HTMLInputElement>) {
    setMonthlyFee(formatCurrency(event.target.value));
  }

  function handleStartDateChange(event: ChangeEvent<HTMLInputElement>) {
    setStartDate(event.target.value);
    if (!isDateBeforeToday(event.target.value)) {
      setError({ field: 'startDate', message: 'A data de lançamento não pode ser futura!' });
    } else {
      removeError('startDate');
    }
  }

  function handleContractDateChange(event: ChangeEvent<HTMLInputElement>) {
    setContractDate(event.target.value);
    if (!isDateBeforeToday(event.target.value)) {
      setError({ field: 'contractDate', message: 'A data do contrato não pode ser futura!' });
    } else {
      removeError('contractDate');
    }
  }

  function handleKindOfCompanyChange(kind: 'default' | 'seller') {
    setKindOfCompany(kind);
  }

  function handlePlanValueChange(event: ChangeEvent<HTMLInputElement>) {
    setPlanValue(formatCurrency(event.target.value));
    if(parseCurrencyStringToFloat(formatCurrency(event.target.value)) as number >= 49.9) {
      setError({ field: 'planValue', message: 'O valor padrão é de R$ 49,90. Insira um valor menor que este' })
    } else {
      removeError('planValue')
    }
  }

  function handlePlanTrialChange(event: ChangeEvent<HTMLInputElement>) {
    setPlanTrial(onlyNumbers(event.target.value));
    if (onlyNumbers(event.target.value) <= 7) {
      setError({ field: 'planTrial', message: 'O período padrão é de 7 dias. Insira um período maior que este' })
    } else {
      removeError('planTrial')
    }
  }

  const handleCepChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setCep(formatCep(event.target.value));
      if (!event.target.value) {
        setError({ field: 'cep', message: 'CEP é obrigatório!' });
      }
      if (formatCep(event.target.value).length === 9) {
        setIsGettingCepInfo(true);
        const response = await fetch(`https://viacep.com.br/ws/${event.target.value}/json/`);
        const cepInfo = await response.json();
        if (cepInfo.erro) {
          setError({ field: 'cep', message: 'CEP inválido!' });
          setStreetName('');
          setDistrict('');
          setCity('');
          setUf('');
          return;
        }
        setStreetName(cepInfo.logradouro);
        setDistrict(cepInfo.bairro);
        setCity(cepInfo.localidade);
        setUf(cepInfo.uf);
      }
      if (formatCep(event.target.value).length !== 9) {
        setError({ field: 'cep', message: 'CEP inválido!' });
        setStreetName('');
        setDistrict('');
        setCity('');
        setUf('');
        return;
      }
      removeError('cep');
    } catch (error) {
      toast.error(`Ocorreu um erro ao buscar o CEP (${error})`);
    } finally {
      setIsGettingCepInfo(false);
    }
  }, [removeError, setError]);

  function resetFields() {
    setCnpj('');
    setCorporateName('');
    setFantasyName('');
    setContactName('');
    setContactCellphone('');
    setContactEmail('');
    setCep('');
    setStreetName('');
    setNumber('');
    setComplement('');
    setDistrict('');
    setCity('');
    setUf('');
    setPixType({ value: 'E-mail', label: 'E-mail' });
    setCnpjPix('');
    setCpfPix('');
    setEmailPix('');
    setCellphonePix('');
    setRandomPix('');
    setAgency('');
    setAccount('');
    setCardholder('');
    setCnpjBank('');
    setSelectedBank({ value: '', label: 'Selecione um banco' });
    setElegiblesQuantity('');
    setAdherenceGoal('');
    setEmployeesQuantity('');
    setMonthlyFee('');
    setStartDate('');
    setContractDate('');
    setKindOfCompany('default');
    setAuthenticationType({ value: 'cpf', label: 'CPF' });
    setIsReadOnly(false);
    setAssociatedCompanies([]);
    setLoginEmail('');
    setPassword('');
    setPasswordConfirmation('');
    setCupons([]);
    setCupomName('');
    setCupomPlanTrial('');
    setCupomPlanValue('');
    setCupomPlanKind({ value: '', label: 'Selecione um tipo de benefício' });
    setPlanTrial('');
    setPlanValue('');
    setPlanKind({ value: '', label: 'Selecione um tipo de benefício' });
    setShowAtCompaniesListAppRegister(true);
    setViewByAssociatedCompanies(false);
  }

  return {
    getErrorMessageByFieldName,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    setLoginEmail,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    isGettingCepInfo,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    employeesQuantity,
    handleEmployeesQuantityChange,
    elegiblesQuantity,
    handleEligibleQuantityChange,
    adherenceGoal,
    handleAderenceGoalChange,
    resetFields,
    errors,
    setEmployeesQuantity,
    setElegiblesQuantity,
    setAdherenceGoal,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    monthlyFee,
    handleMonthlyFeeChange,
    contractDate,
    handleContractDateChange,
    startDate,
    handleStartDateChange,
    setStartDate,
    setContractDate,
    setMonthlyFee,
    handleKindOfCompanyChange,
    kindOfCompany,
    setAuthenticationType,
    authenticationType,
    setIsReadOnly,
    isReadOnly,
    setKindOfCompany,
    setPlanKind,
    planValue,
    handlePlanValueChange,
    planKind,
    planTrial,
    handlePlanTrialChange,
    setPlanValue,
    setPlanTrial,
    planId,
    setPlanId,
    setShowAtCompaniesListAppRegister,
    showAtCompaniesListAppRegister,
    setViewByAssociatedCompanies,
    viewByAssociatedCompanies,
  };
}
