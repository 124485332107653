import formatDate from '../../../../../utils/formatDate';
import { Card } from '../styles';
import { Dispatch, SetStateAction } from 'react';

interface DateCardInterface {
  date: string;
  setRehabilitateDate: Dispatch<SetStateAction<string>>;
  setRehabilitateDateModalShow: Dispatch<SetStateAction<boolean>>;
  deleteImage: string;
}

export default function DateCard({
  date,
  setRehabilitateDate,
  setRehabilitateDateModalShow,
  deleteImage,
}: DateCardInterface) {
  return (
    <Card>
      <div className="info">
        <div className="card-title">
          <strong>{formatDate(date?.split('_')[0])}</strong>
          <small>
            {date?.includes('_') ? date?.split('_')[1] : 'Dia inteiro'}
          </small>
          </div>
      </div>
      <div className="actions">
        <button
          type="button"
          onClick={() => {
            setRehabilitateDate(date);
            setRehabilitateDateModalShow(true);
          }}
        >
          <img
            src={deleteImage}
            alt="delete"
            title="Reabilitar data"
          />
        </button>
      </div>
    </Card>
  );
}
