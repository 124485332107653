/* eslint-disable react/forbid-prop-types */
import { useState } from 'react';
import { VictoryPie } from 'victory';

import NoData from '../../../components/NoData';
import OpacityAnimation from '../../../components/OpacityAnimation';
import useThemeContext from '../../../contexts/theme';
import { Card } from '../styles';
import { GenericKeyValueType } from '../types';

interface IUsersSoCard {
  operatingSystems: GenericKeyValueType;
}

export default function UsersSoCard({ operatingSystems }: IUsersSoCard) {
  const hasInfo = operatingSystems['Android'] || operatingSystems['iOS'];
  const [labelBeingShow, setLabelBeingShow] = useState({ description: '', value: '' });
  const { selectedTheme } = useThemeContext();

  return (
    <Card>
      <div className="card-title">
        <div>Uso por SO</div>
      </div>

      {!!operatingSystems && !!hasInfo && (
      <>
        <OpacityAnimation>
          <div className="card-main-graph">
            <VictoryPie
              data={[
                {
                  x: 'Android',
                  y: operatingSystems['Android'],
                  label: ' ',
                },
                {
                  x: 'iOS',
                  y: operatingSystems['iOS'],
                  label: ' ',
                },
              ]}
              animate={{
                duration: 2000,
                onLoad: { duration: 5000 },
              }}
              colorScale='qualitative'
              innerRadius={80}
              height={250}
              padAngle={0}
              padding={{ bottom: 10, top: 0 }}
              events={[{
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: 'data',
                        mutation: ({ style, data, index }) => {
                          setLabelBeingShow({ description: data[index].x, value: data[index].y });
                          return { style: Object.assign({}, style, { opacity: '0.8', cursor: 'help' }) };
                        }
                      }
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: 'data',
                        mutation: () => {
                          setLabelBeingShow({ description: '', value: '' });
                          return null;
                        }
                      }
                    ];
                  }
                }
              }]}
              style={{
                labels: { fontSize: 0, fill: selectedTheme === 'dark' ? '#fff' : '#000' }
              }}
              labelPlacement="vertical"
              labelRadius={0.1}
              labelPosition="endAngle"
            />
            <label>
              <span>{labelBeingShow.value ? `${Number(labelBeingShow.value).toFixed(0)}%` : ''}</span>
              <span>{labelBeingShow.description}</span>
          </label>
          </div>
        </OpacityAnimation>
      </>
      )}

      {(!operatingSystems || !hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis sobre uso por SO
          </>
                  )}
      />
      )}
    </Card>
  );
}
