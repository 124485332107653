import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray[75]};
  padding-bottom: 16px;

  header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;

    >div {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.gray[201]};
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
        span {
          width: 100%;
          height: 100%;
          background: ${({ theme }) => theme.colors.primary.light};
          color: ${({ theme }) => theme.colors.gray[900]};
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          font-size: 20px;
        }
    }

    strong {
      font-size: 16px;
    }

    span {
      opacity: 0.8;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      &:hover{
        opacity: 0.5;
        transition: ease-in 0.2s;
      }
      &:not(:hover) {
        opacity: 1;
        transition: ease-in 0.2s;
      }
    }

    a {
      background: transparent;
      border: none;
      /* width: 23px;
      height: 23px; */

      img {
        padding: 0;
        width: 23px;
      }
      img {
        padding: 0;
        width: 23px;
      }
      }

    a.qrCodeLink {
      padding: 0;
      width: 24px;
      margin-top: 1px;

      img {
        width: 100% !important;
      }
    }

    button {
      border: none;
      background: transparent;
      /* margin-right: 8px; */


      img {
        padding: 0;
        width: 23px;
      }
    }

    button.profileImage {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.gray[201]};
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
`;
