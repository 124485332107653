import { useState } from "react"
import { CategoryBeingDeletedType } from "../../types";

export default function useDeleteModal() {
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [categoryBeingDeleted, setCategoryBeingDeleted] = useState({} as CategoryBeingDeletedType);

  function handleOpenDeleteModal(category: CategoryBeingDeletedType) {
    setCategoryBeingDeleted(category)
    setDeleteModalShow(true);
  }

  return {
    handleOpenDeleteModal,
    categoryBeingDeleted,
    setDeleteModalShow,
    deleteModalShow,
  }
}
