import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import edit from '../../../../../assets/images/icons/edit.svg';
import OpacityAnimation from '../../../../../components/OpacityAnimation';
import { PartnerActivityType } from '../../../types';
import {
  Card,
} from './styles';

interface ActivitiesListInterface {
  list: PartnerActivityType[];
}

export default function ActivitiesList({
  list,
}: ActivitiesListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {list?.map((activity) => (
          <Col key={activity.id}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{activity.name}</strong>
                    <small>{activity.status}</small>
                  </div>
                  <span>
                    Categoria:
                    {' '}
                    {activity.generalData.category}
                  </span>
                  <span>
                    Subcategoria:
                    {' '}
                    {activity.generalData.subcategory}
                  </span>
                  <span>
                    Faixa etária:
                    {' '}
                    De
                    {' '}
                    {activity.generalData.ageRange[0]}
                    {' '}
                    a
                    {' '}
                    {activity.generalData.ageRange[1]}
                    {' '}
                    anos
                  </span>
                  {/* <span>
                      {activity.generalData.kind}
                    </span> */}
                </div>

                <div className="actions">
                  <Link to={`/activities/${activity.id}?return=partners`}>
                    <img src={edit} alt="edit" title="Editar atividade" className="primaryColor" />
                  </Link>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
