import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface ForumServiceInterface {
  id?: string;
  reqBody?: ReqBodyType;
  token: string;
}

class ForumService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getPosts = async ({
    token,
  }: ForumServiceInterface) => {
    return this.httpClient.get({
      path: '/forum-internal',
      token,
    });
  }

  createPost = async ({
    token,
    reqBody,
  }: ForumServiceInterface) => {
    return this.httpClient.post({
      path: '/forum-internal/post',
      token,
      reqBody,
      contentType: 'multipart/form-data',
    });
  }

  createComment = async ({
    token,
    reqBody,
  }: ForumServiceInterface) => {
    return this.httpClient.post({
      path: '/forum-internal/comment',
      reqBody,
      token,
    });
  }

  updatePost = async ({
    id,
    reqBody,
    token,
  }: ForumServiceInterface) => {
    return this.httpClient.put({
      path: `/forum-internal/post/${id}`,
      reqBody,
      token,
    });
  }

  updateComment = async ({
    id,
    reqBody,
    token,
  }: ForumServiceInterface) => {
    return this.httpClient.put({
      path: `/forum-internal/comment/${id}`,
      reqBody,
      token,
    });
  }

  deletePost = ({
    id,
    token,
  }: ForumServiceInterface) => {
    return this.httpClient.delete({
      path: `/forum-internal/post/${id}`,
      token,
    });
  }

  deleteComment = ({
    id,
    token,
  }: ForumServiceInterface) => {
    return this.httpClient.delete({
      path: `/forum-internal/comment/${id}`,
      token,
    });
  }
}

export default new ForumService();
