import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { MappedCategoriesType } from "../../types";

interface IUseSearches {
  categories: MappedCategoriesType[];
  setFilteredList: Dispatch<SetStateAction<MappedCategoriesType[]>>
}

export default function useSearches({ categories, setFilteredList }: IUseSearches) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState({ value: '', label: 'Todos' });

  const filterCategories = useCallback(() => {
    const filteredCategories = categories.filter((ct) => {
      const nameFilter = ct.name.toLowerCase().includes(String(searchTerm).toLowerCase());
      const typeFilter = !selectedType.value || ct.type === selectedType.value;

      return nameFilter && typeFilter;
    });

    setFilteredList(filteredCategories);
  }, [categories, setFilteredList, searchTerm, selectedType.value]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleTypeChange = useCallback((type: { value: string, label: string }) => {
    setSelectedType(type);
  }, []);

  useEffect(() => {
    filterCategories();
  }, [filterCategories]);

  return {
    searchTerm,
    handleChangeSearchTerm,
    selectedType,
    handleTypeChange,
  }
}
