const tags = [
"acrobacia",
"alimentação",
"animação",
"apresentação musical",
"apresentação teatral",
"arte",
"arte circense",
"arte marcial",
"artes",
"artes cênicas",
"artes plásticas",
"artista",
"artistas",
"autodefesa",
"balé",
"biliguismo",
"biologia",
"brincadeira",
"brincadeiras",
"brincadeiras cooperativas",
"brincadeiras de roda",
"canal no youtube",
"cantigas",
"canto",
"ciências",
"colagem",
"comidas",
"composição de músicas",
"contos",
"coordenação motora",
"cores",
"corte",
"costura",
"cozinha",
"criação de vídeos",
"criatividade",
"cultura",
"cultura chinesa",
"cultura coreana",
"customização",
"dança",
"dança circular",
"dança contemporânea",
"dança ritmica",
"danças regionais",
"desenho",
"desenho coreano",
"desenho japones",
"desenho profissional",
"edição",
"edição de vídeo",
"empreendedorismo",
"esporte",
"estratégia",
"experiências",
"experimentos",
"fazendo a própria tinta",
"física",
"geogame",
"gestos",
"gincanas",
"história",
"histórias",
"instrumentos musicais",
"jazz",
"jogo",
"jogo de celular",
"jogo de computador",
"jogos",
"leitura",
"língua de sinais",
"linguagem",
"literatura",
"livros",
"ludicidade",
"mágica",
"malabares",
"matemática",
"materiais reciclavéis",
"meditação",
"modelagem",
"música",
"nota musical",
"olfato",
"palco",
"palhaçaria",
"peça teatral",
"pigmentos naturais",
"pintura",
"programação",
"programas de tv",
"química",
"respiração",
"riscantes",
"roupas",
"samba",
"sapateado",
"saúde emocional",
"saúde mental",
"séries",
"socioemocional",
"tato",
"técnicas de desenho",
"técnicas de teatro",
"tecnologia",
"texto",
"textura",
"tinta",
"tinta orgânica",
"valsa",
"visão",
"vocalista",];

export default tags;
