/* eslint-disable max-len */
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import schedulesService from '../../../../services/schedulesService';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { ScheduleType, ScheduleTypeFromAllSchedulesApi } from '../../types';

interface UseDownloadExcelInterface {
  schedules: ScheduleType[];
  dateBeingShown: string;
}

export default function useDownloadExcel({
  schedules,
  dateBeingShown,
}: UseDownloadExcelInterface) {
  const { apiCall } = useApiCall();

  const downloadFullExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: schedulesService.getAllSchedules,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível baixar a planilha (${apiResponse.error})`);
          return;
        }
        const schedulesList: ScheduleTypeFromAllSchedulesApi[] = apiResponse.appointments;
        const transformAllChildsInArray = schedulesList?.map((sch) => (
          { ...sch, crianca: Array.isArray(sch.crianca) ? sch.crianca : [sch.crianca] }
        ));

        const mappedSchedulesList = transformAllChildsInArray?.map(({
          crianca, parceiro, data, horario, usuario, atividade, empresa
        }) => {
          type CurType = { birthDate: string, name: string }
          type AccType = { [key: string]: string | CurType }

          const childs = crianca.reduce((acc: AccType, cur: CurType, index) => {
            const propertyName = `Criança ${index + 1}`;
            acc[propertyName] = cur?.name || cur;
            return acc;
          }, {} as AccType);

          return ({
            Atividade: atividade,
            Parceiro: parceiro,
            'Data do agendamento': data,
            Horário: horario,
            Usuário: usuario,
            Empresa: empresa,
            ...childs,
          });
        });
        const currentDate = getCurrentDateWithHyphen();
        ExportXlsx({
          filename: `Agendamentos Kiddlepass (Todo Histórico) - ${currentDate}`,
          data: mappedSchedulesList,
        });
      },
      catchMessage: 'Não foi possível baixar a lista de agendamentos'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  function downloadPageExcel() {
    const mappedSchedulesList = schedules?.map(({
      criancas, atividade, parceiro, data, horario,
    }) => {
      const childProps = criancas?.reduce((props, { nome, responsavel, nascimento, empresa }, index) => ({
        ...props,
        [`Nome criança ${index + 1}`]: nome,
        [`Responsável criança ${index + 1}`]: responsavel,
        [`Empresa do responsável criança ${index + 1}`]: empresa,
        [`Data de nascimento criança ${index + 1}`]: nascimento,
      }), {});

      return {
        Atividade: atividade,
        Parceiro: parceiro,
        'Data do agendamento': data,
        Horário: horario,
        ...childProps,
      };
    });

    ExportXlsx({
      filename: `Agendamentos Kiddlepass do dia ${dateBeingShown}`,
      data: mappedSchedulesList,
    });
  }

  return {
    downloadFullExcel,
    downloadPageExcel,
  };
}
