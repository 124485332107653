/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  useState,
} from 'react';
import useDownloadExcel from './functions/useDownloadExcel';
import useLoadCompanies from './functions/useLoadCompanies';
import useSearches from './functions/useSearches';
import useUpdateCompanyStatus from './functions/useUpdateCompanyStatus';
import { MappedCompany } from '../types';

export default function useActivities() {
  const [fullCompanies, setFullCompanies] = useState<MappedCompany[]>([]);

  const {
    searchTerm,
    setSearchTerm,
    selectedStatus,
    currentPage,
    filteredCompanies,
    setCompaniesSplited,
    setCurrentPage,
    setFilteredCompanies,
    handlePageChange,
    pagesQuantity,
    handleStatusChange,
    handleChangeSearchTerm,
  } = useSearches({
    fullCompanies,
  });

  const {
    loadCompanies,
    isLoadLoading,
    doesListApiHasError,
  } = useLoadCompanies({
    setFullCompanies,
    setSearchTerm,
    setCompaniesSplited,
    setCurrentPage,
    setFilteredCompanies,
  });

  const {
    isUpdating,
    changeStatusModalShow,
    setChangeStatusModalShow,
    companyChangingStatus,
    updateCompanyStatus,
    handleOpenChangeStatusModal,
  } = useUpdateCompanyStatus({ loadCompanies });

  const { downloadExcel } = useDownloadExcel({ fullCompanies });

  const isLoading = isLoadLoading || isUpdating;

  function handleTryAgain() {
    loadCompanies();
  }

  return {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    doesListApiHasError,
    filteredCompanies,
    handleTryAgain,
    fullCompanies,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleStatusChange,
    selectedStatus,
    setChangeStatusModalShow,
    updateCompanyStatus,
    changeStatusModalShow,
    companyChangingStatus,
    handleOpenChangeStatusModal,
    downloadExcel,
  };
}
