import { useState } from "react";
import { ProgramBeingDeletedType, ProgramBeingEditedType, ProgramToBeSentFeedbackType } from "../../types";

export default function useModals() {
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [feedbackModalShow, setFeedbackModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [programBeingEdited, setProgramBeingEdited] = useState({} as ProgramBeingEditedType);
  const [programToBeSentFeedback, setProgramToBeSentFeedback] = useState({} as ProgramToBeSentFeedbackType);
  const [programBeingDeleted, setProgramBeingDeleted] = useState({} as ProgramBeingDeletedType);

  function handleOpenEditModal(programObject: ProgramBeingEditedType) {
    setEditModalShow(true);
    setProgramBeingEdited(programObject);
  }

  function handleOpenFeedbackModal(programObject: ProgramToBeSentFeedbackType) {
    setFeedbackModalShow(true);
    setProgramToBeSentFeedback(programObject)
  }

  function handleOpenDeleteModal(programObject: ProgramBeingDeletedType) {
    setDeleteModalShow(true);
    setProgramBeingDeleted(programObject);
  }

  return {
    setAddModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    addModalShow,
    editModalShow,
    setEditModalShow,
    programBeingEditted: programBeingEdited,
    deleteModalShow,
    setDeleteModalShow,
    programBeingDeleted,
    feedbackModalShow,
    setFeedbackModalShow,
    programToBeSentFeedback,
    handleOpenFeedbackModal,
  }
}
