import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import MyModal from "../../../../../../components/Modal";
import { FilterRadioButtonsContainer } from "../../../../../../components/FilterRadioButtonsContainer";
import FilterRadioButton from "../../../../../../components/FilterRadioButtons";
import Input from "../../../../../../components/Input";
import { Link } from "react-router-dom";
import { ModalContainer } from "./styles";
import List from "./components/list";
import OpacityAnimation from "../../../../../../components/OpacityAnimation";

interface IAssociatedCompaniesModal {
  associatedCompaniesModalShow: boolean;
  setAssociatedCompaniesModalShow: Dispatch<SetStateAction<boolean>>;
  associatedCompanies: string[];
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  addOneAssociatedCompany: (company: string) => void;
  removeAssociatedCompany: (company: string) => void;
  companyBeingAdded: string;
  setCompanyBeingAdded: Dispatch<SetStateAction<string>>;
}

export default function AssociatedCompaniesModal({
  associatedCompaniesModalShow,
  setAssociatedCompaniesModalShow,
  associatedCompanies,
  handleFileUpload,
  addOneAssociatedCompany,
  removeAssociatedCompany,
  companyBeingAdded,
  setCompanyBeingAdded,
}: IAssociatedCompaniesModal) {
  const [view, setView] = useState('list');

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const csvEgRef = useRef<HTMLAnchorElement>(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };
  const downloadCsvEg = () => {
    csvEgRef.current?.click();
  }

  return (
    <MyModal
      show={associatedCompaniesModalShow}
      onClose={() => setAssociatedCompaniesModalShow(false)}
      title="Gerenciamento de empresas associadas"
      modalBody={(
        <ModalContainer>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              onClick={() => setView('list')}
              selected={view === 'list'}
            >
              Lista
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => setView('addOne')}
              selected={view === 'addOne'}
            >
              Adicionar
            </FilterRadioButton>
            <FilterRadioButton
              onClick={() => handleClick()}
              selected={false}
            >
              Adicionar vários (csv)
            </FilterRadioButton>
            <FilterRadioButton
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => downloadCsvEg()}
              selected={false}
            >
              <Link ref={csvEgRef} to="/files/Modelo importar empresas associadas.csv" target="_blank" download style={{ display: 'none' }} />
                Exemplo CSV
            </FilterRadioButton>
            <Input
              type="file"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(e) => handleFileUpload(e)}
              accept=".csv"
              autoComplete="new-password"
            />
          </FilterRadioButtonsContainer>
          {view === 'list' && (
            <OpacityAnimation delay={0.1}>
              <List
                list={associatedCompanies}
                onDeleteAssociatedCompany={(company: string) => removeAssociatedCompany(company)}
              />
            </OpacityAnimation>
          )}
          {view === 'addOne' && (
          <OpacityAnimation delay={0.1}>
            <Input
              value={companyBeingAdded}
              onChange={(event) => setCompanyBeingAdded(event.target.value)}
              placeholder="Nome da empresa associada"
            />
          </OpacityAnimation>
          )}
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type={view === 'addOne' ? 'action' : 'info'}
      actionButtonLabel="Adicionar"
      isActionButtonDisabled={!companyBeingAdded}
      onAction={() => addOneAssociatedCompany(companyBeingAdded)}
    />
  )
}
