import { Dispatch, SetStateAction } from "react";
import Button from "../../../../../../../../components/Button";
import { ViewType } from "../../types";
import { DeleteCommentContainer } from "./styles";

interface IDeleteComment {
  deleteComment: () => Promise<void>;
  setView: Dispatch<SetStateAction<ViewType>>;
}

export default function DeleteComment({ deleteComment, setView }: IDeleteComment) {
  return (
    <DeleteCommentContainer>
      <Button cancel small onClick={() => setView('default')}>
        Cancelar
      </Button>

      <Button small danger onClick={() => deleteComment()}>
        Confirmar
      </Button>
    </DeleteCommentContainer>
  )
}
