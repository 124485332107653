/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import MyModal from "../../../../../components/Modal";
import Input from "../../../../../components/Input";
import { ModalContainer } from "./styles";
import OpacityAnimation from "../../../../../components/OpacityAnimation";
import FormGroup from "../../../../../components/FormGroup";
import useEditModal from "./useEditModal";
import { CategoryBeingEditedType } from "../../types";
import { Group } from "../AddModal/styles";

interface IEditModal {
  editModalShow: boolean;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  categoryBeingEditted: CategoryBeingEditedType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadCategories: () => Promise<void>;
}

export default function EditModal({
  editModalShow,
  setEditModalShow,
  setIsLoading,
  loadCategories,
  categoryBeingEditted,
}: IEditModal) {
  const {
    editCategory,
    name,
    handleNameChange,
    getErrorMessageByFieldName,
    isEditFormValid,
  } = useEditModal({ setIsLoading, loadCategories, setEditModalShow, categoryBeingEditted });

  return (
    <MyModal
      show={editModalShow}
      onClose={() => setEditModalShow(false)}
      title={`Editar categoria ${categoryBeingEditted.name}`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
          <Group>
            <div className="title">
              Nome da categoria
            </div>
            <FormGroup error={getErrorMessageByFieldName('name')}>
              <Input
                error={getErrorMessageByFieldName('name')}
                value={name}
                onChange={handleNameChange}
                placeholder="Nome da categoria"
              />
            </FormGroup>
          </Group>
          </OpacityAnimation>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Editar"
      isActionButtonDisabled={!isEditFormValid}
      onAction={() => editCategory()}
    />
  )
}
