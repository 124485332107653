import { CommentType } from "../../../../types";
import { ContentContainer, MediaContainer } from "./styles";

interface IContent {
  text: string;
  file?: string;
  comments?: CommentType[];
  fileType?: string;
}

export default function Content({ text, file, comments, fileType }: IContent) {
  console.log(comments);

  return (
    <ContentContainer>
      <div>
        {text}
      </div>

    {file && (
      <MediaContainer>
        {fileType?.startsWith('image') ? (<img src={file} alt={fileType} />) : (<video src={file} controls />)}
      </MediaContainer>
    )}
    </ContentContainer>
  )
}
