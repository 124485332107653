import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { ChangeEvent } from 'react';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';

interface ComplementaryDataInterface {
  classPrice: string;
  handleClassPriceChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contractDate: string;
  handleContractDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  loginEmail: string;
  handleLoginEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  edit: boolean;
  password: string;
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmation: string;
  handlePasswordConfirmationChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function ComplementaryData({
  classPrice,
  handleClassPriceChange,
  contractDate,
  handleContractDateChange,
  getErrorMessageByFieldName,
  loginEmail,
  handleLoginEmailChange,
  edit,
  password,
  handlePasswordChange,
  passwordConfirmation,
  handlePasswordConfirmationChange,
}: ComplementaryDataInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados complementares
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Valor/aula
          </div>
          <FormGroup>
            <Input
              value={classPrice}
              onChange={handleClassPriceChange}
              placeholder="Valor acordado por aula"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Data início contrato vigente
          </div>
          <FormGroup error={getErrorMessageByFieldName('contractDate')}>
            <Input
              type="date"
              value={contractDate}
              onChange={handleContractDateChange}
              error={getErrorMessageByFieldName('contractDate')}
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            E-mail de login (Principal) *
          </div>
          <FormGroup error={getErrorMessageByFieldName('loginEmail')}>
            <Input
              value={loginEmail}
              onChange={handleLoginEmailChange}
              placeholder="E-mail para acessar o sistema administrativo"
              error={getErrorMessageByFieldName('loginEmail')}
              disabled={edit}
            />
          </FormGroup>
        </Group>
        {!edit && (
          <>
            <Group>
            <div className="title">
              Senha para primeiro acesso *
            </div>
            <FormGroup error={getErrorMessageByFieldName('password')}>
              <Input
                type='password'
                value={password}
                onChange={handlePasswordChange}
                placeholder="Senha provisória"
                error={getErrorMessageByFieldName('password')}
              />
            </FormGroup>
            </Group>
            <Group>
              <div className="title">
                Confirmação de senha *
              </div>
              <FormGroup error={getErrorMessageByFieldName('passwordConfirmation')}>
                <Input
                  type='password'
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  placeholder="Confirme a senha"
                  error={getErrorMessageByFieldName('passwordConfirmation')}
                />
              </FormGroup>
            </Group>
          </>
        )}
      </AsideContainer>
    </StyledContainer>
  );
}
