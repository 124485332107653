/* eslint-disable no-nested-ternary */
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  Card,
} from './styles';
import { ApprovementType } from '../../types';


interface ApprovementDetailsInterface {
  approvementId: string;
  activityId: string;
  name: string;
  minima?: number;
  maxima?: number;
  descricao?: string;
  datasDesabilitadas?: { acao: string, data: string };
  field: 'descrição' | 'datas desabilitadas' | 'idade';
}

interface ListInterface {
  filteredList: ApprovementType[];
  handleOpenDetailModal: ({
    approvementId,
    activityId,
    name,
    minima,
    maxima,
    field,
    descricao,
    datasDesabilitadas,
  }: ApprovementDetailsInterface) => void;
}

export default function List({
  filteredList,
  handleOpenDetailModal,
}: ListInterface) {
  const navigate = useNavigate();
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((appr) => (
          <Col key={appr.approvementId}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: false }}
              transition={{ delay: 0.1, type: 'tween', stiffness: 10000 }}
            >
              <Card onClick={() => {
                if (appr.action === 'create') {
                  navigate(`/activitiesApprove/${appr.approvementId}`);
                  return;
                }
                handleOpenDetailModal({
                  approvementId: appr.approvementId,
                  activityId: appr.activityId,
                  name: appr.name,
                  field: appr.field === 'description' ? 'descrição' : (
                    appr.field === 'ages' ? 'idade' : 'datas desabilitadas'
                  ),
                  minima: appr?.minima,
                  maxima: appr?.maxima,
                  descricao: appr.descricao,
                  datasDesabilitadas: appr.datasDesabilitadas,
                });
              }}
              >
                <div className="info">
                  <div className="card-title">
                    <strong>{appr.name}</strong>
                    <small className={appr.action === 'create' ? 'green' : ''}>
                      {`${appr.action === 'create' ? 'Nova atividade' : (
                        appr.field === 'description' ? 'Descrição alterada' : (
                          appr.field === 'ages' ? 'Idade alterada' : 'Datas desabilitadas'
                        )
                      )}`}
                    </small>
                  </div>
                  <span>
                    Clique para visualizar os detalhes e aprovar.
                  </span>
                </div>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
