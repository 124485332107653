import React from "react";
import { Dispatch, SetStateAction } from "react";

interface TitleInterface {
  hasClasses: boolean;
  addImage: string;
  setAddTimetableModalShow: Dispatch<SetStateAction<boolean>>
}

export default function Title({
  hasClasses,
  addImage,
  setAddTimetableModalShow,
}: TitleInterface) {
  return (
    <div className="card-title">
      <div>
        Grade horária *
      </div>
      {hasClasses && (
      <img
        src={addImage}
        alt="add"
        className="primaryColor"
        title="Adicionar novo horário"
        onClick={() => setAddTimetableModalShow(true)}
      />
      )}
    </div>
  );
}
