import { Col, Container, Row } from 'react-bootstrap';
import differenceBetweenDates from '../../../../utils/differenceBetweenDates';
import { ChildrenAtScheduleType } from '../../types';
import { KidsContainer, KidsGroup } from './styles';

interface KidsListInterface {
  list: ChildrenAtScheduleType[];
}

export default function KidsList({ list }: KidsListInterface) {
  return (
    <KidsContainer>
      <Container>
        <Row xs={1} md={2} lg={2}>
          {list.map((child) => (
            <Col key={child.nome}>
              <KidsGroup>
                <div className="info">
                  <div className="card-title">
                    <strong>{child.nome}</strong>
                    {child.nascimento && (
                      <small>{`${differenceBetweenDates(child.nascimento).years} anos`}</small>
                    )}
                  </div>
                  <span>
                    Responsável:
                    {' '}
                    {child.responsavel}
                  </span>
                  <span>
                    {child.hasDeficiency ? '⚠️' : ''} Observação:
                    {' '}
                    {child.observation || 'Sem observação'}
                  </span>
                  <span>
                    Necessário Intérprete de Libras:
                    {' '}
                    {child.needsInterpreter ? '✅' : '❌'}
                  </span>
                </div>
              </KidsGroup>
            </Col>
          ))}
        </Row>
      </Container>
    </KidsContainer>
  );
}
