import { useCallback, useEffect, useState } from "react";
import useApiCall from "../../../../hooks/useApiCall"
import { InternalUserObject } from "../../types";
import { toast } from "react-toastify";
import internalUsersService from "../../../../services/internalUsersService";

export default function useLoadInternalUsers() {
  const [isLoading, setIsLoading] = useState(false);
  const [internalUsers, setInternalUsers] = useState<InternalUserObject[]>([])
  const [filteredInternalUsers, setFilteredInternalUsers] = useState<InternalUserObject[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const { apiCall } = useApiCall();

  const loadInternalUsers = useCallback(async () => {
    await apiCall({
      apiToCall: internalUsersService.getInternalUsers,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response) => {
        const internalUsersList: InternalUserObject[] = response.users;
        if (!internalUsersList) {
          toast.error(`Não foi possível carregar a lista de usuários`);
          setDoesListApiHasError(true);
          return;
        }
        setInternalUsers(internalUsersList)
        setFilteredInternalUsers(internalUsersList);
        setDoesListApiHasError(false);
      }
    });
  }, [apiCall])

  useEffect(() => { loadInternalUsers() }, [loadInternalUsers]);

  return {
    isLoading,
    filteredInternalUsers,
    doesListApiHasError,
    loadInternalUsers,
    setFilteredInternalUsers,
    setIsLoading,
    internalUsers,
  }
}
