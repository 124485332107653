/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';
import HttpClient from './utils/HttpClient';

interface SchedulesServiceInterface {
  date?: string;
  id?: string;
  token: string;
  reqBody?: ReqBodyType;
  userId?: string;
}

class SchedulesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getAllSchedules = async ({
    token,
  }: SchedulesServiceInterface) => {
    return this.httpClient.get({
      path: '/appointments/download',
      token,
    });
  }

  getSchedulesList = async ({
    date,
    token
  }: SchedulesServiceInterface) => {
    return this.httpClient.get({
      path: `/appointments?date=${encodeURIComponent(date!)}`,
      token,
    });
  }

  createSchedule = async ({
    userId,
    token,
    reqBody,
  }: SchedulesServiceInterface) => {
    return this.httpClient.post({
      path: `/appointments?userId=${userId}`,
      token,
      reqBody,
    });
  }

  deleteSchedule = async ({
    id,
    token,
  }: SchedulesServiceInterface) => {
    return this.httpClient.delete({
      path: `/appointments/${id}`,
      token,
    });
  }

  getSchedulesPerformance = async ({
    token,
  }: SchedulesServiceInterface) => {
    return this.httpClient.get({
      path: '/appointments-performance',
      token,
    });
  }

  getActivitiesRanking = async ({
    token,
  }: SchedulesServiceInterface) => {
    return this.httpClient.get({
      path: '/appointments-ranking',
      token,
    });
  }
}

export default new SchedulesService();
