/* eslint-disable no-nested-ternary */
import { Col, Container, Row } from 'react-bootstrap';

import { useQuery } from '../../hooks/useQuery';

import Loader from '../Loader';
import PageHeader from '../PageHeader';
import { Container as StyledContainer } from './styles';

import Button from '../Button';
import FilePreview from '../FilePreview';
import { ButtonContainer } from '../Form';

import useActivityForm from './useActivityForm';

import renderArray from '../../utils/renderArrayWithComa';
import OpacityAnimation from '../OpacityAnimation';
import DisabledDatesCard from './components/DisabledDatesCard';
import Duration from './components/Duration';
import GeneralDataCard from './components/GeneralDataCard';
import KindOfActivity from './components/KindOfActivity';
import RequirementsDataCard from './components/RequirementsDataCard';
import TimetableCard from './components/TimetableCard';

interface ActivityFormInterface {
  edit?: boolean;
  approvement?: boolean;
}

export default function ActivityForm({ edit, approvement }: ActivityFormInterface) {
  const {
    isLoading,
    setKindOfActivity,
    kindOfActivity,
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    category,
    categories,
    setCategory,
    subcategory,
    subcategoriesOptions,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    companionOptions,
    setNeedsCompanion,
    level,
    levelOptions,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    setAddTimetableModalShow,
    quantityOfMondayClasses,
    setDayTimesModalShow,
    setDayInfoBeingShow,
    mondayClasses,
    quantityOfTuesdayClasses,
    tuesdayClasses,
    quantityOfWednesdayClasses,
    wednesdayClasses,
    quantityOfThursdayClasses,
    thursdayClasses,
    quantityOfFridayClasses,
    fridayClasses,
    quantityOfSaturdayClasses,
    saturdayClasses,
    quantityOfSundayClasses,
    sundayClasses,
    hasClasses,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    addTimetableModalShow,
    addNewTimeAtTimetable,
    startTimeBeingAdded,
    endTimeBeingAdded,
    dayBeingAddedAtTimetable,
    timetableDaysOptions,
    setDayBeingAddedAtTimetable,
    handleStartTimeBeingAddedChange,
    vacanciesQty,
    handleVacanciesQtyChange,
    dayTimesModalShow,
    dayInfoBeingShow,
    removeTimeAtTimetable,
    isFormValid,
    minAge,
    maxAge,
    description,
    isRecurrent,
    setIsRecurrent,
    recurrentOptions,
    createActivity,
    partners,
    partner,
    setPartner,
    duration,
    handleDurationChange,
    updateActivity,
    setDisableDateModalShow,
    hasDatesToBeRendered,
    finalDates,
    setRehabilitateDate,
    setRehabilitateDateModalShow,
    selectedRehabilitateDate,
    rehabilitateDate,
    disableDateModalShow,
    selectedDisableDate,
    handleDisableDateChange,
    disableDate,
    isFutureDateChoosed,
    rehabilitateDateModalShow,
    resetDisabledDates,
    hasOriginalDatesChanged,
    handleApprovementActivityDecision,
    isSpecificTime,
    setIsSpecificTime,
    timesOptions,
    selectedDisableTime,
    setSelectedDisableTime,
    featPartner,
    setFeatPartner,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
    selectedTags,
    handleTagsChange,
    requiredFieldsErrorsLabel,
    isFree,
    setIsFree,
    classValue,
    handleClassValueChange,
  } = useActivityForm({ approvement });

  const query = useQuery();
  const returnLink = query.has('return') ? `/${query.get('return')}` : (
    approvement ? '/activitiesApprove' : '/activities'
  );

  return (
    <>
      <Loader isLoading={isLoading} />
      {edit && (
        <PageHeader
          title={`Editar Atividade ${name}`}
          link={returnLink}
        />
      )}

      {approvement && (
        <PageHeader
          title={`Aprovar Atividade ${name}`}
          link={returnLink}
        />
      )}
      <KindOfActivity setKindOfActivity={setKindOfActivity} kindOfActivity={kindOfActivity} />
      {kindOfActivity === 'online' && (
        <Duration handleDurationChange={handleDurationChange} duration={duration} />
      )}
      <Container>
        <Row xs={1} md={1} lg={1}>
          <Col>
            <OpacityAnimation>
              <GeneralDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                name={name}
                handleNameChange={handleNameChange}
                category={category}
                categories={categories}
                setCategory={setCategory}
                partner={partner}
                partners={partners}
                setPartner={setPartner}
                subcategory={subcategory}
                subcategoriesOptions={subcategoriesOptions}
                setSubcategory={setSubcategory}
                isRecurrent={isRecurrent}
                setIsRecurrent={setIsRecurrent}
                recurrentOptions={recurrentOptions}
                setMinAge={setMinAge}
                setMaxAge={setMaxAge}
                handleDescriptionChange={handleDescriptionChange}
                description={description}
                kindOfActivity={kindOfActivity}
                handleVideoLinkChange={handleVideoLinkChange}
                videoLink={videoLink}
                handleMethodologyChange={handleMethodologyChange}
                methodology={methodology}
                handleOrientationsChange={handleOrientationsChange}
                orientations={orientations}
                featPartner={featPartner}
                setFeatPartner={setFeatPartner}
                handleSpecificPeriodChange={handleSpecificPeriodChange}
                specificPeriod={specificPeriod}
                setPeriodSelectionModalShow={setPeriodSelectionModalShow}
                specificPeriodLabel={specificPeriodLabel}
                periodSelectionModalShow={periodSelectionModalShow}
                selectedTags={selectedTags}
                handleTagsChange={handleTagsChange}
                isFree={isFree}
                setIsFree={setIsFree}
                classValue={classValue}
                handleClassValueChange={handleClassValueChange}
              />
            </OpacityAnimation>
          </Col>

          <Col>
            <OpacityAnimation>
              <RequirementsDataCard
                getErrorMessageByFieldName={getErrorMessageByFieldName}
                minAge={minAge}
                handleMinAgeChange={handleMinAgeChange}
                subcategory={subcategory}
                maxAge={maxAge}
                handleMaxAgeChange={handleMaxAgeChange}
                needsCompanion={needsCompanion}
                companionOptions={companionOptions}
                setNeedsCompanion={setNeedsCompanion}
                level={level}
                levelOptions={levelOptions}
                setLevel={setLevel}
                handleNecessaryMaterialsChange={handleNecessaryMaterialsChange}
                necessaryMaterials={necessaryMaterials}
                handleNecessaryKnowledgeChange={handleNecessaryKnowledgeChange}
                necessaryKnowledge={necessaryKnowledge}
                handleClothesChange={handleClothesChange}
                clothes={clothes}
              />
            </OpacityAnimation>
          </Col>
          {kindOfActivity === 'online' && (
            <Col>
              <TimetableCard
                setAddTimetableModalShow={setAddTimetableModalShow}
                quantityOfMondayClasses={quantityOfMondayClasses}
                setDayTimesModalShow={setDayTimesModalShow}
                setDayInfoBeingShow={setDayInfoBeingShow}
                mondayClasses={mondayClasses}
                quantityOfTuesdayClasses={quantityOfTuesdayClasses}
                tuesdayClasses={tuesdayClasses}
                quantityOfWednesdayClasses={quantityOfWednesdayClasses}
                wednesdayClasses={wednesdayClasses}
                quantityOfThursdayClasses={quantityOfThursdayClasses}
                thursdayClasses={thursdayClasses}
                quantityOfFridayClasses={quantityOfFridayClasses}
                fridayClasses={fridayClasses}
                quantityOfSaturdayClasses={quantityOfSaturdayClasses}
                saturdayClasses={saturdayClasses}
                quantityOfSundayClasses={quantityOfSundayClasses}
                sundayClasses={sundayClasses}
                addTimetableModalShow={addTimetableModalShow}
                addNewTimeAtTimetable={addNewTimeAtTimetable}
                startTimeBeingAdded={startTimeBeingAdded}
                endTimeBeingAdded={endTimeBeingAdded}
                dayBeingAddedAtTimetable={dayBeingAddedAtTimetable}
                timetableDaysOptions={timetableDaysOptions}
                setDayBeingAddedAtTimetable={setDayBeingAddedAtTimetable}
                handleStartTimeBeingAddedChange={handleStartTimeBeingAddedChange}
                vacanciesQty={vacanciesQty}
                handleVacanciesQtyChange={handleVacanciesQtyChange}
                dayTimesModalShow={dayTimesModalShow}
                dayInfoBeingShow={dayInfoBeingShow}
                removeTimeAtTimetable={removeTimeAtTimetable}
                hasClasses={hasClasses}
              />
            </Col>
          )}
        </Row>

        <Row xs={1} md={1} lg={2}>
          <Col>
            <StyledContainer>
              <div className="card-title">
                Foto *
              </div>
              <FilePreview
                selectedFile={imageFile}
                setSelectedFile={setImageFile}
                fileType="image"
                noDataLabel="Por favor, escolha uma imagem para ser a capa (thumbnail) de sua atividade. Essa será a imagem mostrada no aplicativo. OBRIGATÓRIO"
              />
            </StyledContainer>
          </Col>
          {kindOfActivity === 'video' && (
            <Col>
              <StyledContainer>
                <div className="card-title">
                  Vídeo *
                </div>
                <FilePreview
                  selectedFile={videoFile}
                  setSelectedFile={setVideoFile}
                  fileType="video"
                  noDataLabel="Por favor, realize o upload do vídeo da atividade clicando no botão ESCOLHER VÍDEO acima. Caso sua aula seja ao-vivo, altere o campo selecionado no começo do formulário"
                />
              </StyledContainer>
            </Col>
          )}
          {kindOfActivity === 'online' && (
            <Col>
              <DisabledDatesCard
                setDisableDateModalShow={setDisableDateModalShow}
                hasDatesToBeRendered={hasDatesToBeRendered}
                finalDates={finalDates}
                setRehabilitateDate={setRehabilitateDate}
                setRehabilitateDateModalShow={setRehabilitateDateModalShow}
                selectedRehabilitateDate={selectedRehabilitateDate}
                rehabilitateDate={rehabilitateDate}
                disableDateModalShow={disableDateModalShow}
                selectedDisableDate={selectedDisableDate}
                handleDisableDateChange={handleDisableDateChange}
                disableDate={disableDate}
                isFutureDateChoosed={isFutureDateChoosed}
                rehabilitateDateModalShow={rehabilitateDateModalShow}
                resetDisabledDates={resetDisabledDates}
                hasOriginalDatesChanged={hasOriginalDatesChanged}
                isSpecificTime={isSpecificTime}
                setIsSpecificTime={setIsSpecificTime}
                timesOptions={timesOptions}
                selectedDisableTime={selectedDisableTime}
                setSelectedDisableTime={setSelectedDisableTime}
              />
            </Col>
          )}
        </Row>

        <ButtonContainer>
          {!edit && !approvement && (
            <Button
              disabled={!isFormValid}
              onClick={createActivity}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Adicionar atividade ${name ?? ''}`}
            </Button>
          )}

          {edit && (
            <Button
              disabled={!isFormValid}
              onClick={() => updateActivity()}
              tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
              tooltipExplanation='Campos obrigatórios não preenchidos:'
            >
              {`Salvar atividade ${name ?? ''}`}
            </Button>
          )}

          {approvement && (
            <>
              <Button
                disabled={!isFormValid}
                onClick={() => handleApprovementActivityDecision(false)}
                tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
                tooltipExplanation='Campos obrigatórios não preenchidos:'
              >
                {`Rejeitar atividade ${name ?? ''}`}
              </Button>
              <Button
                disabled={!isFormValid}
                onClick={() => handleApprovementActivityDecision(true)}
                tooltip={(renderArray(requiredFieldsErrorsLabel) ?? '')}
                tooltipExplanation='Campos obrigatórios não preenchidos:'
              >
                {`Aprovar atividade ${name ?? ''}`}
              </Button>
            </>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
}
