import React from 'react';
import { Card } from '../styles';
import NoData from '../../../components/NoData';

interface AdherenceCardInterface {
  activeUsersQty: number;
  registeredUsersQty: number;
}

export default function AdherenceCard({
  activeUsersQty,
  registeredUsersQty,
}: AdherenceCardInterface) {
  const adherencePercentage = ((activeUsersQty / registeredUsersQty) * 100).toFixed(2);
  const hasInfo = registeredUsersQty;

  return (
    <Card>
      <div className="card-title">
        <div>Ativos / Cadastrados</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {activeUsersQty}
          /
          {registeredUsersQty}
        </div>
        <small>
          {adherencePercentage}
          %
          {' '}
          dos registrados ativaram o plano.
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há dados disponíveis a respeito da relação entre ativados e registrados
          </>
                    )}
      />
      )}
    </Card>
  );
}
