import Loader from '../../components/Loader';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
// import MyModal from '../../components/Modal';
import NoData from '../../components/NoData';
import DeleteUserModal from './components/DeleteUserModal';
import Filters from './components/Filters';
import ListHeader from './components/header';
import List from './components/list';
import SearchAtPage from './components/searchAtPage';
import useUsers from './useUsers';

export default function Users() {
  const {
    isLoading,
    users,
    searchTerm,
    handleChangeSearchTerm,
    selectedCompany,
    handleCompanyChange,
    companysList,
    doesListApiHasError,
    filteredUsers,
    handleTryAgain,
    pagesQuantity,
    currentPage,
    handlePageChange,
    handleOpenDeleteModal,
    userBeingDeleted,
    setDeleteModalShow,
    deleteUser,
    deleteModalShow,
    downloadExcel,
    downloadRatings,
    handleStatusChange,
    handleAgeSelection,
    selectedAgeRange,
    setSelectedAgeRange,
    statusOptions,
    selectedStatus,
  } = useUsers();

  const hasUsers = users?.length !== 0 && !!users;
  const filteredListLength = filteredUsers?.length ?? 0;
  const searchNotFound = filteredListLength === 0 && hasUsers;
  const isListEmpty = !doesListApiHasError && (!isLoading && users.length === 0);

  return (
    <>
      <Sidebar active="Users" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasUsers && (
          <>
            <Filters
              companysList={companysList}
              selectedCompany={selectedCompany}
              statusOptions={statusOptions}
              selectedStatus={selectedStatus}
              handleStatusChange={handleStatusChange}
              handleCompanyChange={handleCompanyChange}
              selectedAgeRange={selectedAgeRange}
              handleAgeSelection={handleAgeSelection}
              setSelectedAgeRange={setSelectedAgeRange}
            />

            <SearchAtPage
              searchTerm={searchTerm}
              onChangeSearchTerm={handleChangeSearchTerm}
            />
          </>
        )}

        <ListHeader
          doesListExists={hasUsers}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="usuário"
          pluralLabel="usuários"
          onPageChange={handlePageChange}
          pagesQuantity={pagesQuantity}
          currentPage={currentPage}
          downloadExcel={downloadExcel}
        />

        {hasUsers && (
          <List
            filteredList={filteredUsers}
            downloadRatings={downloadRatings}
            onOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        <DeleteUserModal
          userBeingDeleted={userBeingDeleted}
          setDeleteModalShow={setDeleteModalShow}
          deleteUser={deleteUser}
          deleteModalShow={deleteModalShow}
        />

        {searchNotFound && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Nenhum resultado foi encontrado para
                {' '}
                <strong>{searchTerm || '" "'}</strong>
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista dos usuários.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhum usuário, baixe o app para cadastrar.
              </>
)}
          />
        )}
      </Transitions>
    </>
  );
}
