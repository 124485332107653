import exit from '../../assets/images/icons/exit.svg';
import { Container, MenuItem } from './styles';

import { ReactNode } from 'react';
import { useAppContext } from '../../contexts/auth';
import AdminSidebar from './components/AdminSidebar';
import AnalyticSidebar from './components/AnalyticsSidebar';
import ComercialSidebar from './components/ComercialSidebar';
import CoordinatorSidebar from './components/CoordinatorSidebar';
import FinancialSidebar from './components/FinanciaSidebar';
import SupportSidebar from './components/SupportSidebar';
import { SidebarInterface } from './interface';

type SidebarPermisionsMapType = {
  [key: string]: ReactNode;
}

export default function Navbar({ active }: SidebarInterface) {
  const { signOut } = useAppContext();
  const appContext = useAppContext();
  const { permission } = appContext.user;

  const sidebarPermissionMap: SidebarPermisionsMapType = {
    super: <AdminSidebar active={active} />,
    coordinator: <CoordinatorSidebar active={active} />,
    support: <SupportSidebar active={active} />,
    analytics: <AnalyticSidebar active={active} />,
    financial: <FinancialSidebar active={active} />,
    comercial: <ComercialSidebar active={active} />,
  };

  return (
    <Container>
      <div style={{ overflow: 'auto', maxHeight: '85%' }}>
        {sidebarPermissionMap[permission]}
      </div>

      <MenuItem onClick={() => signOut()} style={{ paddingTop: '16px'}}>
        <img src={exit} alt="exit" />
        Sair
      </MenuItem>

    </Container>
  );
}

