/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import FilterRadioButton from "../../../../components/FilterRadioButtons";
import { FilterRadioButtonsContainer } from "../../../../components/FilterRadioButtonsContainer";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import MyModal from "../../../../components/Modal";
import OpacityAnimation from "../../../../components/OpacityAnimation";
import { Group } from "../../styles";
import { CupomFromApiObject } from "../../types";
import { ModalContainer } from "./styles";
import useEditModal from "./useEditModal";

interface ICuponsModal {
  editModalShow: boolean;
  setEditModalShow: Dispatch<SetStateAction<boolean>>;
  cupomObjectBeingEditted: CupomFromApiObject;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadCupons: () => Promise<void>;
}

export default function EditModal({
  editModalShow,
  setEditModalShow,
  setIsLoading,
  loadCupons,
  cupomObjectBeingEditted,
}: ICuponsModal) {
  const {
    editCupom,
    cupomPlanKind,
    cupomPlanValue,
    cupomPlanTrial,
    cupomName,
    handleCupomPlanValueChange,
    handleCupomPlanTrialChange,
    handleExpirationDateChange,
    expirationDate,
    expires,
    setExpires,
    setCupomPlanKind,
    getErrorMessageByFieldName,
    isCupomFormValid,
    companyAssociated,
    setWillLimitUsage,
    willLimitUsage,
    handleUseLimitChange,
    useLimit,
    considerTestUntilTrialDays,
    setConsiderTestUntilTrialDays,
  } = useEditModal({ setIsLoading, loadCupons, setEditModalShow, cupomObjectBeingEditted });

  const cupomPlanOptions = [
    { value: 'free', label: 'Acesso grátis' },
    { value: 'discount', label: 'Desconto na mensalidade' },
    { value: 'trial', label: 'Período de teste grátis' },
    { value: 'trial_no_subscription', label: 'Período de teste grátis (sem cobrança)' },
    { value: 'trial_discount', label: 'Período de teste grátis. Após, desconto na mensalidade' },
  ];

  return (
    <MyModal
      show={editModalShow}
      onClose={() => setEditModalShow(false)}
      title={`Editar cupom ${cupomName}`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
                Empresa associada
              </div>
              <Select
                value={{ value: companyAssociated?.value || '', label: companyAssociated?.label || 'Nenhuma empresa' }}
                isDisabled
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Group>

            <Group>
              <div className="title">
                Tipo do benefício do cupom
              </div>
              <Select
                value={{ value: cupomPlanKind?.value, label: cupomPlanKind?.label }}
                options={cupomPlanOptions}
                onChange={(opt) => {
                  setCupomPlanKind({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Group>

            <Group>
              <div className="title">
                Terá vencimento?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={expires} onClick={() => setExpires(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!expires} onClick={() => setExpires(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>

            {expires && (
              <Group>
                <div className="title">
                  Data de vencimento
                </div>
                <Input
                  type="date"
                  onChange={(event) => handleExpirationDateChange(event)}
                  value={expirationDate}
                />
              </Group>
            )}

            <Group>
              <div className="title">
                Terá limite de uso?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={willLimitUsage} onClick={() => setWillLimitUsage(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!willLimitUsage} onClick={() => setWillLimitUsage(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>

            {willLimitUsage && (
              <Group>
                <div className="title">
                  Limite de utilização
                </div>
                <Input
                  onChange={(event) => handleUseLimitChange(event)}
                  value={useLimit}
                />
              </Group>
            )}

          {(cupomPlanKind?.value?.includes('trial')) && (
            <Group>
              <div className="title">
                Usuários constarão como teste durante todo o período grátis?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={considerTestUntilTrialDays} onClick={() => setConsiderTestUntilTrialDays(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!considerTestUntilTrialDays} onClick={() => setConsiderTestUntilTrialDays(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>
          )}

            <Group>
              <div className="title">
                Código (nome) do cupom
              </div>
              <FormGroup>
                <Input
                  value={cupomName}
                  disabled
                  placeholder="Código do cupom"
                />
              </FormGroup>
            </Group>

            {(cupomPlanKind?.value === 'discount' || cupomPlanKind?.value === 'trial_discount') && (
              <Group>
                <div className="title">
                  Valor do plano
                </div>
                <FormGroup error={getErrorMessageByFieldName('cupomPlanValue')}>
                  <Input
                    value={cupomPlanValue}
                    onChange={handleCupomPlanValueChange}
                    placeholder="Novo valor do plano com a aplicação do desconto"
                    error={getErrorMessageByFieldName('cupomPlanValue')}
                  />
                </FormGroup>
              </Group>
          )}

          {(cupomPlanKind?.value === 'trial' || cupomPlanKind?.value === 'trial_discount' || cupomPlanKind?.value === 'trial_no_subscription') && (
            <Group>
              <div className="title">
                Período de teste grátis (em dias)
              </div>
              <FormGroup error={getErrorMessageByFieldName('cupomPlanTrial')}>
                <Input
                  value={cupomPlanTrial}
                  onChange={handleCupomPlanTrialChange}
                  placeholder="Período em dias de teste grátis"
                  error={getErrorMessageByFieldName('cupomPlanTrial')}
                />
              </FormGroup>
            </Group>
          )}
          </OpacityAnimation>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Editar"
      isActionButtonDisabled={!isCupomFormValid}
      onAction={() => editCupom()}
    />
  )
}
