import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../hooks/useApiCall";
import givenClassesService from "../../../services/givenClassesService";
import splitArray from "../../../utils/splitArray";
import { ActivityOptionType, GivenClassType, PartnerOptionType, PeriodType } from "../types";

export default function useGivenClasses() {
  const [isLoading, setIsLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const [changePeriodModalShow, setChangePeriodModalShow] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodType>({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
  const [periodBeingSelected, setPeriodBeingSelected] = useState<PeriodType>({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });

  const [selectedPartner, setSelectedPartner] = useState({} as PartnerOptionType);
  const [partnerOptions, setPartnerOptions] = useState<PartnerOptionType[]>([]);

  const [selectedActivity, setSelectedActivity] = useState({} as ActivityOptionType);
  const [activitiesList, setActivitiesList] = useState<ActivityOptionType[]>([]);

  const [givenClasses, setGivenClasses] = useState<GivenClassType[]>([]);
  const [filteredGivenClasses, setFilteredGivenClasses] = useState<GivenClassType[]>([]);
  const [givenClassesSplited, setGivenClassesSplited] = useState<GivenClassType[][]>([]);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [givenClassBeingDeleted, setGivenClassBeingDeleted] = useState<GivenClassType | null>(null);

  const [downloadRelatoryModalShow, setDownloadRelatoryModalShow] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const pagesQuantity = useMemo(() => givenClassesSplited.length, [givenClassesSplited.length]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredGivenClasses(givenClassesSplited[page]);
  }

  const isFirstRender = useRef(true);

  const { apiCall } = useApiCall();

  const partnersWithGivenClassesOpt = useMemo(() => {
    const allPartnersIdsWithGivenClasses = givenClasses.map(gvCl => gvCl.partner.id);

    return [{ value: null, label: 'Filtrar por Parceiro'}, ...partnerOptions.filter(pt => allPartnersIdsWithGivenClasses.includes(pt.value ?? ''))];
  }, [givenClasses, partnerOptions]);

  const getGivenClasses = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.getGivenClassesByPeriod,
      queryParams: {
        month: selectedPeriod.month,
        year: selectedPeriod.year,
      },
      actionAfterResponse: (response: { success: boolean, givenClasses: GivenClassType[] }) => {
        if (!response.success) {
          setDoesListApiHasError(true);
          toast.error('Não foi possível carregar as aulas dadas. Por favor, tente novamente');
          return;
        }
        setDoesListApiHasError(false);
        setGivenClasses(response.givenClasses);
        const splitedArray = splitArray(response.givenClasses);
        setGivenClassesSplited(splitedArray);
        setFilteredGivenClasses(splitedArray[0]);
        setCurrentPage(0);
        setSelectedActivity({ value: null, label: 'Filtrar por Atividade', hasFeatPartner: false });
        setSelectedPartner({ value: null, label: 'Filtrar por Parceiro' });
      }
    })
  }, [apiCall, selectedPeriod.month, selectedPeriod.year]);

  const getPartnerOptions = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.findPartnersOptions,
      actionAfterResponse: (response: { success: boolean, partners: { nome_fantasia: string, id: string }[] }) => {
        if (!response.success) {
          toast.error('Não foi possível carregar as opções de filtro de parceiros.');
          return;
        }
        const mappedPartners = response.partners.map(partner => ({ value: partner.id, label: partner.nome_fantasia }));
        setPartnerOptions([{ value: null, label: 'Filtrar por Parceiro' }, ...mappedPartners]);
      }
    })
  }, [apiCall]);

  const getActivitiesOptions = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.findActivitiesOptions,
      actionAfterResponse: (response: { success: boolean, activities: { nome: string, id: string, featPartner: null | { id: string } }[] }) => {
        if (!response.success) {
          toast.error('Não foi possível carregar as opções de filtro de atividades.');
          return;
        }
        const mappedActivities = response.activities.map(activity => ({ value: activity.id, label: activity.nome, hasFeatPartner: !!activity.featPartner }));
        setActivitiesList([{ value: null, label: 'Filtrar por Atividade', hasFeatPartner: false }, ...mappedActivities]);
      }
    })
  }, [apiCall]);

  const loadPage = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([
      getGivenClasses(),
      getPartnerOptions(),
      getActivitiesOptions(),
    ]);
    setIsLoading(false);
  }, [getActivitiesOptions, getGivenClasses, getPartnerOptions]);

  const deleteGivenClass = useCallback(async () => {
    if (!givenClassBeingDeleted) return;

    await apiCall({
      apiToCall: givenClassesService.deleteGivenClass,
      queryParams: { id: givenClassBeingDeleted.id },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response: { success: boolean }) => {
        if (!response.success) {
          toast.error('Não foi possível deletar a aula dada. Por favor, tente novamente');
          return;
        }
        handleCloseDeleteModal();
        await getGivenClasses();
        toast.success('Aula deletada com sucesso');
      },
    });
  }, [apiCall, getGivenClasses, givenClassBeingDeleted]);

  function handleTryAgain() {
    loadPage();
  }

  const filterGivenClasses = useCallback(() => {
    const filteredGvClasses = givenClasses.filter((gvCl) => {
      const activityFilter = gvCl.activity.id === selectedActivity.value || !selectedActivity.value;
      const partnerFilter = gvCl.partner.id === selectedPartner.value || !selectedPartner.value;

      return activityFilter && partnerFilter;
    });

    const givenClassesSplittedByFilters = splitArray(filteredGvClasses);
    setGivenClassesSplited(givenClassesSplittedByFilters);
    setFilteredGivenClasses(givenClassesSplittedByFilters[0]);
  }, [givenClasses, selectedActivity.value, selectedPartner.value]);

  const downloadPdfRelatory = useCallback(async () => {
    setIsLoading(true);

    await apiCall({
      apiToCall: givenClassesService.downloadPdfRelatory,
      queryParams: {
        month: selectedPeriod.month,
        year: selectedPeriod.year,
      },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response: Blob) => {
        console.log(response);
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Relatorio_Repasse_Parceiros_${selectedPeriod.month}_${selectedPeriod.year}.zip`);
        document.body.appendChild(link);
        link.click();

        // Revoga o URL após o download
        window.URL.revokeObjectURL(url);
      }
    });
  }, [apiCall, selectedPeriod.month, selectedPeriod.year]);

  const downloadPdfRelatoryByPartner = useCallback(async (partnerId: string) => {
    setIsLoading(true);

    await apiCall({
      apiToCall: givenClassesService.downloadPdfRelatoryByPartner,
      queryParams: {
        month: selectedPeriod.month,
        year: selectedPeriod.year,
        partnerId,
      },
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: async (response: Blob) => {
        console.log(response);
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Relatorio_Repasse_Financeiro_${selectedPeriod.month}_${selectedPeriod.year}_${selectedPartner.label}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Revoga o URL após o download
        window.URL.revokeObjectURL(url);
      }
    });
  }, [apiCall, selectedPartner.label, selectedPeriod.month, selectedPeriod.year]);

  function handleOpenDeleteModal(givenClassToBeDeleted: GivenClassType) {
    setGivenClassBeingDeleted(givenClassToBeDeleted);
    setDeleteModalShow(true);
  }

  function handleCloseDeleteModal() {
    setGivenClassBeingDeleted(null);
    setDeleteModalShow(false);
  }

  useEffect(() => {
    filterGivenClasses();
  }, [filterGivenClasses]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getGivenClasses();
  }, [getGivenClasses]);

  return {
    isLoading,
    setIsLoading,
    doesListApiHasError,
    givenClasses,
    filteredGivenClasses,
    partnerOptions,
    setSelectedPartner,
    selectedPartner,
    activitiesList,
    selectedActivity,
    setSelectedActivity,
    setSelectedPeriod,
    periodBeingShown: selectedPeriod,
    changePeriodModalShow,
    setChangePeriodModalShow,
    periodBeingSelected,
    setPeriodBeingSelected,
    givenClassesSplited,
    currentPage,
    pagesQuantity,
    handlePageChange,
    handleTryAgain,
    selectedPeriod,
    handleOpenDeleteModal,
    givenClassBeingDeleted,
    handleCloseDeleteModal,
    deleteGivenClass,
    deleteModalShow,
    downloadPdfRelatory,
    downloadPdfRelatoryByPartner,
    setDownloadRelatoryModalShow,
    downloadRelatoryModalShow,
    partnersWithGivenClassesOpt,
  }
}
