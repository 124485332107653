import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import companiesService from '../../../../../services/companiesService';
import useApiCall from '../../../../../hooks/useApiCall';

export default function useRanking() {
  const [ranking, setRanking] = useState([]);
  const [hasRankingError, setHasRankingError] = useState(false);

  const { companyId } = useParams();
  const { apiCall } = useApiCall();

  const getRanking = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: companiesService.getRanking,
      queryParams: { companyId, associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setRanking(apiResponse);
        setHasRankingError(false);
      },
      catchAction: () => setHasRankingError(true),
      catchMessage: 'Não foi possível carregar a informação das avaliações',
    })
  }, [apiCall, companyId]);

  return {
    ranking,
    hasRankingError,
    getRanking,
  };
}
