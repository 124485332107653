/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import highlightsService from '../../../../services/highlightsService';
import { HighlightBeingUpdatedInfoType, SelectedNewHighlightType } from '../../types';

interface UseChangeHighlightInterface {
  loadHighlightsPage: () => void;
  selectedType: 'live' | 'record';
}

export default function useChangeHighlight({
  loadHighlightsPage,
  selectedType,
}: UseChangeHighlightInterface) {
  const [isChangingHighlight, setIsChangingHighlight] = useState(false);
  const [manageHighlightModalShow, setManageHighlightModalShow] = useState(false);
  const [selectedNewHighlight, setSelectedNewHighlight] = useState<SelectedNewHighlightType>({} as SelectedNewHighlightType);
  const [highlightBeingUpdatedInfo, setHighlightBeingUpdatedInfo] = useState<HighlightBeingUpdatedInfoType>({} as HighlightBeingUpdatedInfoType);

  const { apiCall } = useApiCall();

  const manageHighlight = useCallback(async (order: number | null) => {
    if (!order) {
      toast.warn('Selecione uma posição para o novo destaque')
      return;
    }
    await apiCall({
      apiToCall: highlightsService.createOrUpdateHighlight,
      queryParams: {
        activityId: selectedNewHighlight.value,
        order,
        type: selectedType,
      },
      onStartLoad: () => setIsChangingHighlight(true),
      onEndLoad: () => setIsChangingHighlight(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível alterar o destaque. Por favor, tente novamente!`);
          return;
        }
        setManageHighlightModalShow(false);
        setHighlightBeingUpdatedInfo({} as HighlightBeingUpdatedInfoType);
        setSelectedNewHighlight({} as SelectedNewHighlightType);
        loadHighlightsPage();
        toast.success('Destaque atualizado com sucesso!');
      },
      catchMessage: 'Não foi possível gerenciar o destaque. Por favor, tente novamente',
    })
  }, [apiCall, loadHighlightsPage, selectedNewHighlight.value, selectedType]);


  return {
    isChangingHighlight,
    manageHighlightModalShow,
    highlightBeingUpdatedInfo,
    setManageHighlightModalShow,
    manageHighlight,
    selectedNewHighlight,
    setSelectedNewHighlight,
  };
}
