import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group } from '../styles';
import { ChangeEvent } from 'react';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';

interface ContactDataCardInterface {
  contactName: string;
  handleContactNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contactCellphone: string;
  handleContactCellphoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  contactEmail: string;
  handleContactEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
}

export default function ContactDataCard({
  contactName,
  handleContactNameChange,
  contactCellphone,
  handleContactCellphoneChange,
  contactEmail,
  handleContactEmailChange,
  getErrorMessageByFieldName,
}: ContactDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados de contato
      </div>
      <Group>
        <div className="title">
          Nome *
        </div>
        <FormGroup error={getErrorMessageByFieldName('contactName')}>
          <Input
            value={contactName}
            onChange={handleContactNameChange}
            error={getErrorMessageByFieldName('contactName')}
            placeholder="Informe o nome do contato"
          />
        </FormGroup>
      </Group>
      <Group>
        <div className="title">
          Celular *
        </div>
        <FormGroup error={getErrorMessageByFieldName('contactCellphone')}>
          <Input
            value={contactCellphone}
            onChange={handleContactCellphoneChange}
            error={getErrorMessageByFieldName('contactCellphone')}
            placeholder="Informe o celular de contato"
            maxLength={15}
          />
        </FormGroup>
      </Group>
      <Group>
        <div className="title">
          E-mail *
        </div>
        <FormGroup error={getErrorMessageByFieldName('contactEmail')}>
          <Input
            value={contactEmail}
            onChange={handleContactEmailChange}
            error={getErrorMessageByFieldName('contactEmail')}
            placeholder="Informe o e-mail de contato"
          />
        </FormGroup>
      </Group>
    </StyledContainer>
  );
}
