import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import { UseDashboardDataInterface } from '../interfaces';

export default function useActiveUsersPeriod({ setHasError }: UseDashboardDataInterface) {
  const [todayActiveUsers, setTodayActiveUsers] = useState(0);
  const [lastWeekActiveUsers, setLastWeekActiveUsers] = useState(0);
  const [lastMonthActiveUsers, setLastMonthActiveUsers] = useState(0);

  const { apiCall } = useApiCall();

  const getActiveUsersByPeriod = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getActiveUsersByPeriod,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a informação de usuários por período (${apiResponse.error})`, {
            toastId: 'getActivitiesDashboardFail',
          });
          return;
        }
        const { today, lastWeek, lastMonth } = apiResponse;
        setTodayActiveUsers(today);
        setLastWeekActiveUsers(lastWeek);
        setLastMonthActiveUsers(lastMonth);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível carregar a lista de atividades',
    })
  }, [apiCall, setHasError]);

  return {
    todayActiveUsers,
    lastWeekActiveUsers,
    lastMonthActiveUsers,
    getActiveUsersByPeriod,
  };
}
