import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import AddDateModal from "./components/AddDateModal";
import DeleteDateModal from "./components/DeleteDateModal";
import ImportCsvModal from "./components/ImportCsvModal";
import ListHeader from "./components/header";
import List from "./components/list";
import SearchAtPage from "./components/searchAtPage";
import useDisabledDates from "./useDisabledDates";

export default function DisabledDates() {
  const {
    fullDates,
    filteredDates,
    isLoading,
    doesListApiHasError,
    searchTerm,
    handleChangeSearchTerm,
    downloadExcel,
    setAddDateModalShow,
    handleOpenDeleteModal,
    handleTryAgain,
    addDateModalShow,
    dateToDisable,
    handleDateToDisableChange,
    handleAddDateModalClose,
    disableDate,
    dateBeingDeleted,
    setModalDeleteShow,
    deleteDate,
    modalDeleteShow,
    modalImportCsvShow,
    setCsvAction,
    csvAction,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose,
    setImportCsvModalShow,
  } = useDisabledDates();

  const hasDates = fullDates?.length !== 0 && !!fullDates && !isLoading;
  const filteredListLength = filteredDates?.length ?? 0;
  const anyResultsByFilterSelection = !filteredDates && !isLoading && !doesListApiHasError && hasDates;
  const isListEmpty = !doesListApiHasError && (!isLoading && fullDates.length === 0);

  return (
    <>
      <Sidebar active="DisabledDates" />
      <Transitions>
        <Loader isLoading={isLoading} />

        {hasDates && (
          <SearchAtPage
            searchTerm={searchTerm}
            onChangeSearchTerm={handleChangeSearchTerm}
          />
        )}

        <ListHeader
          doesListExists={hasDates}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="Data desabilitada futura"
          pluralLabel="Datas desabilitadas futuras"
          downloadExcel={downloadExcel}
          setAddDateModalShow={setAddDateModalShow}
          setImportCsvModalShow={setImportCsvModalShow}
          filePathDownload="/files/Modelo importar datas.csv"
        />

        {hasDates && (
          <List
            filteredList={filteredDates}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        )}

        {anyResultsByFilterSelection && (
          <NoData
            icon="searchNotFound"
            label={(
              <>
                Não encontramos nenhum resultado conforme o termo de pesquisa.
              </>
  )}
          />
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter a lista das datas desabilitadas futuras.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma data futura desabilitada, clique no botão
                {' '}
                <strong>Desabilitar nova data</strong>
                {' '}
                acima para desabilitar uma data!
              </>
)}
          />
        )}

        <AddDateModal
          addDateModalShow={addDateModalShow}
          dateToDisable={dateToDisable}
          handleDateToDisableChange={handleDateToDisableChange}
          handleAddDateModalClose={handleAddDateModalClose}
          disableDate={disableDate}
        />

        <DeleteDateModal
          modalDeleteShow={modalDeleteShow}
          dateBeingDeleted={dateBeingDeleted}
          setModalDeleteShow={setModalDeleteShow}
          deleteDate={deleteDate}
        />

        <ImportCsvModal
          modalImportCsvShow={modalImportCsvShow}
          setCsvAction={setCsvAction}
          csvAction={csvAction}
          csvFileName={csvFileName}
          handleFileUpload={handleFileUpload}
          handleSubmitFile={handleSubmitFile}
          handleImportModalClose={handleImportModalClose}
        />
      </Transitions>
    </>
  );
}
