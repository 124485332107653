import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import activitiesService from '../../../../services/activitiesService';
import { ApprovementType, ApprovementsListFromApi } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useLoadApprovements() {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [approvements, setApprovements] = useState<ApprovementType[]>([]);

  const { apiCall } = useApiCall();

  const getApprovements = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesApprovements,
      onStartLoad: () => {
        setIsLoadLoading(true);
        setHasError(false);
      },
      onEndLoad: () => setIsLoadLoading(false),
      actionAfterResponse: (apiResponse) => {
        const approvmenetsList: ApprovementsListFromApi = apiResponse.list
        if (!approvmenetsList) {
          setHasError(true);
          toast.error('Não foi possível recuperar a list de atividades sob aprovação. Por favor, tente novamente', { toastId: 'errorGetApprovement' });
          return;
        }
        const approvementsMapped: ApprovementType[] = approvmenetsList.map((act) => ({
          approvementId: act.id,
          activityId: act.activityId,
          name: act.nome,
          action: act.categoria ? 'create' : 'update',
          // eslint-disable-next-line no-nested-ternary
          field: act.descricao ? 'description' : (
            act.datasDesabilitadas ? 'dates' : 'ages'
          ),
          value: !act.categoria ? (act.descricao ?? act.datasDesabilitadas ?? {
            minima: act.minima,
            maxima: act.maxima,
          }) : null,
          descricao: act.descricao,
          datasDesabilitadas: act.datasDesabilitadas,
          minima: act.minima,
          maxima: act.maxima,
        }));
        setApprovements(approvementsMapped);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível carregar as atividades que precisam ser aprovadas. Por favor, tente novamente',
    })
  }, [apiCall]);

  useEffect(() => {
    getApprovements();
  }, [getApprovements]);

  return {
    isLoadLoading,
    approvements,
    getApprovements,
    hasError,
  };
}
