import FilterRadioButton from '../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../components/FilterRadioButtonsContainer';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';
import DeleteHighlightModal from './components/DeleteHighlightModal';
import HighlightsHeader from './components/HighlightsHeader';
import ManageHighlightModal from './components/ManageHighlighModal';
import List from './components/list';
import useHighlights from './useHighlights';

export default function Highlights() {
  const {
    isLoading,
    hasError,
    activitiesList,
    list,
    modalShow,
    handleTryAgain,
    setManageHighlightModalShow,
    handleOpenDeleteModal,
    deleteModalShow,
    setDeleteModalShow,
    manageHighlight,
    selectedNewHighlight,
    setSelectedNewHighlight,
    highlightBeingDeleted,
    deleteHighlight,
    selectedActType,
    setSelectedActType,
  } = useHighlights();

  const hasHighlights = list.length !== 0 && !hasError;
  const isListEmpty = !isLoading && !hasError && list.length === 0

  return (
    <>
      <Sidebar active="Highlights" />
      <Transitions>
        <Loader isLoading={isLoading} />
        {!isLoading && !hasError && (
          <>
            <FilterRadioButtonsContainer>
              <FilterRadioButton selected={selectedActType === 'live'} onClick={() => setSelectedActType('live')}>
                Aulas em Grupo
              </FilterRadioButton>
              <FilterRadioButton selected={selectedActType === 'record'} onClick={() => setSelectedActType('record')}>
                Aulas Gravadas
              </FilterRadioButton>
            </FilterRadioButtonsContainer>
            <HighlightsHeader
              doesListExists={hasHighlights}
              hasError={hasError}
              listLength={list.length}
              setManageHighlightModalShow={setManageHighlightModalShow}
            />

          {list.length > 0 && (
            <List
              list={list}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          )}

            <DeleteHighlightModal
              highlightBeingDeleted={highlightBeingDeleted}
              setDeleteModalShow={setDeleteModalShow}
              deleteHighlight={deleteHighlight}
              deleteModalShow={deleteModalShow}
            />

            <ManageHighlightModal
              modalShow={modalShow}
              setModalShow={setManageHighlightModalShow}
              setSelectedNewHighlight={setSelectedNewHighlight}
              manageHighlight={manageHighlight}
              selectedNewHighlight={selectedNewHighlight}
              activitiesList={activitiesList}
            />
          </>
        )}

        {!isLoading && hasError && (
        <NoData
          icon="sad"
          label={(
            <>
              Ocorreu um erro ao obter os destaques.
              <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
            </>
)}
        />
        )}

        {isListEmpty && (
          <NoData
          icon="emptyBox"
          label={(
            <>
              Não há nenhum destaque definido ainda no App. Clique no botão
              {' '}
              <strong>Gerenciar Destaques</strong>
              {' '}
              acima para gerenciá-los!
            </>
)}
        />
        )}
      </Transitions>
    </>
  );
}
