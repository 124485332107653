import Select from 'react-select';
import useThemeContext from '../../../../contexts/theme';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import { CustomStyle as CustomStyleDarkTheme } from '../../../../components/CustomSelectStyleDarkTheme';

interface CompanySelectionI {
  companyOptions: string[];
  handleCompanyChange: (company: { value: string | null, label: string }) => void;
  selectedCompany: { value: string | null, label: string }
}

export default function CompanySelection({ companyOptions, handleCompanyChange, selectedCompany }: CompanySelectionI) {
  const { selectedTheme } = useThemeContext();

  const companyOptionsMapped = companyOptions.map((cp) => ({ value: cp, label: cp }));

  const options = [
    { value: null, label: 'Visão Geral '},
    ...companyOptionsMapped,
  ];

  return (
    <Select
      value={selectedCompany}
      options={options}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onChange={(company) => handleCompanyChange(company!)}
      placeholder="Visão Geral"
      styles={selectedTheme === 'dark' ? CustomStyleDarkTheme : CustomStyle}
      menuPortalTarget={document.body}
      classNamePrefix="react-select"
      className="react-select-container-as-filter"
    />
  );
}
