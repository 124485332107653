import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import AddPostModal from "./components/AddPostModal";
import CommentsModal from "./components/CommentsModal";
import DeletePostModal from "./components/DeletePostModal";
import EditPostModal from "./components/EditPostModal";
import Post from "./components/Post";
import ListHeader from "./components/header";
import useForum from "./useForum";

export default function Forum() {
  const {
    posts,
    isLoading,
    filteredPosts,
    doesListApiHasError,
    setAddPostModalShow,
    addPostModalShow,
    setIsLoading,
    loadForum,
    commentsModalShow,
    handleOpenCommentsModal,
    handleCloseCommentsModal,
    comments,
    postIdOfCommentsBeingShow,
    handleOpenEditModal,
    handleCloseEditModal,
    editPostModalShow,
    postBeingEditted,
    deleteModalShow,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
    postIdBeingDeleted,
  } = useForum();

  const hasPosts = posts?.length !== 0 && !!posts && !isLoading;
  const filteredListLength = filteredPosts?.length ?? 0;

  return (
    <>
      <Sidebar active="Forum" />
      <Transitions>
        <Loader isLoading={isLoading} />
        <ListHeader
          doesListExists={hasPosts}
          hasError={doesListApiHasError}
          filteredListLength={filteredListLength}
          singularLabel="Publicação"
          pluralLabel="Publicações"
          setAddPostModalShow={setAddPostModalShow}
        />

        {!doesListApiHasError && (
          <>
            {posts.length > 0 ? (
              <>
                {posts.map((post) => (
                  <Post
                    key={post.id}
                    post={post}
                    handleOpenCommentsModal={() => handleOpenCommentsModal(post.comments ?? [], post.id)}
                    handleOpenEditModal={() => handleOpenEditModal(post)}
                    handleOpenDeleteModal={() => handleOpenDeleteModal(post.id)}
                  />
                ))}
              </>
            ) : (
              <NoData
                icon="emptyBox"
                label={(
                  <>
                    Não há nenhum post criado, clique no botão
                    {' '}
                    <strong>Nova Publicação</strong>
                    {' '}
                    acima para cadastrar o primeiro post do forum!
                  </>
                )}
          />
            )}
            <AddPostModal
              addPostModalShow={addPostModalShow}
              setAddPostModalShow={setAddPostModalShow}
              loadForum={loadForum}
              setIsLoading={setIsLoading}
            />

            <EditPostModal
              editPostModalShow={editPostModalShow}
              handleCloseEditModal={handleCloseEditModal}
              loadForum={loadForum}
              setIsLoading={setIsLoading}
              post={postBeingEditted}
            />

            <DeletePostModal
              deletePostModalShow={deleteModalShow}
              handleCloseDeleteModal={handleCloseDeleteModal}
              loadForum={loadForum}
              setIsLoading={setIsLoading}
              postIdBeingDeleted={postIdBeingDeleted}
            />

            <CommentsModal
              comments={comments}
              commentsModalShow={commentsModalShow}
              handleCloseCommentsModal={handleCloseCommentsModal}
              loadForum={loadForum}
              setIsLoading={setIsLoading}
              postIdOfComments={postIdOfCommentsBeingShow}
            />
          </>
        )}

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao carregaro o fórum.
                <button type="button" onClick={() => loadForum()}>Tentar Novamente</button>
                .
              </>
            )}
          />
        )}

      </Transitions>
    </>
  )
}
