import MyModal from '../../../components/Modal';
import { Dispatch, SetStateAction } from 'react';

interface DeleteHighlightModalInterface {
  highlightBeingDeleted: {
    id: string;
    name: string;
  };
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deleteHighlight: (highlightsId: string) => void;
  deleteModalShow: boolean;
}

export default function DeleteHighlightModal({
  highlightBeingDeleted,
  setDeleteModalShow,
  deleteHighlight,
  deleteModalShow,
}: DeleteHighlightModalInterface) {
  return (
    <MyModal
      title={`Remover ${highlightBeingDeleted.name} dos destaques`}
      closeButtonLabel="Cancelar"
      actionButtonLabel="Excluir"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja remover a atividade
            {' '}
            <strong>{highlightBeingDeleted.name}</strong>
            {' dos destaques do App'}
            ?
          </div>
        </>
          )}
      onClose={() => setDeleteModalShow(false)}
      onAction={() => deleteHighlight(highlightBeingDeleted.id)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
