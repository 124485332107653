import { useState } from "react";
import useLoadPrograms from "./functions/useLoadPrograms";
import useModals from "./functions/useModals";

export default function usePrograms() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    programs,
    doesListApiHasError,
    dateTimeOptions,
    loadPrograms,
    activityName,
  } = useLoadPrograms({ setIsLoading });

  const {
    setAddModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    addModalShow,
    editModalShow,
    setEditModalShow,
    programBeingEditted,
    deleteModalShow,
    setDeleteModalShow,
    programBeingDeleted,
    feedbackModalShow,
    setFeedbackModalShow,
    programToBeSentFeedback,
    handleOpenFeedbackModal,
  } = useModals();

  function handleTryAgain() {
    loadPrograms()
  }

  return {
    programs,
    doesListApiHasError,
    isLoading,
    setAddModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleTryAgain,
    dateTimeOptions,
    addModalShow,
    loadPrograms,
    setIsLoading,
    editModalShow,
    setEditModalShow,
    programBeingEditted,
    deleteModalShow,
    setDeleteModalShow,
    programBeingDeleted,
    activityName,
    feedbackModalShow,
    setFeedbackModalShow,
    programToBeSentFeedback,
    handleOpenFeedbackModal,
  }
}
