import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from '../../styles';

interface NfDateInterface {
  nfDate?: string;
}

export default function NfDateCard({ nfDate }: NfDateInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentDateNumber = Number(`${currentYear}${currentMonth}${currentDay}`);

  const nfDay = Number(nfDate?.split('/')[0]);
  const nfMonth = Number(nfDate?.split('/')[1]);
  const nfYear = Number(nfDate?.split('/')[2]);
  const nfDateNumber = Number(`${nfYear}${nfMonth}${nfDay}`);

  const differenceBetweenDates = nfDate
    ? (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(nfYear, Number(nfMonth - 1), nfDay),
    }))
    : null;

    const isAfterCurrentDate = currentDateNumber > nfDateNumber;
    const isBeforeCurrentDate = nfDateNumber > currentDateNumber;
    const differenceBetweenDatesInDays = differenceBetweenDates?.days;
    const differenceBetweenDatesInMonths = differenceBetweenDates?.months;
    const differenceBetweenDatesInYears = differenceBetweenDates?.years;

  return (
    <Card>
      <div className="card-title">
        <div>Envio da NF-e</div>
      </div>

      {!!nfDate && (
      <div className="card-main">
        <div>
          Enviada em
          {' '}
          {nfDate}
        </div>
        <small>
        {(isAfterCurrentDate && differenceBetweenDatesInDays != 0) && (
              `${differenceBetweenDatesInYears
                ? `${differenceBetweenDatesInYears} ${differenceBetweenDatesInYears != 1 ? 'anos' : 'ano'}, `
                : ''}
            ${differenceBetweenDatesInMonths
                ? `${differenceBetweenDatesInMonths} ${differenceBetweenDatesInMonths != 1 ? 'meses' : 'mês'}, e `
                : ''}
             ${differenceBetweenDatesInDays} dias atrás`
            )}
            {(isBeforeCurrentDate && differenceBetweenDatesInDays != 0) && (
              `Daqui a ${differenceBetweenDatesInDays} dia(s)`
            )}
            {differenceBetweenDatesInDays == 0 && (
              'Hoje'
            )}
        </small>
      </div>
      )}

      {(!nfDate) && (
      <div className="card-main">
        <div>
          Não recebida
        </div>
        <small>
          Caso já tenha sido enviada, verifique novamente mais tarde.
        </small>
      </div>
      )}
    </Card>
  );
}
