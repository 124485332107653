/* eslint-disable max-len */
import React from 'react';
import {
  VictoryChart, VictoryVoronoiContainer, VictoryTooltip, VictoryLine,
} from 'victory';
import { zonedTimeToUtc } from 'date-fns-tz';

import NoData from '../../../components/NoData';
import { Card } from '../styles';
import { ChurnType } from '../types';

interface ChurnCardInterface {
  churnData: ChurnType;
}

export default function ChurnCard({
  churnData,
}: ChurnCardInterface) {
  const hasInfo = !!churnData[1] || churnData[1] === 0;
  const monthsAbvMap: { [key: string]: string } = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
  };

  const brasiliaTimeZone = 'America/Sao_Paulo';
  const currentDate = zonedTimeToUtc(new Date(), brasiliaTimeZone);
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const transformedData = Object.keys(churnData).map((key) => {
    const monthsAgo = parseInt(key);
    const date = zonedTimeToUtc(new Date(currentYear, currentMonth - monthsAgo - 1, 1), brasiliaTimeZone);
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      churnPercentage: Number((churnData[key]).toFixed(2)),
    };
  });

  const largestChurn = Math.max(...Object.values(churnData));

  transformedData.sort((a, b) => {
    if (a.year < b.year) {
      return -1;
    } if (a.year > b.year) {
      return 1;
    }
    // years are equal, sort by month
    if (a.month < b.month) {
      return -1;
    } if (a.month > b.month) {
      return 1;
    }
    return 0;
  });

  return (
    <Card>
      <div className="card-title">
        <div>Churn (%)</div>
      </div>

      <div className="card-main-graph">
        <VictoryChart
          height={150}
          width={300}
          padding={{
            top: 15, bottom: 30, left: 30, right: 30,
          }}
          maxDomain={{ y: largestChurn * 1.2 }}
          containerComponent={(
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x} / ${datum.year}, ${datum.y}%`}
              labelComponent={(
                <VictoryTooltip
                  constrainToVisibleArea
                  centerOffset={{ x: 5, y: 60 }}
                  style={{ fill: 'black' }}
                />
              )}
            />
                          )}
        >
          <VictoryLine
            style={{
              data: { stroke: '#4aafff' },
              parent: { border: 'none' },
            }}
            data={transformedData.map((data) => (
              { x: `${monthsAbvMap[data.month]}`, y: data.churnPercentage, year: data.year }
            ))}
            interpolation="natural"
            animate={{
              duration: 3000,
              onLoad: { duration: 5000 },
            }}
          />
        </VictoryChart>
      </div>

      {!hasInfo && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Não há informações a respeito dos agendamentos
            </>
                    )}
        />
      )}
    </Card>
  );
}
