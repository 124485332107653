export default {
  colors: {
    background: '#F6F5FC',
    darkerBackground: '#cac5e8',
    lighterBackground: '#fff',
    defaultBorder: '#dededf',
    backgroundWithOpacity: 'rgba(246, 245, 252, 0.7)',

    secondary: {
      lighter: '#fff6b0',
      light: '#FFED6C',
      main: '#ffe322',
      dark: '#b39d05',
    },

    chart: {
      main: '#4aafff',
    },

    primary: {
      lighter: '#8af1f1',
      light: '#5ee9e9',
      main: '#0075b2',
      dark: '#007171',
    },

    blue: {
      50: '#afe6ff',
      100: '#1097e5',
      200: '#005481',
      900: '#003250',
    },

    gray: {
      900: '#566a7f',
      201: '#bcbcbc',
      200: '#bcbcbc',
      100: '#d9d9d9',
      75: '#E6E6E6',
      50: '#f1f1f1',
    },

    green: {
      500: '#3ea76a',
      100: '#c2ebd4',
    },

    orange: {
      500: '#da7c0f',
      100: '#fddcb2',
    },

    purple: {
      500: '#dd0eb7',
      100: '#feb4f2',
    },

    danger: {
      lighter: '#feb5b5',
      light: '#F97171',
      main: '#FC5050',
      dark: '#F63131',
    },
  },

  filters: {
    primary: 'invert(24%) sepia(58%) saturate(3480%) hue-rotate(183deg) brightness(96%) contrast(101%)',
  },
};
