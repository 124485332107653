import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import schedulesService from '../../../../services/schedulesService';
import { SchedulesToRemoveBeingShown } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface UseRemoveScheduleInterface {
  getScheduleList: (date: string) => void;
  dateBeingShown: string;
}

export default function useRemoveSchedule({
  getScheduleList,
  dateBeingShown,
}: UseRemoveScheduleInterface) {
  const [isRemovingSchedule, setIsRemovingSchedule] = useState(false);
  const [schedulesRemoveBeingShow, setSchedulesRemoveBeingShow] = useState<SchedulesToRemoveBeingShown>({} as SchedulesToRemoveBeingShown);
  const [schedulesRemoveModalShow, setSchedulesRemoveModalShow] = useState(false);
  const [selectedScheduleIdToRemove, setSelectedScheduleIdToRemove] = useState('');

  const { apiCall } = useApiCall();

  const removeSchedule = useCallback(async (id: string) => {
    await apiCall({
      apiToCall: schedulesService.deleteSchedule,
      queryParams: { id },
      onStartLoad: () => setIsRemovingSchedule(true),
      onEndLoad: () => setIsRemovingSchedule(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível remover o agendamento. Por favor, tente novamente');
          return;
        }
        setSchedulesRemoveModalShow(false);
        getScheduleList(dateBeingShown);
        toast.success('Agendamendo removido com sucesso!');
      },
      catchMessage: 'Não foi possível remover o agendamento. Por favor, tente novamente'
    })
  }, [apiCall, dateBeingShown, getScheduleList]);

  return {
    isRemovingSchedule,
    schedulesRemoveBeingShow,
    setSchedulesRemoveBeingShow,
    schedulesRemoveModalShow,
    selectedScheduleIdToRemove,
    setSelectedScheduleIdToRemove,
    removeSchedule,
    setSchedulesRemoveModalShow,
  };
}
