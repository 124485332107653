import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import financialService from '../../../../services/financialService';
import useApiCall from '../../../../hooks/useApiCall';

interface UseCompanyViewInterface {
  reloadCompanyData: () => void;
  companyId: string;
}

export default function useCompanyView({
  companyId,
  reloadCompanyData,
}: UseCompanyViewInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentYear = Number(currentDate.split('-')[0]);
  const currentMonth = currentDate.split('-')[1];

  const [isLoading, setIsLoading] = useState(false);

  const [dateModalShow, setDateModalShow] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number | ''>(Number(currentMonth));
  const [selectedYear, setSelectedYear] = useState(Number(currentYear));
  const [currentMonthBeingShow, setCurrentMonthBeingShow] = useState<number | ''>(Number(currentMonth));
  const [currentYearBeingShow, setCurrentYearBeingShow] = useState(Number(currentYear));

  const [nfUploadModalShow, setNfUploadModalShow] = useState(false);
  const [file, setFile] = useState<File[]>([]);
  const [nfFileName, setNfFileName] = useState('');

  const { apiCall } = useApiCall();

  const handleNfFileSubmit = useCallback(async () => {
    await apiCall({
      apiToCall: financialService.uploadCompanyNf,
      queryParams: { companyId },
      reqBody: [
        { key: 'doc', value: file[0] },
        { key: 'data', value: `${currentMonthBeingShow}_${currentYearBeingShow}` },
      ],
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error('Não foi possível realizar o upload da NF. Por favor, tente novamente');
        }
        toast.success('NF enviada com sucesso!');
        reloadCompanyData();
        setNfUploadModalShow(false);
      },
      catchMessage: 'Não foi possível realizar o upload da NF. Por favor, tente novamente',
    })
  }, [apiCall, companyId, currentMonthBeingShow, currentYearBeingShow, file, reloadCompanyData]);

  function handleNfFileUpload(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      setNfFileName(files[0].name);
      setFile([files[0]]);
    }
  }

  return {
    currentYear,
    currentMonth,
    dateModalShow,
    setDateModalShow,
    selectedMonth,
    setSelectedMonth,
    selectedYear,
    setSelectedYear,
    currentMonthBeingShow,
    setCurrentMonthBeingShow,
    currentYearBeingShow,
    setCurrentYearBeingShow,
    nfUploadModalShow,
    setNfUploadModalShow,
    handleNfFileSubmit,
    handleNfFileUpload,
    nfFileName,
    isLoading,
  };
}
