import { useMemo } from 'react';

export default function useDates() {
  const dates = useMemo(() => {
    const today = new Date();
    today.setHours(today.getHours() - 3);
    const todayFormatted = today.toJSON().slice(0, 10);
    const currentDay = todayFormatted.split('-')[2];
    const currentMonth = todayFormatted.split('-')[1];
    const currentYear = todayFormatted.split('-')[0];
    const currentDate = `${currentDay}/${currentMonth}/${currentYear}`;

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toJSON().slice(0, 10);
    const tomorrowDay = tomorrowFormatted.split('-')[2];
    const tomorrowMonth = tomorrowFormatted.split('-')[1];
    const tomorrowYear = tomorrowFormatted.split('-')[0];
    const tomorrowDate = `${tomorrowDay}/${tomorrowMonth}/${tomorrowYear}`;

    const afterTomorrow = new Date(today);
    afterTomorrow.setDate(afterTomorrow.getDate() + 2);
    const afterTomorrowFormatted = afterTomorrow.toJSON().slice(0, 10);
    const afterTomorrowDay = afterTomorrowFormatted.split('-')[2];
    const afterTomorrowMonth = afterTomorrowFormatted.split('-')[1];
    const afterTomorrowYear = afterTomorrowFormatted.split('-')[0];
    const afterTomorrowDate = `${afterTomorrowDay}/${afterTomorrowMonth}/${afterTomorrowYear}`;

    return {
      currentDate,
      tomorrowDate,
      afterTomorrowDate,
    };
  }, []);

  return dates;
}
