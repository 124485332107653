/* eslint-disable max-len */
import useFormSubmit from './functions/useFormSubmit';
import useInputChanges from './functions/useInputChanges';
import useLoadForm from './functions/useLoadForm';

export default function usePartnersForm() {
  const {
    kindOfPartner,
    handlePartnerChange,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    description,
    handleDescriptionChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    responsibleName,
    handleResponsibleNameChange,
    responsibleRole,
    handleResponsibleRoleChange,
    responsibleCellphone,
    handleResponsibleCellphoneChange,
    responsibleEmail,
    handleResponsibleEmailChange,
    facebook,
    handleFacebookChange,
    site,
    handleSiteChange,
    instagram,
    handleInstagramChange,
    tiktok,
    handleTiktokChange,
    resetFields,
    errors,
    setKindOfPartner,
    setCpf,
    setName,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setDescription,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    setResponsibleName,
    setResponsibleRole,
    setResponsibleCellphone,
    setResponsibleEmail,
    setFacebook,
    setSite,
    setInstagram,
    setTiktok,
    isGettingCepInfo,
    classPrice,
    setClassPrice,
    handleClassPriceChange,
    contractDate,
    handleContractDateChange,
    setContractDate,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    setLoginEmail,
    setPassword,
  } = useInputChanges();

  const {
    isLoadLoading,
    pixOptions,
    banksOptions,
  } = useLoadForm({
    setKindOfPartner,
    setCpf,
    setName,
    setCnpj,
    setCorporateName,
    setFantasyName,
    setDescription,
    setContactName,
    setContactCellphone,
    setContactEmail,
    setCep,
    setStreetName,
    setNumber,
    setComplement,
    setDistrict,
    setCity,
    setUf,
    setPixType,
    setCnpjPix,
    setCpfPix,
    setEmailPix,
    setCellphonePix,
    setRandomPix,
    setAgency,
    setAccount,
    setCardholder,
    setCnpjBank,
    setSelectedBank,
    setResponsibleName,
    setResponsibleRole,
    setResponsibleCellphone,
    setResponsibleEmail,
    setFacebook,
    setSite,
    setInstagram,
    setTiktok,
    setClassPrice,
    setContractDate,
    setLoginEmail,
    setPassword,
  });

  const {
    isSubmitting,
    createPartner,
    isFormValid,
    updatePartner,
    requiredFieldsErrorsLabel,
  } = useFormSubmit({
    resetFields,
    account,
    agency,
    cardholder,
    cellphonePix,
    cep,
    city,
    cnpj,
    cnpjBank,
    cnpjPix,
    complement,
    contactCellphone,
    contactEmail,
    contactName,
    corporateName,
    cpf,
    cpfPix,
    description,
    district,
    emailPix,
    facebook,
    fantasyName,
    instagram,
    kindOfPartner,
    name,
    number,
    pixType,
    randomPix,
    responsibleCellphone,
    responsibleEmail,
    responsibleName,
    responsibleRole,
    selectedBank,
    site,
    streetName,
    tiktok,
    uf,
    classPrice,
    contractDate,
    errors,
    loginEmail,
    password,
  });

  const isLoading = (isLoadLoading || isSubmitting);

  return {
    isLoading,
    kindOfPartner,
    handlePartnerChange,
    getErrorMessageByFieldName,
    cpf,
    handleCpfChange,
    name,
    handleNameChange,
    cnpj,
    handleCnpjChange,
    corporateName,
    handleCorporateNameChange,
    fantasyName,
    handleFantasyNameChange,
    description,
    handleDescriptionChange,
    contactName,
    handleContactNameChange,
    contactCellphone,
    handleContactCellphoneChange,
    contactEmail,
    handleContactEmailChange,
    cep,
    streetName,
    number,
    complement,
    district,
    city,
    uf,
    classPrice,
    handleClassPriceChange,
    handleCepChange,
    handleNumberChange,
    handleComplementChange,
    pixType,
    pixOptions,
    handleChangePixType,
    cnpjPix,
    cpfPix,
    emailPix,
    cellphonePix,
    randomPix,
    banksOptions,
    selectedBank,
    handleSelectBank,
    handleCnpjPixChange,
    handleCpfPixChange,
    handleEmailPixChange,
    handleCellphonePixChange,
    handleRandomPixChange,
    agency,
    handleAgencyChange,
    account,
    handleAccountChange,
    cardholder,
    handleCardholderChange,
    cnpjBank,
    handleCnpjBankChange,
    responsibleName,
    handleResponsibleNameChange,
    responsibleRole,
    handleResponsibleRoleChange,
    responsibleCellphone,
    handleResponsibleCellphoneChange,
    responsibleEmail,
    handleResponsibleEmailChange,
    facebook,
    handleFacebookChange,
    site,
    handleSiteChange,
    instagram,
    handleInstagramChange,
    tiktok,
    handleTiktokChange,
    isFormValid,
    createPartner,
    updatePartner,
    isGettingCepInfo,
    contractDate,
    handleContractDateChange,
    loginEmail,
    handleLoginEmailChange,
    password,
    handlePasswordChange,
    passwordConfirmation,
    handlePasswordConfirmationChange,
    requiredFieldsErrorsLabel,
  };
}
