import { format } from "date-fns";
import comments from '../../../../../../assets/images/icons/comments.svg';
import edit from '../../../../../../assets/images/icons/edit.svg';
import kiddleLogo from '../../../../../../assets/images/icons/kiddleLogo.svg';
import trash from '../../../../../../assets/images/icons/trash.svg';
import useLocalState from "../../../../../../hooks/useLocalState";
import { UserType } from "../../../../types";
import { HeaderContainer } from "./styles";

interface IHeader {
  user: UserType;
  createdAt: Date;
  commentsQty: number;
  handleOpenCommentsModal: () => void;
  handleOpenEditModal: () => void;
  handleOpenDeleteModal: () => void;
}

export default function Header({ user, createdAt, commentsQty, handleOpenCommentsModal, handleOpenEditModal, handleOpenDeleteModal }: IHeader) {
  const [userAtContext] = useLocalState('userData');
  const userId = userAtContext.id;
  const { profileImage, name } = user;
  const formattedDate = format(new Date(createdAt), "dd/MM/yyyy 'às' HH'h'mm'm'");

  return (
    <HeaderContainer>
      <header>
        <div>
          <img src={profileImage ?? kiddleLogo} alt="userProfileImage" />
        </div>
        <strong>{name}</strong>
        <span>{formattedDate}</span>
      </header>

      <div className="actions">
        <button
          type="button"
          onClick={() => handleOpenCommentsModal()}
        >
          <img src={comments} alt="" title="Comentários" className="primaryColor" />
        </button>
        <strong>{commentsQty} {commentsQty === 1 ? 'comentário': 'comentários'}</strong>
        {userId === user.id && (
          <>
            <button
              type="button"
              style={{ marginLeft: '16px' }}
              onClick={() => handleOpenEditModal()}
            >
              <img src={edit} alt="" title="Editar publicação" className="primaryColor" />
            </button>
            <button
              type="button"
              onClick={() => handleOpenDeleteModal()}
            >
              <img src={trash} alt="" title="Remover publicação" />
            </button>
          </>
        )}
      </div>
    </HeaderContainer>
  )
}
