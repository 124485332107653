import { Col, Container, Row } from 'react-bootstrap';
import Loader from '../../components/Loader';
import OpacityAnimation from '../../components/OpacityAnimation';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';

import NoData from '../../components/NoData';
import ActiveUsersCard from './components/ActiveUsersCard';
import ActivitiesQuantityCard from './components/ActivitiesQuantityCard';
import ActivitiesRankingCard from './components/ActivitiesRankingCard';
import AdherenceCard from './components/AdherenceCard';
import CanceledsCard from './components/CanceledsCard';
import ChurnCard from './components/ChurnCard';
import CompaniesChartCard from './components/CompaniesChartCard';
import KidsAgeCard from './components/KidsAgeCard';
import KidsAverageCard from './components/KidsAverageCard';
import NpsCard from './components/NpsCard';
import PartnersQuantityCard from './components/PartnersQuantityCard';
import RatingsCard from './components/RatingsCard';
import SchedulesPerformanceCard from './components/SchedulesPerformanceCard';
import UsersKindCard from './components/UsersKindCard';
import UsersRegionsCard from './components/UsersRegionscard';
import UsersSoCard from './components/UsersSoCard';
import useDashboard from './useDashboard';

export default function Dashboard() {
  const {
    isLoading,
    hasError,
    handleTryAgain,
    activeUsersQty,
    registeredUsersQty,
    partnersQty,
    activitiesQty,
    kidsAverage,
    schedulesQtyByMonth,
    regionsData,
    activitiesRanking,
    ratings,
    percentageKinds,
    companiesUsage,
    kidsAge,
    nps,
    canceleds,
    churnData,
    activitiesVideosViewsRanking,
    soData,
    todayActiveUsers,
    lastWeekActiveUsers,
    lastMonthActiveUsers,
  } = useDashboard();

  return (
    <>
      <Sidebar active="Dashboard" />
      <Transitions>
        <Loader isLoading={isLoading} />
        {!isLoading && !hasError && (
          <Container>
            <Row xs={1} md={5} lg={5}>
              <Col>
                <OpacityAnimation delay={0.15}>
                  <NpsCard
                    nps={nps}
                  />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.3}>
                  <ActivitiesQuantityCard activitiesQty={activitiesQty} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.45}>
                  <PartnersQuantityCard partnersQty={partnersQty} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.6}>
                  <KidsAverageCard average={kidsAverage} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.75}>
                  <CanceledsCard canceleds={canceleds.total} details={canceleds.list} />
                </OpacityAnimation>
              </Col>
            </Row>

            <Row xs={1} md={2} lg={2}>
              <Col>
                <OpacityAnimation delay={0.9}>
                  <SchedulesPerformanceCard schedulesPerformance={schedulesQtyByMonth} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={1.05}>
                  <UsersRegionsCard regionsData={regionsData} />
                </OpacityAnimation>
              </Col>
            </Row>

            <Row xs={1} md={3} lg={4}>
              <Col>
                <OpacityAnimation delay={1.2}>
                  <AdherenceCard
                    activeUsersQty={activeUsersQty}
                    registeredUsersQty={registeredUsersQty}
                  />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={1.35}>
                  <ActivitiesRankingCard ranking={activitiesRanking} label='Ranking aulas mais agendadas' />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={1.35}>
                  <ActivitiesRankingCard ranking={activitiesVideosViewsRanking} label='Ranking vídeos mais assistidos' />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={1.5}>
                  <RatingsCard average={ratings.average} details={ratings.details} />
                </OpacityAnimation>
              </Col>
            </Row>

            <Row xs={1} md={1} lg={2}>
              <Col>
                <OpacityAnimation delay={1.65}>
                  <CompaniesChartCard companiesUsage={companiesUsage} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={1.8}>
                  <ChurnCard churnData={churnData} />
                </OpacityAnimation>
              </Col>
            </Row>

            <Row xs={1} md={2} lg={3}>
              <Col>
                <OpacityAnimation delay={0.15}>
                  <ActiveUsersCard quantity={todayActiveUsers} type="Hoje" />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.3}>
                  <ActiveUsersCard quantity={lastWeekActiveUsers} type="Última Semana" />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.45}>
                  <ActiveUsersCard quantity={lastMonthActiveUsers} type="Último Mês" />
                </OpacityAnimation>
              </Col>
            </Row>

            <Row xs={1} md={2} lg={3}>
              <Col>
                <OpacityAnimation delay={0.6}>
                  <UsersKindCard percentageKinds={percentageKinds} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.75}>
                  <KidsAgeCard ages={kidsAge} />
                </OpacityAnimation>
              </Col>
              <Col>
                <OpacityAnimation delay={0.9}>
                  <UsersSoCard operatingSystems={soData} />
                </OpacityAnimation>
              </Col>
            </Row>
          </Container>
        )}

        {!isLoading && hasError && (
        <NoData
          icon="sad"
          label={(
            <>
              Ocorreu um erro ao obter os dados do dashboard.
              <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
            </>
)}
        />
        )}
      </Transitions>
    </>
  );
}
