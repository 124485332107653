/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import Select from 'react-select';
import { CustomStyle } from '../../../../../../components/CustomSelectStyle';
import FilterRadioButton from "../../../../../../components/FilterRadioButtons";
import { FilterRadioButtonsContainer } from "../../../../../../components/FilterRadioButtonsContainer";
import FormGroup from "../../../../../../components/FormGroup";
import Input from "../../../../../../components/Input";
import MyModal from "../../../../../../components/Modal";
import OpacityAnimation from "../../../../../../components/OpacityAnimation";
import { GetErrorMessageByFieldNameType } from "../../../../../../hooks/useErrors";
import { Group } from "../../styles";
import { CupomObject, FullCupomFromApiObject, PlanKindTypes } from "../../types";
import List from "./components/list";
import RedirectToCuponsPage from "./components/RedirectToCUponsPage";
import { ModalContainer } from "./styles";

interface AddCupomObjectI {
  cupomPlanValue?: string;
  cupomPlanTrial?: number | string;
  cupomPlanKind: PlanKindTypes;
  cupomName: string;
  expiresAt?: string;
  considerTestUntilTrialDays?: boolean;
  useLimit?: number;
}

interface ICuponsModal {
  cuponsModalShow: boolean;
  setCuponsModalShow: Dispatch<SetStateAction<boolean>>;
  cupons: CupomObject[];
  cupomName: string;
  addCupom: (cupom: AddCupomObjectI) => void;
  removeCupom: (cupom: string) => void;
  cupomPlanKind: { value: string, label: string };
  cupomPlanValue: string;
  cupomPlanTrial: number | string;
  handleCupomPlanValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCupomPlanTrialChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCupomNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setCupomPlanKind: Dispatch<SetStateAction<{ value: string, label: string }>>;
  getErrorMessageByFieldNameCupomForm: GetErrorMessageByFieldNameType;
  isCupomFormValid: boolean;
  edit: boolean;
  cuponsOptions: FullCupomFromApiObject[];
  associatedCuponsIds: string[];
  setAssociatedCuponsIds: Dispatch<SetStateAction<string[]>>;
  expires: boolean;
  setExpires: Dispatch<SetStateAction<boolean>>;
  handleExpirationDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  expirationDate: string;
  willLimitUsage: boolean;
  setWillLimitUsage: Dispatch<SetStateAction<boolean>>;
  handleUseLimitChange: (event: ChangeEvent<HTMLInputElement>) => void;
  useLimit: number | string;
  considerTestUntilTrialDays: boolean;
  setConsiderTestUntilTrialDays: Dispatch<SetStateAction<boolean>>;
}

export default function CuponsModal({
  cuponsModalShow,
  setCuponsModalShow,
  cupons,
  addCupom,
  removeCupom,
  cupomPlanKind,
  cupomPlanValue,
  cupomPlanTrial,
  cupomName,
  handleCupomPlanValueChange,
  handleCupomPlanTrialChange,
  handleCupomNameChange,
  setCupomPlanKind,
  getErrorMessageByFieldNameCupomForm,
  isCupomFormValid,
  edit,
  cuponsOptions,
  associatedCuponsIds,
  setAssociatedCuponsIds,
  expires,
  setExpires,
  handleExpirationDateChange,
  expirationDate,
  willLimitUsage,
  setWillLimitUsage,
  handleUseLimitChange,
  useLimit,
  considerTestUntilTrialDays,
  setConsiderTestUntilTrialDays,
}: ICuponsModal) {
  const [view, setView] = useState('list');

  const cupomPlanOptions = [
    { value: 'free', label: 'Acesso grátis' },
    { value: 'discount', label: 'Desconto na mensalidade' },
    { value: 'trial', label: 'Período de teste grátis' },
    { value: 'trial_discount', label: 'Período de teste grátis. Após, desconto na mensalidade' },
  ];

  return (
    <MyModal
      show={cuponsModalShow}
      onClose={() => setCuponsModalShow(false)}
      title={edit ? "Visualização de cupons da empresa" : "Gerenciamento de cupons da empresa"}
      modalBody={(
        <ModalContainer>
          {!edit && (
            <FilterRadioButtonsContainer>
              <FilterRadioButton
                onClick={() => setView('associate')}
                selected={view === 'associate'}
              >
                Associação de cupons já existentes
              </FilterRadioButton>
              <FilterRadioButton
                onClick={() => setView('list')}
                selected={view === 'list'}
              >
                Lista
              </FilterRadioButton>
              <FilterRadioButton
                onClick={() => setView('addOne')}
                selected={view === 'addOne'}
              >
                Adicionar
              </FilterRadioButton>
            </FilterRadioButtonsContainer>
          )}
          {edit && (
            <RedirectToCuponsPage />
          )}
          {view === 'associate' && (
            <OpacityAnimation delay={0.1}>
              <Group>
                <div className="title">
                  Cupons Associados
                </div>
                <Select
                  value={associatedCuponsIds.map((id) => ({ value: id, label: cuponsOptions.find((cp) => cp.id === id)!.name }))}
                  options={cuponsOptions.map((x) => ({ value: x.id, label: x.name }))}
                  onChange={(opt) => {
                    setAssociatedCuponsIds(opt!.map((o) => o.value));
                  }}
                  styles={CustomStyle}
                  classNamePrefix="react-select"
                  className="react-select-container"
                  isMulti
                  placeholder='Selecione um ou mais cupons para serem vinculados a essa empresa'
                />
              </Group>
            </OpacityAnimation>
          )}
          {view === 'list' && (
            <OpacityAnimation delay={0.1}>
              <List
                list={cupons}
                onDeleteCupom={(cupom: string) => removeCupom(cupom)}
                edit={edit}
              />
            </OpacityAnimation>
          )}
          {view === 'addOne' && (
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
                Tipo do benefício do cupom
              </div>
              <Select
                value={{ value: cupomPlanKind?.value, label: cupomPlanKind?.label }}
                options={cupomPlanOptions}
                onChange={(opt) => {
                  setCupomPlanKind({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Group>

            <Group>
              <div className="title">
                Terá vencimento?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={expires} onClick={() => setExpires(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!expires} onClick={() => setExpires(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>

            {expires && (
              <Group>
                <div className="title">
                  Data de vencimento
                </div>
                <Input
                  type="date"
                  onChange={(event) => handleExpirationDateChange(event)}
                  value={expirationDate}
                />
              </Group>
            )}

            <Group>
              <div className="title">
                Terá limite de uso?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={willLimitUsage} onClick={() => setWillLimitUsage(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!willLimitUsage} onClick={() => setWillLimitUsage(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>

            {willLimitUsage && (
              <Group>
                <div className="title">
                  Limite de utilização
                </div>
                <Input
                  onChange={(event) => handleUseLimitChange(event)}
                  value={useLimit}
                />
              </Group>
            )}

          {(cupomPlanKind.value.includes('trial')) && (
            <Group>
              <div className="title">
                Usuários constarão como teste durante todo o período grátis?
              </div>
              <FilterRadioButtonsContainer>
                <FilterRadioButton selected={considerTestUntilTrialDays} onClick={() => setConsiderTestUntilTrialDays(true)}>
                  Sim
                </FilterRadioButton>
                <FilterRadioButton selected={!considerTestUntilTrialDays} onClick={() => setConsiderTestUntilTrialDays(false)}>
                  Não
                </FilterRadioButton>
              </FilterRadioButtonsContainer>
            </Group>
          )}

            <Group>
              <div className="title">
                Código (nome) do cupom
              </div>
              <FormGroup>
                <Input
                  value={cupomName}
                  onChange={handleCupomNameChange}
                  placeholder="Código do cupom"
                />
              </FormGroup>
            </Group>

            {(cupomPlanKind.value === 'discount' || cupomPlanKind.value === 'trial_discount') && (
              <Group>
                <div className="title">
                  Valor do plano
                </div>
                <FormGroup error={getErrorMessageByFieldNameCupomForm('cupomPlanValue')}>
                  <Input
                    value={cupomPlanValue}
                    onChange={handleCupomPlanValueChange}
                    placeholder="Novo valor do plano com a aplicação do desconto"
                    error={getErrorMessageByFieldNameCupomForm('cupomPlanValue')}
                  />
                </FormGroup>
              </Group>
          )}

          {(cupomPlanKind.value === 'trial' || cupomPlanKind.value === 'trial_discount' || cupomPlanKind.value === 'trial_no_subscription') && (
            <Group>
              <div className="title">
                Período de teste grátis (em dias)
              </div>
              <FormGroup error={getErrorMessageByFieldNameCupomForm('cupomPlanTrial')}>
                <Input
                  value={cupomPlanTrial}
                  onChange={handleCupomPlanTrialChange}
                  placeholder="Período em dias de teste grátis"
                  error={getErrorMessageByFieldNameCupomForm('cupomPlanTrial')}
                />
              </FormGroup>
            </Group>
          )}
          </OpacityAnimation>
          )}
        </ModalContainer>
      )}
      closeButtonLabel="Fechar"
      size="lg"
      type={view === 'addOne' ? 'action' : 'info'}
      actionButtonLabel="Adicionar"
      isActionButtonDisabled={!isCupomFormValid}
      onAction={() => addCupom({
        cupomPlanValue,
        cupomPlanTrial,
        cupomPlanKind,
        cupomName,
        expiresAt: expirationDate,
        considerTestUntilTrialDays,
        useLimit: willLimitUsage ? Number(useLimit) : undefined,
      })}
      minHeight="350px"
    />
  )
}
