import { format, subHours } from 'date-fns';
import { ChangeEvent, useState } from 'react';
import { MultiValue } from 'react-select';
import useErrors from '../../../../hooks/useErrors';
import formatCurrency from '../../../../utils/formatCurrency';
import onlyNumbers from '../../../../utils/onlyNumbers';
import { Value } from '../../types/CalendarTypes';
import { LevelType, NeedsCompanionType, PartnerType } from '../../types/FormTypes';

export default function useInputChanges() {
  const [kindOfActivity, setKindOfActivity] = useState('online');
  const [name, setName] = useState('');
  const [category, setCategory] = useState({
    value: '',
    label: 'Selecione uma categoria'
  });
  const [subcategory, setSubcategory] = useState({
    value: '',
    label: 'Selecione uma subcategoria'
  });
  const [partner, setPartner] = useState<PartnerType>({
    value: '',
    label: 'Selecione em parceiro'
  });
  const [featPartner, setFeatPartner] = useState<PartnerType>({
    value: '',
    label: 'Selecione em parceiro'
  });
  const [description, setDescription] = useState('');
  const [methodology, setMethodology] = useState('');
  const [orientations, setOrientations] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [isRecurrent, setIsRecurrent] = useState({ value: true, label: 'Recorrente' });
  const [videoFile, setVideoFile] = useState<File| string | undefined>();
  const [imageFile, setImageFile] = useState<File | string | undefined>();

  const [minAge, setMinAge] = useState<number | string>('');
  const [maxAge, setMaxAge] = useState<number | string>('');
  const [needsCompanion, setNeedsCompanion] = useState<NeedsCompanionType>({ value: false, label: 'Não necessário' });
  const [necessaryMaterials, setNecessaryMaterials] = useState('');
  const [clothes, setClothes] = useState('');
  const [level, setLevel] = useState<LevelType>({ value: null, label: 'Não especificado' });
  const [necessaryKnowledge, setNecessaryKnowLedge] = useState('');

  const [periodSelectionModalShow, setPeriodSelectionModalShow] = useState(false);
  const [specificPeriod, setSpecificPeriod] = useState<Value>([new Date(), new Date()]);
  const [specificPeriodLabel, setSpecificPeriodLabel] = useState('Selecione um período');

  const [selectedTags, setSelectedTags] = useState<MultiValue<{ value: string, label: string }>>([]);
  const [isFree, setIsFree] = useState(false);
  const [classValue, setClassValue] = useState<number | string>('');

  const isRecordedActivity = kindOfActivity === 'video';

  const {
    setError, removeError, getErrorMessageByFieldName, errors,
  } = useErrors();

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({ field: 'name', message: 'Nome é obrigatório!' });
    } else {
      removeError('name');
    }
  }

  function handleDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value);

    if (!event.target.value) {
      setError({ field: 'description', message: 'Descrição é obrigatória!' });
    } else {
      removeError('description');
    }
  }

  function handleVideoLinkChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setVideoLink(event.target.value);

    if (!event.target.value) {
      setError({ field: 'videoLink', message: 'Link da aula é obrigatória!' });
    } else {
      removeError('videoLink');
    }
  }

  function handleMethodologyChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setMethodology(event.target.value);
  }

  function handleOrientationsChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setOrientations(event.target.value);
  }

  function handleClassValueChange(event: ChangeEvent<HTMLInputElement>) {
    setClassValue(formatCurrency(event.target.value));
  }

  function handleMinAgeChange(event: ChangeEvent<HTMLInputElement>) {
    setMinAge(Number(onlyNumbers(event.target.value)));

    if (!event.target.value) {
      setError({ field: 'minAge', message: 'Idade mínima é obrigatória' });
    } else if (Number(event.target.value) > Number(maxAge) && !!maxAge) {
      setError({ field: 'minAge', message: 'Idade mínima deve ser menor que idade máxima!' });
    } else {
      removeError('minAge');
      const maxAgeError = errors.find((error) => error.field === 'maxAge');
      if (maxAgeError?.message === 'Idade máxima deve ser maior que idade mínima!') {
        removeError('maxAge');
      }
    }
  }

  function handleMaxAgeChange(event: ChangeEvent<HTMLInputElement>) {
    setMaxAge(Number(onlyNumbers(event.target.value)));

    if (!event.target.value) {
      setError({ field: 'maxAge', message: 'Idade máxima é obrigatória!' });
    } else if (Number(event.target.value) > 12) {
      setError({ field: 'maxAge', message: 'Idade máxima permitida é de 12 anos!' });
    } else if (event.target.value < minAge) {
      setError({ field: 'maxAge', message: 'Idade máxima deve ser maior que idade mínima!' });
    } else {
      removeError('maxAge');
      const minAgeError = errors.find((error) => error.field === 'minAge');
      if (minAgeError?.message === 'Idade mínima deve ser menor que idade máxima!') {
        removeError('minAge');
      }
    }
  }

  function handleNecessaryMaterialsChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNecessaryMaterials(event.target.value);
  }

  function handleNecessaryKnowledgeChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNecessaryKnowLedge(event.target.value);
  }

  function handleClothesChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setClothes(event.target.value);
  }

  function handleSpecificPeriodChange(value: Value) {
    if (Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const adjustedByTimezone = value.map((date) => subHours(date!, 3))
      setSpecificPeriod([adjustedByTimezone[0], adjustedByTimezone[1]]);

      const periodLabel = `De ${format(value[0] as Date, 'dd/MM/yyyy')} até ${format(value[1] as Date, 'dd/MM/yyyy')}`
      setSpecificPeriodLabel(periodLabel);
    }
  }

  function handleTagsChange(opt: MultiValue<{ value: string, label: string}>) {
    setSelectedTags(opt);
  }

  return {
    getErrorMessageByFieldName,
    name,
    handleNameChange,
    category,
    setCategory,
    subcategory,
    setSubcategory,
    setMinAge,
    setMaxAge,
    handleDescriptionChange,
    handleVideoLinkChange,
    videoLink,
    handleMethodologyChange,
    methodology,
    handleOrientationsChange,
    orientations,
    handleMinAgeChange,
    handleMaxAgeChange,
    needsCompanion,
    setNeedsCompanion,
    level,
    setLevel,
    handleNecessaryMaterialsChange,
    necessaryMaterials,
    handleNecessaryKnowledgeChange,
    necessaryKnowledge,
    handleClothesChange,
    clothes,
    imageFile,
    setImageFile,
    videoFile,
    setVideoFile,
    minAge,
    maxAge,
    description,
    isRecurrent,
    setIsRecurrent,
    partner,
    setPartner,
    setName,
    setDescription,
    setMethodology,
    setOrientations,
    setVideoLink,
    setNecessaryMaterials,
    setClothes,
    setNecessaryKnowLedge,
    errors,
    isRecordedActivity,
    setKindOfActivity,
    kindOfActivity,
    featPartner,
    setFeatPartner,
    handleSpecificPeriodChange,
    specificPeriod,
    setPeriodSelectionModalShow,
    specificPeriodLabel,
    periodSelectionModalShow,
    setSpecificPeriod,
    setSpecificPeriodLabel,
    selectedTags,
    setSelectedTags,
    handleTagsChange,
    isFree,
    setIsFree,
    classValue,
    handleClassValueChange,
    setClassValue,
  };
}
