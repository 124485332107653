/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import Select from 'react-select';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import MyModal from "../../../../components/Modal";
import OpacityAnimation from "../../../../components/OpacityAnimation";
import { Group } from "../../styles";
import { ModalContainer } from "./styles";
import useAddModal from "./useAddModal";

interface IInternalUsersModal {
  addInternalUserModalShow: boolean;
  setAddInternalUserModalShow: Dispatch<SetStateAction<boolean>>;
  loadInternalUsers: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function AddInternalUserModal({
  addInternalUserModalShow,
  setAddInternalUserModalShow,
  loadInternalUsers,
  setIsLoading,
}: IInternalUsersModal) {
  const {
    addInternalUser,
    email,
    password,
    passwordConfirmation,
    permission,
    handleEmailChange,
    handlePasswordChange,
    handlePasswordConfirmationChange,
    setPermission,
    getErrorMessageByFieldName,
    isInternalUserFormValid,
  } = useAddModal({ loadInternalUsers, setAddInternalUserModalShow, setIsLoading });

  const permissionOptions = [
    { value: 'super', label: 'Administrador' },
    { value: 'coordinator', label: 'Coordenador' },
    { value: 'analytics', label: 'Analítico' },
    { value: 'financial', label: 'Financeiro' },
    { value: 'support', label: 'Suporte' },
    { value: 'comercial', label: 'Comercial' },
  ];

  return (
    <MyModal
      show={addInternalUserModalShow}
      onClose={() => setAddInternalUserModalShow(false)}
      title={`Adicionar novo usuário`}
      modalBody={(
        <ModalContainer>
          <OpacityAnimation delay={0.1}>
            <Group>
              <div className="title">
                Perfil
              </div>
              <Select
                value={{ value: permission?.value, label: permission?.label }}
                options={[{
                  value: '', label: 'Selecione um perfil'
                }, ...permissionOptions]}
                onChange={(opt) => {
                  setPermission({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Group>

            <Group>
              <div className="title">
                E-mail
              </div>
              <FormGroup error={getErrorMessageByFieldName('email')}>
                <Input
                  value={email}
                  onChange={handleEmailChange}
                  error={getErrorMessageByFieldName('email')}
                  placeholder="E-mail de login"
                />
              </FormGroup>
            </Group>

            <Group>
              <div className="title">
                Senha
              </div>
              <FormGroup error={getErrorMessageByFieldName('password')}>
                <Input
                  value={password}
                  onChange={handlePasswordChange}
                  error={getErrorMessageByFieldName('password')}
                  type="password"
                  placeholder="Insira uma senha inicial"
                />
              </FormGroup>
            </Group>

            <Group>
              <div className="title">
                Confirmação de senha
              </div>
              <FormGroup error={getErrorMessageByFieldName('passwordConfirmation')}>
                <Input
                  value={passwordConfirmation}
                  onChange={handlePasswordConfirmationChange}
                  error={getErrorMessageByFieldName('passwordConfirmation')}
                  type="password"
                  placeholder="Confirme a senha"
                />
              </FormGroup>
            </Group>

          </OpacityAnimation>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='action'
      actionButtonLabel="Adicionar"
      isActionButtonDisabled={!isInternalUserFormValid}
      onAction={() => addInternalUser()}
    />
  )
}
