import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import { GenericKeyValueType } from '../../types';
import { UseDashboardDataInterface } from '../interfaces';

export default function useUsersSo({ setHasError }: UseDashboardDataInterface) {
  const [soData, setSoData] = useState<GenericKeyValueType>({});

  const { apiCall } = useApiCall();

  const getUsersSo = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersSo,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar os dados de utilização por SO', {
            toastId: 'getUsersDashboardFail',
          });
          return;
        }
        const usersOperatingSystems = apiResponse.operatingSystems;
        setSoData(usersOperatingSystems);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar os dados de maior utilização por SO'
    })
  }, [apiCall, setHasError]);

  return {
    soData,
    getUsersSo,
  };
}
