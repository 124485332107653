/* eslint-disable max-len */
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiCall from '../../../../../../hooks/useApiCall';
import { ErrorsInterface } from '../../../../../../hooks/useErrors';
import companiesService from '../../../../../../services/companiesService';
import parseCurrencyStringToFloat from '../../../../../../utils/parseCurrencyStringToFloat';
import { AuthenticationTypeTypes, CupomObject, PlanKindTypes } from '../../types';

interface UseFormSubmitInterface {
  plan_id?: number | null;
  fantasyName: string;
  corporateName: string;
  cnpj: string;
  contactName: string;
  contactCellphone: string;
  contactEmail: string;
  elegiblesQuantity: number | '';
  employeesQuantity: number | '';
  adherenceGoal: number | '';
  cep: string;
  streetName: string;
  district: string;
  city: string;
  uf: string;
  number: number | '';
  complement: string;
  selectedBank: { value: string, label: string };
  agency: string;
  account: string;
  cardholder: string;
  cnpjBank: string;
  pixType: { value: string, label: string };
  cnpjPix: string;
  cpfPix: string;
  cellphonePix: string;
  emailPix: string;
  randomPix: string;
  monthlyFee: string;
  contractDate: string;
  startDate: string;
  authenticationType: AuthenticationTypeTypes;
  isReadOnly: boolean;
  associatedCompanies: string[];
  loginEmail: string;
  password: string;
  kindOfCompany: 'default' | 'seller';
  cupons: CupomObject[];
  planKind: PlanKindTypes;
  planTrial: number | string;
  planValue: string;
  viewByAssociatedCompanies: boolean;
  showAtCompaniesListAppRegister: boolean;
  resetFields: () => void;
  errors: ErrorsInterface[];
  edit: boolean;
  associatedCuponsIds: string[];
}

type SelectedPixLiteralsType = {
  [key: string]: string;
}

export default function useFormSubmit({
  plan_id,
  fantasyName,
  corporateName,
  cnpj,
  contactName,
  contactCellphone,
  contactEmail,
  elegiblesQuantity,
  employeesQuantity,
  adherenceGoal,
  cep,
  streetName,
  district,
  city,
  uf,
  number,
  complement,
  selectedBank,
  agency,
  account,
  cardholder,
  cnpjBank,
  pixType,
  cnpjPix,
  cpfPix,
  cellphonePix,
  emailPix,
  randomPix,
  monthlyFee,
  contractDate,
  startDate,
  authenticationType,
  isReadOnly,
  associatedCompanies,
  kindOfCompany,
  loginEmail,
  password,
  resetFields,
  errors,
  cupons,
  planKind,
  planTrial,
  planValue,
  viewByAssociatedCompanies,
  showAtCompaniesListAppRegister,
  edit,
  associatedCuponsIds,
}: UseFormSubmitInterface) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFormValid = (
    (cnpj && corporateName && fantasyName)
    && (contactName && contactCellphone && contactEmail)
    && (cep && streetName && number && district && uf && city)
    && (loginEmail && (edit || password))
    && (cnpjPix || cpfPix || cellphonePix || emailPix || randomPix || (selectedBank.value && agency && account && cardholder && cnpjBank))
    && ((isReadOnly && cupons && (cupons.length > 0 || associatedCuponsIds.length > 0)) || (!isReadOnly && planKind.value))
    && errors.length === 0
  );

  const requiredFieldsErrorsLabel: string[] = useMemo(() => {
    return [
      !cnpj ? 'CNPJ' : '',
      !corporateName ? 'Razão Social' : '',
      !fantasyName ? 'Nome Fantasia' : '',
      !contactName ? 'Nome do contato' : '',
      !contactCellphone ? 'Celular do contato' : '',
      !contactEmail ? 'E-mail do contato' : '',
      !cep ? 'CEP' : '',
      !streetName ? 'Logradouro' : '',
      !number ? 'Número' : '',
      !district ? 'Bairro' : '',
      !uf ? 'UF' : '',
      !city ? 'Cidade' : '',
      (!loginEmail && (!edit || !password)) ? 'E-mail de login' : '',
      (!cnpjPix && !cpfPix && !cellphonePix && !emailPix && !randomPix && (!selectedBank.value || !agency || !account || !cardholder || !cnpjBank)) ? 'Dados bancários (Algum PIX, ou detalhes da conta)' : '',
      (isReadOnly && (!cupons || (cupons.length === 0 && associatedCuponsIds.length === 0))) ? 'Cupons (Método de aplicação do benefício está como Cupom, portanto precisa ser criado pelo menos um)' : '',
      (!isReadOnly && !planKind.value) ? 'Tipo do Plano' : '',
    ].filter((error) => !!error);
  }, [account, agency, cardholder, cellphonePix, cep, city, cnpj, cnpjBank, cnpjPix, contactCellphone, contactEmail, contactName, corporateName, cpfPix, cupons, district, edit, emailPix, fantasyName, isReadOnly, loginEmail, number, password, planKind.value, randomPix, selectedBank.value, streetName, uf, associatedCuponsIds]);

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiCall();

  const createCompany = useCallback(async () => {
    const selectedPixTypeMap: SelectedPixLiteralsType = {
      CNPJ: cnpjPix,
      CPF: cpfPix,
      Celular: cellphonePix,
      'E-mail': emailPix,
      Chave: randomPix,
    };

    await apiCall({
      apiToCall: companiesService.createCompany,
      reqBody: JSON.stringify({
        nome_fantasia: fantasyName,
        razao_social: corporateName,
        cnpj,
        responsavel: contactName,
        contato_celular: contactCellphone,
        contato_email: contactEmail,
        elegiveis: elegiblesQuantity || undefined,
        funcionarios: employeesQuantity || undefined,
        meta_adesao: adherenceGoal || undefined,
        fee_mensal: monthlyFee,
        inicio: startDate,
        inicio_contrato_atual: contractDate,
        endereco: {
          cep,
          rua: streetName,
          bairro: district,
          cidade: city,
          uf,
          numero: number,
          complemento: complement,
        },
        dados_bancarios: {
          banco: selectedBank.value,
          agencia: agency,
          conta: account,
          titular: cardholder,
          documento: cnpjBank,
          tipo_pix: pixType.value,
          pix: selectedPixTypeMap[pixType.value],
        },
        authentication_type: isReadOnly ? undefined : (authenticationType.value || undefined),
        readOnly: isReadOnly,
        associated_companies: kindOfCompany === 'seller' ? associatedCompanies : [],
        type: kindOfCompany,
        loginEmail,
        password,
        ...(isReadOnly ? {
          cupons: cupons.length > 0 ? cupons.map((cp) => ({
            name: cp.cupomName,
            planKind: cp.cupomPlanKind.value,
            planTrialDays: cp.cupomPlanTrial || undefined,
            planValue: parseCurrencyStringToFloat(cp.cupomPlanValue) || undefined,
            ...(cp.expiresAt ? { expiresAt: new Date(cp.expiresAt) } : {}),
            ...(cp.useLimit ? { useLimit: cp.useLimit } : {}),
            considerTestUntilTrialDays: cp.considerTestUntilTrialDays,
          })) : [],
          cuponsIdsAssociated: associatedCuponsIds,
        } : {
          planKind: planKind.value,
          planTrialDays: planTrial || undefined,
          planValue: parseCurrencyStringToFloat(planValue) || undefined,
        }),
        apply_bennefit_method: isReadOnly ? 'cupom' : 'uniqueId',
        viewByAssociatedCompanies: isReadOnly ? false : !!viewByAssociatedCompanies,
        showAtCompaniesListAppRegister: isReadOnly ? showAtCompaniesListAppRegister : true,
      }),
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível adicionar a empresa ${fantasyName}. Por favor, tente novamente.`);
          return;
        }

        if (apiResponse.useSamePasswordFromApp) {
          toast.success(`A empresa ${fantasyName} foi adicionada com sucesso!`);
          toast.warn('Como o e-mail de login já está cadastrado no aplicativo, a senha de acesso ao sistema administrativo é a mesma do aplicativo!');
          resetFields();
          return;
        }
        toast.success(`A empresa ${fantasyName} foi adicionada com sucesso, e já é possivel acessar o sistema administrativo através do login e senha informados!`);
        resetFields();
      },
      catchMessage: `Não foi possível adicionar a empresa ${fantasyName}. Por favor, tente novamente`,
    })
  }, [account, adherenceGoal, agency, apiCall, associatedCompanies, associatedCuponsIds, authenticationType?.value, cardholder, cellphonePix, cep, city, cnpj, cnpjBank, cnpjPix, complement, contactCellphone, contactEmail, contactName, contractDate, corporateName, cpfPix, cupons, district, elegiblesQuantity, emailPix, employeesQuantity, fantasyName, isReadOnly, kindOfCompany, loginEmail, monthlyFee, number, password, pixType?.value, planKind?.value, planTrial, planValue, randomPix, resetFields, selectedBank?.value, showAtCompaniesListAppRegister, startDate, streetName, uf, viewByAssociatedCompanies]);

  const updateCompany = useCallback(async () => {
    const selectedPixTypeMap: SelectedPixLiteralsType = {
      CNPJ: cnpjPix,
      CPF: cpfPix,
      Celular: cellphonePix,
      'E-mail': emailPix,
      Chave: randomPix,
    };

    const valueAndTrialByPlanKindLiterals: { [key: string]: { planValue: null | number, planTrialDays: null | number }} = {
      free: { planValue: null, planTrialDays: null },
      trial: { planValue: null, planTrialDays: Number(planTrial) },
      trial_no_subscription: { planValue: null, planTrialDays: Number(planTrial) },
      discount: { planValue: parseCurrencyStringToFloat(planValue), planTrialDays: null },
      trial_discount: { planValue: parseCurrencyStringToFloat(planValue), planTrialDays: Number(planTrial) },
    }

    await apiCall({
      apiToCall: companiesService.updateCompany,
      queryParams: { companyId: id },
      reqBody: JSON.stringify({
        nome_fantasia: fantasyName,
        razao_social: corporateName,
        cnpj,
        responsavel: contactName,
        contato_celular: contactCellphone,
        contato_email: contactEmail,
        elegiveis: elegiblesQuantity || undefined,
        funcionarios: employeesQuantity || undefined,
        meta_adesao: adherenceGoal || undefined,
        fee_mensal: monthlyFee,
        inicio: startDate,
        inicio_contrato_atual: contractDate,
        endereco: {
          cep,
          rua: streetName,
          bairro: district,
          cidade: city,
          uf,
          numero: number,
          complemento: complement,
        },
        dados_bancarios: {
          banco: selectedBank.value,
          agencia: agency,
          conta: account,
          titular: cardholder,
          documento: cnpjBank,
          tipo_pix: pixType.value,
          pix: selectedPixTypeMap[pixType.value],
        },
        authentication_type: isReadOnly ? undefined : authenticationType.value ?? undefined,
        readOnly: isReadOnly,
        associated_companies: associatedCompanies,
        type: kindOfCompany,
        ...(isReadOnly ? {} : {
          planKind: planKind.value,
          ...valueAndTrialByPlanKindLiterals[planKind.value],
        }),
        apply_bennefit_method: isReadOnly ? 'cupom' : 'uniqueId',
        plan_id: plan_id || undefined,
        viewByAssociatedCompanies: isReadOnly ? false : !!viewByAssociatedCompanies,
        showAtCompaniesListAppRegister: isReadOnly ? showAtCompaniesListAppRegister : true,
      }),
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad:() => setIsSubmitting(false),
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível editar a empresa ${fantasyName}. Por favor, tente novamente.`);
          return;
        }
        toast.success(`A empresa ${fantasyName} foi editada com sucesso!`);
        navigate('/companies');
      },
      catchMessage: `Não foi possível editar a empresa ${fantasyName}. Por favor, tente novamente`,
    })
  }, [account, adherenceGoal, agency, apiCall, associatedCompanies, authenticationType.value, cardholder, cellphonePix, cep, city, cnpj, cnpjBank, cnpjPix, complement, contactCellphone, contactEmail, contactName, contractDate, corporateName, cpfPix, district, elegiblesQuantity, emailPix, employeesQuantity, fantasyName, id, isReadOnly, kindOfCompany, monthlyFee, navigate, number, pixType.value, planKind.value, planTrial, planValue, plan_id, randomPix, selectedBank.value, showAtCompaniesListAppRegister, startDate, streetName, uf, viewByAssociatedCompanies]);

  return {
    isSubmitting,
    isFormValid,
    createCompany,
    updateCompany,
    requiredFieldsErrorsLabel,
  };
}
