/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { addDays, endOfDay, format, getDay, isAfter, isBefore, lastDayOfMonth, parse, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApiCall from "../../../../../hooks/useApiCall";
import { useQuery } from "../../../../../hooks/useQuery";
import givenClassesService from "../../../../../services/givenClassesService";
import getStringWeekDayByIndex from "../../../../../utils/getStringWeekDayByIndex";
import { GivenClassType } from "../../../types";
import { ActivitiesOptionsApiResponse, ActivityType, DateTimeOption, DateTimeType, PartnerType } from "../types";

export default function useGivenClassesForm({ isEdit }: { isEdit: boolean}) {
  const [isLoading, setIsLoading] = useState(false);

  const [partnerOptions, setPartnerOptions] = useState<PartnerType[]>([]);
  const [selectedPartner, setSelectedPartner] = useState({} as PartnerType);
  const [selectedFeatPartner, setSelectedFeatPartner] = useState({} as PartnerType);

  const [activityOptions, setActivityOptions] = useState<ActivityType[]>([]);
  const [selectedActivity, setSelectedActivity] = useState({} as ActivityType);

  const [dateTimeOptions, setDateTimeOptions] = useState<DateTimeType>({} as DateTimeType);
  const [selectedDateTime, setSelectedDateTime] = useState({} as DateTimeOption);

  const { apiCall } = useApiCall();
  const navigate = useNavigate();
  const query = useQuery();
  const { givenClassId } = useParams();
  const month = query.get('month');
  const year = query.get('year');

  const getPartnerOptions = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.findPartnersOptions,
      // onStartLoad: () => setIsLoading(true),
      // onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: { success: boolean, partners: { nome_fantasia: string, id: string }[] }) => {
        if (!response.success) {
          toast.error('Não foi possível carregar as opções de parceiros.');
          navigate('/givenClasses');
          return;
        }
        const mappedPartners = [{ value: '', label: 'Selecione um Parceiro'}].concat(response.partners.map(partner => ({ value: partner.id, label: partner.nome_fantasia })));
        setPartnerOptions(mappedPartners);
      },
      catchAction: () => {
        toast.error('Não foi possível carregar as opções de parceiros.');
          navigate('/givenClasses');
          return;
      }
    })
  }, [apiCall, navigate]);

  const getActivitiesOptions = useCallback(async () => {
    let activities: ActivityType[] = [];
    await apiCall({
      apiToCall: givenClassesService.findActivitiesOptions,
      // onStartLoad: () => setIsLoading(true),
      // onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: ActivitiesOptionsApiResponse) => {
        if (!response.success) {
          toast.error('Não foi possível carregar as opções de atividades.');
          navigate('/givenClasses');
          return;
        }
        const mappedActivities = response.activities.map(activity => ({ value: activity.id, label: activity.nome, ...activity }));
        setActivityOptions(mappedActivities);
        activities = mappedActivities;
      },
      catchAction: () => {
        toast.error('Não foi possível carregar as opções de parceiros.');
          navigate('/givenClasses');
          return;
      }
    });
    return activities;
  }, [apiCall, navigate]);

  const getGivenClasses = useCallback(async () => {
    let givenClasses: GivenClassType[] = [];
    await apiCall({
      apiToCall: givenClassesService.getGivenClassesByPeriod,
      queryParams: {
        month: month,
        year: year,
      },
      // onStartLoad: () => setIsLoading(true),
      // onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response: { success: boolean, givenClasses: GivenClassType[] }) => {
        if (!response.success) {
          toast.error('Não foi possível carregar o formulário. Por favor, tente novamente');
          navigate('/givenClasses');
          return;
        }
        givenClasses = response.givenClasses;
      },
      catchAction: () => {
        toast.error('Não foi possível carregar as opções de parceiros.');
          navigate('/givenClasses');
          return;
      }
    });
    return givenClasses;
  }, [apiCall, month, navigate, year]);

  const getGivenClass = useCallback(async (activityOptionsParam: ActivityType[]) => {
    await apiCall({
      apiToCall: givenClassesService.getGivenClassById,
      queryParams: { id: givenClassId },
      actionAfterResponse: (response: { success: boolean, givenClass: GivenClassType }) => {
        if (!response.success) {
          toast.error('Não foi possível carregar a aula realizada. Por favor, tente novamente.');
          navigate('/givenClasses');
          return;
        }
        const correspondingActivity = activityOptionsParam.find((activity) => activity.value === response.givenClass.activity.id);

        if (!correspondingActivity) {
          toast.error('Não foi possível carregar a aula realizada. Por favor, tente novamente.');
          navigate('/givenClasses');
          return;
        }

        setSelectedPartner({ value: response.givenClass.partner.id, label: response.givenClass.partner.nome_fantasia });
        setSelectedActivity(correspondingActivity);
      }
    })
  }, [apiCall, givenClassId, navigate]);

  const loadPage = useCallback(async () => {
    try {
      setIsLoading(true);

      const [gvClasses, activitiesOpt] = await Promise.all([
        getGivenClasses(),
        getActivitiesOptions(),
        getPartnerOptions(),
      ]);

      const timeOptionsByActMap = new Map();

      const compareDateTimeOptions = (a: DateTimeOption, b: DateTimeOption): number => {
        // Converte as strings de data para objetos Date
        const dateA = parse(a.date!, 'd/M/yyyy', new Date());
        const dateB = parse(b.date!, 'd/M/yyyy', new Date());

        // Compara as datas
        const dateComparison = dateA.getTime() - dateB.getTime();

        // Se as datas são iguais, compara os startTime
        if (dateComparison === 0) {
          if (!a.startTime || !b.startTime) {
            return 0;
          }
            return a.startTime.localeCompare(b.startTime);
        }

        return dateComparison;
      };

      activitiesOpt.forEach((activity) => {
        const weekDaysOfActivity = activity.grade.filter((timetable) => {
          return timetable.horarios.length > 0
        });

        const endDate = activity.recorrente ? lastDayOfMonth(new Date(Number(year), Number(month) - 1, 1)) : new Date(activity.specificPeriod[1]);


        const dateTimeOptionsOfActivityInCurMonth: DateTimeOption[] = [];

        weekDaysOfActivity.forEach((weekDay) => {
          // eslint-disable-next-line prefer-const
          let startDate = new Date(Number(year), Number(month) - 1, 1);

          while (isAfter(endOfDay(endDate), startDate)) {
            if (activity.recorrente) {
              const currentDateWeekDayStringAtLoop = getStringWeekDayByIndex(getDay(startDate));

              if (currentDateWeekDayStringAtLoop === weekDay.dia) {
                const timesAtDay = weekDay.horarios;

                timesAtDay.forEach((time) => {
                  dateTimeOptionsOfActivityInCurMonth.push({
                    id: `${format(startDate, 'd/M/yyyy')}|${time.horarioInicio}|${time.horarioTermino}`,
                    date: format(startDate, 'd/M/yyyy'),
                    startTime: time.horarioInicio,
                    endTime: time.horarioTermino,
                  });
                })
              }
              startDate = addDays(startDate, 1);
            } else {
              const currentDateWeekDayStringAtLoop = getStringWeekDayByIndex(getDay(startDate));
              const newStartDate = new Date(activity.specificPeriod[0]);

              if (currentDateWeekDayStringAtLoop === weekDay.dia && isBefore(newStartDate, startDate)) {
                const timesAtDay = weekDay.horarios;

                timesAtDay.forEach((time) => {
                  dateTimeOptionsOfActivityInCurMonth.push({
                    id: `${format(startDate, 'd/M/yyyy')}|${time.horarioInicio}|${time.horarioTermino}`,
                    date: format(startDate, 'd/M/yyyy'),
                    startTime: time.horarioInicio,
                    endTime: time.horarioTermino,
                  });
                })
              }
              startDate = addDays(startDate, 1);
            }
          }
        });

        const removedDateTimesThatAlreadyHasGvCl = dateTimeOptionsOfActivityInCurMonth.filter((dateTime) => {
          const gvClassesIdsConcat = gvClasses.map((gv) => `${format(new Date(gv.date), 'd/M/yyyy')}|${gv.startTime}|${gv.endTime}`);

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return !gvClassesIdsConcat.includes(dateTime.id!);
        });
        removedDateTimesThatAlreadyHasGvCl.sort(compareDateTimeOptions);

        timeOptionsByActMap.set(activity.value, removedDateTimesThatAlreadyHasGvCl);
      });

      setDateTimeOptions(Object.fromEntries(timeOptionsByActMap));

      if (isEdit) {
        await getGivenClass(activitiesOpt);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  }, [getActivitiesOptions, getGivenClass, getGivenClasses, getPartnerOptions, isEdit, month, year]);

  const addGivenClass = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.createGivenClass,
      reqBody: JSON.stringify({
        activityId: selectedActivity.value,
        partnerId: selectedPartner.value,
        date: parse(selectedDateTime.date!, 'd/M/yyyy', startOfDay(new Date())),
        startTime: selectedDateTime.startTime,
        endTime: selectedDateTime.endTime,
        ...(selectedFeatPartner.value ? { featPartnerId: selectedFeatPartner.value } : {})
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível adicionar a aula realizada. Por favor, tente novamente.');
          return;
        }
        toast.success('Aula realizada adicionada com sucesso!');
        setSelectedActivity({} as ActivityType);
        setSelectedPartner({} as PartnerType);
        setSelectedDateTime({} as DateTimeOption);
        setDateTimeOptions((prevState) => ({ ...prevState, [selectedActivity.value]: prevState[selectedActivity.value].filter((dateTime) => dateTime.id !== selectedDateTime.id)}))
      },
    })
  }, [apiCall, selectedActivity.value, selectedDateTime.date, selectedDateTime.endTime, selectedDateTime.id, selectedDateTime.startTime, selectedFeatPartner.value, selectedPartner.value]);

  const updateGivenClass = useCallback(async () => {
    await apiCall({
      apiToCall: givenClassesService.updateGivenClass,
      queryParams: { id: givenClassId },
      reqBody: JSON.stringify({
        partnerId: selectedPartner.value,
      }),
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível atualizar o parceiro que realizou essa aula. Por favor, tente novamente.');
          return;
        }
        toast.success('Parceiro atualizado com sucesso!');
        navigate('/givenClasses');
      },
    })
  }, [apiCall, givenClassId, navigate, selectedPartner.value]);

  const isFormValid = useMemo(() => (
    selectedActivity.value &&
    selectedPartner.value &&
    selectedDateTime.id
  ), [selectedActivity, selectedDateTime, selectedPartner]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return {
    isLoading,
    selectedActivity,
    selectedPartner,
    selectedDateTime,
    partnerOptions,
    setSelectedPartner,
    activityOptions,
    setSelectedActivity,
    dateTimeOptions,
    setSelectedDateTime,
    isFormValid,
    addGivenClass,
    updateGivenClass,
    selectedFeatPartner,
    setSelectedFeatPartner,
  };
}
