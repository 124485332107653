export default {
  colors: {
    background: '#121212',
    defaultBorder: '#121212',
    lighterBackground: '#000',
    darkerBackground: '#404040',
    backgroundWithOpacity: 'rgba(18, 18, 18, 0.7)',

    primary: {
      dark: '#fff6b0',
      main: '#FFED6C',
      light: '#ffe322',
      lighter: '#b39d05',
    },

    secondary: {
      dark: '#8af1f1',
      main: '#0ab3db',
      light: '#0ab3db',
      lighter: '#007171',
    },

    chart: {
      main: '#4aafff',
    },

    blue: {
      900: '#7ed3ff',
      200: '#1097e5',
      100: '#005481',
      50: '#003250',
    },

    gray: {
      50: '#566a7f',
      75: '#bcbcbc',
      100: '#566a7f',
      200: '#E6E6E6',
      201: '#bcbcbc',
      900: '#f1f1f1',
    },

    green: {
      100: '#1f5d3a',
      500: '#7ed2a2',
    },

    purple: {
      100: '#dd0eb7',
      500: '#feb4f2',
    },

    orange: {
      100: '#da7c0f',
      500: '#fddcb2',
    },

    danger: {
      darker: '#feb5b5',
      dark: '#F97171',
      main: '#FC5050',
      light: '#F63131',
    },
  },

  filters: {
    primary: 'invert(99%) sepia(76%) saturate(1313%) hue-rotate(334deg) brightness(101%) contrast(101%)',
  },
};
