import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import schedulesService from '../../../../services/schedulesService';
import { UseDashboardDataInterface } from '../interfaces';
import { SchedulesQtyByMonthType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useSchedulesPerformance({ setHasError }: UseDashboardDataInterface) {
  const [schedulesQtyByMonth, setSchedulesQtyByMonth] = useState<SchedulesQtyByMonthType>({} as SchedulesQtyByMonthType);

  const { apiCall } = useApiCall();

  const getSchedulesPerformance = useCallback(async () => {
    await apiCall({
      apiToCall: schedulesService.getSchedulesPerformance,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar a a performance de agendamentos', {
            toastId: 'getSchedulesDashboardFail',
          });
          return;
        }
        const schedulesPerformace = apiResponse;
        setSchedulesQtyByMonth(schedulesPerformace);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar a performance de agendamentos'
    })
  }, [apiCall, setHasError]);

  return {
    schedulesQtyByMonth,
    getSchedulesPerformance,
  };
}
