import PageHeader from "../../components/PageHeader";
import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import GivenClassesForm from "./components/GivenClassesForm";

export default function NewGivenClass() {
  return (
    <>
      <Sidebar active="GivenClasses" />
      <Transitions>
        <PageHeader
          title="Nova Aula Realizada"
          link="/givenClasses"
        />
        <GivenClassesForm isEdit={false} />
      </Transitions>
    </>
  )
}
