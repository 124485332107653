import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0px;
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 4px;
  border-radius: 4px;
  /* max-height: 500px;
  overflow: auto;
  overflow-x: hidden; */
  /* min-height: 125px; */
  height: 100%;
  @media(max-width: 770px) {
      overflow: auto;
      overflow-x: auto;
    }

  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-weight: bold;
    div {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 15px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      margin-left: 4px;
      &:hover {
        opacity: 0.7;
      }
    }
    .download{
      img{
        width: 20px;
      }
    }
  }

  .card-main-graph-hover-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 8px;
    div {
      font-weight: bold;
      font-size: 32px !important;
    }
    small {
      font-size: 12px;
    }
    .VictoryContainer svg text tspan {
      font-size: 5px !important;
      fill: ${({ theme }) => theme.colors.primary.main} !important;
    } //Style the charts label
    .VictoryContainer svg #pie-labels-1{
      display: none !important;
    } //Removes pie label
    .VictoryContainer svg #pie-labels-0{
      display: none !important;
    } //Removes pie label
    .VictoryContainer svg #pie-labels-2{
      display: none !important;
    } //Removes pie label
  }

  .card-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 2px;
    div {
      font-weight: bold;
      font-size: 24px;
    }
    small {
      font-size: 12px;
    }
    .VictoryContainer svg text {display: none;} //Removes the labels
  }

  .card-main-graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 8px;
    div {
      font-weight: bold;
      font-size: 32px !important;
    }
    small {
      font-size: 12px;
    }
    .VictoryContainer svg text tspan {
      font-size: 8px !important;
      fill: ${({ theme }) => theme.colors.chart.main} !important;
    } //Style the charts label
    .VictoryContainer svg #pie-labels-0{
      display: none !important;
    } //Removes pie label
    .VictoryContainer svg #pie-labels-1{
      display: none !important;
    } //Removes pie label
    .VictoryContainer svg #pie-labels-2{
      display: none !important;
    } //Removes pie label
    .VictoryContainer svg #pie-labels-3{
      display: none !important;
    } //Removes pie label

    .VictoryContainer {
      svg {
        g {
          g {
            text {
              tspan {
                max-width: 10px !important;
                text-overflow: ellipsis !important;
                overflow: hidden !important;
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }

    > label {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span:first-child {
        font-size: 32px;
        font-weight: bold;
        white-space: pre;
      }

      span:last-child {
        font-size: 12px;
        text-align: center;
        white-space: pre;
      }
    }
  }

  .legend {
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-right: 8px;
      small {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 12px;
        font-weight: bold;
      }
      small.green{
        color: #8b9a71;
      }
      small.red{
        color: #ff6361;
      }
      small.orange{
        color: #ffa600;
      }
      small.lightBlue{
        color: #4aafff;
      }
      small.darkBlue{
        color: #006FE6;
      }
      small.blue{
        color: #006FE6;
      }
      small.mediumslateblue{
        color: #006FE6;
      }
      small.mediumpurple{
        color: #bc5090;
      }
      small.gray{
        color: gray;
      }
    }
  }
`;

export const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 9px;
  max-height: 73px;
  overflow: auto;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    strong {
      margin-right: 7px;
      margin-left: 3px;
      font-size: 12px;
    }

    span {
      font-size: 12px;
    }

    img {
      width: 21px;
      margin: 2px;
      margin-left: 0px;
    }
  }
`;
