import { Col, Container, Row } from 'react-bootstrap';
import NoData from '../../../../components/NoData';
import { SchedulesInLast30DaysType } from '../../types';
import { Container as CustomContainer, Group } from './styles';

interface SchedulesListInterface {
  list: SchedulesInLast30DaysType[];
}

export default function SchedulesList({ list }: SchedulesListInterface) {
  const hasSchedules = list.length !== 0;

  return (
    <CustomContainer>
      {hasSchedules && (
        <Container>
          <Row xs={1} md={list.length === 1 ? 1 : 2} lg={list.length === 1 ? 1 : 2}>
            {list.map((sch) => (
              <Col key={sch.id}>
                <Group>
                  <div className="info">
                    <div className="card-title">
                      <strong>{sch.activity.nome}</strong>
                      <small>{`${sch.dataAgendamento}`}</small>
                    </div>
                    <span>
                      Data da aula:
                      {' '}
                      {sch.data}
                    </span>
                    <span>
                      Horário:
                      {' '}
                      {sch.horario}
                    </span>
                  </div>
                </Group>
              </Col>
            ))}
          </Row>
        </Container>
      )}

      {!hasSchedules && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Este assinante não realizou nenhum agendamento nos últimos 30 dias.
              .
            </>
          )}
        />
      )}
    </CustomContainer>
  );
}
