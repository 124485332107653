import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import subscribersService from '../../../../services/subscribersService';
import { UseDashboardDataInterface } from '../interfaces';
import { CanceledsType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useCanceleds({ setHasError }: UseDashboardDataInterface) {
  const [canceleds, setCanceleds] = useState<CanceledsType>({} as CanceledsType);

  const { apiCall } = useApiCall();

  const getCanceleds = useCallback(async () => {
    await apiCall({
      apiToCall: subscribersService.getSubscribersCanceled,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar os dados dos canelamentos', {
            toastId: 'getCanceledsDashboardFail',
          });
          return;
        }
        setCanceleds(apiResponse);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar os dados dos canelamentos',
    })
  }, [apiCall, setHasError]);

  return {
    canceleds,
    getCanceleds,
  };
}
