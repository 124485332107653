/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import MyModal from "../../../../../components/Modal";
import useDeleteModal from "./useDeleteModal";
import { CategoryBeingDeletedType } from "../../types";

interface IDeleteModal {
  deleteModalShow: boolean;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  categoryBeingDeleted: CategoryBeingDeletedType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadCategories: () => Promise<void>;
}

export default function DeleteModal({
  deleteModalShow,
  setDeleteModalShow,
  setIsLoading,
  loadCategories,
  categoryBeingDeleted,
}: IDeleteModal) {
  const {
    deleteCategory
  } = useDeleteModal({ setIsLoading, loadCategories, setDeleteModalShow, categoryBeingDeleted });

  return (
    <MyModal
      show={deleteModalShow}
      onClose={() => setDeleteModalShow(false)}
      title={`Editar categoria ${categoryBeingDeleted.name}`}
      modalBody={(
        <> Tem certeza que deseja remover a categoria <strong>{categoryBeingDeleted.name}</strong>?</>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='deleteAction'
      actionButtonLabel="Remover"
      onAction={() => deleteCategory()}
    />
  )
}
