import MyModal from '../../../components/Modal';
import ActivitiesList from './list/activitiesList';
import { PartnerActivitiesBeingShowType } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface PartnersActivitiesModalInterface {
  partnerActivitiesBeingShow: PartnerActivitiesBeingShowType;
  setPartnerActivitiesModalShow: Dispatch<SetStateAction<boolean>>;
  partnerActivitiesModalShow: boolean;
}

export default function PartnersActivitiesModal({
  partnerActivitiesBeingShow,
  setPartnerActivitiesModalShow,
  partnerActivitiesModalShow,
}: PartnersActivitiesModalInterface) {
  return (
    <MyModal
      title={`Atividades do parceiro ${partnerActivitiesBeingShow.name}`}
      closeButtonLabel="Fechar"
      modalBody={(
        <ActivitiesList list={partnerActivitiesBeingShow.activities} />
          )}
      onClose={() => setPartnerActivitiesModalShow(false)}
      show={partnerActivitiesModalShow}
      size="xl"
    />
  );
}
