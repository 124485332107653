import { useState } from "react"
import useLoadCategories from "./functions/useLoadCategories";
import useSearches from "./functions/useSearches";
import useAddModal from "./functions/useAddModal";
import useEditModal from "./functions/useEditModal";
import useImportCsvModal from "./functions/useImportCsvModal";
import useDownloadExcel from "./functions/useDownloadExcel";
import useDeleteModal from "./functions/useDeleteModal";

export default function useCategories() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    categories,
    doesListApiHasError,
    filteredList,
    handleTryAgain,
    loadCategories,
    setFilteredList,
  } = useLoadCategories({ setIsLoading });

  const {
    searchTerm,
    handleChangeSearchTerm,
    selectedType,
    handleTypeChange,
  } = useSearches({ setFilteredList, categories })

  const { setAddModalShow, addModalShow,} = useAddModal();

  const {
    handleOpenEditModal,
    categoryBeingEditted,
    setEditModalShow,
    editModalShow,
  } = useEditModal();

  const {
    handleOpenDeleteModal,
    categoryBeingDeleted,
    setDeleteModalShow,
    deleteModalShow,
  } = useDeleteModal();

  const {
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose,
    setModalImportCsvShow,
  } = useImportCsvModal({ setIsLoading, loadCategories });

  const { downloadExcel } = useDownloadExcel({ categories });

  return {
    isLoading,
    categories,
    searchTerm,
    handleChangeSearchTerm,
    selectedType,
    handleTypeChange,
    doesListApiHasError,
    filteredList,
    handleTryAgain,
    handleOpenEditModal,
    categoryBeingEditted,
    setEditModalShow,
    editModalShow,
    setAddModalShow,
    addModalShow,
    downloadExcel,
    loadCategories,
    setIsLoading,
    modalImportCsvShow,
    csvFileName,
    handleFileUpload,
    handleSubmitFile,
    handleImportModalClose,
    setModalImportCsvShow,
    handleOpenDeleteModal,
    categoryBeingDeleted,
    setDeleteModalShow,
    deleteModalShow,
  }
}
