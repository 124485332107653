/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch, SetStateAction } from "react";
import MyModal from "../../../../../components/Modal";
import { ProgramBeingDeletedType } from "../../types";
import useDeleteModal from "./useDeleteModal";

interface IDeleteModal {
  deleteModalShow: boolean;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  programBeingDeleted: ProgramBeingDeletedType;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadPrograms: () => Promise<void>;
}

export default function DeleteModal({
  deleteModalShow,
  setDeleteModalShow,
  setIsLoading,
  loadPrograms,
  programBeingDeleted,
}: IDeleteModal) {
  const {
    deleteProgram
  } = useDeleteModal({ setIsLoading, loadPrograms, setDeleteModalShow, programBeingDeleted });

  return (
    <MyModal
      show={deleteModalShow}
      onClose={() => setDeleteModalShow(false)}
      title={`Remover programação pedagógica de ${programBeingDeleted.date} | ${programBeingDeleted.startTime} - ${programBeingDeleted.endTime}`}
      modalBody={(
        <>
          Tem certeza que deseja remover essa programação pedagógica?
          <br />
          <strong>Descrição da programação: </strong>{programBeingDeleted.specificProgram}
        </>
              )}
      closeButtonLabel="Fechar"
      size="lg"
      type='deleteAction'
      actionButtonLabel="Remover"
      onAction={() => deleteProgram()}
    />
  )
}
