import Loader from "../../../components/Loader";
import NoData from "../../../components/NoData";
import PageHeader from "../../../components/PageHeader";
import Sidebar from "../../../components/Sidebar";
import Transitions from "../../../components/Transition";
import AddModal from "./components/AddModal";
import DeleteModal from "./components/DeleteModal";
import EditModal from "./components/EditModal";
import FeedbackModal from "./components/FeedbackModal";
import ListHeader from "./components/header";
import List from "./components/list";
import usePrograms from "./usePrograms";

export default function ActivityProgram() {
  const {
    programs,
    doesListApiHasError,
    isLoading,
    setAddModalShow,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleTryAgain,
    dateTimeOptions,
    addModalShow,
    loadPrograms,
    setIsLoading,
    editModalShow,
    setEditModalShow,
    programBeingEditted,
    deleteModalShow,
    setDeleteModalShow,
    programBeingDeleted,
    activityName,
    feedbackModalShow,
    setFeedbackModalShow,
    programToBeSentFeedback,
    handleOpenFeedbackModal,
  } = usePrograms();

  const hasPrograms = programs?.length !== 0 && !!programs;
  const listLength = programs?.length ?? 0;
  const isListEmpty = !doesListApiHasError && (!isLoading && programs.length === 0);

  return (
    <>
      <Sidebar active="Activities" />
      <Transitions>
        <Loader isLoading={isLoading} />

        <PageHeader
          title={`Programação específica ${activityName}`}
          link={'/activities'}
        />

        <ListHeader
          doesListExists={hasPrograms}
          hasError={doesListApiHasError}
          filteredListLength={listLength}
          singularLabel={'Programação'}
          pluralLabel={'Programações'}
          setAddModalShow={setAddModalShow}
          renderAddModalShowButtonTrigger={dateTimeOptions.length > 0}
        />

        {hasPrograms && (
          <List
            filteredList={programs}
            onOpenEditModal={handleOpenEditModal}
            onOpenDeleteModal={handleOpenDeleteModal}
            onOpenFeedbackModal={handleOpenFeedbackModal}
          />
        )}

        <AddModal
          dateTimeOptions={dateTimeOptions}
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
          loadProgram={loadPrograms}
          setIsLoading={setIsLoading}
        />

        <EditModal
          editModalShow={editModalShow}
          setEditModalShow={setEditModalShow}
          setIsLoading={setIsLoading}
          loadPrograms={loadPrograms}
          programBeingEditted={programBeingEditted}
        />

        <FeedbackModal
          feedbackModalShow={feedbackModalShow}
          setFeedbackModalShow={setFeedbackModalShow}
          programToBeSentFeedback={programToBeSentFeedback}
          setIsLoading={setIsLoading}
        />

        <DeleteModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          setIsLoading={setIsLoading}
          loadPrograms={loadPrograms}
          programBeingDeleted={programBeingDeleted}
        />

        {doesListApiHasError && (
          <NoData
            icon="sad"
            label={(
              <>
                Ocorreu um erro ao obter as programações da atividade.
                <button type="button" onClick={handleTryAgain}>Tentar Novamente</button>
                .
              </>
)}
          />
        )}

        {isListEmpty && (
          <NoData
            icon="emptyBox"
            label={(
              <>
                Não há nenhuma programação específica para uma data futura nessa atividade. Clique no botão
                {' '}
                <strong>Nova Programação</strong>
                {' '}
                para realizar alguma!
              </>
)}
          />
        )}
      </Transitions>
    </>
  )
}
