import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface DisabledDatesServiceI {
  token: string;
  reqBody?: ReqBodyType;
}

class DisabledDatesService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getDates = async ({
    token,
  }: DisabledDatesServiceI) => {
    return this.httpClient.get({
      path: '/disabled-dates',
      token,
    });
  }

  disableDates = async({
    reqBody,
    token,
  }: DisabledDatesServiceI) => {
    return this.httpClient.post({
      path: '/disabled-dates',
      reqBody,
      token,
    });
  }

  deleteDisabledDates = ({
    token,
    reqBody,
  }: DisabledDatesServiceI) => {
    return this.httpClient.delete({
      path: '/disabled-dates',
      reqBody,
      token,
    });
  }
}

export default new DisabledDatesService();
