import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import activitiesService from '../../../../services/activitiesService';
import schedulesService from '../../../../services/schedulesService';
import { UseDashboardDataInterface } from '../interfaces';

export default function useActivitiesRanking({ setHasError }: UseDashboardDataInterface) {
  const [activitiesRanking, setActivitiesRanking] = useState<string[]>([]);
  const [activitiesVideosViewsRanking, setActivitiesVideosViewsRanking] = useState<string[]>([]);

  const { apiCall } = useApiCall();

  const getActivitiesRanking = useCallback(async () => {
    await apiCall({
      apiToCall: schedulesService.getActivitiesRanking,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar o ranking de atividades', {
            toastId: 'getActivitiesRankingDashboardFail',
          });
          return;
        }
        setActivitiesRanking(apiResponse);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar o ranking de atividades',
    })
  }, [apiCall, setHasError]);

  const getActivitiesVideosViewsRanking = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesVideoViewsRanking,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar o ranking de vídeos mais assistidos', {
            toastId: 'getActivitiesRankingDashboardFail',
          });
          return;
        }
        setActivitiesVideosViewsRanking(apiResponse);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar o ranking de atividades',
    })
  }, [apiCall, setHasError]);

  return {
    activitiesRanking,
    getActivitiesRanking,
    getActivitiesVideosViewsRanking,
    activitiesVideosViewsRanking,
  };
}
