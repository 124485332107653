import { Dispatch, SetStateAction, useCallback } from "react";
import { CategoryBeingDeletedType } from "../../types";
import useApiCall from "../../../../../hooks/useApiCall";
import { toast } from "react-toastify";
import categoriesService from "../../../../../services/categoriesService";

interface UseDeleteModal {
  loadCategories: () => Promise<void>;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  categoryBeingDeleted: CategoryBeingDeletedType;
}

export default function useDeleteModal({ loadCategories, setDeleteModalShow, setIsLoading, categoryBeingDeleted }: UseDeleteModal) {
  const { apiCall } = useApiCall();

  const deleteCategory = useCallback(async () => {
    await apiCall({
      apiToCall: categoriesService.deleteCategory,
      onStartLoad: () => { setIsLoading(true) },
      queryParams: { categoryId: categoryBeingDeleted.id },
      actionAfterResponse: (response) => {
        if (response.success) {
          toast.success('Categoria removida com sucesso!');
          setDeleteModalShow(false);
          loadCategories();
          return;
        }
        toast.error('Não foi possível remover a categoria. Por favor, tente novamente');
        setIsLoading(false);
      },
      catchMessage: 'Não foi possível editada a categoria. Por favor, tente novamente',
      catchAction: () => setIsLoading(false),
    })
  }, [apiCall, categoryBeingDeleted.id, loadCategories, setDeleteModalShow, setIsLoading]);


  return {
    deleteCategory,
  }
}
