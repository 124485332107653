import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import partnersService from '../../../../services/partnersService';
import { UseDashboardDataInterface } from '../interfaces';
import useApiCall from '../../../../hooks/useApiCall';

export default function usePartnersQuantity({ setHasError }: UseDashboardDataInterface) {
  const [partnersQty, setPartnersQty] = useState(0);

  const { apiCall } = useApiCall();

  const getPartnersQuantity = useCallback(async () => {
    await apiCall({
      apiToCall: partnersService.getPartnersList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de parceiros (${apiResponse.error})`, {
            toastId: 'getPartnersDashboardFail',
          });
          return;
        }
        const partnersList = apiResponse.list;
        setPartnersQty(partnersList.length);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível carregar a lista de parceiros'
    })
  }, [apiCall, setHasError]);

  return {
    partnersQty,
    getPartnersQuantity,
  };
}
