import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import activitiesService from '../../../../services/activitiesService';
import highlightsService from '../../../../services/highlightsService';
import { ActivitiesListFromApiType, MappedActivitiesList } from '../../types';

interface HighlightsListType {
  activity: {
    nome: string;
    categoria: string;
    subcategoria: string;
    partner: {
      nome_fantasia: string;
    };
    featPartner: {
      nome_fantasia: string;
    };
  }
  order: number;
  id: string;
  type: 'live' | 'record';
}

interface IUseLoadHighlightsPage {
  selectedType: 'live' | 'record';
}

export default function useLoadHighlightsPage({ selectedType }: IUseLoadHighlightsPage) {
  const [isLoadLoading, setIsLoadLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [fullActivitesList, setFullActivitiesList] = useState<MappedActivitiesList>([]);
  const [list, setList] = useState<HighlightsListType[]>([]);

  const { apiCall } = useApiCall();

  const getActivitiesList = useCallback(async () => {
    await apiCall({
      apiToCall: activitiesService.getActivitiesList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar a lista de atividades (${apiResponse.error})`, {
            toastId: 'getActivitiesHighlightsFail',
          });
          return;
        }
        const activities: ActivitiesListFromApiType = apiResponse.list;
        const activeActivities = activities.filter((act) => act.ativo)
        const mappedActivitiesList = activeActivities?.map((activity) => ({
          value: activity.id,
          label: `${activity.nome} (${activity.categoria})`,
          type: activity.tipo,
        }));
        setFullActivitiesList(mappedActivitiesList);
      },
      catchMessage: 'Não foi possível carregar a lista dos colaboradores',
    })
  }, [apiCall]);

  const activitiesBySelectedType = useMemo(() => {
    if (selectedType === 'record') {
      return fullActivitesList.filter((act) => act.type === 'record').map((act) => ({ value: act.value, label: act.label }));
    } else {
      return fullActivitesList.filter((act) => act.type === 'live').map((act) => ({ value: act.value, label: act.label }));
    }
  }, [fullActivitesList, selectedType])

  const getHighlights = useCallback(async () => {
    await apiCall({
      apiToCall: highlightsService.getHighlightsList,
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível carregar os destaques. Por favor, tente novamente.`);
          return;
        }
        setList(apiResponse.list);
      },
      catchMessage: 'Não foi possível carregar a lista dos destaques',
    })
  }, [apiCall]);

  const highlightsBySelectedType = useMemo(() => {
    if (selectedType === 'record') {
      return list.filter((hl) => hl.type === 'record');
    } else {
      return list.filter((hl) => hl.type === 'live');
    }
  }, [list, selectedType])

  const loadHighlightsPage = useCallback(async () => {
    try {
      setIsLoadLoading(true);
      await Promise.all([
        getActivitiesList(),
        getHighlights(),
      ]);
    } catch (error) {
      toast.error(`Não foi possível carregar os destaques (${error})`, {
        toastId: 'loadHighlightsPageFail',
      });
      setHasError(true);
    } finally {
      setIsLoadLoading(false);
    }
  }, [getActivitiesList, getHighlights]);

  useEffect(() => {
    loadHighlightsPage();
  }, [loadHighlightsPage]);

  return {
    isLoadLoading,
    hasError,
    list: highlightsBySelectedType,
    loadHighlightsPage,
    fullActivitesList: activitiesBySelectedType,
  };
}
