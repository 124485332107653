import Sidebar from "../../components/Sidebar";
import Transitions from "../../components/Transition";
import GivenClassesForm from "./components/GivenClassesForm";

export default function EditGivenClass() {
  return (
    <>
    <Sidebar active="GivenClasses" />
    <Transitions>
      <GivenClassesForm isEdit />
    </Transitions>
  </>
  )
}
