import { useState } from 'react';
/* eslint-disable max-len */
import {
  VictoryChart, VictoryVoronoiContainer, VictoryTooltip, VictoryLine,
} from 'victory';
import { zonedTimeToUtc } from 'date-fns-tz';

import NoData from '../../../components/NoData';
import { Card } from '../styles';
import MyModal from '../../../components/Modal';
import expand from '../../../assets/images/icons/expand.svg';
import { SchedulesQtyByMonthType } from '../types';

interface SchedulesPerformanceCardInterface {
  schedulesPerformance: SchedulesQtyByMonthType
}

export default function SchedulesPerformanceCard({
  schedulesPerformance,
}: SchedulesPerformanceCardInterface) {
  const [expandedSchedulesPerformance, setExpandedSchedulesPerformance] = useState(false);

  const hasInfo = schedulesPerformance[1];
  const monthsAbvMap: { [key: number]: string } = {
    1: 'Jan',
    2: 'Fev',
    3: 'Mar',
    4: 'Abr',
    5: 'Mai',
    6: 'Jun',
    7: 'Jul',
    8: 'Ago',
    9: 'Set',
    10: 'Out',
    11: 'Nov',
    12: 'Dez',
  };

  const brasiliaTimeZone = 'America/Sao_Paulo';
  const currentDate = zonedTimeToUtc(new Date(), brasiliaTimeZone);
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const transformedData = Object.keys(schedulesPerformance).map((key) => {
    const monthsAgo = parseInt(key);
    const date = zonedTimeToUtc(new Date(currentYear, currentMonth - monthsAgo - 1, 1), brasiliaTimeZone);
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      schedules: schedulesPerformance[Number(key)],
    };
  });

  const largestSchedules = Math.max(...Object.values(schedulesPerformance));
  const smallestSchedules = Math.min(...Object.values(schedulesPerformance));

  transformedData.sort((a, b) => {
    if (a.year < b.year) {
      return -1;
    } if (a.year > b.year) {
      return 1;
    }
    // years are equal, sort by month
    if (a.month < b.month) {
      return -1;
    } if (a.month > b.month) {
      return 1;
    }
    return 0;
  });

  return (
    <Card>
      <div className="card-title">
        <div>Agendamentos ao longo dos meses (Número agendamentos x mês/ano)</div>
        {!!hasInfo && (
          <img
            src={expand}
            alt=""
            title="Expandir gráfico"
            onClick={() => setExpandedSchedulesPerformance(true)}
            className="primaryColor"
          />
        )}
      </div>

      {!!hasInfo && (
        <div className="card-main-graph">
              <VictoryChart
                height={150}
                width={300}
                padding={{
                  top: 15, bottom: 30, left: 30, right: 30,
                }}
                maxDomain={{ y: largestSchedules * 1.05 }}
                minDomain={{ y: smallestSchedules * 0.95 }}
                containerComponent={(
                  <VictoryVoronoiContainer
                    labels={({ datum }) => `${datum.x} / ${datum.year}, ${datum.y}`}
                    labelComponent={(
                      <VictoryTooltip
                        constrainToVisibleArea
                        centerOffset={{ x: 5, y: 60 }}
                        style={{ fill: 'black' }}
                      />
                    )}
                  />
                                )}
              >
                <VictoryLine
                  style={{
                    data: { stroke: '#4aafff' },
                    parent: { border: 'none' },
                  }}
                  data={transformedData.map((data) => (
                    { x: `${monthsAbvMap[data.month]}`, y: data.schedules, year: data.year }
                  ))}
                  interpolation="natural"
                  animate={{
                    duration: 3000,
                    onLoad: { duration: 5000 },
                  }}
                />
              </VictoryChart>
        </div>
      )}

      <MyModal
        show={expandedSchedulesPerformance}
        title="Agendamentos ao longo dos meses (Número agendamentos x mês/ano)"
        closeButtonLabel="Fechar"
        onClose={() => setExpandedSchedulesPerformance(false)}
        size="xl"
        modalBody={(
          <div className="card-main-graph">
            <VictoryChart
              height={300}
              width={800}
              padding={{
                top: 25, bottom: 30, left: 40, right: 40,
              }}
              maxDomain={{ y: largestSchedules * 1.05 }}
              minDomain={{ y: smallestSchedules * 0.95 }}
              containerComponent={(
                <VictoryVoronoiContainer
                  labels={({ datum }) => `${datum.x}, ${datum.y}`}
                  labelComponent={(
                    <VictoryTooltip
                      constrainToVisibleArea
                      centerOffset={{ x: 5, y: 60 }}
                    />
                )}
                />
                            )}
            >
              <VictoryLine
                style={{
                  data: { stroke: '#4aafff' },
                  parent: { border: 'none' },
                }}
                data={transformedData.map((data) => (
                  { x: `${monthsAbvMap[data.month]} / ${data.year}`, y: data.schedules, year: data.year }
                ))}
                interpolation="natural"
                animate={{
                  duration: 3000,
                  onLoad: { duration: 3000 },
                }}
              />
            </VictoryChart>
          </div>
)}
      />

      {!hasInfo && (
        <NoData
          icon="emptyBox"
          label={(
            <>
              Não há informações a respeito dos agendamentos
            </>
                    )}
        />
      )}
    </Card>
  );
}
