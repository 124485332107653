import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import usersService from '../../../../services/usersService';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { SubscriberFromAllApiType } from '../../types';

export default function useDownloadExcel() {
  const { apiCall } = useApiCall();

  const downloadExcel = useCallback(async () => {
    await toast.promise(apiCall({
      apiToCall: usersService.getUsersList,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível baixar a planilha (${apiResponse.error})`);
          return;
        }
        const engagementMap: { [key: string]: string } = {
          bad: 'Ruim',
          warn: 'Alerta',
          great: 'Ótimo',
        };

        const usersList: SubscriberFromAllApiType[] = apiResponse.list;
        const subscribersList = usersList.filter((usr) => !!usr.assinatura_status);
        const mappedUsersList = subscribersList?.map((usr) => ({
          Nome: usr.sobrenome ? `${usr.nome} ${usr.sobrenome}` : usr.nome,
          CPF: usr.cpf,
          Celular: usr.celular,
          'E-mail': usr.email,
          'Data de nascimento': usr.data_nascimento || 'Não Informado',
          Gênero: usr.genero || 'Não Informado',
          Status: usr.status,
          Cidade: usr.endereco?.cidade || 'Não informado',
          UF: usr.endereco?.UF || 'Não informado',
          Empresa: usr.empresa || 'B2C',
          Cupom: usr.cupom?.name || 'Não utilizado',
          Engajamento: usr.engagementStatus ? engagementMap[usr.engagementStatus] : 'Não informado',
          'Data de cadastro': usr.data_cadastro,
          'Data de ativação': usr.data_ativacao || 'Não Identificado',
          'Criança 1 - Nome': usr.criancas[0]?.name || 'Não possui',
          'Criança 1 - Data de nascimento': usr.criancas[0]?.birthDate || 'Não possui',
          // 'Criança 1 - Periodos': renderArray(usr.criancas[0]?.periodos) || 'Não possui',
          // 'Criança 1 - Interesses': renderArray(usr.criancas[0]?.areas_interesse) || 'Não possui',
          'Criança 2 - Nome': usr.criancas[1]?.name || 'Não possui',
          'Criança 2 - Data de nascimento': usr.criancas[1]?.birthDate || 'Não possui',
          // 'Criança 2 - Periodos': renderArray(usr.criancas[1]?.periodos) || 'Não possui',
          // 'Criança 2 - Interesses': renderArray(usr.criancas[1]?.areas_interesse) || 'Não possui',
          'Criança 3 - Nome': usr.criancas[2]?.name || 'Não possui',
          'Criança 3 - Data de nascimento': usr.criancas[2]?.birthDate || 'Não possui',
          // 'Criança 3 - Periodos': renderArray(usr.criancas[2]?.periodos) || 'Não possui',
          // 'Criança 3 - Interesses': renderArray(usr.criancas[2]?.areas_interesse) || 'Não possui',
        }));
        const currentDate = getCurrentDateWithHyphen();
        ExportXlsx({
          filename: `Assinantes Kiddlepass - ${currentDate}`,
          data: mappedUsersList,
        });
      },
      catchMessage: 'Não foi possível baixar a lista de assinantes',
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  return { downloadExcel };
}
