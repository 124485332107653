import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0px;
  background: ${({ theme }) => theme.colors.lighterBackground};
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 4px;
  border-radius: 4px;
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
  min-height: 125px;
  @media(max-width: 770px) {
      overflow: auto;
      overflow-x: auto;
    }

  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    font-weight: bold;
    div {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 15px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      margin-left: 4px;
      &:hover {
        opacity: 0.7;
      }
    }
    .download{
      img{
        width: 20px;
      }
    }
  }

  .card-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8px 2px;
    div {
      font-weight: bold;
      font-size: 24px;
    }
    small {
      font-size: 12px;
    }
  }

  .legend {
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-right: 8px;
      small {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 12px;
        font-weight: bold;
      }
      small.green{
        color: #8b9a71;
      }
      small.red{
        color: #ff6361;
      }
      small.orange{
        color: #ffa600;
      }
      small.lightBlue{
        color: #4aafff;
      }
      small.darkBlue{
        color: #006FE6;
      }
      small.blue{
        color: #006FE6;
      }
      small.mediumslateblue{
        color: #006FE6;
      }
      small.mediumpurple{
        color: #bc5090;
      }
      small.gray{
        color: gray;
      }
    }
  }
`;

export const MonthsGrid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 0 auto;
  max-width: 32;
  width: 100%;
  flex-wrap: wrap;
  button {
    margin: 4px;
  }
`;
