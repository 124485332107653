import MyModal from '../../../components/Modal';
import { CompanyChangingStatus, UpdateCompanyStatusInterface } from '../types';
import { Dispatch, SetStateAction } from 'react';

interface ChangeStatusModalInterface {
  companyChangingStatus: CompanyChangingStatus;
  setChangeStatusModalShow: Dispatch<SetStateAction<boolean>>;
  updateCompanyStatus: ({ id, ativo }: UpdateCompanyStatusInterface) => void;
  changeStatusModalShow: boolean;
}

export default function ChangeStatusModal({
  companyChangingStatus,
  setChangeStatusModalShow,
  updateCompanyStatus,
  changeStatusModalShow,
}: ChangeStatusModalInterface) {
  return (
    <MyModal
      title={companyChangingStatus.status === 'Ativo' ? `Inativar empresa ${companyChangingStatus.name}` : `Reativar empresa ${companyChangingStatus.name}`}
      closeButtonLabel="Cancelar"
      actionButtonLabel={companyChangingStatus.status === 'Ativo' ? 'Inativar' : 'Reativar'}
      modalBody={(
        <div>
          {companyChangingStatus.status === 'Ativo' ? 'Tem certeza que deseja inativar a empresa' : 'Tem certeza que deseja reativar a empresa'}
          {' '}
          <strong>{companyChangingStatus.name}</strong>
          {' '}
          ?
        </div>
          )}
      onClose={() => setChangeStatusModalShow(false)}
      onAction={() => updateCompanyStatus({
        id: companyChangingStatus.id,
        ativo: companyChangingStatus.status !== 'Ativo',
      })}
      show={changeStatusModalShow}
      type={companyChangingStatus.status === 'Ativo' ? 'suspendAction' : 'activateAction'}
    />
  );
}
