import { pathName } from '../pathName';
import HttpClient from './utils/HttpClient';

interface UploadDocPayload {
  key: string;
  value: string | File | null | number | boolean | undefined;
}

interface PartnersServiceInterface {
  permission?: string;
  page?: number;
  partnerId?: string;
  ativo?: boolean;
  reqBody?: string | null | UploadDocPayload[];
  token: string;
}

class PartnersService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getPartnersList = async ({
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.get({
      path: '/partners',
      token,
    });
  }

  getResumedPartnersList = async ({ token }: PartnersServiceInterface) => {
    return this.httpClient.get({
      path: '/partners-list',
      token,
    });
  }

  getPartnerDetails = async ({
    partnerId,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.get({
      path: `/partners/${partnerId}`,
      token,
    });
  }

  getPartnerRatings = async ({
    token,
    partnerId,
  }: PartnersServiceInterface) => {
    return this.httpClient.get({
      path: `/activities-ratings/partner/${partnerId}`,
      token,
    });
  }

  createPartner = async ({
    reqBody,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.post({
      path: '/partners',
      reqBody,
      token,
    });
  }

  createOrUpdatePartnerImage = async({
    partnerId,
    reqBody,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.post({
      contentType: 'multipart/form-data',
      path: `/partners/image/${partnerId}`,
      reqBody,
      token
    })
  }

  updatePartner = async ({
    partnerId,
    reqBody,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.put({
      path: `/partners/${partnerId}`,
      token,
      reqBody,
    });
  }

  updatePartnerStatus = async ({
    partnerId,
    ativo,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.patch({
      path: `/partners/${partnerId}?ativo=${ativo}`,
      token,
    });
  }

  deletePartner = async ({
    partnerId,
    token,
  }: PartnersServiceInterface) => {
    return this.httpClient.delete({
      path: `/partners/${partnerId}`,
      token,
    });
  }
}

export default new PartnersService();
