/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Select from 'react-select';

import { CustomStyle } from '../../../../CustomSelectStyle';
import FilterRadioButton from '../../../../FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../FilterRadioButtonsContainer';
import FormGroup from '../../../../FormGroup';
import MyModal from '../../../../Modal';
import { DisableModalContainer } from '../styles';
import OpacityAnimation from '../../../../OpacityAnimation';

interface DisableDateModalInterface {
  disableDateModalShow: boolean;
  selectedDisableDate: string;
  handleDisableDateChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setDisableDateModalShow: Dispatch<SetStateAction<boolean>>;
  disableDate: () => void;
  isFutureDateChoosed: boolean;
  isSpecificTime: boolean;
  setIsSpecificTime: Dispatch<SetStateAction<boolean>>;
  timesOptions: { value: string, label: string }[];
  selectedDisableTime: { value: string, label: string };
  setSelectedDisableTime: Dispatch<SetStateAction<{ value: string, label: string }>>;
}

export default function DisableDateModal({
  disableDateModalShow,
  selectedDisableDate,
  handleDisableDateChange,
  setDisableDateModalShow,
  disableDate,
  isFutureDateChoosed,
  isSpecificTime,
  setIsSpecificTime,
  timesOptions,
  selectedDisableTime,
  setSelectedDisableTime,
}: DisableDateModalInterface) {
  const hasTimesOptions = timesOptions.length !== 0
  return (
    <MyModal
      show={disableDateModalShow}
      title="Desabilitar nova data"
      modalBody={(
        <DisableModalContainer>
          <FilterRadioButtonsContainer>
            <FilterRadioButton
              selected={!isSpecificTime}
              onClick={() => setIsSpecificTime(false)}
            >
              Dia inteiro
            </FilterRadioButton>
            <FilterRadioButton
              selected={isSpecificTime}
              onClick={() => setIsSpecificTime(true)}
            >
              Horário específico
            </FilterRadioButton>
          </FilterRadioButtonsContainer>
          <FormGroup>
            <label htmlFor="dayDisable">Dia</label>
            <input type="date" value={selectedDisableDate} onChange={handleDisableDateChange} />
          </FormGroup>
          {hasTimesOptions && isSpecificTime && (
            <OpacityAnimation>
              <FormGroup>
              <label htmlFor="timeDisable">Horário</label>
              <Select
                value={{ value: selectedDisableTime.value, label: selectedDisableTime.label }}
                options={timesOptions}
                onChange={(opt) => {
                  setSelectedDisableTime({ value: opt!.value, label: opt!.label });
                }}
                styles={CustomStyle}
                classNamePrefix="react-select"
                className="react-select-container"
              />
              </FormGroup>
            </OpacityAnimation>
          )}
        </DisableModalContainer>
        )}
      closeButtonLabel="Fechar"
      actionButtonLabel="Desabilitar"
      onClose={() => setDisableDateModalShow(false)}
      onAction={() => disableDate()}
      isActionButtonDisabled={isSpecificTime ? (!isFutureDateChoosed || !selectedDisableDate || !selectedDisableTime.value) : (!isFutureDateChoosed || !selectedDisableDate)}
      type="deleteAction"
      minHeight={isSpecificTime ? '350px' : ''}
    />
  );
}
