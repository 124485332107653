import { Dispatch, SetStateAction } from "react";
import OpacityAnimation from "../../../../../../components/OpacityAnimation";
import { CommentType } from "../../../../types";
import Content from "./components/Content";
import DeleteComment from "./components/DeleteComment";
import EditComment from "./components/EditComment";
import Header from "./components/Header";
import { CommentContainer } from "./styles";
import useCommentActions from "./useCommentActions";

interface IComment {
  comment: CommentType;
  loadForum: () => Promise<void>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function Comment({ comment, loadForum, setIsLoading }: IComment) {
  const {
    view,
    setView,
    editComment,
    handleCommentTextChange,
    commentText,
    deleteComment,
  } = useCommentActions({
    comment,
    loadForum,
    setIsLoading,
  })

  const modalBodyRendering: { [key: string]: JSX.Element } = {
    default: <Content text={comment.text} />,
    delete: <DeleteComment deleteComment={deleteComment} setView={setView} />,
    edit: <EditComment editComment={editComment} setView={setView} commentText={commentText} handleCommentTextChange={handleCommentTextChange} />
  }

  return (
    <CommentContainer key={comment.id}>
      <Header user={comment.user} createdAt={comment.createdAt} setView={setView} />

      <OpacityAnimation>
        {modalBodyRendering[view]}
      </OpacityAnimation>
    </CommentContainer>
  )
}
