import MyModal from '../../../components/Modal';
import KidsList from './kidsList';
import { Dispatch, SetStateAction } from 'react';
import { ScheduleKidsListBeingShown } from '../types';

interface KidsListModalInterface {
  kidsModalShow: boolean;
  setKidsModalShow: Dispatch<SetStateAction<boolean>>;
  scheduleKidsListBeingShow: ScheduleKidsListBeingShown;
}

export default function KidsListModal({
  kidsModalShow,
  setKidsModalShow,
  scheduleKidsListBeingShow,
}: KidsListModalInterface) {
  return (
    <MyModal
      show={kidsModalShow}
      onClose={() => setKidsModalShow(false)}
      title={`Crianças agendadas para a aula ${scheduleKidsListBeingShow?.title}`}
      modalBody={(
        <KidsList
          list={scheduleKidsListBeingShow.list}
        />
              )}
      closeButtonLabel="Fechar"
      size='lg'
    />
  );
}
