import { format } from 'date-fns';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../../hooks/useApiCall';
import partnersService from '../../../../services/partnersService';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { MappedPartnerType, RatingsFromApi } from '../../types';

interface UseDownloadExcelInterface {
  fullPartners: MappedPartnerType[];
}

export default function useDownloadExcel({
  fullPartners,
}: UseDownloadExcelInterface) {
  const { apiCall } = useApiCall();

  const downloadExcel = useCallback(() => {
    const mappedPartnersList = fullPartners?.map((prt) => ({
      Nome: prt.name || 'Não informado',
      'Razão Social': prt.companyName || 'Não informado',
      CNPJ: prt.cnpj || 'Pessoa Física',
      CPF: prt.cpf || 'Pessoa Juridica',
      Status: prt.status || 'Não informado',
      Responsável: prt.responsible || 'Não informado',
      'Telefone do responsável': prt.responsiblePhone || 'Não informado',
      'Cargo do responsável': prt.responsibleRole || 'Não informado',
      'E-mail do responsável': prt.responsibleEmail || 'Não informado',
      'Nome de contato': prt.contactName || 'Não informado',
      'Celular de contato': prt.contactCellphone || 'Não informado',
      'Telefone de contato': prt.contactPhone || 'Não informado',
      'E-mail do contato': prt.contactEmail || 'Não informado',
      Cidade: prt.city || 'Não informado',
      UF: prt.uf || 'Não informado',
    }));
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Parceiros Kiddlepass - ${currentDate}`,
      data: mappedPartnersList,
    });
  }, [fullPartners]);

  const downloadRatings = useCallback(async (partnerId: string) => {
    await toast.promise(apiCall({
      apiToCall: partnersService.getPartnerRatings,
      queryParams: { partnerId },
      actionAfterResponse: (apiResponse: RatingsFromApi) => {
          const ratings = apiResponse.ratings;
          if (!ratings) {
            toast.error(`Não foi possível baixar a lista de avaliações desse parceiro`, {
              toastId: 'downloadActivitiesRatingsFail',
            });
            return;
          }

          const mappedRatingsList = ratings.map((rt) => ({
            Usuário: rt.userName,
            Celular: rt.userPhone,
            Atividade: rt.activity,
            'Data Avaliação': format(new Date(rt.ratedAt), "dd/MM/yyyy 'às' HH'h'mm'm'"),
            'Nota Professor (1 a 5)': rt.teacherRate,
            'Nota Conteúdo (1 a 5)': rt.contentRate,
            'Duração da aula': rt.durationRate ?? 'Não informado',
            'Comentário Adicional': rt.comment ?? '',
          }));

          const { partner } = ratings[0];

          const currentDate = getCurrentDateWithHyphen();
          ExportXlsx({
            filename: `Avaliações (${partner}) - ${currentDate}`,
            data: mappedRatingsList,
          });
      },
      catchMessage: 'Não foi possível baixar a lista de avaliações'
    }), {
      pending: 'Sua planilha está sendo baixada',
      success: 'Sua planilha foi baixada!',
      error: 'Não foi possível baixar sua planilha',
    })
  }, [apiCall]);

  return {
    downloadExcel,
    downloadRatings,
  };
}
