import { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import companiesService from '../../../../../services/companiesService';
import useApiCall from '../../../../../hooks/useApiCall';
import { useNavigate, useParams } from 'react-router-dom';

interface UseAddCollaboratorInterface {
  loadUsers: (currentPage: number) => void;
  currentPage: number;
}

export default function useAddCollaborator({
  loadUsers,
  currentPage,
}: UseAddCollaboratorInterface) {
  const [isAdding, setIsAdding] = useState(false);
  const [modalCreateShow, setModalCreateShow] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState('');
  const [selectedCompanyForm, setSelectedCompanyForm] = useState<{ value: string | null, label: string }>({ value: null, label: 'Selecione uma empresa associada' });

  const { apiCall } = useApiCall();
  const navigate = useNavigate();

  const { companyId } = useParams();

  function handleCollaboratorIdChange(event: ChangeEvent<HTMLInputElement>) {
    setCollaboratorId(event.target.value);
  }

  function handleCreateModalShow() {
    setModalCreateShow(true);
  }

  function handleCreateModalClose() {
    setModalCreateShow(false);
  }

  function handleCompanyFormChange(company: { value: string | null, label: string }) {
    setSelectedCompanyForm(company);
  }

  const addCollaborator = useCallback(async () => {
    if (!companyId) {
      toast.error('Não identificamos a empresa através da URL. Por favor, tente novamente.');
      navigate('/companies');
      return;
    }

    await apiCall({
      apiToCall: companiesService.createMultiUsers,
      queryParams: { companyId },
      reqBody: JSON.stringify({
        list: [{ id: String(collaboratorId).toLowerCase(), associated_company: selectedCompanyForm.value }],
      }),
      onStartLoad: () => {
        setModalCreateShow(false);
        setIsAdding(true);
      },
      onEndLoad: () => {
        setIsAdding(false);
        setCollaboratorId('');
      },
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          setIsAdding(false);
          toast.error('Não foi possível adicionar o colaborador');
          return;
        }
        loadUsers(currentPage);
        toast.success('Colaborador adicionado com sucesso');
      },
      catchMessage: 'Não foi possível adicionar o colaborador'
    });
  }, [companyId, apiCall, collaboratorId, selectedCompanyForm.value, navigate, loadUsers, currentPage]);

  return {
    isAdding,
    modalCreateShow,
    setModalCreateShow,
    collaboratorId,
    handleCollaboratorIdChange,
    addCollaborator,
    handleCreateModalShow,
    handleCreateModalClose,
    handleCompanyFormChange,
    selectedCompanyForm,
  };
}
