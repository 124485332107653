import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { InternalUserObject } from "../../types";

interface UseSearchesI {
  setFilteredInternalUsers: Dispatch<SetStateAction<InternalUserObject[]>>;
  internalUsers: InternalUserObject[];
}

export default function useSearches({ setFilteredInternalUsers, internalUsers }: UseSearchesI) {
  const [searchTerm, setSearchTerm] = useState('');

  const filterCupons = useCallback(() => {
    const filteredActivities = internalUsers.filter((user) => {
      const searchFilter = user.email.toLowerCase().includes(String(searchTerm).toLowerCase());

      return searchFilter;
    });

    setFilteredInternalUsers(filteredActivities);
  }, [internalUsers, setFilteredInternalUsers, searchTerm]);

  useEffect(() => {
    filterCupons();
  }, [filterCupons]);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  return {
    searchTerm,
    handleChangeSearchTerm,
  }
}
