import React from 'react';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';
import { ChangeEvent } from 'react';

interface GeneralDataCardInterface {
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
  cnpj: string;
  onCnpjChange: (event: ChangeEvent<HTMLInputElement>) => void;
  corporateName: string;
  onCorporateNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fantasyName: string;
  onFantasyNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  employeesQuantity: number | '';
  onEmployeesQuantityChange: (event: ChangeEvent<HTMLInputElement>) => void;
  elegiblesQuantity: number | '';
  onEligibleQuantityChange: (event: ChangeEvent<HTMLInputElement>) => void;
  adherenceGoal: number | '';
  onAdherenceGoalChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function GeneralDataCard({
  getErrorMessageByFieldName,
  cnpj,
  onCnpjChange,
  corporateName,
  onCorporateNameChange,
  fantasyName,
  onFantasyNameChange,
  employeesQuantity,
  onEmployeesQuantityChange,
  elegiblesQuantity,
  onEligibleQuantityChange,
  adherenceGoal,
  onAdherenceGoalChange,
}: GeneralDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados Gerais
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            CNPJ *
          </div>
          <FormGroup error={getErrorMessageByFieldName('cnpj')}>
            <Input
              value={cnpj}
              onChange={onCnpjChange}
              error={getErrorMessageByFieldName('cnpj')}
              placeholder="Informe o CNPJ"
              maxLength={18}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Razão Social *
          </div>
          <FormGroup error={getErrorMessageByFieldName('corporateName')}>
            <Input
              value={corporateName}
              onChange={onCorporateNameChange}
              error={getErrorMessageByFieldName('corporateName')}
              placeholder="Informe a razão social da empresa"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome fantasia *
          </div>
          <FormGroup error={getErrorMessageByFieldName('fantasyName')}>
            <Input
              value={fantasyName}
              onChange={onFantasyNameChange}
              error={getErrorMessageByFieldName('fantasyName')}
              placeholder="Informe o nome fantasia da empresa"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Número de funcionários
          </div>
          <FormGroup>
            <Input
              value={employeesQuantity}
              onChange={onEmployeesQuantityChange}
              placeholder="Informe a quantidade de funcionários"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
      <AsideContainer>
        <Group>
          <div className="title">
            Número de elegíveis
          </div>
          <FormGroup>
            <Input
              value={elegiblesQuantity}
              onChange={onEligibleQuantityChange}
              placeholder="Informe a quantidade de elegíveis"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Meta de adesão (%)
          </div>
          <FormGroup>
            <Input
              value={adherenceGoal}
              onChange={onAdherenceGoalChange}
              placeholder="Informe a meta de adesão (%)"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
