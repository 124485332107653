import React from 'react';
import intervalToDuration from 'date-fns/intervalToDuration';
import { Card } from '../../styles';

interface NfDateInterface {
  nfDate?: string;
}

export default function NfDateCard({ nfDate }: NfDateInterface) {
  const currentDate = new Date().toJSON().slice(0, 10);
  const currentDay = Number(currentDate.split('-')[2]);
  const currentMonth = Number(currentDate.split('-')[1]);
  const currentYear = Number(currentDate.split('-')[0]);

  const nfDay = Number(nfDate?.split('/')[0]);
  const nfMonth = Number(nfDate?.split('/')[1]);
  const nfYear = Number(nfDate?.split('/')[2]);

  const differenceBetweenDates = nfDate
    ? (intervalToDuration({
      end: new Date(currentYear, Number(currentMonth - 1), currentDay),
      start: new Date(nfYear, Number(nfMonth - 1), nfDay),
    }))
    : null;

  const differenceBetweenDatesInDays = differenceBetweenDates?.days;

  return (
    <Card>
      <div className="card-title">
        <div>Envio da NF-e</div>
      </div>

      {!!nfDate && (
      <div className="card-main">
        <div>
          Enviada em
          {' '}
          {nfDate}
        </div>
        <small>
          {differenceBetweenDatesInDays != 0 && (
            `${differenceBetweenDatesInDays} dias atrás`
          )}
          {differenceBetweenDatesInDays == 0 && (
            'Hoje'
          )}
        </small>
      </div>
      )}

      {(!nfDate) && (
      <div className="card-main">
        <div>
          Não enviada
        </div>
        <small>
          Caso já tenha enviado, verifique novamente mais tarde.
        </small>
      </div>
      )}
    </Card>
  );
}
