import { Dispatch, SetStateAction } from 'react';
import whitePaperPlane from '../../../../assets/images/icons/white-paper-plane.svg';
import Button from "../../../../components/Button";
import MyModal from "../../../../components/Modal";
import NoData from '../../../../components/NoData';
import Textarea from "../../../../components/Textarea";
import { CommentType } from "../../types";
import Comment from './components/Comment';
import { AddCommentContainer, CommentsContainer, ModalContainer } from "./styles";
import useCommentsModal from './useCommentsModal';

interface ICommentsModal {
  commentsModalShow: boolean;
  comments: CommentType[];
  handleCloseCommentsModal: () => void;
  postIdOfComments: string;
  loadForum: () => Promise<void>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
}

export default function CommentsModal({
  commentsModalShow,
  comments,
  postIdOfComments,
  handleCloseCommentsModal,
  loadForum,
  setIsLoading,
}: ICommentsModal) {
  const {
    commentText,
    handleCommentTextChange,
    isFormValid,
    addComment,
  } = useCommentsModal({
    loadForum,
    postIdOfComments,
    setIsLoading,
  });

  return (
    <MyModal
      show={commentsModalShow}
      onClose={() => handleCloseCommentsModal()}
      title={`Comentários da publicação`}
      modalBody={(
        <ModalContainer>
            <CommentsContainer>
              {comments.length > 0 ? (
                <>
                  {comments.map((comment) => (
                    <Comment comment={comment} key={comment.id} loadForum={loadForum} setIsLoading={setIsLoading} />
                  ))}
                </>
              ) : (
                <NoData
                  icon='emptyBox'
                  label="Nenhum comentário foi adicionado ainda!"
                />
              )}
            </CommentsContainer>

            <AddCommentContainer>
              <Textarea
                value={commentText}
                onChange={handleCommentTextChange}
                placeholder="O que você deseja comentar sobre essa publicação?"
                height={60}
              />

              <Button onClick={() => addComment()} disabled={!isFormValid} >
                <img src={whitePaperPlane} alt="sendComment" title="Enviar comentário" />
              </Button>
            </AddCommentContainer>
        </ModalContainer>
              )}
      closeButtonLabel="Fechar"
      size="xl"
      type='info'
    />
  )
}
