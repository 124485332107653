import ActivityForm from '../../components/ActivityForm';
import Sidebar from '../../components/Sidebar';
import Transitions from '../../components/Transition';

export default function ActivityCreateApprovement() {
  return (
    <>
      <Sidebar active="Approve" />
      <Transitions>
        <ActivityForm approvement />
      </Transitions>
    </>
  );
}
