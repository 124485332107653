import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../../contexts/auth';
import useApiCall from '../../../../hooks/useApiCall';
import { ErrorsInterface } from '../../../../hooks/useErrors';
import activitiesService from '../../../../services/activitiesService';
import formatDateToUsa from '../../../../utils/formatDateToUsa';
import parseCurrencyStringToFloat from '../../../../utils/parseCurrencyStringToFloat';
import renderArray from '../../../../utils/renderArrayWithComa';
import replaceSpecialChars from '../../../../utils/replaceSpecialChars';
import uploadAndGetVideoUrlFirebase from '../../../../utils/uploadAndGetVideoUrlFirebase';
import { Value } from '../../types/CalendarTypes';
import { IsRecurrentType, LevelType, NeedsCompanionType, PartnerType, SubcategoryType } from '../../types/FormTypes';
import { TimetableTranslatedType } from '../../types/TimetableTranslatedType';
import toDomainTimetable from '../../utils/toDomainTimetable';

interface UseActivitySubmitInterface {
  partner: PartnerType;
  featPartner: PartnerType;
  kindOfActivity: string;
  name: string;
  description: string;
  subcategory: SubcategoryType;
  minAge: number | string;
  maxAge: number | string;
  methodology: string;
  necessaryMaterials: string;
  needsCompanion: NeedsCompanionType;
  orientations: string;
  clothes: string;
  isRecurrent: IsRecurrentType;
  level: LevelType;
  necessaryKnowledge: string;
  videoLink: string;
  timetable: TimetableTranslatedType;
  imageFile: File | string | undefined;
  videoFile?: File | string;
  updatedDates: string[];
  duration: number;
  isRecordedActivity: boolean;
  hasClasses: boolean;
  errors: ErrorsInterface[];
  specificPeriod: Value;
  selectedTags: MultiValue<{ value: string, label: string }>;
  isFree: boolean;
  classValue: string | number;
}

export default function useActivitySubmit({
  partner,
  featPartner,
  kindOfActivity,
  name,
  description,
  subcategory,
  minAge,
  maxAge,
  methodology,
  necessaryMaterials,
  needsCompanion,
  orientations,
  clothes,
  isRecurrent,
  level,
  necessaryKnowledge,
  videoLink,
  timetable,
  imageFile,
  videoFile,
  updatedDates,
  duration,
  isRecordedActivity,
  hasClasses,
  errors,
  specificPeriod,
  selectedTags,
  isFree,
  classValue,
}: UseActivitySubmitInterface) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAppContext();
  const { apiCall } = useApiCall();

  const createActivity = useCallback(async () => {
    setIsSubmitting(true);
    let videoUrl = videoLink;

    if (kindOfActivity !== 'online' && videoFile && typeof videoFile === 'object') {
      console.log('Caiu condicional para upload video');
      videoUrl = await uploadAndGetVideoUrlFirebase({
        video: videoFile,
        slugAtividade: replaceSpecialChars(name),
        slugParceiro: replaceSpecialChars(partner.label),
      })
    }
    await apiCall({
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      apiToCall: activitiesService.createActivity,
      reqBody: [
        { key: 'partner_id', value: partner.value },
        { key: 'feat_partner_id', value: featPartner.value },
        { key: 'duracao', value: kindOfActivity === 'online' ? Number(duration) : null },
        { key: 'nome', value: name },
        { key: 'descricao', value: description },
        { key: 'categoryId', value: subcategory.value },
        { key: 'minima', value: minAge },
        { key: 'maxima', value: maxAge },
        { key: 'metodologia', value: methodology },
        { key: 'levar', value: necessaryMaterials },
        { key: 'acompanhante', value: needsCompanion.value },
        { key: 'orientacoes', value: orientations },
        { key: 'vestuario', value: clothes },
        { key: 'recorrente', value: isRecurrent.value },
        { key: 'nivel', value: level.value },
        { key: 'conhecimento', value: necessaryKnowledge },
        { key: 'videoUrl', value: videoUrl || videoLink },
        { key: 'tipo', value: kindOfActivity === 'online' ? 'live' : 'record' },
        { key: 'grade', value: kindOfActivity === 'online' ? JSON.stringify(toDomainTimetable(timetable)) : undefined },
        { key: 'cover', value: imageFile },
        { key: 'specificPeriod', value: isRecurrent.value ? null : JSON.stringify(specificPeriod) },
        { key: 'slugAtividade', value: replaceSpecialChars(name) },
        { key: 'slugParceiro', value: replaceSpecialChars(partner.label) },
        { key: 'classValue', value: parseCurrencyStringToFloat(String(classValue)) },
        { key: 'isFree', value: isFree },
        {
          key: 'datasDesabilitadas',
          value: JSON.stringify(updatedDates.filter((date) => (
            !!date
          )).map((date) => (formatDateToUsa(date)))),
        },
        {
          key: 'tags',
          value: JSON.stringify(selectedTags.map((tag) => (
            tag.value
          ))),
        },
      ],
      catchMessage: 'Não foi possível adicionar a atividade. Por favor, tente novamente',
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível gravar a atividade. Por favor, tente novamente');
          return;
        }
        toast.success(`A atividade ${name} foi adicionada com sucesso!`);
        navigate('/activities');
      },
    });
  }, [apiCall, classValue, clothes, description, duration, featPartner.value, imageFile, isFree, isRecurrent.value, kindOfActivity, level.value, maxAge, methodology, minAge, name, navigate, necessaryKnowledge, necessaryMaterials, needsCompanion.value, orientations, partner.label, partner.value, selectedTags, specificPeriod, subcategory.value, timetable, updatedDates, videoFile, videoLink]);

  const updateActivity = useCallback(async () => {
    setIsSubmitting(true);
    let videoUrl = videoLink;

    if (kindOfActivity !== 'online' && videoFile && typeof videoFile === 'object') {
      console.log('Caiu condicional para upload video');
      videoUrl = await uploadAndGetVideoUrlFirebase({
        video: videoFile,
        slugAtividade: replaceSpecialChars(name),
        slugParceiro: replaceSpecialChars(partner.label),
      })
    }

    console.log('VIDEO FINAL', videoUrl);

    await apiCall({
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      apiToCall: activitiesService.updateActivity,
      queryParams: { id },
      reqBody: [
        { key: 'partner_id', value: partner.value },
        { key: 'feat_partner_id', value: featPartner.value },
        { key: 'duracao', value: kindOfActivity === 'online' ? Number(duration) : null },
        { key: 'nome', value: name },
        { key: 'descricao', value: description },
        { key: 'categoryId', value: subcategory.value },
        { key: 'minima', value: minAge },
        { key: 'maxima', value: maxAge },
        { key: 'metodologia', value: methodology },
        { key: 'levar', value: necessaryMaterials },
        { key: 'acompanhante', value: needsCompanion.value },
        { key: 'orientacoes', value: orientations },
        { key: 'vestuario', value: clothes },
        { key: 'recorrente', value: isRecurrent.value },
        { key: 'nivel', value: level.value },
        { key: 'conhecimento', value: necessaryKnowledge },
        { key: 'videoUrl', value: videoUrl || videoLink },
        { key: 'tipo', value: kindOfActivity === 'online' ? 'live' : 'record' },
        { key: 'grade', value: kindOfActivity === 'online' ? JSON.stringify(toDomainTimetable(timetable)) : undefined },
        { key: 'cover', value: imageFile },
        { key: 'slugAtividade', value: replaceSpecialChars(name) },
        { key: 'slugParceiro', value: replaceSpecialChars(partner.label) },
        { key: 'isFree', value: isFree },
        { key: 'classValue', value: parseCurrencyStringToFloat(String(classValue)) },
        { key: 'specificPeriod', value: isRecurrent.value ? null : JSON.stringify(specificPeriod) },
        {
          key: 'datasDesabilitadas',
          value: JSON.stringify(updatedDates.filter((date) => (
            !!date
          )).map((date) => (formatDateToUsa(date)))),
        },
        {
          key: 'tags',
          value: JSON.stringify(selectedTags.map((tag) => (
            tag.value
          ))),
        },
      ],
      catchMessage: 'Não foi possível editar a atividade. Por favor, tente novamente',
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error('Não foi possível editar a atividade. Por favor, tente novamente');
          return;
        }
        toast.success(`A atividade ${name} foi editada com sucesso!`);
        navigate('/activities');
      }
    })
  // eslint-disable-next-line max-len
  }, [apiCall, classValue, clothes, description, duration, featPartner.value, id, imageFile, isFree, isRecurrent.value, kindOfActivity, level.value, maxAge, methodology, minAge, name, navigate, necessaryKnowledge, necessaryMaterials, needsCompanion.value, orientations, partner.label, partner.value, selectedTags, specificPeriod, subcategory.value, timetable, updatedDates, videoFile, videoLink]);

  const handleApprovementActivityDecision = useCallback(async (approve: boolean) => {
    await apiCall({
      onStartLoad: () => setIsSubmitting(true),
      onEndLoad: () => setIsSubmitting(false),
      apiToCall: activitiesService.handleActivityApprovement,
      reqBody: JSON.stringify({
        approve_id: id,
        action: 'create',
        approve,
        permission: user.permission,
        activity: {
          partner_id: partner.value,
          duracao: kindOfActivity === 'online' ? Number(duration) : null,
          nome: name,
          descricao: description,
          categoryId: subcategory.value,
          minima: minAge,
          maxima: maxAge,
          metodologia: methodology,
          levar: necessaryMaterials,
          acompanhante: needsCompanion.value,
          orientacoes: orientations,
          vestuario: clothes,
          recorrente: isRecurrent.value,
          nivel: level.value,
          conhecimento: necessaryKnowledge,
          videoUrl: videoLink,
          tipo: kindOfActivity === 'online' ? 'live' : 'record',
          grade: kindOfActivity === 'online' ? JSON.stringify(toDomainTimetable(timetable)) : undefined,
          cover: imageFile,
          video: videoFile,
          slugAtividade: replaceSpecialChars(name),
          slugParceiro: replaceSpecialChars(partner.label),
          specificPeriod: JSON.stringify(specificPeriod),
          tags: JSON.stringify(selectedTags.map((tag) => (
            tag.value
          ))),
        },
      }),
      catchMessage: `Não foi possível ${approve ? 'aprovar' : 'rejeitar'} a atividade. Por favor, tente novamente`,
      actionAfterResponse: (apiResponse) => {
        if (!apiResponse.success) {
          toast.error(`Não foi possível ${approve ? 'aprovar' : 'rejeitar'} a atividade. Por favor, tente novamente`);
          return;
        }
        toast.success(`A atividade ${name} foi ${approve ? 'aprovada' : 'rejeitada'} com sucesso!`);
        navigate('/activitiesApprove');
      }
    })
  // eslint-disable-next-line max-len
  }, [apiCall, clothes, description, duration, id, imageFile, isRecurrent.value, kindOfActivity, level.value, maxAge, methodology, minAge, name, navigate, necessaryKnowledge, necessaryMaterials, needsCompanion.value, orientations, partner.label, partner.value, selectedTags, specificPeriod, subcategory.value, timetable, user.permission, videoFile, videoLink]);

  const isFormValid = isRecordedActivity
    ? (
      (name
        && partner.value
        && subcategory.value
        && description
        && maxAge
        && minAge
        && videoFile
        && imageFile)
    && errors.length === 0
    )
    : (
      !!(name
        && partner.value
        && subcategory.value
        && description
        && maxAge
        && minAge
        && videoLink
        && imageFile
        && hasClasses
        && classValue)
    && errors.length === 0
    && ((!isRecurrent.value && specificPeriod) || isRecurrent.value)
    );

  const requiredFieldsErrorsLabel: string[] = useMemo(() => {
    if (isRecordedActivity) {
      return [
        !name ? 'Nome' : '',
        !partner.value ? 'Parceiro' : '',
        !subcategory.value ? 'Categoria' : '',
        !description ? 'Descrição' : '',
        !maxAge ? 'Idade máxima' : '',
        !minAge ? 'Idade mínima' : '',
        !videoFile ? 'Vídeo' : '',
        !imageFile ? 'Imagem' : '',
      ].filter((error) => !!error);
    } else {
      return [
        !name ? 'Nome' : '',
        !partner.value ? 'Parceiro' : '',
        !subcategory.value ? 'Categoria' : '',
        !description ? 'Descrição' : '',
        !maxAge ? 'Idade máxima' : '',
        !minAge ? 'Idade mínima' : '',
        !videoLink ? 'Link Zoom' : '',
        !imageFile ? 'Imagem' : '',
        !hasClasses ? 'Grade de horários' : '',
        (!isRecurrent.value && !specificPeriod) ? 'Período específico (atividade não recorrente)' : '',
        !classValue ? 'Valor da aula' : '',
      ].filter((error) => !!error);
    }
  }, [classValue, description, hasClasses, imageFile, isRecordedActivity, isRecurrent.value, maxAge, minAge, name, partner.value, specificPeriod, subcategory.value, videoFile, videoLink]);

  console.log('requiredFieldsErrorsLabel', renderArray(requiredFieldsErrorsLabel));

  return {
    isSubmitting,
    createActivity,
    updateActivity,
    handleApprovementActivityDecision,
    isFormValid,
    requiredFieldsErrorsLabel,
  };
}
