import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import splitArray from '../../../../utils/splitArray';
import { MappedPartnerType, SelectedStatusType } from '../../types';

interface UsePageSearchesInterface {
  fullPartners: MappedPartnerType[];
  setPartnersSplited: Dispatch<SetStateAction<Array<MappedPartnerType[]>>>;
  setFilteredPartners: Dispatch<SetStateAction<MappedPartnerType[]>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  partnersSplitted: Array<MappedPartnerType[]>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

export default function usePageSearches({
  fullPartners,
  setPartnersSplited,
  setFilteredPartners,
  setCurrentPage,
  partnersSplitted,
  searchTerm,
  setSearchTerm,
}: UsePageSearchesInterface) {
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatusType>('Todos');

  const filterPartners = useCallback(() => {
    const filteredPartners = fullPartners.filter((partner) => {
      const statusFiter = partner.status === selectedStatus || selectedStatus === 'Todos';
      const searchTermFilters = partner.name?.toLowerCase().includes(searchTerm);

      return statusFiter && searchTermFilters;
    });

    const partnersSplittedByFilters = splitArray(filteredPartners);
    setPartnersSplited(partnersSplittedByFilters);
    setFilteredPartners(partnersSplittedByFilters[0]);
  }, [fullPartners, searchTerm, selectedStatus, setFilteredPartners, setPartnersSplited]);

  useEffect(() => {
    filterPartners();
  }, [filterPartners]);

  const handleStatusChange = useCallback((event: SelectedStatusType) => {
    setSelectedStatus(event);
  }, []);

  const handleChangeSearchTerm = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, [setSearchTerm]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
    setFilteredPartners(partnersSplitted[page]);
  }

  return {
    handleStatusChange,
    handleChangeSearchTerm,
    handlePageChange,
    selectedStatus,
  };
}
