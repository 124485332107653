import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../../hooks/useApiCall";
import companiesService from "../../../../services/companiesService";
import cuponsService from "../../../../services/cuponsService";
import splitArray from "../../../../utils/splitArray";
import { CupomFromApiObject } from "../../types";

interface UseLoadCuponsI {
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

interface CompanyFromApiI {
  id: string, nome_fantasia: string, razao_social: string, nome: string, apply_bennefit_method: 'cupom' | 'uniqueId'
}

export default function useLoadCupons({ setCurrentPage }: UseLoadCuponsI) {
  const [isLoading, setIsLoading] = useState(false);
  const [fullCupons, setFullCupons] = useState<CupomFromApiObject[]>([]);
  const [cuponsSplited, setCuponsSplited] = useState<Array<CupomFromApiObject[]>>([]);
  const [filteredCupons, setFilteredCupons] = useState<CupomFromApiObject[]>([]);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<{ value: string, label: string }[]>([]);

  const { apiCall } = useApiCall();

  const loadCupons = useCallback(async () => {
    await apiCall({
      apiToCall: cuponsService.getCuponsList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response) => {
        const cuponsList: CupomFromApiObject[] = response.cupons;
        if (!cuponsList) {
          toast.error(`Não foi possível carregar a lista de cupons`);
          setDoesListApiHasError(true);
          return;
        }
        setFullCupons(cuponsList);

        const splitedArray = splitArray(cuponsList);
        setCuponsSplited(splitedArray);
        setFilteredCupons(splitedArray[0]);
        setCurrentPage(0);
        setDoesListApiHasError(false);
      }
    });

    await apiCall({
      apiToCall: companiesService.getCompaniesList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (response) => {
        if (!response.list) {
          toast.error(`Não foi possível carregar a lista de empresas (${response.erro})`);
          setDoesListApiHasError(true);
          setIsLoading(false);
          return;
        }
        const onlyCompaniesWithCupomMethod = response.list.filter((company: CompanyFromApiI) => company.apply_bennefit_method === 'cupom');
        const mappedCompanies = onlyCompaniesWithCupomMethod.map((company: CompanyFromApiI) => ({
          value: company.id,
          label: company.nome || company.nome_fantasia || company.razao_social,
        }));
        setCompaniesOptions(mappedCompanies);
      }
    })
  }, [apiCall, setCurrentPage])

  useEffect(() => { loadCupons() }, [loadCupons]);

  return {
    isLoading,
    fullCupons,
    cuponsSplited,
    filteredCupons,
    doesListApiHasError,
    loadCupons,
    setCuponsSplited,
    setFilteredCupons,
    companiesOptions,
    setIsLoading,
  }
}
