import { Card } from '../styles';
import NoData from '../../../components/NoData';

interface NpsCardInterface {
  nps: number;
}

export default function NpsCard({
  nps,
}: NpsCardInterface) {
  const hasInfo = !!nps;

  return (
    <Card>
      <div className="card-title">
        <div>Avaliação NPS</div>
        {/* <img src={download} alt="download" title="Baixar relatório excel" /> */}
      </div>

      {!!hasInfo && (
      <div className="card-main">
        <div>
          {nps}
        </div>
        <small>
          É o NPS Kiddlepass
        </small>
      </div>
      )}

      {(!hasInfo) && (
      <NoData
        icon="emptyBox"
        label={(
          <>
            Não há um NPS disponível para consulta
          </>
                    )}
      />
      )}
    </Card>
  );
}
