import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import companiesService from '../../../../../services/companiesService';
import useApiCall from '../../../../../hooks/useApiCall';

type RatingsType = {
  ratings?: number,
}

export default function useRatings() {
  const [ratings, setRatings] = useState<RatingsType>({});
  const [hasRatingsError, setHasRatingsError] = useState(false);

  const { companyId } = useParams();
  const { apiCall } = useApiCall();

  const getRatings = useCallback(async (company: string | null) => {
    await apiCall({
      apiToCall: companiesService.getRatings,
      queryParams: { companyId, associatedCompany: company || undefined },
      actionAfterResponse: (apiResponse) => {
        setRatings(apiResponse);
        setHasRatingsError(false);
      },
      catchAction: () => setHasRatingsError(true),
      catchMessage: 'Não foi possível carregar a informação das avaliações',
    })
  }, [apiCall, companyId]);

  return {
    ratings,
    hasRatingsError,
    getRatings,
  };
}
