import { format } from 'date-fns';
import { useCallback } from 'react';
import ExportXlsx from '../../../../utils/ExportXlsx';
import getCurrentDateWithHyphen from '../../../../utils/getCurrentDateWithHyphen';
import { RatingType, UserFromApi } from '../../types';

interface UseDownloadExcelInterface {
  usersFullData: UserFromApi[]
}

export default function useDownloadExcel({
  usersFullData,
}: UseDownloadExcelInterface) {
  const downloadExcel = useCallback(() => {
    const mappedUsersList = usersFullData?.map((usr) => ({
      Nome: usr.sobrenome ? `${usr.nome} ${usr.sobrenome}` : usr.nome,
      CPF: usr.cpf,
      Celular: usr.celular,
      'E-mail': usr.email,
      'Data de nascimento': usr.data_nascimento || 'Não Informado',
      Gênero: usr.genero || 'Não Informado',
      Status: usr.status || 'Cadastrado',
      Cidade: usr.endereco?.cidade || 'Não informado',
      UF: usr.endereco?.UF || 'Não informado',
      Empresa: usr.empresa || 'B2C',
      Cupom: usr.cupom?.name || 'Não utilizado',
      'Data de cadastro': usr.data_cadastro,
      'Data de ativação': usr.data_ativacao || 'Não Identificado',
      'Criança 1 - Nome': usr.kids[0]?.name || 'Não possui',
      'Criança 1 - Data de nascimento': usr.kids[0]?.birthDate || 'Não possui',
      // 'Criança 1 - Periodos': renderArray(usr.criancas[0]?.periodos) || 'Não possui',
      // 'Criança 1 - Interesses': renderArray(usr.criancas[0]?.areas_interesse) || 'Não possui',
      'Criança 2 - Nome': usr.kids[1]?.name || 'Não possui',
      'Criança 2 - Data de nascimento': usr.kids[1]?.birthDate || 'Não possui',
      // 'Criança 2 - Periodos': renderArray(usr.criancas[1]?.periodos) || 'Não possui',
      // 'Criança 2 - Interesses': renderArray(usr.criancas[1]?.areas_interesse) || 'Não possui',
      'Criança 3 - Nome': usr.kids[2]?.name || 'Não possui',
      'Criança 3 - Data de nascimento': usr.kids[2]?.birthDate || 'Não possui',
      // 'Criança 3 - Periodos': renderArray(usr.criancas[2]?.periodos) || 'Não possui',
      // 'Criança 3 - Interesses': renderArray(usr.criancas[2]?.areas_interesse) || 'Não possui',
    }));
    const currentDate = getCurrentDateWithHyphen();
    ExportXlsx({
      filename: `Usuários Kiddlepass - ${currentDate}`,
      data: mappedUsersList,
    });
  }, [usersFullData]);

  const downloadRatings = useCallback((ratingsArray: RatingType[], userName: string) => {
    console.log(ratingsArray);
    const mappedRatings = ratingsArray.map((rt) => ({
      Nota: rt.nota,
      Data: rt.ratedAt ? format(new Date(rt.ratedAt), 'dd/MM/yyyy') : 'Sem dados da data'
    }))
    const currentDate = getCurrentDateWithHyphen();

    ExportXlsx({
      filename: `Notas dadas por ${userName} até ${currentDate}`,
      data: mappedRatings,
    });
  }, []);

  return { downloadExcel, downloadRatings };
}
