import { Col, Container, Row } from 'react-bootstrap';
import {
  Card,
} from './styles';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import trash from '../../../../assets/images/icons/trash.svg';

interface ListInterface {
  filteredList: string[];
  handleOpenDeleteModal: (date: string) => void;
}

export default function List({
  filteredList,
  handleOpenDeleteModal,
}: ListInterface) {
  return (
    <Container>
      <Row xs={2} md={4} lg={5}>
        {filteredList?.map((date) => {
          return (
            <Col key={date}>
            <OpacityAnimation delay={0.1}>
              <Card>
                <div className="info">
                  <div className="card-title">
                    <strong>{date}</strong>
                  </div>
                </div>
                <div className="actions">
                  <button
                      type="button"
                      onClick={() => handleOpenDeleteModal(date)}
                    >
                      <img src={trash} alt="" title="Remover cupom" />
                  </button>
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
          )
        })}
      </Row>
    </Container>
  );
}
