/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import chartPie from '../../../assets/images/icons/chartPie.svg';
import comments from '../../../assets/images/icons/comments.svg';
import lock from '../../../assets/images/icons/lock.svg';
import { SidebarInterface } from '../interface';
import { ActiveItem, MenuItem } from '../styles';

export default function AnalyticSidebar({ active }: SidebarInterface) {
  return (
    <>
      {active === 'Dashboard'
        ? (
          <ActiveItem>
            <Link to="/">
              <MenuItem>
                <img src={chartPie} alt="dashboard" />
                Dashboard
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/">
            <MenuItem>
              <img src={chartPie} alt="dashboard" />
              Dashboard
            </MenuItem>
          </Link>
        )}

{/* {active === 'Forum'
        ? (
          <ActiveItem>
            <Link to="/forum">
              <MenuItem>
                <img src={comments} alt="forum" />
                Forum
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forum">
            <MenuItem>
              <img src={comments} alt="forum" />
              Forum
            </MenuItem>
          </Link>
        )} */}

{active === 'ForumInternal'
        ? (
          <ActiveItem>
            <Link to="/forumInternal">
              <MenuItem>
                <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
                Forum interno
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/forumInternal">
            <MenuItem>
              <img src={comments} alt="forum (apenas para time interno Kiddle Pass)" />
              Forum interno
            </MenuItem>
          </Link>
        )}


      {active === 'ChangePassword'
        ? (
          <ActiveItem>
            <Link to="/financial">
              <MenuItem>
                <img src={lock} alt="lock" />
                Alterar senha
              </MenuItem>
            </Link>
          </ActiveItem>
        )
        : (
          <Link to="/changePassword">
            <MenuItem>
              <img src={lock} alt="lock" />
              Alterar senha
            </MenuItem>
          </Link>
        )}
    </>
  );
}

AnalyticSidebar.propTypes = {
  active: PropTypes.string.isRequired,
};
