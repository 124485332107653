import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import partnersService from '../../../../services/partnersService';
import { PartnerActivityFromApiType, PartnerActivitiesBeingShowType } from  '../../types';
import useApiCall from '../../../../hooks/useApiCall';

interface HandleOpenPartnerActivitiesModalInterface {
  id: string;
  name: string;
}

export default function useShowPartnersActivities() {
  const [partnerActivitiesBeingShow, setPartnerActivitiesBeingShow] = useState<PartnerActivitiesBeingShowType>({} as PartnerActivitiesBeingShowType);
  const [partnerActivitiesModalShow, setPartnerActivitiesModalShow] = useState(false);
  const [isPartnerActivitiesLoading, setIsPartnerActivitiesLoading] = useState(false);

  const { apiCall } = useApiCall();

  const handleOpenPartnerActivitiesModal = useCallback(async ({
    id, name,
  }: HandleOpenPartnerActivitiesModalInterface) => {
    const categoryLiterals: { [key: string]: string } = {
      'Atividades Educacionais': 'Atividades Educacionais',
      'Atividade Educacional': 'Atividades Educacionais',
      Educação: 'Atividades Educacionais',
      Entretenimento: 'Entretenimento',
      Lazer: 'Entretenimento',
      Cultura: 'Entretenimento',
      'Atividade Física': 'Atividades Físicas',
      'Atividades Físicas': 'Atividades Físicas',
    };
    await apiCall({
      apiToCall: partnersService.getPartnerDetails,
      queryParams: { partnerId: id },
      onStartLoad: () => {
        setIsPartnerActivitiesLoading(true);
        setPartnerActivitiesBeingShow({ name, activities: [] });
      },
      onEndLoad: () => setIsPartnerActivitiesLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error(`Não foi possível encontrar as atividades do parceiro ${name}`);
          return;
        }
        if (apiResponse.activities.length === 0) {
          toast.error(`O parceiro ${name} não possui nenhuma atividade cadastrada`);
          return;
        }
        const partnerActivitiesFromApi: PartnerActivityFromApiType[] = apiResponse.activities;

        const partnerActivities = partnerActivitiesFromApi.map((activity) => ({
          id: activity.id,
          name: activity.nome,
          status: (activity.ativo === 'false' || activity.ativo === false) ? 'Inativo' : 'Ativo',
          generalData: {
            status: (activity.ativo === 'false' || activity.ativo === false) ? 'Inativo' : 'Ativo',
            category: categoryLiterals[activity.categoria],
            subcategory: activity.subcategoria,
            ageRange: [activity.minima, activity.maxima],
            kind: activity.videoTipo === 'Interno' ? 'Aula em vídeo' : 'Aula ao vivo',
          },
        }));

        setPartnerActivitiesBeingShow({ name, activities: partnerActivities });
        setPartnerActivitiesModalShow(true);
      },
      catchMessage: 'Não foi possível encontrar as atividades do parceiro',
    })
  }, [apiCall]);

  return {
    partnerActivitiesBeingShow,
    partnerActivitiesModalShow,
    isPartnerActivitiesLoading,
    handleOpenPartnerActivitiesModal,
    setPartnerActivitiesModalShow,
  };
}
