import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import companiesService from '../../../../services/companiesService';
import financialService from '../../../../services/financialService';
import { CompanyDetailsFromApi, SelectedCompanyType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useCompanyData() {
  const [isCompanyDataLoading, setIsCompanyDataLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompanyType>({} as SelectedCompanyType);
  const [companyData, setCompanyData] = useState<CompanyDetailsFromApi>({} as CompanyDetailsFromApi);
  const [companyDataError, setCompanyDataError] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [agreedAmounth, setAgreedAmounth] = useState('');

  const { apiCall } = useApiCall();

  const reloadCompanyData = useCallback(async () => {
    try {
      setIsCompanyDataLoading(true);
      await apiCall({
        apiToCall: financialService.getCompanyData,
        queryParams: { companyId: selectedCompany.value },
        actionAfterResponse: (apiResponse) => {
          setCompanyData(apiResponse.details);
          setCompanyDataError(false);
        }
      });

      await apiCall({
        apiToCall: companiesService.getCompanyDetails,
        queryParams: { id: selectedCompany.value },
        actionAfterResponse: (apiResponse) => {
          if (apiResponse.erro) {
            toast.error('Não foi possível recuperar os dados da empresa');
            setCompanyDataError(true);
          }
          setStartDate(apiResponse.details.inicio);
          setAgreedAmounth(apiResponse.details.fee_mensal);
        }
      })
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros dessa empresa (${error})`);
      setCompanyDataError(true);
    } finally {
      setIsCompanyDataLoading(false);
    }
  }, [apiCall, selectedCompany.value]);

  const handleSelectedCompanyChange = useCallback(async (company: SelectedCompanyType) => {
    try {
      setIsCompanyDataLoading(true);
      setSelectedCompany(company);

      await apiCall({
        apiToCall: financialService.getCompanyData,
        queryParams: { companyId: company.value },
        actionAfterResponse: (apiResponse) => {
          setCompanyData(apiResponse.details);
        }
      });

      await apiCall({
        apiToCall: companiesService.getCompanyDetails,
        queryParams: { companyId: company.value },
        actionAfterResponse: (apiResponse) => {
          if (apiResponse.erro) {
            toast.error('Não foi possível recuperar os dados da empresa');
            setCompanyDataError(true);
          }
          setCompanyDataError(false);
          setStartDate(apiResponse.details.inicio);
          setAgreedAmounth(apiResponse.details.fee_mensal);
        }
      })
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros dessa empresa (${error})`);
      setCompanyDataError(true);
    } finally {
      setIsCompanyDataLoading(false);
    }
  }, [apiCall]);

  const handleTryGetCompanyDataAgain = useCallback(async () => {
    try {
      setIsCompanyDataLoading(true);

      await apiCall({
        apiToCall: financialService.getCompanyData,
        queryParams: { companyId: selectedCompany.value },
        actionAfterResponse: (apiResponse) => {
          setCompanyData(apiResponse.details);
        }
      })

      await apiCall({
        apiToCall: companiesService.getCompanyDetails,
        queryParams: { id: selectedCompany.value },
        actionAfterResponse: (apiResponse) => {
          if (apiResponse.erro) {
            toast.error('Não foi possível recuperar os dados da empresa');
            setCompanyDataError(true);
            return;
          }
          setStartDate(apiResponse.details.inicio);
          setAgreedAmounth(apiResponse.details.fee_mensal);
          setCompanyDataError(false);
        }
      })
    } catch (error) {
      toast.error(`Não foi possível carregar os dados financeiros dessa empresa (${error})`);
      setCompanyDataError(true);
    } finally {
      setIsCompanyDataLoading(false);
    }
  }, [apiCall, selectedCompany.value]);

  return {
    isCompanyDataLoading,
    selectedCompany,
    companyData,
    companyDataError,
    reloadCompanyData,
    handleSelectedCompanyChange,
    handleTryGetCompanyDataAgain,
    startDate,
    agreedAmounth,
  };
}
