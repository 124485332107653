import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import usersService from '../../../../services/usersService';
import { UseDashboardDataInterface } from '../interfaces';
import { RatingsType } from '../../types';
import useApiCall from '../../../../hooks/useApiCall';

export default function useRatings({ setHasError }: UseDashboardDataInterface) {
  const [ratings, setRatings] = useState<RatingsType>({} as RatingsType);
  const [nps, setNps] = useState(0);

  const { apiCall } = useApiCall();

  const getUsersRatings = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersRatings,
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.erro) {
          toast.error('Não foi possível recuperar as avaliações NPS', {
            toastId: 'getUsersRatingsDashboardFail',
          });
          return;
        }
        setRatings({
          average: apiResponse.averageNPS,
          details: apiResponse.list,
        });
        setNps(apiResponse.NPS);
      },
      catchAction: () => setHasError(true),
      catchMessage: 'Não foi possível recuperar as avaliações NPS',
    })
  }, [apiCall, setHasError]);

  return {
    nps,
    ratings,
    getUsersRatings,
  };
}
