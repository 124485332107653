import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface KidsServiceInterface {
  name?: string;
  reqBody?: ReqBodyType;
  token: string;
  kidId?: string;
  userId?: string;
}

class KidsService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  createKid = async ({
    userId,
    reqBody,
    token,
  }: KidsServiceInterface) => {
    return this.httpClient.post({
        path: `/kids/${userId}`,
        reqBody,
        token,
      });
  }

  updateKid = async ({
    userId,
    kidId,
    reqBody,
    token,
  }: KidsServiceInterface) => {
    return this.httpClient.put({
        path: `/kids/${userId}?kidId=${kidId}`,
        reqBody,
        token,
      });
  }

  deleteKid = async ({
    userId,
    kidId,
    token,
  }: KidsServiceInterface) => {
    return this.httpClient.delete({
        path: `/kids/${userId}?kidId=${kidId}`,
        token,
      });
  }
}

export default new KidsService();
