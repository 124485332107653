import HttpClient from './utils/HttpClient';
import { pathName } from '../pathName';
import { ReqBodyType } from './types/reqBody';

interface FinancialServiceInterface {
  permission?: string;
  partnerId?: string;
  companyId?: string;
  reqBody?: ReqBodyType;
  token: string;
}

class FinancialService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

   getPartnerData = async({
    partnerId,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.get({
      path: `/partners/financial/${partnerId}`,
      token,
    });
  }

   getCompanyData = async({
    companyId,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.get({
      path: `/companies/financial/${companyId}`,
      token,
    });
  }

   uploadCompanyNf = async ({
    companyId,
    reqBody,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.post({
      path: `/companies/financial/${companyId}`,
      reqBody,
      contentType: 'multipart/form-data',
      token,
    });
  }

   uploadPartnerPaymentVoucher = async ({
    partnerId,
    reqBody,
    token,
  }: FinancialServiceInterface) => {
    return this.httpClient.post({
      path: `/partners/financial/${partnerId}`,
      reqBody,
      contentType: 'multipart/form-data',
      token,
    });
  }
}

export default new FinancialService();
