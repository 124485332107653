import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Container } from './styles';
import { Dispatch, SetStateAction } from 'react';
import { CurrrentMonthBeingShowType, CurrrentYearBeingShowType } from '../../../../types';

interface HeaderInterface {
  currentMonthBeingShow: CurrrentMonthBeingShowType;
  currentYearBeingShow: CurrrentYearBeingShowType;
  handleDateModalShow: Dispatch<SetStateAction<boolean>>;
  isNfUploaded: boolean;
  nfDownloadLink?: string;
  isPaymentVoucherUploaded: boolean;
  paymentVoucherLink?: string;
  handleNfModalShow: Dispatch<SetStateAction<boolean>>;
}

export default function Header({
  currentMonthBeingShow,
  currentYearBeingShow,
  handleDateModalShow,
  isNfUploaded,
  nfDownloadLink,
  isPaymentVoucherUploaded,
  paymentVoucherLink,
  handleNfModalShow,
}: HeaderInterface) {
  const monthsMap = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.1, type: 'tween', stiffness: 10 }}
    >
      <Container>
        <strong>
          {monthsMap[currentMonthBeingShow]}
          {' '}
          de
          {' '}
          {currentYearBeingShow}
        </strong>
        <div className="actionButtons">
          <button type="button" onClick={() => handleDateModalShow(true)}>
            Alterar mês
          </button>
          {!isNfUploaded && (
            <button type="button" onClick={() => handleNfModalShow(true)}>
              Upload NF-e
            </button>
          )}
          {isNfUploaded && (
            <a href={nfDownloadLink} target="_blank" download rel="noreferrer">
              Baixar NF-e enviada
            </a>
          )}
          {isPaymentVoucherUploaded && (
            <a href={paymentVoucherLink} target="_blank" download rel="noreferrer">
              Baixar comprovante de pagamento
            </a>
          )}
        </div>
      </Container>
    </motion.div>
  );
}

Header.propTypes = {
  currentMonthBeingShow: PropTypes.number.isRequired,
  currentYearBeingShow: PropTypes.number.isRequired,
  handleDateModalShow: PropTypes.func.isRequired,
  isNfUploaded: PropTypes.bool.isRequired,
  isPaymentVoucherUploaded: PropTypes.bool.isRequired,
  nfDownloadLink: PropTypes.string.isRequired,
  paymentVoucherLink: PropTypes.string.isRequired,
  handleNfModalShow: PropTypes.func.isRequired,
};
