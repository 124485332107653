import { ChangeEvent } from 'react';
import FormGroup from '../../../../../components/FormGroup';
import Input from '../../../../../components/Input';
import { Container as StyledContainer, Group, AsideContainer } from '../styles';
import { GetErrorMessageByFieldNameType } from '../../../../../hooks/useErrors';

interface ResponsibleDataCardInterface {
  responsibleName: string;
  handleResponsibleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  responsibleRole: string;
  handleResponsibleRoleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  responsibleCellphone: string;
  handleResponsibleCellphoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  responsibleEmail: string;
  handleResponsibleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  getErrorMessageByFieldName: GetErrorMessageByFieldNameType;
}

export default function ResponsibleDataCard({
  responsibleName,
  handleResponsibleNameChange,
  responsibleRole,
  handleResponsibleRoleChange,
  responsibleCellphone,
  handleResponsibleCellphoneChange,
  responsibleEmail,
  handleResponsibleEmailChange,
  getErrorMessageByFieldName,
}: ResponsibleDataCardInterface) {
  return (
    <StyledContainer>
      <div className="card-title">
        Dados do responsável
      </div>
      <AsideContainer>
        <Group>
          <div className="title">
            Nome *
          </div>
          <FormGroup error={getErrorMessageByFieldName('responsibleName')}>
            <Input
              value={responsibleName}
              onChange={handleResponsibleNameChange}
              error={getErrorMessageByFieldName('responsibleName')}
              placeholder="Informe o nome do responsável"
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            Cargo
          </div>
          <FormGroup error={getErrorMessageByFieldName('responsibleRole')}>
            <Input
              value={responsibleRole}
              onChange={handleResponsibleRoleChange}
              error={getErrorMessageByFieldName('responsibleRole')}
              placeholder="Informe o cargo do responsável"
            />
          </FormGroup>
        </Group>
      </AsideContainer>

      <AsideContainer>
        <Group>
          <div className="title">
            Telefone *
          </div>
          <FormGroup error={getErrorMessageByFieldName('responsibleCellphone')}>
            <Input
              value={responsibleCellphone}
              onChange={handleResponsibleCellphoneChange}
              error={getErrorMessageByFieldName('responsibleCellphone')}
              placeholder="Informe o telefone do responsável"
              maxLength={15}
            />
          </FormGroup>
        </Group>
        <Group>
          <div className="title">
            E-mail *
          </div>
          <FormGroup error={getErrorMessageByFieldName('responsibleEmail')}>
            <Input
              value={responsibleEmail}
              onChange={handleResponsibleEmailChange}
              error={getErrorMessageByFieldName('responsibleEmail')}
              placeholder="Informe o e-mail do responsável"
            />
          </FormGroup>
        </Group>
      </AsideContainer>
    </StyledContainer>
  );
}
